import { ApolloProvider } from '@apollo/client';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { globalAppErrorAtom } from 'atoms';
import { PrintProvider } from 'context';
import { useAuth, useFactoryApolloClient, useMessage } from 'hooks';

import { AuthProvider } from '../auth-context/auth-context';

export const AppProvider: FunctionComponent = ({ children }) => {
  const { signOut } = useAuth();
  const globalAppError = useAtomValue(globalAppErrorAtom);
  const { redirectWithMessage } = useMessage();
  const { t } = useTranslation();

  useEffect(() => {
    if (globalAppError) {
      if (
        globalAppError.graphQLErrors?.find(
          (item) => item?.extensions?.code === 'UNAUTHENTICATED'
        )
      ) {
        signOut();
      } else {
        redirectWithMessage({
          variant: 'negative',
          title: t('notifications.error'),
          body: globalAppError.message,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAppError]);

  const apolloClient = useFactoryApolloClient();
  
  return (
    <ApolloProvider client={apolloClient}>
      <PrintProvider >
        <AuthProvider>{children}</AuthProvider>
      </PrintProvider>
    </ApolloProvider>
  );
};
