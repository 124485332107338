import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import Table, { TableHeader, TableBody } from '@ingka/table';
import Text from '@ingka/text';

import { Activity, CircularItemHistoryItem } from 'types';
import { formatDate } from 'utils';

import { useItemDetails } from '../../hooks/use-item-details/use-item-details';

import { ActivityHoverBox } from './activity-hover-box';
import { GradeChangeHoverBox } from './grade-change-hover-box';
import styles from './item-history.module.scss';
import { SaleHoverBox } from './sale-hover-box';

interface ItemHistoryProps {
  circularItemId: string;
  onEditActivityClicked: (activity: Activity) => void;
  onDeleteActivityClicked: (activity: Activity) => void;
}

export const ItemHistory: FC<ItemHistoryProps> = ({
  onEditActivityClicked,
  onDeleteActivityClicked,
  circularItemId
}) => {
  const { t } = useTranslation();
  const [viewAll, setViewAll] = useState<boolean>(false);
  const { itemHistory } = useItemDetails(circularItemId);

  const historyMapper = (item: CircularItemHistoryItem) => {
    let content: React.ReactNode;
    switch (item._type) {
      case 'sale':
        content = <SaleHoverBox {...item} />;
        break;

      case 'gradeChange':
        content = <GradeChangeHoverBox {...item} />;
        break;

      case 'activity':
        content = <ActivityHoverBox {...item} />;
        break;
    }

    return (
      <tr key={`history-item-${item.id}`}>
        <td className={styles.dateColumn}>
          {formatDate(item.createdTimestamp)}
        </td>
        <td className={styles.username}>{item?.userName || item.userId}</td>
        <td>
          <Text
            className={styles.activityType}
            data-cy={'item-history-row-history-type'}
          >
            {item.historyType}
          </Text>
          <div className={styles.displayHoverModal}>
            <div className={styles.hoverModal}>
              <div className={styles.box}>{content}</div>
            </div>
          </div>
        </td>
        <td>
          {item._type === 'activity' && (
            <div className={styles.btnGroup}>
              <Button data-cy="item-activity-edit" iconOnly ssrIcon={pencil} onClick={() => onEditActivityClicked(item)} xsmallIconOnly type='tertiary'/>
              <Button data-cy="item-activity-delete" iconOnly ssrIcon={trashCan} onClick={() => onDeleteActivityClicked(item)} xsmallIconOnly type='tertiary'/>
            </div>
          )}
        </td>
      </tr>
    );
  };

  return (
    <section className={styles.itemHistory}>
      <div className={styles.historyHeader}>
        <Text tagName="h3" headingSize="m" className={styles.subHeading}>
          {t('history')}
        </Text>
        {itemHistory.length ? (
          <Text onClick={() => setViewAll(!viewAll)} className={styles.viewAll}>
            {viewAll ? <>{t('showLess')}</> : <>{t('viewAll')}</>}
          </Text>
        ) : null}
      </div>
      {itemHistory.length ? (
        <Table className={styles.table} data-cy={'details-view-history-table'}>
          <TableHeader className={styles.tableHeader}>
            <tr>
              <th className={styles.tableHeaderItem}>{t('time')}</th>
              <th className={styles.tableHeaderItem}>{t('user')}</th>
              <th className={styles.tableHeaderItem}>{t('what')}</th>
              <th>{/* Intentionally blank */}</th>
            </tr>
          </TableHeader>
          <TableBody data-cy="details-view-history-table-body">
            {viewAll
              ? itemHistory.map(historyMapper)
              : itemHistory.slice(0, 6).map(historyMapper)}
          </TableBody>
        </Table>
      ) : (
        <Text
          className={styles.historyEmpty}
          data-cy={'details-view-empty-history'}
        >
          {t('historyEmpty')}
        </Text>
      )}
    </section>
  );
};
