import { useMutation, useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { globalAppErrorAtom, globalLoadingAtom } from 'atoms';
import { DEFAULT_PAGE_SIZE } from 'config';
import { usePageParams } from 'hooks';
import {
  mutationRemoveShipment,
  mutationRemoveShipmentItems,
  mutationShipShipment,
  queryShipmentWithItems,
} from 'services';
import { CircularItem, QueryShipmentArgs } from 'types';
import { QueryShipmentResult } from 'types/query-order-result';
import { groupBy } from 'utils';

import { useShipmentStock } from '../../shipment-stock/hooks/use-shipment-stock';
import { ShipmentDetailsPageProps } from '../shipment-details-page/shipment-details-page';

export const useShipmentView = () => {
  const [removeShipmentMutation] = useMutation(mutationRemoveShipment);
  const [removeShipmentItemsMutation] = useMutation(
    mutationRemoveShipmentItems
  );
  const [shipShipmentMutation] = useMutation(mutationShipShipment);
  const { skip, searchTerm } = usePageParams();
  const { refetch } = useShipmentStock({
    skip,
    limit: DEFAULT_PAGE_SIZE,
    searchTerm,
  });

  const [groupedLines, setGroupedLines] = useState<
    Record<string, CircularItem[]>
  >({});
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const { id } = useParams<ShipmentDetailsPageProps>();
  const {
    loading,
    error,
    data,
    refetch: refetchShipment,
  } = useQuery<QueryShipmentResult, QueryShipmentArgs>(queryShipmentWithItems, {
    variables: {
      id: id,
    },
  });

  const shipmentId = useMemo(() => data?.shipment.id, [data?.shipment.id]);
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const history = useHistory();

  useEffect(() => {
    setGlobalLoading(loading);
  }, [loading, setGlobalLoading]);

  useEffect(() => {
    setGlobalAppError(error);
  }, [error, setGlobalAppError]);

  useEffect(() => {
    if (data?.shipment) {
      setTotalItemsCount(data?.shipment?.circularItems.length);
      setGroupedLines(
        groupBy(
          data?.shipment?.circularItems,
          (i) => i.businessStructure?.productAreaName || 'no range'
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.shipment?.circularItems]);

  const removeShipment = async (id: string) => {
    try {
      await removeShipmentMutation({
        variables: {
          shipmentId: id,
        },
      });
      refetch();
      history.push('/shipments', {
        message: {
          title: t('notifications.delete.success', {
            type: t('shipment.shipment'),
          }),
          variant: 'positive',
        },
      });
    } catch (error) {
      console.error(error);
      history.replace(history.location.pathname, {
        message: {
          title: t('notifications.delete.error'),
          variant: 'negative',
        },
      });
    }
  };

  const removeShipmentItems = async (itemIds: string[]) => {
    if (shipmentId) {
      await removeShipmentItemsMutation({
        variables: {
          input: {
            shipmentId: shipmentId,
            circularItemIds: itemIds,
          },
        },
      });
      refetchShipment();
    }
    return;
  };

  const shipShipment = async () => {
    if (shipmentId) {
      try {
        await shipShipmentMutation({
          variables: {
            shipmentId: shipmentId,
          },
        });

        refetchShipment();
      } catch (e) {
        history.replace(history.location.pathname, {
          message: {
            title: t('notifications.shipment.shipShipment'),
            variant: 'negative',
          },
        });
      }
    }
    return;
  };

  return {
    removeShipment,
    removeShipmentItems,
    shipment: data?.shipment,
    groupedLines,
    totalItemsCount,
    shipShipment,
  };
};
