import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { selectedItemsClearAtom } from 'atoms';
import { ItemBulkMenu, ShipmentDetailsView } from 'components';
import { usePrint, useSelect, useUpdateShipment } from 'hooks';
import { CircularItem } from 'types';

import { useShipmentView } from '../hooks/use-shipment-view';
import { UpdateShipmentDetailsFormValues } from '../shipment-details-form/shipment-details-form';

export interface ShipmentDetailsPageProps {
  id: string;
}

export const ShipmentDetailsPage: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { selectedItems } = useSelect();
  const [printItems, setPrintItems] = useState<string[]>();
  const { bulkPrint } = usePrint();
  const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);
  const { updateShipment } = useUpdateShipment();

  const {
    shipment,
    groupedLines,
    totalItemsCount,
    removeShipment,
    removeShipmentItems,
    shipShipment,
  } = useShipmentView();

  const handleBulkPrint = async (printItems: string[]) => {
    bulkPrint(printItems);
    setPrintItems(undefined);
    clearSelectedItems();
  };

  const onSubmitShipmentDetails = async (
    values: UpdateShipmentDetailsFormValues
  ) => {
    if (!shipment?.id) {
      return;
    }

    await updateShipment(shipment.id, {
      carrier: values.carrier,
      comment: values.comment,
      euroPalletCount: values.euroPalletCount,
      longPalletCount: values.longPalletCount,
    });

    history.push(`/shipments/${shipment.id}`, {
      message: {
        title: t('sourcedBatch.updateSuccess'),
        variant: 'positive',
      },
    });
  };

  useEffect(() => {
    if (printItems) {
      handleBulkPrint(printItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printItems]);

  const handleRemoveShipmentItems = () => {
    const itemIds = selectedItems.map((item: CircularItem) => item.id);
    removeShipmentItems(itemIds);
  };

  return shipment ? (
    <>
      <ItemBulkMenu
        menuItemKeys={['activity', 'print', 'status', 'remove', 'grade']}
        setPrintItems={setPrintItems}
        handleRemoveItems={handleRemoveShipmentItems}
      />
      <ShipmentDetailsView
        shipment={shipment}
        editPage={!!location.pathname.endsWith('/edit')}
        groupedLines={groupedLines}
        totalItemsCount={totalItemsCount}
        removeShipment={removeShipment}
        removeShipmentItems={removeShipmentItems}
        shipShipment={shipShipment}
        onUpdateShipmentDetails={onSubmitShipmentDetails}
      />
    </>
  ) : null;
};
