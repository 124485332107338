import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, ReactNode } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { tokenAtom, userAtom } from 'atoms';
import { useAuth } from 'hooks';

export const PrivateRoute: FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const { Guard } = useAuth();
  const token = useAtomValue(tokenAtom);
  const user = useAtomValue(userAtom);

  const render = ({ location }: RouteComponentProps): ReactNode =>
    token && !user ? null : user ? children : <Guard location={location} />;

  return <Route {...rest} render={render} />;
};
