import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Search from '@ingka/search';
import Text from '@ingka/text';

import {
  BatchList,
  FilterRow,
  PageContent,
  PageHeader,
  Pagination,
  SelectedFilterRow,
} from 'components';
import { usePageParams, useSourceFilter } from 'hooks';
import { SearchData, SourcedBatch, SourcedBatchOrderBy } from 'types';

import styles from './batch-stock-view.module.scss';

interface BatchStockViewProps {
  batchItems: SourcedBatch[];
  pageMessage?: string;
  limit: number;
  skip: number;
  total?: number;
  orderBy: SourcedBatchOrderBy;
  error: boolean;
  loading: boolean;
}

export const BatchStockView: FC<BatchStockViewProps> = ({
  batchItems,
  skip,
  limit,
  total,
  orderBy,
  error,
  loading,
}: BatchStockViewProps) => {
  const { t } = useTranslation();
  const [pageMessage, setPageMessage] = useState<string>(t('batchStockEmpty'));

  const { searchTerm, updatePageSearch } = usePageParams();
  const {
    currentFilterValues,
    setSourcedBatchFilterValue,
    getSelectedFilterOptions,
  } = useSourceFilter();
  const [searchInput, setSearchInput] = useState<string>(searchTerm || '');

  useEffect(() => {
    let nextMessage: string = '';
    if (total) {
      nextMessage = t('batchStockCount.default', {
        pageSize: `${skip + 1}-${Math.min(skip + limit, total)}`,
        total,
      });
    } else {
      nextMessage = t('batchStockEmpty');
    }
    setPageMessage(nextMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, skip, limit]);

  const onFilterChange = (filterInput: string | null) => {
    setSourcedBatchFilterValue(filterInput);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const onSearchSubmit = (_e: Event, { value = '' }: SearchData) => {
    updatePageSearch(value);
  };

  const onSearchClear = () => {
    setSearchInput('');
    updatePageSearch('');
  };

  return (
    <PageContent>
      <PageHeader className={styles.root__header}>
        <div className={styles.root__header_title}>
          <Text tagName="h1" headingSize="m">
            {t('sourcedBatches')}
          </Text>
          <Text bodySize="s">{pageMessage}</Text>
        </div>
        <div className={styles.root__header_searchwrapper}>
          <Search
            id="search"
            className={styles.root__header_searchbar}
            ariaLabel={t('search')}
            placeholder={t('search')}
            clearBtnText={t('clear')}
            onSearch={onSearchSubmit}
            onClear={onSearchClear}
            onChange={onSearchChange}
            value={searchInput}
            autoFocus
          />
          <FilterRow
            filters={currentFilterValues}
            onFilterChange={(_, value) => onFilterChange(value)}
          />
        </div>
        <div className={styles.root__header_selectedfilters}>
          <SelectedFilterRow
            filterOptions={getSelectedFilterOptions()}
            onRemoveFilterClick={() => onFilterChange(null)}
          />
        </div>
        <Text bodySize="s" className={styles.root__header_count}>
          {pageMessage}
        </Text>
      </PageHeader>
      <BatchList
        items={batchItems}
        error={error}
        loading={loading}
        orderBy={orderBy}
      />
      <Pagination hide={!batchItems?.length} />
    </PageContent>
  );
};
