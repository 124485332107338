import { useQuery } from '@apollo/client';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { globalLoadingAtom, globalAppErrorAtom } from 'atoms';
import { querySourcedBatch } from 'services';
import { QuerySourcedBatchArgs, QuerySourcedBatchResult } from 'types';

import { SourcedBatchDetailsPageProps } from '../sourced-batch-details-page/sourced-batch-details-page';

export const useSourcedBatch = () => {
  const { id } = useParams<SourcedBatchDetailsPageProps>();
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);

  const { loading, error, data } = useQuery<
    QuerySourcedBatchResult,
    QuerySourcedBatchArgs
  >(querySourcedBatch, {
    variables: {
      id: id,
    }
  });

  useEffect(() => {
    setGlobalLoading(loading);
  }, [loading, setGlobalLoading]);

  useEffect(() => {
    setGlobalAppError(error);
  }, [error, setGlobalAppError]);

  return {
    sourcedBatch: data?.sourcedBatch,
  };
};
