import { useMutation } from '@apollo/client';

import { mutationUpdateSourcedBatch } from 'services';

export function useUpdateSourcedBatch() {
  const [updateSourcedBatchMutation] = useMutation(mutationUpdateSourcedBatch);

  const updateSourcedBatch = async (
    id: string,
    durationInMinutes: number | null,
    comment: string | null
  ) => {
    try {
      await updateSourcedBatchMutation({
        variables: {
          id,
          input: {
            comment,
            durationInMinutes,
          },
        },
        update: (cache, { data }) => {
          if (!data) {
            return;
          }
          const updateSourcedBatch = data.updateSourcedBatch;

          // Update cache for sourcedBatch
          cache.modify({
            id: cache.identify({
              ...updateSourcedBatch,
            }),
            fields: {
              comment: () => updateSourcedBatch.comment,
              durationInMinutes: () => updateSourcedBatch.durationInMinutes,
            },
          });
        },
      });

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  return { updateSourcedBatch };
}
