import { createContext, FunctionComponent } from 'react';

import { Settings } from '../../constants';

import usePrinterProvider from './print-context-provider';
import usePrinterProviderMock from './print-context-provider-mock';

export interface PrintContextValue {
  getPrinterMethod: () => Promise<((zpl: string) => void) | undefined>;
}

export const defaultPrintContextValue: PrintContextValue = {
  getPrinterMethod: async () => {
    return undefined;
  },
};

export const PrintContext = createContext<PrintContextValue>(
  defaultPrintContextValue
);

export const PrintProvider: FunctionComponent = ({ children }) => {
  const printerProvider = usePrinterProvider();
  const printerProviderMock = usePrinterProviderMock();
  const mockPrinter = Settings.mockPrinter;
  return (
    <PrintContext.Provider value={{ getPrinterMethod: mockPrinter ? printerProviderMock : printerProvider }}>
      {children}
    </PrintContext.Provider>
  );
};
