import { memo, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Footer, Header, Message, SecondaryMenu } from 'components';
import { FactoryComponentProps } from 'types';

import styles from './layout.module.scss';

export interface LayoutProps extends FactoryComponentProps {
  contentClassName?: string;
}

export const Layout: FunctionComponent<LayoutProps> = memo(
  ({ children, className = '', contentClassName = '', ...rest }) => {
    const { t } = useTranslation();
    const { state } = useLocation();

    useEffect(() => {
      // Clear state to have lingering messages
      window.history.replaceState({}, '');
    }, [state]);

    return (
      <div className={`${styles.container} ${className}`}>
        <Header title={t('applicationName')}>
          <SecondaryMenu />
        </Header>
        <main className={`${styles.content} ${contentClassName}`}>
          <Message>{children}</Message>
        </main>
        <Footer />
        <div id="printContainer" className={styles.printContainer} />
      </div>
    );
  }
);

export default Layout;
