import { FormikHelpers, useFormik } from 'formik';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { createItemSchema } from 'yup-schemas/yup-schemas';

import Accordion, { AccordionItem } from '@ingka/accordion';
import AspectRatioImage from '@ingka/aspect-ratio-image';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Text from '@ingka/text';
import TextArea from '@ingka/text-area';

import { globalLoadingAtom, imageSrcAtom } from 'atoms';
import {
  Columns,
  DetailsForm,
  DetailsFormInputs,
  PageContent,
  PageHeader,
  Row,
  UploadImage,
} from 'components';
import {
  AssignToBatch,
  AssignToBatchFormValues,
} from 'components/_form/assign-to-batch/assign-to-batch';
import { useFormContext } from 'hooks';

import styles from './create-item-view.module.scss';

export interface CreateItemViewProps {
  onSubmit: (
    values: CreateItemFormValues,
    formikHelpers: FormikHelpers<CreateItemFormValues>
  ) => void | Promise<any>;
  onCancel?: MouseEventHandler<Element>;
  setPrintDialogueResponse: (value: boolean) => void;
}

/** Simple mixin interface that has all of the props necessary to describe a batch of
 * newly-created objects of the same type.
 */
export interface CreateItemFormValues
  extends DetailsFormInputs,
    AssignToBatchFormValues {
  batchType: 'new' | 'existing';
  durationInMinutes?: number;
  comment?: string;
}

export const CreateItemView: FunctionComponent<CreateItemViewProps> = memo(
  ({ onSubmit, onCancel, setPrintDialogueResponse }) => {
    const {
      values,
      handleBlur,
      handleChange,
      submitForm,
      setFieldValue,
      setValues,
      setSubmitting,
      isValid,
      dirty,
      errors,
    } = useFormik<CreateItemFormValues>({
      initialValues: { batchType: 'new' },
      onSubmit,
      validationSchema: createItemSchema,
    });

    const { t } = useTranslation();
    const globalLoading = useAtomValue(globalLoadingAtom);
    const imageSrc = useAtomValue(imageSrcAtom);
    const { retailItem } = useFormContext();

    /**
     * Handles the submit coming from the DetailsForm component, which is responsible
     * for triggering a submission. Copies the values from that component into this
     * top-level form value, then submits the entire form.
     *
     * NOTE: This might be a bit unorthodox, but it's the best way to combine
     * multiple forms. This way, the DetailsForm component can remain independent
     * from this parent component, and all of its reactivity is encapsulated and doesn't
     * need to be extracted to a higher-level component.
     *
     * @param detailFormValues
     * @param helpers A collection of helper functions for the DetailsForm component.
     */
    const handleDetailsSubmit = async (
      detailFormValues: DetailsFormInputs,
      { setSubmitting: setDetailsSubmitting }: FormikHelpers<DetailsFormInputs>
    ) => {
      setSubmitting(true);
      const combinedValues = Object.assign(values, detailFormValues);
      setValues(combinedValues);
      await submitForm();
      setSubmitting(false);
      setDetailsSubmitting(false);
    };

    const handleDurationChange = (value: string) => {
      const parsed = parseInt(value);
      if (!errors.durationInMinutes && !isNaN(parsed)) {
        setFieldValue('durationInMinutes', parsed);
      } else {
        // Set the raw value so that the validation can take over
        setFieldValue('durationInMinutes', value);
      }
    };

    return (
      <PageContent>
        <PageHeader className={styles.header} divider={true}>
          <Row position="start" className={styles.start}>
            <Text tagName="h1" headingSize="m" className={styles.title}>
              {t('actions.addCustomProduct')}
            </Text>
          </Row>
        </PageHeader>
        <Columns>
          <figure>
            <AspectRatioImage
              src={
                retailItem
                  ? retailItem?.mainProductImages?.[
                      retailItem.mainProductImages.length - 1
                    ]?.href || ''
                  : imageSrc || ''
              }
              ratio="square"
            />
            {!retailItem && <UploadImage loading={globalLoading} />}
          </figure>
          <section>
            <Text headingSize="m" className={styles.heading}>
              {t('batchInformation')}
            </Text>
            <AssignToBatch
              disabled={globalLoading}
              formValues={values}
              onBatchTypeChange={(v) => setFieldValue('batchType', v)}
              onDateInputChange={(v) => setFieldValue('sourcingTimestamp', v)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {values.batchType === 'new' && (
              <Accordion>
                <AccordionItem
                  id="additionalSourcedBatchDetails"
                  title={t('additionalInformation')}
                >
                  <FormField
                    className={styles.field}
                    fieldHelper={{
                      msg: t('helpMessages.createItem.durationInMinutes'),
                    }}
                    valid={!errors.durationInMinutes}
                    shouldValidate={!!errors.durationInMinutes}
                    validation={{ msg: errors.durationInMinutes }}
                  >
                    <InputField
                      id="durationInMinutes"
                      name="durationInMinutes"
                      type="text"
                      label={t('durationType')}
                      value={values.durationInMinutes}
                      onBlur={handleBlur}
                      onChange={(event: any) =>
                        handleDurationChange(event.target.value)
                      }
                      data-cy="create-item-batch-duration"
                    />
                  </FormField>
                  <FormField className={styles.field}>
                    <TextArea
                      id="comment"
                      name="comment"
                      label={t('comment')}
                      value={values.comment}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      data-cy="create-item-batch-comment"
                    />
                  </FormField>
                </AccordionItem>
              </Accordion>
            )}
            <DetailsForm
              onSubmit={handleDetailsSubmit}
              onCancel={onCancel}
              setPrintDialogueResponse={setPrintDialogueResponse}
              loading={globalLoading}
              disabled={!(dirty && isValid) || globalLoading}
            />
          </section>
        </Columns>
      </PageContent>
    );
  }
);
