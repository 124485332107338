import { useMutation } from '@apollo/client';
import { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ingka/text';

import { useCurrency } from 'hooks/use-currency/use-currency';
import { mutationCircularItemDetermineDiscount } from 'services';
import {
  GradeValue,
  MutationCircularItemDetermineDiscountArgs,
  MutationCircularItemDetermineDiscountResult,
} from 'types';

import styles from './estimated-value-display.module.scss';

export interface EstimatedValueDisplayProps {
  gradeValue: GradeValue | undefined;
  regularItemSalesPriceInclTax?: number;
  regularItemSalesPriceCurrencyCode?: string;
  itemNumber: string;
}

export const EstimatedValueDisplay: FunctionComponent<
  EstimatedValueDisplayProps
> = ({
  gradeValue,
  regularItemSalesPriceInclTax,
  regularItemSalesPriceCurrencyCode,
  itemNumber,
}) => {
  const { t } = useTranslation();
  const [getDiscount, { data }] = useMutation<
    MutationCircularItemDetermineDiscountResult,
    MutationCircularItemDetermineDiscountArgs
  >(mutationCircularItemDetermineDiscount);
  const [discount, setDiscount] = useState<number | undefined>(undefined);
  const [useCalculatedValue, setUseCalculatedValue] = useState<boolean>(false);
  const [calculatedValue, setCalculatedValue] = useState<number | undefined>(
    undefined
  );

  const {formatCurrency} = useCurrency();

  useEffect(() => {
    if (gradeValue && itemNumber) {
      getDiscount({
        variables: {
          input: { itemNumber, grade: gradeValue },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeValue]);

  useEffect(() => {
    if (gradeValue && gradeValue !== 'Recycle' && calculatedValue && discount) {
      setUseCalculatedValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeValue, discount, calculatedValue]);

  useEffect(() => {
    if (
      regularItemSalesPriceInclTax &&
      typeof data?.circularItemDetermineDiscount !== 'undefined'
    ) {
      setDiscount(data.circularItemDetermineDiscount);
      setCalculatedValue(
        regularItemSalesPriceInclTax * (1 - data.circularItemDetermineDiscount)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, regularItemSalesPriceInclTax]);

  return (
    <div className={styles.container} data-cy="estimated-value-display">
      <Text
        headingSize="s"
        className={styles.header}
        id="CreateEstimatedValue-estimatedValue-label"
      >
        {t('estimatedSalesPrice.basedOnGrade')}
      </Text>
      <div className={styles.values}>
        {useCalculatedValue && calculatedValue && (
          <Text bodySize="l" data-cy="details-form-estimated-value-discount">
            {formatCurrency(calculatedValue, regularItemSalesPriceCurrencyCode)}
          </Text>
        )}
        {regularItemSalesPriceInclTax ? (
          <Text
            bodySize="l"
            className={styles.dimmed}
            data-cy="details-form-estimated-value-dimmed"
          >
            (
            {t('estimatedSalesPrice.regularPrice', {
              value: formatCurrency(regularItemSalesPriceInclTax, regularItemSalesPriceCurrencyCode)
            })}
            {gradeValue && gradeValue !== 'New' && gradeValue !== 'Recycle' && (
              <span>
                {' '}
                {t('estimatedSalesPrice.discount', {
                  grade: gradeValue,
                  discount: Math.round(discount! * 100),
                })}
              </span>
            )}
            ){!gradeValue && <span> {t('estimatedSalesPrice.noGrade')}</span>}
          </Text>
        ) : (
          <Text
            bodySize="l"
            className={styles.dimmed}
            data-cy="details-form-estimated-value-dimmed"
          >
            {t('estimatedSalesPrice.regularPriceNotAvailable')}
          </Text>
        )}
      </div>
    </div>
  );
};
