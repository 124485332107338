import 'i18n';
import 'browserify-zlib';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider as StateProvider } from 'jotai';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {
  CreateItemPage,
  CreateItemLandingPage,
  GlobalStyles,
  ItemDetailPage,
  ItemEditPage,
  ItemPrintPage,
  Layout,
  LoginPage,
  NavigationActions,
  NotFoundPage,
  PrivateRoute,
  ItemStockPage,
  ShipmentDetailsPage,
  ShipmentStockPage,
  ReferenceDetailsPage,
  CreateItemPageNew,
  BatchStockPage,
  SourcedBatchDetailsPage,
  EditSourcesPage,
} from 'components';
import { AppProvider } from 'context';

import { Settings } from './constants';
import reportWebVitals from './reportWebVitals';
Sentry.init({
  dsn: Settings.sentryDns,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [Settings.graphqlUrl ?? ''],
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  environment: Settings.sentryEnviroment,
  tracesSampleRate: parseFloat(Settings.sentrySampleRate || '1.0'),
  // We dont want our error log to be filled with print errors if there is no printer connected, e.g dev environment
  ignoreErrors: ['Failed to initialize printer, no printer connected'],
  denyUrls: ['http://localhost:9100/default', "/localhost:9100/'"],
});

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <StateProvider>
        <AppProvider>
          <GlobalStyles />
          <NavigationActions />
          <Switch>
            <Route exact path="/">
              <LoginPage />
            </Route>
            <Route>
              <Layout>
                <Switch>
                  {/* TODO Change routes & remove old create #inflowManagement */}
                  <PrivateRoute exact path="/newcreate">
                    <CreateItemPageNew />
                  </PrivateRoute>
                  <PrivateRoute exact path="/sourcedBatches">
                    <BatchStockPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/sourcedBatch/:id">
                    <SourcedBatchDetailsPage />
                  </PrivateRoute>
                  {/* ... */}
                  <PrivateRoute exact path="/create">
                    <CreateItemLandingPage />
                  </PrivateRoute>
                  <PrivateRoute path={`/create/item/:paramItemNumber?`}>
                    <CreateItemPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/inventory">
                    <ItemStockPage />
                  </PrivateRoute>
                  <PrivateRoute path="/inventory/:id/edit">
                    <ItemEditPage />
                  </PrivateRoute>
                  <PrivateRoute path="/inventory/:id/print">
                    <ItemPrintPage />
                  </PrivateRoute>
                  <PrivateRoute path="/inventory/:id">
                    <ItemDetailPage />
                  </PrivateRoute>
                  <PrivateRoute exact path="/shipments">
                    <ShipmentStockPage />
                  </PrivateRoute>
                  <PrivateRoute path="/shipments/:id">
                    <ShipmentDetailsPage />
                  </PrivateRoute>
                  <PrivateRoute path="/shipments/:id/edit">
                    <ShipmentDetailsPage />
                  </PrivateRoute>
                  <PrivateRoute path="/reference/:id">
                    <ReferenceDetailsPage />
                  </PrivateRoute>

                  {/* "ADMIN" PAGES */}
                  <PrivateRoute path="/edit-sources">
                    <EditSourcesPage />
                  </PrivateRoute>

                  <Route path="*">
                    <NotFoundPage />
                  </Route>
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </AppProvider>
      </StateProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
