import { useState, memo, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import Modal, { Prompt, ModalFooter } from '@ingka/modal';

import { FactoryComponentProps } from 'types';

import styles from './delete.module.scss';

export interface DeleteProps extends FactoryComponentProps {
  buttonText: string;
  deleteAction: () => void;
  type: string;
  dataCy: string;
}

export const Delete: FunctionComponent<DeleteProps> = memo(
  ({ buttonText, deleteAction, type, dataCy }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const { t } = useTranslation();

    const onClick = () => setVisible(true);

    const handleCloseBtn = () => setVisible(false);

    const handlePositiveClick = () => {
      setVisible(false);
      deleteAction();
    };

    return (
      <>
        <Button
          type="danger"
          onClick={onClick}
          text={buttonText}
          small={true}
          data-cy={dataCy}
        />
        <Modal
          visible={visible}
          handleCloseBtn={handleCloseBtn}
          escapable={true}
          className={styles.modal}
        >
          <Prompt
            title={t('dialogues.delete.title', {
              type: type,
            })}
            footer={
              <ModalFooter>
                <Button
                  text={t('actions.confirmDelete')}
                  type="primary"
                  onClick={handlePositiveClick}
                  // loading={loading}
                  data-cy="dialogue-prompt-positive-btn"
                />
                <Button
                  text={t('actions.customCancel')}
                  type="secondary"
                  // onClick={handleCloseBtn}
                  // loading={loading}
                  data-cy={'dialogue-prompt-negative-btn'}
                />
              </ModalFooter>
            }
          >
            {t('dialogues.delete.bodySingle', {
              type: type,
            })}
          </Prompt>
        </Modal>
      </>
    );
  }
);
