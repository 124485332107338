import { useQuery } from '@apollo/client';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';

import { globalLoadingAtom, globalAppErrorAtom } from 'atoms';
import { useAuth } from 'hooks';
import { queryCircularItemsCollection } from 'services';
import {
  QueryCircularItemsCollectionResult,
  QueryCircularItemsCollectionArgs,
  CircularItem,
} from 'types';
import { nullFilter } from 'utils';

export const useItemStock = ({
  skip,
  limit,
  order,
  searchTerm,
  filter,
}: QueryCircularItemsCollectionArgs) => {
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const { getCurrentCpcId } = useAuth();
  const [stockItems, setStockItems] = useState<CircularItem[]>([]);
  const [total, setTotal] = useState<number>(0);

  const { data, loading, error, refetch } = useQuery<
    QueryCircularItemsCollectionResult,
    QueryCircularItemsCollectionArgs
  >(queryCircularItemsCollection, {
    variables: {
      skip,
      limit,
      order,
      searchTerm,
      filter,
      cpcId: getCurrentCpcId(),
    }
  });

  useEffect(() => {
    setGlobalLoading(loading);
    setGlobalAppError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  useEffect(() => {
    if (data?.circularItemsCollection) {
      setTotal(data.circularItemsCollection.total || 0);
      if (data.circularItemsCollection.items) {
        const filteredItems: CircularItem[] =
          data.circularItemsCollection.items.filter<CircularItem>(nullFilter);
        setStockItems(filteredItems);
      }
    }
  }, [data]);

  return {
    stockItems,
    total,
    refetch,
  };
};
