import {
  ActivityTypesDocument,
  AddShipmentItemsDocument,
  AssignItemsAndCreateNewSourcedBatchDocument,
  AssignItemsToSourcedBatchDocument,
  AvailableHfBsDocument,
  CircularItemDetermineDiscountDocument,
  CircularItemDocument,
  CircularItemImageUploadUrlDocument,
  CircularItemsCollectionDocument,
  CircularProcessCenterDocument,
  CreateBatchedItemsDocument,
  CreateCircularItemDocument,
  CreateSaleDocument,
  CreateShipmentDocument,
  CreateSourceDocument,
  DeleteSourceDocument,
  ExternalReferenceDocument,
  ExternalReferencesDocument,
  GradeItemDocument,
  GradesDocument,
  ItemActivitiesDocument,
  LoginDocument,
  MeDocument,
  RemoveCircularItemDocument,
  RemoveItemActivityDocument,
  RemoveShipmentDocument,
  RemoveShipmentItemsDocument,
  RetailItemDocument,
  SetItemStatusesDocument,
  ShipmentDocument,
  ShipmentsCollectionDocument,
  ShipmentWithCircularItemsDocument,
  ShipShipmentDocument,
  SourcedBatchDocument,
  SourcedBatchCollectionDocument,
  SourcesDocument,
  UpdateCircularItemDocument,
  CreateActivitiesInBulkDocument,
  UpdateItemActivityDocument,
  CreateBatchOfItemsDocument,
  CreateItemsInExistingBatchDocument,
  UpdateSourcedBatchDocument,
  UpdateShipmentDocument,
} from 'types';

export const mutationAddShipmentItems = AddShipmentItemsDocument;
export const mutationAssignItemsAndCreateNewSourcedBatch =
  AssignItemsAndCreateNewSourcedBatchDocument;
export const mutationAssignItemsToSourcedBatch =
  AssignItemsToSourcedBatchDocument;
export const mutationCircularItemDetermineDiscount =
  CircularItemDetermineDiscountDocument;
export const mutationCreateCircularItem = CreateCircularItemDocument;
export const mutationCreateBatchedItems = CreateBatchedItemsDocument;
export const mutationCreateBatchOfItems = CreateBatchOfItemsDocument;
export const mutationCreateItemsInExistingBatch =
  CreateItemsInExistingBatchDocument;
export const mutationUpdateItemActivity = UpdateItemActivityDocument;
export const mutationCreateActivitiesInBulk = CreateActivitiesInBulkDocument;
export const mutationCreateSale = CreateSaleDocument;
export const mutationCreateShipment = CreateShipmentDocument;
export const mutationCreateSource = CreateSourceDocument;
export const mutationDeleteSource = DeleteSourceDocument;
export const mutationGradeItem = GradeItemDocument;
export const mutationLogin = LoginDocument;
export const mutationRemoveCircularItem = RemoveCircularItemDocument;
export const mutationRemoveShipment = RemoveShipmentDocument;
export const mutationRemoveShipmentItems = RemoveShipmentItemsDocument;
export const mutationSetStatuses = SetItemStatusesDocument;
export const mutationShipShipment = ShipShipmentDocument;
export const mutationUpdateShipment = UpdateShipmentDocument;
export const mutationUpdateCircularItem = UpdateCircularItemDocument;
export const mutationRemoveItemActivity = RemoveItemActivityDocument;
export const mutationUpdateSourcedBatch = UpdateSourcedBatchDocument;

export const queryActivityTypes = ActivityTypesDocument;
export const queryAvailableHFBs = AvailableHfBsDocument;
export const queryCircularItem = CircularItemDocument;
export const queryCircularItemGrades = GradesDocument;
export const queryCircularItemImageUploadUrl =
  CircularItemImageUploadUrlDocument;
export const queryCircularItemsCollection = CircularItemsCollectionDocument;
export const queryCircularProcessCenter = CircularProcessCenterDocument;
export const querySources = SourcesDocument;
export const queryExternalReference = ExternalReferenceDocument;
export const queryExternalReferences = ExternalReferencesDocument;
export const queryItemActivities = ItemActivitiesDocument;
export const queryMe = MeDocument;
export const queryRetailItem = RetailItemDocument;
export const queryShipment = ShipmentDocument;
export const queryShipmentCollection = ShipmentsCollectionDocument;
export const queryShipmentWithItems = ShipmentWithCircularItemsDocument;
export const querySourcedBatch = SourcedBatchDocument;
export const querySourcedBatchCollection = SourcedBatchCollectionDocument;
