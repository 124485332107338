import { FunctionComponent, memo } from 'react';

import { FactoryComponentProps } from 'types';

import styles from './row.module.scss';

export interface RowProps extends FactoryComponentProps {
  position?: 'start' | 'end';
}

export const Row: FunctionComponent<RowProps> = memo(
  ({ className = '', position, children }) => {
    return (
      <div
        className={`${styles.container} ${
          position && styles[position]
        } ${className}`}
      >
        {children}
      </div>
    );
  }
);
