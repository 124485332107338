import { useState } from 'react';

export const useItemNumber = () => {
  const [itemNumber, setItemNumber] = useState<string>('');

  return {
    itemNumber,
    setItemNumber,
  };
};
