import { ApolloError, FetchResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { FormikHelpers, useFormik } from 'formik';
import { GraphQLError } from 'graphql';
import { FunctionComponent, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { assignToBatchSchema } from 'yup-schemas/yup-schemas';

import Button from '@ingka/button';
import Modal, { ModalBody, ModalFooter, Sheets } from '@ingka/modal';
import Text from '@ingka/text';

import { AssignToBatch } from 'components/_form/assign-to-batch/assign-to-batch';
import { ConfirmDeleteBatchDialogue } from 'components/_prompts/confirm-delete-batch/confirm-delete-batch';
import { ConfirmMoveBatchDialogue } from 'components/_prompts/confirm-move-batch/confirm-move-batch';
import { useAssignToBatch } from 'hooks/use-assign-to-batch/use-assign-to-batch';
import { AssignItemsAndCreateNewSourcedBatchInput, AssignItemsToSourcedBatchInput, FactoryComponentProps, FactoryLocationState, SourcedBatch } from 'types';

import styles from './assign-items-to-batch-modal.module.scss';


export interface AssignItemsToBatchFormValues {
  ids: string[];
  batchType: ('new' | 'existing');
  sourceId?: string;
  sourcedBatchId?: string;
  sourcingTimestamp?: Date;
  moveSourceConfirmation?: boolean;
  deleteConfirmation?: boolean;
}

export interface AssignItemsToBatchProps extends FactoryComponentProps {
  /** The IDs of the selected Circular objects to be reassigned */
  ids: string[];

  /** Whether this modal is visible */
  visible: boolean;

  /** Hook for when the user clicks on the 'X' or 'Cancel' button to close the modal */
  onClose(): void;
}

export const AssignItemsToBatchModal: FunctionComponent<AssignItemsToBatchProps> = memo(
  ({ ids, visible, onClose }) => {

    /** SETUPS */
    const [displayConfirmMoveDialogue, setDisplayConfirmMoveDialogue] = useState(false);
    const [displayConfirmDeleteDialogue, setDisplayConfirmDeleteDialogue] = useState(false);
    const {assignToSourcedBatch, assignToNewSourcedBatch} = useAssignToBatch()
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const initialValues: AssignItemsToBatchFormValues = {
      ids: ids,
      batchType: 'new' as ('new' | 'existing'),
      sourceId: ''
    };
    const history = useHistory<FactoryLocationState>();

    /**
     * Reads a collection of GraphQL errors to see whether any of them indicate a need for user
     * confirmation; if so, returns the actions that the user must complete.
     *
     * @param errors
     * @returns
     */
    const getUserConfirmationActions = (errors: readonly GraphQLError[]): string[] => {
      if (!errors?.length) {
        return [];
      }
      return errors.filter(e => e.extensions["code"] === "USER_CONFIRMATION").flatMap(e => e.extensions["actions"] as string[]);
    }

    /**
     * Checks a collection of GraphQL errors to see whether any of them are indicating that user
     * confirmation is required. If so, displays a dialogue prompting the user to confirm the
     * corresponding action.
     *
     * @param errors
     * @returns True if there were user confirmation actions; false otherwise
     */
    const checkForUserConfirmationActions = (errors: GraphQLErrors): boolean => {
      const userConfirmationActions = getUserConfirmationActions(errors);
      if (userConfirmationActions.some(a => a === "CHANGE_OF_SOURCE")) {
        setDisplayConfirmMoveDialogue(true);
        return true;
      }
      if (userConfirmationActions.some(a => a === "DELETE_EMPTY_SOURCED_BATCH")) {
        setDisplayConfirmDeleteDialogue(true);
        return true;
      }

      return false;
    }

    const onSubmit = async (
      formValues: AssignItemsToBatchFormValues,
      { setSubmitting }: FormikHelpers<AssignItemsToBatchFormValues>
    ) => {
      setSubmitting(true);
      try {
        let response: FetchResult<SourcedBatch, Record<string, any>, Record<string, any>>
        let input: any = {
          circularItemIds: ids,
          moveSourceConfirmation: formValues.moveSourceConfirmation,
          deleteConfirmation: formValues.deleteConfirmation
        }
        if (formValues.batchType === "new") {
          input.sourceId = formValues.sourceId!
          response = await assignToNewSourcedBatch(input as AssignItemsAndCreateNewSourcedBatchInput);
        } else {
          input.sourcedBatchId = formValues.sourcedBatchId!;
          response = await assignToSourcedBatch(input as AssignItemsToSourcedBatchInput);
        }

        if (!response) {
          throw new Error("No response returned from GraphQL");
        }

        history.replace("/sourcedBatches",
          {
            message: {
              variant: 'positive',
              title: formValues.batchType === "new" ?
                t("notifications.createBatchedItems.newBatch") :
                t("notifications.createBatchedItems.existingBatch", { id: formValues.sourcedBatchId })
            }
          })
      }

      catch (e) {
        // Handle user confirmation errors
        let messageBody: string = "";
        if (Object.hasOwn(e as object, "graphQLErrors")) {
          const graphQLErrors = (e as ApolloError).graphQLErrors;
          if (checkForUserConfirmationActions(graphQLErrors)) {
            return;
          }
        } else {
          // It's just a normal error, get the message from it
          messageBody = (e as Error).message;
        }
        history.replace("/sourcedBatches", {
          message: {
            title: t("notifications.error"),
            body: messageBody,
            variant: 'negative'
          }
        });
      }

      // TODO: GQL here
      finally {
        setSubmitting(false);
      }
    };

    const {
      isSubmitting,
      values,
      handleSubmit,
      handleChange,
      setFieldValue,
      resetForm,
      submitForm,
      isValid,
      dirty
    } = useFormik<AssignItemsToBatchFormValues>({
      initialValues,
      onSubmit,
      validationSchema: assignToBatchSchema
    });

    /** EVENT HANDLER DECL'S */
    const onCloseBtnClick = () => onClose();
    const onCancelClick = () => onClose();
    const onModalClosed = () => resetForm();
    const onBatchTypeChange = (batchType: string) => setFieldValue('batchType', batchType);
    const onDateChange = (sourcingTimestamp: Date) => setFieldValue('sourcingTimestamp', sourcingTimestamp);
    const handleDialogueResponse = (fieldName: string, response: boolean) => {
      setFieldValue(fieldName, response);
      if (response) {
        submitForm();
      }
    }

    const footer = (
      <ModalFooter>
        <Button
          type="primary"
          fluid={true}
          // loading={isSubmitting || loading}
          htmlType="submit"
          disabled={isSubmitting || !isValid || !dirty}
          text={t('actions.save')}
          data-cy={'modal-submit-btn'}
          onClick={handleSubmit} // Necessary because otherwise onClose will be invoked.
        />
        <Button
          type="secondary"
          fluid={true}
          // loading={isSubmitting || loading}
          text={t('actions.cancel')}
          onClick={onCancelClick}
        />
      </ModalFooter>
    );
    return (
      <>
        <form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
          <Modal
            visible={visible}
            handleCloseBtn={onCloseBtnClick}
            onModalClosed={onModalClosed}
          >
            <Sheets size="small" footer={footer}>
              <ModalBody>
                <Text headingSize={'m'} className={styles.heading}>
                  {t('assignItemsToBatch.headerInterpolation', { quantity: ids.length })}
                </Text>
                <AssignToBatch
                  disabled={isSubmitting}
                  onBatchTypeChange={onBatchTypeChange}
                  onDateInputChange={onDateChange}
                  onChange={handleChange}
                  formValues={{...values}} />
              </ModalBody>
            </Sheets>
          </Modal>
        </form>
        <ConfirmMoveBatchDialogue
          displayDialogue={displayConfirmMoveDialogue}
          setDisplayDialogue={setDisplayConfirmMoveDialogue}
          handleDialogueResponse={(v) => handleDialogueResponse("moveSourceConfirmation", v)}
          quantity={ids.length}
          batchId={values.sourcedBatchId} />
        <ConfirmDeleteBatchDialogue
          displayDialogue={displayConfirmDeleteDialogue}
          setDisplayDialogue={setDisplayConfirmDeleteDialogue}
          handleDialogueResponse={(v) => handleDialogueResponse("deleteConfirmation", v)} />
      </>
    );
  }
);
