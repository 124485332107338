import { FormikHelpers, useFormik } from 'formik';
import {
  ChangeEvent,
  FunctionComponent,
  memo,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  nonVerifiedItemEditSchema,
  verifiedItemSchema,
} from 'yup-schemas/yup-schemas';

import Button from '@ingka/button';
import Checkbox from '@ingka/checkbox';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Switch from '@ingka/switch';
import Text from '@ingka/text';
import TextArea from '@ingka/text-area';

import {
  DetailsFormBusinessStructure,
  DetailsFormItemNumber,
  DetailsFormManufacturingDate,
  DetailsFormSgf,
  DialoguePrompt,
  EstimatedValueDisplay,
  GradeInput,
  SourceInput,
  StatusInput,
} from 'components';
import { useAuth, useFormContext } from 'hooks';
import {
  FactoryComponentProps,
  GradeValue,
  RetailItemBusinessStructureInput,
  SgfLocationInput,
  Status,
} from 'types';
import { formatItemNumberWithRegex, omitTypenames } from 'utils';

import styles from './details-form-edit.module.scss';

export interface DetailsFormEditInputs {
  status?: Status;
  productName?: string;
  productDescription?: string;
  itemNumber?: string;
  businessStructure?: RetailItemBusinessStructureInput;
  grade?: GradeValue;
  estimatedSalesPrice?: string;
  regularItemSalesPriceInclTax?: string;
  regularItemSalesPriceCurrencyCode?: string;
  sourceId?: string;
  sekundReference?: string;
  supplierNumber?: string;
  year?: string; // Week: dd
  week?: string; // Year: dddd
  sgfLocation?: SgfLocationInput;
  note?: string;
  eligibleForFAAS?: boolean;
  FAASCustomer?: string;
  tags?: string[];
  print?: boolean;
}

export interface DetailsFormEditProps extends FactoryComponentProps {
  onSubmit(
    values: DetailsFormEditInputs,
    helpers: FormikHelpers<DetailsFormEditInputs>
  ): void;
  loading?: boolean;
  initialValues: DetailsFormEditInputs;
  onCancel?: MouseEventHandler;
  isInShipment: boolean;
}

const defaultValues = {
  status: undefined,
  productName: '',
  productDescription: '',
  itemNumber: '',
  businessStructure: undefined,
  grade: undefined,
  estimatedSalesPrice: '',
  regularItemSalesPriceInclTax: '',
  regularItemSalesPriceCurrencyCode: '',
  sourceId: undefined,
  FAASCustomer: '',
  eligibleForFAAS: undefined,
  year: undefined,
  week: undefined,
  supplierNumber: '',
  sekundReference: '',
  sgfLocation: undefined,
  note: '',
  tags: [],
  print: false,
};

export const DetailsFormEdit: FunctionComponent<DetailsFormEditProps> = memo(
  ({
    onSubmit,
    loading = false,
    onCancel,
    initialValues = {},
    isInShipment,
  }) => {
    const { t } = useTranslation();
    const { retailItem, itemNumber } = useFormContext();
    const { getCurrentCpc } = useAuth();
    const [hfbError, setHfbError] = useState<boolean>(false);
    const [displayStatusDialogue, setDisplayStatusDialogue] =
      useState<boolean>(false);

    const {
      isValid,
      isSubmitting,
      values,
      touched,
      errors,
      handleSubmit,
      setFieldValue,
      setValues,
      handleBlur,
      handleChange,
    } = useFormik<DetailsFormEditInputs>({
      initialValues: { ...defaultValues, ...initialValues },
      onSubmit,
      validationSchema:
        retailItem?.itemNumber === itemNumber
          ? verifiedItemSchema
          : nonVerifiedItemEditSchema,
      enableReinitialize: true,
    });
    const setStatusValue = (status: Status | null) => {
      setFieldValue('status', status, false);
    };

    const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue('productName', event.target.value.toLocaleUpperCase());
    };
    const setItemNumber = (itemNumber: string) => {
      setFieldValue('itemNumber', formatItemNumberWithRegex(itemNumber));
    };

    const setBusinessStructureValue = (
      businessStructure: RetailItemBusinessStructureInput
    ) => {
      setFieldValue('businessStructure', businessStructure, false);
    };

    const setGradeValue = (grade: GradeValue | undefined) =>
      setFieldValue('grade', grade, false);

    const setSgfLocation = (sgfLocation: SgfLocationInput) => {
      setFieldValue('sgfLocation', sgfLocation, false);
    };

    const onForFAASChange = () => {
      setFieldValue('eligibleForFAAS', !values.eligibleForFAAS);
    };

    // TODO #inflowManagement
    // const onSourceDateInput = (date: Date) => {
    //   console.log(date);
    // };

    useEffect(() => {
      if (values.businessStructure?.homeFurnishingBusinessNumber) {
        setHfbError(values.businessStructure.productAreaNumber ? false : true);
      }
    }, [values.businessStructure]);

    const handleSetRetailItem = () => {
      if (retailItem) {
        setValues({
          ...values,
          productName: retailItem.globalProductName || undefined,
          productDescription: retailItem.productDescription || undefined,
          itemNumber: formatItemNumberWithRegex(retailItem.itemNumber),
          businessStructure: retailItem.businessStructure
            ? omitTypenames(retailItem.businessStructure)
            : undefined,
          regularItemSalesPriceInclTax:
            retailItem.regularItemSalesPriceInclTax?.toString(),
        });
      }
    };

    const preSubmit = () => {
      if (
        isInShipment &&
        (values.status === 'pending' || values.status === 'available')
      ) {
        setDisplayStatusDialogue(true);
      } else {
        handleSubmit();
      }
    };

    const handleDialogueResponse = (value: boolean) => {
      if (value) {
        handleSubmit();
      }
    };

    return (
      <form
        className={styles.form}
        onSubmit={preSubmit}
        data-cy="details-form-edit"
      >
        <DialoguePrompt
          positiveBtnText={t('actions.confirm')}
          negativeBtnText={t('actions.noDont')}
          promptTitle={t('dialogues.status.title')}
          promptBody={t('dialogues.status.bodySingle', {
            status: values.status,
          })}
          displayDialogue={displayStatusDialogue}
          setDisplayDialogue={setDisplayStatusDialogue}
          handleDialogueResponse={handleDialogueResponse}
        />
        <section className={styles.formSection}>
          <Text headingSize="m" className={styles.heading}>
            {t('generalInformation')}
          </Text>
          <FormField
            className={styles.grade}
            disabled={isSubmitting || loading}
          >
            <Text headingSize="s" className={styles.statusHeading}>
              {t('status')}
            </Text>
            <StatusInput
              setValue={setStatusValue}
              value={values.status}
              loading={loading || isSubmitting}
              empty={!values.status}
            />
          </FormField>
          <FormField className={styles.field}>
            <InputField
              id="productName"
              name="productName"
              type="text"
              label={t('productName')}
              disabled={
                isSubmitting || loading || (!!values.itemNumber && !!retailItem)
              }
              onChange={onProductNameChange}
              onBlur={handleBlur}
              value={values.productName}
              data-cy="details-form-input-product-name"
            />
          </FormField>
          <FormField className={styles.field}>
            <InputField
              id="productDescription"
              name="productDescription"
              type="text"
              maxLength={75}
              label={t('productDescription')}
              disabled={
                isSubmitting || loading || (!!values.itemNumber && !!retailItem)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.productDescription}
              data-cy="details-form-input-product-description"
            />
            <Text className={styles.exampleDescription} bodySize={'s'}>
              {t('exProductDescription')}
            </Text>
          </FormField>
          <DetailsFormItemNumber
            className={styles.field}
            disabled={isSubmitting || loading}
            onBlur={handleBlur}
            setValue={setItemNumber}
            itemNumberError={errors.itemNumber}
            itemNumberTouched={!!touched.itemNumber}
            itemNumberInput={values.itemNumber}
            handleSetRetailItem={handleSetRetailItem}
          />
          <DetailsFormBusinessStructure
            loading={isSubmitting || loading}
            onBlur={handleBlur}
            value={values.businessStructure}
            setValue={setBusinessStructureValue}
            disabled={
              isSubmitting || loading || (!!values.itemNumber && !!retailItem)
            }
          />
          <FormField
            className={styles.grade}
            valid={!(touched.grade && errors.grade)}
            validation={
              touched.grade && errors.grade
                ? {
                    msg: errors.grade,
                    id: 'grade-error',
                  }
                : undefined
            }
            disabled={isSubmitting || loading}
          >
            <Text headingSize="s" className={styles.gradeHeading}>
              {t('grade')}
            </Text>
            <GradeInput
              loading={loading || isSubmitting}
              className={styles.gradeInput}
              empty={!initialValues.grade}
              value={values.grade}
              setValue={setGradeValue}
              data-cy="grade-input-component-value"
            />
          </FormField>
        </section>

        <section className={styles.formSection}>
          <Text headingSize="m">{t('price')}</Text>
          <>
            {retailItem && (
              <EstimatedValueDisplay
                gradeValue={values.grade}
                regularItemSalesPriceInclTax={
                  retailItem?.regularItemSalesPriceInclTax!
                }
                regularItemSalesPriceCurrencyCode={
                  retailItem.regularItemSalesPriceCurrencyCode!
                }
                itemNumber={retailItem.itemNumber}
              />
            )}
            {/* Sometimes a retail item can have no price data, so we give the ability to enter the data manually */}
            {!retailItem?.regularItemSalesPriceInclTax && (
              <FormField
                className={styles.field}
                shouldValidate={touched.regularItemSalesPriceInclTax}
                valid={!errors.regularItemSalesPriceInclTax}
                validation={
                  errors.regularItemSalesPriceInclTax
                    ? {
                        msg: errors.regularItemSalesPriceInclTax,
                        id: 'originalSalesPrice-error',
                      }
                    : undefined
                }
                data-cy="details-form-regular-item-sale-price-wrapper"
              >
                <InputField
                  id="regularItemSalesPriceInclTax"
                  name="regularItemSalesPriceInclTax"
                  type="number"
                  label={t('originalSalesPrice')}
                  suffixLabel={getCurrentCpc().defaultCurrencyCode}
                  disabled={isSubmitting || loading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.regularItemSalesPriceInclTax}
                  data-cy="details-form-regular-item-sales-price"
                />
              </FormField>
            )}
            <FormField
              className={styles.field}
              shouldValidate={touched.estimatedSalesPrice}
              valid={!errors.estimatedSalesPrice}
              validation={
                errors.estimatedSalesPrice
                  ? {
                      msg: errors.estimatedSalesPrice,
                      id: 'estimatedSalesPrice-error',
                    }
                  : undefined
              }
              data-cy="details-form-estimated-value-wrapper"
            >
              <InputField
                id="estimatedSalesPrice"
                name="estimatedSalesPrice"
                type="number"
                label={t('recSalesPrice')}
                suffixLabel={getCurrentCpc().defaultCurrencyCode}
                disabled={isSubmitting || loading}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.estimatedSalesPrice}
                data-cy="details-form-estimated-value"
              />
            </FormField>
          </>
        </section>

        <section className={styles.formSection}>
          <Text headingSize="m">{t('additionalInformation')}</Text>
          <FormField>
            <InputField
              id="sekundReference"
              name="sekundReference"
              className={styles.field}
              type="text"
              label={t('sekundReference')}
              data-cy="details-form-input-sekund-id"
              disabled={isSubmitting || loading}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.sekundReference}
            />
          </FormField>
          <DetailsFormManufacturingDate
            loading={isSubmitting || loading}
            year={values.year}
            week={values.week}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isSubmitting || loading}
          />
          {/* <DateInput
            setValue={onSourceDateInput}
            value={new Date()}
            label={t('sourcingDate')}
          /> */}
          <FormField
            className={styles.field}
            shouldValidate={
              touched.supplierNumber && !!values.supplierNumber?.length
            }
            valid={!errors.supplierNumber}
            validation={
              errors.supplierNumber
                ? {
                    msg: errors.supplierNumber,
                    id: 'supplierNumber-error',
                  }
                : undefined
            }
            data-cy="details-form-supplier-number-wrapper"
          >
            <InputField
              id="supplierNumber"
              name="supplierNumber"
              type="number"
              max="99999"
              label={t('supplierNumber')}
              disabled={isSubmitting || loading}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.supplierNumber}
              data-cy="details-form-supplier-number"
            />
          </FormField>
          <SourceInput
            loading={loading || isSubmitting}
            value={values.sourceId}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isSubmitting || loading}
          />
          <DetailsFormSgf
            loading={loading || isSubmitting}
            value={values?.sgfLocation}
            onBlur={handleBlur}
            setValue={setSgfLocation}
            disabled={isSubmitting || loading}
          />
          <FormField className={styles.field}>
            <TextArea
              id="note"
              name="note"
              label={t('comment')}
              disabled={isSubmitting || loading}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
              data-cy="details-form-note"
            />
          </FormField>
        </section>

        <section className={styles.formSection}>
          <Text headingSize="m">{t('faas')}</Text>
          <FormField
            className={styles.field}
            disabled={isSubmitting || loading}
          >
            <fieldset role="group" className="radio-button-group">
              <legend>{t('eligibleForFaaS')}</legend>
              <Switch
                name="eligibleForFAAS"
                id="eligibleForFAAS"
                onChange={onForFAASChange}
                onBlur={handleBlur}
                disabled={loading}
                subtle={true}
                value="eligibleForFAAS"
                label={
                  values.eligibleForFAAS === undefined
                    ? ''
                    : values.eligibleForFAAS === true
                    ? t('yes')
                    : t('no')
                }
                checked={values.eligibleForFAAS}
                data-cy="details-form-for-faas"
              />
            </fieldset>
          </FormField>
          {values.eligibleForFAAS && (
            <FormField
              className={styles.field}
              disabled={isSubmitting || loading}
            >
              <InputField
                id="FAASCustomer"
                name="FAASCustomer"
                type="text"
                label={t('FaaSCustomer')}
                disabled={isSubmitting || loading}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.FAASCustomer}
              />
            </FormField>
          )}
        </section>

        <section className={styles.formSection}>
          <Text headingSize="m">{t('print')}</Text>
          <FormField
            className={styles.field}
            disabled={isSubmitting || loading}
          >
            <Checkbox
              id="print"
              name="print"
              value="print"
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('actions.print.custom')}
              disabled={isSubmitting || loading}
            />
          </FormField>
        </section>

        <div className={styles.actions}>
          <Button
            type="primary"
            loading={isSubmitting}
            disabled={loading || hfbError || !isValid}
            // htmlType="submit"
            onClick={preSubmit}
            text={t('actions.save')}
            data-cy="details-form-submit"
          />
          {!!onCancel && (
            <Button
              type="secondary"
              loading={isSubmitting}
              disabled={loading}
              onClick={onCancel}
              text={t('actions.cancel')}
            />
          )}
        </div>
      </form>
    );
  }
);
