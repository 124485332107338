import { useMutation } from "@apollo/client";

import { useAuth } from "hooks";
import { mutationDeleteSource, querySources } from "services";
import { Source } from "types";

export function useDeleteSource() {
  const [deleteSourceMutation] = useMutation(mutationDeleteSource);
  const {getCurrentCpcId} = useAuth();
  const deleteSource = async (source: Source) => {
    return await deleteSourceMutation({
      variables: {
        sourceId: source.id
      },
      refetchQueries: [{
        query: querySources,
        variables: {
          cpcId: getCurrentCpcId()
        }
      }]
    })
  }

  return { deleteSource }
}
