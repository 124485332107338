import { FunctionComponent, memo } from 'react';

import Button from '@ingka/button';
import minusSmall from '@ingka/ssr-icon/paths/minus-small';

import { Source } from 'types';

import styles from './source-list-row.module.scss';

export interface BatchListRowProps {
  source: Source;
  handleDeleteButtonClick: () => void;
}

export const SourceListRow: FunctionComponent<BatchListRowProps> = memo(
  ({ source, handleDeleteButtonClick }: BatchListRowProps) => {
    return (
      <tr className={`${styles.row}`}>
        <td>{source.id}</td>
        <td>{source.name}</td>
        <td>
          <Button
            ssrIcon={minusSmall}
            iconOnly
            small
            xsmallIconOnly
            onClick={handleDeleteButtonClick}
          />
        </td>
      </tr>
    );
  }
);
