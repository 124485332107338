import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { FactoryLocationState } from 'types';

export interface UseButtonLink {
  buttonLinkHandler(event: MouseEvent<HTMLAnchorElement>): void;
}

export const useButtonLink = (): UseButtonLink => {
  const { push } = useHistory<FactoryLocationState>();

  const buttonLinkHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const url = new URL(event.currentTarget.href);
    if (url.pathname) {
      push(url.pathname);
    }
  };

  return { buttonLinkHandler };
};
