import { makeReference, useMutation } from '@apollo/client';

import { mutationCreateActivitiesInBulk } from 'services';
import {
  ActivityBulkInput,
  CircularItemActivity,
} from 'types';

export function useAddActivities() {

  const [addActivityBulkMutation] = useMutation(
    mutationCreateActivitiesInBulk
  )

  const addActivities = async (
    circularItemId: string,
    activities: ActivityBulkInput[]
  ): Promise<CircularItemActivity | null | undefined> => {
    const result = await addActivitiesBulk(
      [circularItemId],
      activities,
    );

    return (result || [])[0];
  };

  const addActivitiesBulk = async (
    circularItemIds: string[],
    activities: ActivityBulkInput[]
  ): Promise<
    CircularItemActivity[] | null | undefined
  > => {
    try {
      const response = await addActivityBulkMutation({
        variables: {
          input: {
            activities,
            circularItemIds,
          }
        },
        update(cache, { data }) {
          if (!data) {
            return;
          }
          data.createActivitiesInBulk?.forEach(result => {
          // Add activity to cache
          cache.modify({
            id: cache.identify(makeReference('ROOT_QUERY')),
            fields: {
              itemActivities: (previous, { toReference }) => {
                return [...previous, result];
              },
            },
          });
          // Update grade on item
          cache.modify({
            id: cache.identify({
              __typename: 'CircularItem',
              id: result.circularItemId,
            }),
            fields: {
              currentGrade: () => {
                if (result.grade) {
                  return {
                    id: result.grade?.id,
                    value: result.grade?.value,
                  }
                }
              },
            }
          })
        })
        },
      });
      return response.data?.createActivitiesInBulk as CircularItemActivity[];
} catch (error) {
  //@TODO redirect with another message?
  console.error(error);
  return null;
}
  };
return { addActivities, addActivitiesBulk };
}
