import { useQuery } from '@apollo/client';
import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '@ingka/form-field';
import Select, { Option } from '@ingka/select';

import { queryAvailableHFBs } from 'services';
import {
  FactoryFormFieldProps,
  HomeFurnishingBusiness,
  ProductArea,
  QueryAvailableHfbResult,
  RetailItemBusinessStructureInput,
} from 'types';

import styles from './details-form-hfb-pa.module.scss';

export interface DetailsFormBusinessStructureProps
  extends FactoryFormFieldProps {
  value: RetailItemBusinessStructureInput | undefined;
  setValue(value: RetailItemBusinessStructureInput | undefined): void;
}

export const DetailsFormBusinessStructure: FunctionComponent<
  DetailsFormBusinessStructureProps
> = ({ value, onBlur, setValue, disabled }) => {
  const { t } = useTranslation();
  const { loading, error, data } =
    useQuery<QueryAvailableHfbResult>(queryAvailableHFBs);
  const [businessStructure, setBusinessStructure] = useState(value);
  const [availableHFBs, setAvailableHFBs] =
    useState<HomeFurnishingBusiness[]>();
  const [productAreas, setProductAreas] = useState<ProductArea[]>();

  function handleHFBinput(number: string) {
    if (availableHFBs) {
      const selectedHFB = availableHFBs.find(
        (hfb: HomeFurnishingBusiness) => hfb.number === number
      );

      if (
        selectedHFB?.name &&
        selectedHFB.number &&
        selectedHFB.number !== value?.homeFurnishingBusinessNumber
      ) {
        const {
          name: homeFurnishingBusinessName,
          number: homeFurnishingBusinessNumber,
        } = selectedHFB;
        setBusinessStructure({
          homeFurnishingBusinessName,
          homeFurnishingBusinessNumber,
          productAreaNumber: '',
        });
      }
    }
  }

  function handlePAinput(number: string) {
    if (
      availableHFBs &&
      productAreas &&
      businessStructure?.homeFurnishingBusinessNumber
    ) {
      const productRangeAreaNumber = number.slice(0, -1);
      const i = availableHFBs.findIndex(
        (hfb) => hfb.number === businessStructure.homeFurnishingBusinessNumber
      );
      const productRange = availableHFBs[i].productRanges?.find(
        (obj) => obj.number === productRangeAreaNumber
      );
      const productArea = productRange?.productAreas?.find(
        (obj) => obj.number === number
      );

      if (
        productRange?.name &&
        productRange.number &&
        productArea?.name &&
        productArea.number &&
        productArea.number !== value?.productAreaNumber
      ) {
        const { name: productRangeAreaName, number: productRangeAreaNumber } =
          productRange;
        const { name: productAreaName, number: productAreaNumber } =
          productArea;

        const obj = {
          ...businessStructure,
          productAreaName,
          productAreaNumber,
          productRangeAreaName,
          productRangeAreaNumber,
        };
        setBusinessStructure(obj);
      }
    }
  }

  useEffect(() => {
    if (data?.availableHFBs) {
      setAvailableHFBs(data.availableHFBs);
    }
  }, [data]);

  useEffect(() => {
    if (availableHFBs) {
      const selectedHFB = availableHFBs.find(
        ({ number }: HomeFurnishingBusiness) =>
          number === value?.homeFurnishingBusinessNumber
      );
      if (selectedHFB?.productRanges) {
        setProductAreas(
          selectedHFB.productRanges.flatMap((o) => o.productAreas!)
        );
      }
    }
  }, [availableHFBs, value?.homeFurnishingBusinessNumber]);

  useEffect(() => {
    if (businessStructure !== value) {
      setValue(businessStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessStructure]);

  const onHfbChange = (event: ChangeEvent<HTMLSelectElement>) =>
    handleHFBinput(event.target.value);

  const onPaChange = (event: ChangeEvent<HTMLSelectElement>) =>
    handlePAinput(event.target.value);

  return error ? null : (
    <FormField
      className={styles.field}
      disabled={loading}
      shouldValidate={!!value?.homeFurnishingBusinessNumber}
      valid={!!value?.productAreaNumber}
      data-cy="details-form-input-hfb"
    >
      <fieldset role="group" className={styles.hfbpa}>
        <Select
          id="homeFurnishingBusinessNumber"
          name="homeFurnishingBusinessNumber"
          label={t('hfb.short')}
          onChange={onHfbChange}
          onBlur={onBlur}
          disabled={loading || disabled}
          value={value?.homeFurnishingBusinessNumber || undefined}
          data-cy="details-form-input-hfb-select"
          className={styles.hfb}
        >
          {availableHFBs?.map(({ name, number }: HomeFurnishingBusiness) => (
            <Option
              value={number!}
              name={`${number} - ${name}`}
              key={`homeFurnishingBusiness-${number}`}
            />
          ))}
        </Select>

        <Select
          id="productAreaNumber"
          name="productAreaNumber"
          label={t('pa.short')}
          onChange={onPaChange}
          onBlur={onBlur}
          disabled={loading || !productAreas || disabled}
          value={value?.productAreaNumber || undefined}
          data-cy="details-form-input-pa-select"
          className={styles.pa}
        >
          {productAreas?.map(({ name, number }: ProductArea) => (
            <Option
              value={number!}
              name={`${number} - ${name}`}
              key={`productRange-${number}`}
            />
          ))}
        </Select>
      </fieldset>
    </FormField>
  );
};
