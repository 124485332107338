import { FunctionComponent, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import Skeleton from '@ingka/skeleton';
import SSRIcon from '@ingka/ssr-icon';
import iconDownload from '@ingka/ssr-icon/paths/arrow-down';
import iconBroken from '@ingka/ssr-icon/paths/image-strikethrough';
import Text from '@ingka/text';

import { Back, PageContent, Row, PageHeader } from 'components';
import { useButtonLink, usePrint } from 'hooks';
import { CircularItem } from 'types';

import styles from './item-print-view.module.scss';

export interface ItemPrintViewProps {
  circularItem?: CircularItem;
}

export const ItemPrintView: FunctionComponent<ItemPrintViewProps> = memo(
  ({ circularItem, ...rest }) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const [imageError, setImageError] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const { t } = useTranslation();
    const { print } = usePrint();
    const { buttonLinkHandler } = useButtonLink();

    const onClick = () => {
      if (imageRef.current?.src) {
        print(imageRef.current.src);
      }
    };

    const onImageLoaded = () => setImageLoaded(true);
    const onImageError = () => setImageError(true);

    return circularItem ? (
      <PageContent>
        <PageHeader divider={true}>
          <Row>
            <Back
              label={t('actions.back.detail')}
              href={`/inventory/${circularItem.id}`}
              onClick={buttonLinkHandler}
              dataCy={'Back-Button'}
            />
            <Text tagName="h1" headingSize="m" className={styles.title}>
              {t('printInterpolation', { id: circularItem.id })}
            </Text>
          </Row>
          <Row>
            {circularItem.labelImageUrl && (
              <a
                role="button"
                download={`CircularItemLabel-${circularItem.id}.jpg`}
                className="btn btn--small leading-icon btn--tertiary"
                href={circularItem.labelImageUrl}
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn__inner">
                  <SSRIcon paths={iconDownload} />
                  <span className="btn__label">{t('actions.download')}</span>
                </span>
              </a>
            )}
          </Row>
        </PageHeader>
        {circularItem.labelImageUrl && (
          <div className={styles.container}>
            <div className={styles.imageContainer}>
              {imageError ? (
                <div className={styles.error}>
                  <SSRIcon paths={iconBroken} />
                </div>
              ) : (
                !imageLoaded && <Skeleton className={styles.skeleton} />
              )}
              <img
                className={`${styles.image} ${
                  imageLoaded && styles.imageLoaded
                }`}
                src={circularItem.labelImageUrl}
                crossOrigin="anonymous"
                alt={t('printAlt')}
                onLoad={onImageLoaded}
                onError={onImageError}
                ref={imageRef}
              />
            </div>

            <div className={styles.buttons}>
              <Button
                className={styles.button}
                disabled={imageError}
                loading={!imageLoaded && !imageError}
                type="primary"
                onClick={onClick}
                text={t(
                  imageError
                    ? 'notifications.print.error'
                    : 'actions.print.long'
                )}
                small={true}
              />
            </div>
          </div>
        )}
      </PageContent>
    ) : null;
  }
);
