import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useMessage } from "hooks";
import { FactoryLocationState } from 'types';

export default function usePrinterProviderMock() {

  const location = useLocation<FactoryLocationState>();
  const { setCustomMessage } = useMessage();
  const [isInitialized, setIsInitialized] = useState(false);
  /** Watch the global `labels` state  */
  useEffect(() => {
    if (location.state?.labels) {
      setCustomMessage({
        title: 'Print data received',
        body: "Check the console for image URLs",
        variant: 'informative',
      });
      console.log("Print label URLs:") //eslint-disable-line no-console
      console.log(location.state.labels); //eslint-disable-line no-console
    }
  }, [location.state?.labels, setCustomMessage]);

  useEffect(() => {
    if (isInitialized) {
      return;
    }
    if (location.pathname === '/inventory' || location.pathname === "/sourcedBatches") {
      setCustomMessage({
        title: '🖨 Mock printer initialized',
        variant: 'informative',
      });
    }
    setIsInitialized(true);
  }, [location.pathname, setCustomMessage, isInitialized]);


  /**
   * Stub method. Receives zpl data but doesn't do anything with it - it's not human-readable,
   * so it's not really valuable to log it anywhere.
   */
  const onPrintDataReceived = (zpl: string) => {
    // Intentionally blank.
  }

  const mockProviderFunction = async (): Promise<
  ((zpl: string) => void) | undefined> => {
    return Promise.resolve(onPrintDataReceived);
  }

  return mockProviderFunction;
}
