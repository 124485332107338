import { SgfLocation } from 'types';

export const joinSgfWithDot = (obj: SgfLocation) => {
  const level = obj.level ? obj.level : '-';
  const location = obj.location ? obj.location : '-';
  const bay = obj.bay ? obj.bay : '-';

  const joined = `${bay}.${location}.${level}`;
  return joined;
};
