import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@ingka/skeleton';
import Table, { TableBody, TableHeader } from '@ingka/table';

import { StockListHeaderItem, StockListHeaderItemProps } from 'components';
import { SourcedBatch, SourcedBatchOrderBy } from 'types';
import { invertOrder } from 'utils';

import { BatchListRow } from './batch-list-row/batch-list-row';
import styles from './batch-list.module.scss';

export interface BatchListProps {
  items: SourcedBatch[];
  orderBy: SourcedBatchOrderBy;
  error: boolean;
  loading: boolean;
}

export const BatchList: FunctionComponent<BatchListProps> = memo(
  ({ items = [], orderBy, error, loading }) => {
    const { t } = useTranslation();
    const max = 10;
    const fallbackHeight = useMemo<number>(() => 77 + max * 83, [max]);

    const rowMapper = (batchItem: SourcedBatch) => {
      return <BatchListRow key={batchItem.id} sourcedBatch={batchItem} />;
    };

    const headers: StockListHeaderItemProps[] = [
      { label: t('id'), className: styles.colId },
      {
        label: t('source'),
        className: styles.colSource,
      },
      {
        label: t('amountOfItems'),
        className: styles.colAmount,
        title: t('amountOfItems'),
      },
      {
        label: t('created'),
        className: styles.colDate,
        title: t('created'),
        sortKey: 'created',
      },
    ];

      const headerMapper = ({
        sortKey,
        sortOrder: defaultSortOrder = 'DESC',
        label,
        className,
        title,
      }: StockListHeaderItemProps) => {
        const { field, order } = orderBy;
        const active = !!sortKey && sortKey === field;
        const sortOrder = sortKey
          ? active && order
            ? invertOrder(order)
            : defaultSortOrder
          : undefined;

        return (
          <StockListHeaderItem
            key={label}
            label={label}
            className={className}
            sortKey={sortKey}
            sortOrder={sortOrder}
            active={active}
            title={title}
          />
        );
      };

    return error ? null : loading ? (
      <Skeleton height={`${fallbackHeight}px`} />
    ) : items?.length ? (
      <Table className={styles.table}>
        <TableHeader>
          <tr className={styles.tableHeader}>{headers.map(headerMapper)}</tr>
        </TableHeader>
        <TableBody>{items.map(rowMapper)}</TableBody>
      </Table>
    ) : null;
  }
);
