import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { DialoguePrompt } from "components/_common"

import { DialogueProps } from "../dialogue-props";

export interface ConfirmMoveBatchDialogueProps extends DialogueProps {
  /** The number of items to be moved to the new batch. Used for messaging. */
  quantity: number;

  /** If moving to an existing batch, the destination batch ID. Used for messaging. */
  batchId?: string;
}

export const ConfirmMoveBatchDialogue: FC<ConfirmMoveBatchDialogueProps> = memo(
  ({
    displayDialogue,
    setDisplayDialogue,
    handleDialogueResponse,
    quantity,
    batchId
  }: ConfirmMoveBatchDialogueProps) => {


    const { t } = useTranslation();
    const getDialogueBody = (): string => {
      return t('dialogues.confirmSourceChange.body', { quantity, batchId })
    }

    return (
      <DialoguePrompt
        id={"prompt-confirm-move"}
        compact={true}
        displayDialogue={displayDialogue}
        setDisplayDialogue={setDisplayDialogue}
        handleDialogueResponse={handleDialogueResponse}
        positiveBtnText={t('actions.assignToBatch.positive')}
        negativeBtnText={t('actions.assignToBatch.negative')}
        promptTitle={t('dialogues.confirmSourceChange.title')}
        promptBody={getDialogueBody()}
      />
    )

  })
