import { useMutation } from '@apollo/client';

import { mutationGradeItem } from 'services';
import {
  MutationGradeItemResult,
  MutationGradeItemArgs,
  GradeValue,
  CircularItem,
  CircularItemGrade,
  CircularItemActivity,
} from 'types';

export const useGradeItem = () => {
  const [gradeItemQuery, gradeItemResult] = useMutation<
    MutationGradeItemResult,
    MutationGradeItemArgs
  >(mutationGradeItem);

  const _gradeItem = async (
    grade: GradeValue,
    id: string,
    activityId?: string
  ) => {
    await gradeItemQuery({
      variables: {
        input: { circularItemId: id, grade: grade, activityId: activityId },
      },
      update(cache, { data }) {
        if (!data?.gradeItem) {
          return;
        }

        cache.modify({
          id: cache.identify({
            __typename: 'CircularItem',
            id: data.gradeItem.circularItemId,
          } as CircularItem),

          fields: {
            currentGrade: () => {
              return {
                id: data.gradeItem.id,
                value: data.gradeItem.value,
              } as CircularItemGrade;
            },
          },
        });

        if (activityId && data.gradeItem.activity?.id) {
          cache.modify({
            id: cache.identify({
              __typename: 'CircularItemActivity',
              id: data.gradeItem.activity.id,
            } as CircularItemActivity),

            fields: {
              grade: () => {
                return {
                  id: data.gradeItem.id,
                  value: data.gradeItem.value,
                } as CircularItemGrade;
              },
            },
          });
        }
      },
    });
  };

  const gradeItem = async (grade: GradeValue, id: string) => {
    _gradeItem(grade, id);
    // Backend sends no error if item with ID not exists
  };

  const gradeItemActivity = async (
    grade: GradeValue,
    id: string,
    activityId: string
  ) => {
    _gradeItem(grade, id, activityId);
  };

  return {
    gradeItem,
    gradeItemActivity,
    gradeItemResult,
  };
};
