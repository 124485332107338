import { FC } from "react"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import Text from '@ingka/text';


import { useCurrency } from "hooks/use-currency/use-currency";
import { CircularItem } from "types";
import { formatDate, omitTypenames, joinSgfWithDot, formatDateShort } from "utils";

import styles from './item-details.module.scss'

export interface ItemDetailProps {
  circularItem: CircularItem;
}

export const ItemDetails : FC<ItemDetailProps> = ({
  circularItem
}) => {

  const {t} = useTranslation();
  const {formatCurrency} = useCurrency();
  return (
    <dl className={styles.details}>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('uniqueId')}</Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="item-details-view-id">
        {circularItem.id || ''}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('source')}</Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-source">
        {circularItem.source?.name || ''}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('sourcingDate')}</Text>
    </dt>
    <dd>{formatDateShort(circularItem.sourcingTimestamp)}</dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('assignedToBatch')}</Text>
    </dt>
    <dd>
      {circularItem?.sourcedBatch ? (
        <Link
          to={`/sourcedBatch/${circularItem?.sourcedBatch.id}`}
          data-cy="details-view-batch-id"
        >
          {circularItem?.sourcedBatch.id}
        </Link>
      ) : (
        ''
      )}
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">
        <abbr title={t('hfb.long')}>{t('hfb.short')}</abbr>
      </Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-hfb">
        {
          circularItem.businessStructure
            ?.homeFurnishingBusinessName!
        }
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">
        <abbr title={t('pa.long')}>{t('pa.short')}</abbr>
      </Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-pa">
        {circularItem.businessStructure?.productAreaName!}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('currentGrade')}</Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-grade">
        {circularItem.currentGrade?.value}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('forFaaS')}</Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-faas">
        {circularItem.eligibleForFAAS === true
          ? t('yes')
          : circularItem.eligibleForFAAS === false
          ? t('no')
          : ''}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('sgf.title')}</Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-sgf">
        {circularItem.sgfLocation
          ? joinSgfWithDot(omitTypenames(circularItem.sgfLocation!))
          : ''}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">
        {t('originalSalesPrice')}
      </Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-original-value">
        {formatCurrency(circularItem?.regularItemSalesPriceInclTax, circularItem?.cpc?.defaultCurrencyCode)}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">
        {t('estimatedSalesPrice.heading')}
      </Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-estimated-value">
        {formatCurrency(circularItem?.estimatedSalesPrice, circularItem?.cpc?.defaultCurrencyCode)}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('manufacturingDate')}</Text>
    </dt>
    <dd>
      <Text
        tagName="span"
        data-cy="details-view-manufacturing-date"
      >
        {circularItem.manufacturingDate || ''}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('sekundReference')}</Text>
    </dt>
    <dd>
      <Text
        tagName="span"
        data-cy="details-view-sekund-ref"
      >
        {circularItem.sekundReference || ''}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('supplierNumber')}</Text>
    </dt>
    <dd>
      <Text tagName="span" data-cy="details-view-product-supply-id">
        {circularItem.supplierNumber || ''}
      </Text>
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('shipment.atReference')}</Text>
    </dt>
    <dd>
      {circularItem?.atExternalReference ? (
        <Link
          to={`/reference/${circularItem?.atExternalReference?.id}`}
          data-cy="details-view-product-at-reference-link"
        >
          {circularItem?.atExternalReference?.externalReference}
        </Link>
      ) : (
        ''
      )}
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('shipment.assigned')}</Text>
    </dt>
    <dd>
      {circularItem?.assignedShipment ? (
        <Link
          to={`/shipments/${circularItem?.assignedShipment?.id}`}
          data-cy="details-view-product-assigned-shipment"
        >
          {circularItem?.assignedShipment?.id}
        </Link>
      ) : (
        ''
      )}
    </dd>
    <dt className={styles.key}>
      <Text headingSize="xs">{t('comment')}</Text>
    </dt>
    <dd>
      <div className={styles.comment}>
        {circularItem.note?.text ? (
          <>
            <Text tagName="span">{circularItem.note.text}</Text>
            <Text tagName="span" className={styles.commentBy}>
              <span>
                <b>{t('lastEdit')}</b>:{' '}
                {formatDate(circularItem.note.updatedTimestamp)}{' '}
              </span>
              <span>
                {circularItem.note.lastUpdatedByUser?.name}
              </span>
            </Text>
          </>
        ) : (
          ''
        )}
      </div>
    </dd>
  </dl>
  )
}
