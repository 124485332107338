import { FormikHelpers } from 'formik';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AspectRatioImage from '@ingka/aspect-ratio-image';
import Skeleton from '@ingka/skeleton';
import copy from '@ingka/ssr-icon/paths/copy';
import documentAdd from '@ingka/ssr-icon/paths/document-add';
import documentPencil from '@ingka/ssr-icon/paths/document-pencil';
import moneyCash from '@ingka/ssr-icon/paths/money-cash';
import printer from '@ingka/ssr-icon/paths/printer';
import Text from '@ingka/text';

import { globalLoadingAtom } from 'atoms';
import {
  PageContent,
  PageHeader,
  Row,
  Back,
  AddActivity,
  Columns,
  MenuTiny,
  MenuItemProps,
  StatusText,
  RegisterSaleModal,
  UpsertShipmentModal,
  UpsertShipmentFormValues,
} from 'components';
import { CopyButton } from 'components/_buttons/copy-button/copy-button';
import { EditActivityFormValues, EditActivityModal } from 'components/_modals/edit-activity-modal/edit-activity-modal';
import { ConfirmDeleteActivityDialogue } from 'components/_prompts/confirm-delete-activity/confirm-delete-activity';
import { useButtonLink, useMessage } from 'hooks';
import { useRemoveActivity } from 'hooks/use-remove-activity/use-remove-activity';
import { useShipment } from 'hooks/use-shipment/use-shipment';
import { useUpdateActivity } from 'hooks/use-update-activity/use-update-activity';
import { Activity, CircularItem } from 'types';
import {
  formatItemNumberWithRegex,
} from 'utils';

import styles from './item-detail-view.module.scss';
import { ItemDetails } from './item-details/item-details';
import { ItemHistory } from './item-history/item-history';


export interface ItemDetailViewProps {
  circularItem?: CircularItem;
  onDuplicateClick: (circularItem: CircularItem) => void;
}

export const ItemDetailView: FunctionComponent<ItemDetailViewProps> = ({ circularItem, onDuplicateClick }) => {
    const { t } = useTranslation();
    const {removeActivity} = useRemoveActivity();
    const globalLoading = useAtomValue(globalLoadingAtom);
    const { buttonLinkHandler } = useButtonLink();
    const message = useMessage();
    const [pendingEditActivity, setPendingEditActivity] = useState<Activity>();
    const { updateItemActivity } = useUpdateActivity()
    const [pendingDeleteActivity, setPendingDeleteActivity] = useState<Activity>();
    const [confirmDeleteActivityVisible, setConfirmDeleteActivityVisible] = useState(false);
    const [upsertShipmentVisible, setUpsertShipmentVisible] = useState(false);
    const [registerSaleVisible, setRegisterSaleVisible] = useState(false);
    const [upsertShipmentError, setUpsertShipmentError] = useState(false);
    const [upsertShipmentErrorMsg, setUpsertShipmentErrorMsg] = useState<
      string | undefined
    >(undefined);
    const { createShipment, addShipmentItems } = useShipment();

    const editActivityVisible = !!pendingEditActivity;

    const isAvailableForShipment = () => {
      return (
        circularItem?.status === 'available' ||
        circularItem?.status === 'pending'
      );
    };

    const handleRegisterSaleClick = () => {
      setRegisterSaleVisible(true);
    };

    const handleDuplicateClick = () => {
      if (circularItem) {
        onDuplicateClick(circularItem);
      }
    };

    const handleAddToShipmentClick = () => {
      setUpsertShipmentVisible(true);
    };

    const handleConfirmDeleteActivityResponse = (response: boolean) => {
      if (response) {
        if (!pendingDeleteActivity) {
          throw new Error("User attempted to delete an activity but pending activity was undefined");
        }
        removeActivity(pendingDeleteActivity.id)
        message.setCustomMessage({title: t("notifications.delete.success", {type: t("activity")}), variant: 'positive'});
      }
      setPendingDeleteActivity(undefined);
      setConfirmDeleteActivityVisible(false);
    }

    const handleDeleteActivity = (activity: Activity) => {
      setPendingDeleteActivity(activity);
      setConfirmDeleteActivityVisible(true);
    }

    const handleEditActivity = (activity: Activity) => {
      // Activities coming from GraphQL are in a different format from what the EditActivity modal expects, therefore
      // we need to massage it first.
      setPendingEditActivity(activity);
    }

    const onEditActivityModalClose = () => {
      setPendingEditActivity(undefined);
    }

    const onEditActivitySubmit = async (values: EditActivityFormValues) => {
      const [activity] = values.activities;
      await updateItemActivity(pendingEditActivity!.id, activity.type!, parseInt(activity.duration!), values.grade)
      setPendingEditActivity(undefined);
    }

    const onCreateShipmentClick = async (
      {
        externalRef,
        externalRefType,
        shipmentId,
        shipmentType,
      }: UpsertShipmentFormValues,
      { setSubmitting }: FormikHelpers<UpsertShipmentFormValues>
    ) => {
      if (!circularItem?.id || !circularItem.cpc?.id) {
        setSubmitting(false);
        setUpsertShipmentError(true);
        return;
      }

      try {
        const result =
          shipmentType === 'new'
            ? await createShipment(
                [circularItem?.id],
                circularItem.cpc.id,
                externalRef,
                externalRefType !== 'new'
              )
            : await addShipmentItems([circularItem?.id], shipmentId);

        if (!result) {
          setUpsertShipmentError(true);
        }

        setSubmitting(false);
        setUpsertShipmentVisible(false);
      } catch (error: any) {
        setUpsertShipmentErrorMsg(error.message);
        setUpsertShipmentError(true);
      }
    };

    const menuItems: MenuItemProps[] = [
      {
        ssrIcon: documentPencil,
        text: t('actions.edit'),
        href: `/inventory/${circularItem?.id}/edit`,
        enabled: true,
        dataCy: 'tiny-menu-item-edit',
      },
      {
        ssrIcon: documentAdd,
        text: t('actions.addToShipment'),
        onClick: handleAddToShipmentClick,
        enabled: isAvailableForShipment(),
        dataCy: 'tiny-menu-item-shipment',
      },
      {
        ssrIcon: moneyCash,
        text: t('actions.registerSale'),
        onClick: handleRegisterSaleClick,
        enabled: true,
        dataCy: 'tiny-menu-item-sale',
      },
      {
        ssrIcon: printer,
        text: t('actions.print.short'),
        href: `/inventory/${circularItem?.id}/print`,
        enabled: true,
        dataCy: 'tiny-menu-item-print',
      },

      {
        ssrIcon: copy,
        text: t('actions.duplicate'),
        enabled: true,
        onClick: handleDuplicateClick,
        dataCy: 'tiny-menu-item-duplicate',
      },
    ];

    return circularItem ? (
      <PageContent>
        
        {/* MODALS AND PROMPTS */}
        <UpsertShipmentModal
          numberOfItems={1}
          visible={upsertShipmentVisible}
          error={upsertShipmentError}
          submitErrorMsg={upsertShipmentErrorMsg}
          onSubmit={onCreateShipmentClick}
          onClose={() => setUpsertShipmentVisible(false)}
        />
        <RegisterSaleModal
          visible={registerSaleVisible}
          onClose={() => setRegisterSaleVisible(false)}
          circularItemId={circularItem.id}
        />
        {pendingEditActivity && (
          <EditActivityModal 
            visible={editActivityVisible}
            onSubmit={onEditActivitySubmit}
            onClose={onEditActivityModalClose}
            activity={pendingEditActivity}
            />
        )}
        <ConfirmDeleteActivityDialogue
          displayDialogue={confirmDeleteActivityVisible}
          setDisplayDialogue={setConfirmDeleteActivityVisible}
          handleDialogueResponse={handleConfirmDeleteActivityResponse}
        />

        {/* ITEM DETAIL PAGE */}
        <PageHeader className={styles.header} divider={true}>
          <Row position="start" className={styles.start}>
            <Back
              label={t('actions.back.root')}
              onClick={buttonLinkHandler}
              href="/inventory"
              dataCy={'Back-Button'}
            />
            {circularItem?.id ? (
              <>
                <Text tagName="h1" headingSize="m" className={styles.title}>
                  {t('idInterpolation', { id: circularItem.id })}
                </Text>
                <CopyButton value={circularItem.id} className={styles.copyBtn} forceConfirmInline/>
              </>
            ) : (
              <Skeleton height="34px" />
            )}
          </Row>
          {circularItem?.id && (
            <Row position="end">
              <AddActivity
                id={circularItem.id}
                currentGrade={circularItem.currentGrade?.value}
              />
              <MenuTiny menuItems={menuItems} />
            </Row>
          )}
        </PageHeader>
        {circularItem && !globalLoading && (
          <Columns data-cy="item-detail-view-columns">
            <figure>
              <AspectRatioImage
                src={
                  circularItem.retailItem?.mainProductImages?.[
                    circularItem.retailItem.mainProductImages.length - 1
                  ]?.href ||
                  circularItem?.images?.[0]?.image.href ||
                  ''
                }
                ratio="square"
                data-cy="details-view-image"
              />
            </figure>
            <section>
              <StatusText
                value={{
                  type: circularItem.status,
                  value: circularItem.status,
                }}
              />
              <Text
                tagName="h2"
                headingSize="m"
                data-cy="details-view-product-name"
                className={styles.productName}
              >
                {circularItem.globalProductName || ''}
              </Text>
              <Text className={styles.productDescription}>
                {circularItem.productDescription || ''}
              </Text>
              {circularItem.itemNumber && (
                <div className={styles.artNo}>
                  <Text tagName='span' headingSize="s" data-cy='details-view-article-number'>
                    {formatItemNumberWithRegex(circularItem.itemNumber)}
                  </Text>
                  <CopyButton value={formatItemNumberWithRegex(circularItem.itemNumber)} className={styles.copyBtn}/>
                </div>
              )}
              <ItemDetails circularItem={circularItem}/>
              <ItemHistory
                circularItemId={circularItem.id}
                onEditActivityClicked={handleEditActivity}
                onDeleteActivityClicked={handleDeleteActivity} />
            </section>
          </Columns>
        )}
      </PageContent>
    ) : null;
  }
