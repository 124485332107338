import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '@ingka/form-field';
import Select, { Option } from '@ingka/select';

import { useSources } from 'hooks/use-sources/use-sources';
import { FactoryFormFieldProps } from 'types';

import styles from './source-input.module.scss';

interface SourceInputProps extends FactoryFormFieldProps {}

export const SourceInput: FC<SourceInputProps> = ({
  onChange,
  onBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslation();

  const { loading, data } = useSources();

  const sourceMapper = ({ id, name }: any) => {
    return <Option value={id} name={name} key={`sourceOption-${id}`} />;
  };

  //
  return (
    <FormField className={styles.field}>
      <Select
        id="sourceId"
        name="sourceId"
        label={t('source')}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled || loading || !data?.sources}
        value={value}
        data-cy="details-form-source"
      >
        {data?.sources?.map(sourceMapper)}
      </Select>
    </FormField>
  );
};
