import { useAtomValue } from 'jotai/utils';
import { useEffect, memo, FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

import { globalAppErrorAtom, globalLoadingAtom } from 'atoms';
import { ShipmentBulkMenu } from 'components';
import { DEFAULT_PAGE_SIZE } from 'config';
import { PaginationProvider } from 'context';
import { usePageParams } from 'hooks';
import { FactoryLocationState } from 'types';

import { useShipmentStock } from '../hooks/use-shipment-stock';
import { ShipmentStockView } from '../shipment-stock-view/shipment-stock-view';

export const ShipmentStockPage: FunctionComponent = memo(() => {
  const location = useLocation<FactoryLocationState>();
  const { search, state } = location;
  const error = useAtomValue(globalAppErrorAtom);
  const loading = useAtomValue(globalLoadingAtom);

  const {
    page,
    pages,
    skip,
    searchTerm,
    shipmentOrder,
    shipmentFilter,
    updatePageParams,
    gotoPage,
    setPages,
  } = usePageParams();

  const { stockShipments, total, removeShipments } = useShipmentStock({
    skip,
    limit: DEFAULT_PAGE_SIZE,
    searchTerm,
    order: shipmentOrder,
    filter: shipmentFilter,
  });

  useEffect(() => {
    updatePageParams(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setPages(Math.ceil(total / DEFAULT_PAGE_SIZE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return error && loading && !pages ? null : (
    <PaginationProvider value={{ page, pages, setPage: gotoPage }}>
      {stockShipments && (
        <>
          <ShipmentBulkMenu
            removeShipments={removeShipments}
            menuItemKeys={['remove']}
          />
          <ShipmentStockView
            shipments={stockShipments}
            limit={DEFAULT_PAGE_SIZE}
            skip={skip}
            total={total}
            orderBy={shipmentOrder}
            highlighted={state?.created || []}
          />
        </>
      )}
    </PaginationProvider>
  );
});
