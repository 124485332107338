import { FormikHelpers, useFormik } from 'formik';
import {
  ChangeEvent,
  FunctionComponent,
  memo,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  nonVerifiedItemSchema,
  verifiedItemSchema,
} from 'yup-schemas/yup-schemas';

import Button from '@ingka/button';
import Checkbox from '@ingka/checkbox';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Switch from '@ingka/switch';
import Text from '@ingka/text';

import {
  CreateItemPageParameters,
  DetailsFormBusinessStructure,
  DetailsFormItemNumber,
  DetailsFormManufacturingDate,
  DetailsFormSgf,
  DialoguePrompt,
  EstimatedValueDisplay,
  GradeInput,
} from 'components';
import { useAuth, useDuplicateItem, useFormContext } from 'hooks';
import {
  FactoryComponentProps,
  GradeValue,
  RetailItemBusinessStructureInput,
  SgfLocationInput,
  Status,
} from 'types';
import { formatItemNumberWithRegex, omitTypenames } from 'utils';

import styles from './details-form.module.scss';
export interface DetailsFormInputs {
  status?: Status;
  productName?: string;
  productDescription?: string;
  itemNumber?: string;
  businessStructure?: RetailItemBusinessStructureInput;
  itemQuantity?: number;
  grade?: GradeValue;
  estimatedSalesPrice?: string;
  regularItemSalesPriceInclTax?: string;
  regularItemSalesPriceCurrencyCode?: string;
  sekundReference?: string;
  supplierNumber?: string;
  sgfLocation?: SgfLocationInput;
  year?: string;
  week?: string;
  FAASCustomer?: string;
  eligibleForFAAS?: boolean;
  tags?: string[];
  print?: boolean;
  sourcedBatchId?: string;
  sourcingTimestamp?: Date;
}

export interface DetailsFormProps extends FactoryComponentProps {
  onSubmit(
    values: DetailsFormInputs,
    helpers: FormikHelpers<DetailsFormInputs>
  ): void;
  loading?: boolean;
  initialValues?: DetailsFormInputs;
  discount?: number;
  disabled?: boolean;
  setGrade?(grade: GradeValue): void;
  setPrintDialogueResponse: (value: boolean) => void;
  onCancel?: MouseEventHandler;
}

const defaultValues: DetailsFormInputs = {
  status: undefined,
  productName: '',
  productDescription: '',
  itemNumber: '',
  itemQuantity: 1,
  businessStructure: undefined,
  grade: undefined,
  estimatedSalesPrice: '',
  regularItemSalesPriceInclTax: '',
  regularItemSalesPriceCurrencyCode: '',
  supplierNumber: '',
  sgfLocation: { bay: '', location: '', level: '' },
  FAASCustomer: '',
  eligibleForFAAS: undefined,
  year: undefined,
  week: undefined,
  tags: [],
  print: false,
};

export const DetailsForm: FunctionComponent<DetailsFormProps> = memo(
  ({ onSubmit, onCancel, loading = false, setPrintDialogueResponse, disabled }) => {
    const { t } = useTranslation();
    const { paramItemNumber } = useParams<CreateItemPageParameters>();

    const { retailItem } = useFormContext();
    const { getCurrentCpc } = useAuth();
    const [displayPrintDialogue, setDisplayPrintDialogue] =
      useState<boolean>(false);
    const { duplicateItemValues, setDuplicateItemValues } = useDuplicateItem();
    const [initialValues, setInitialValues] = useState<
      DetailsFormInputs | undefined
    >(undefined);
    const [hfbError, setHfbError] = useState<boolean>(false);
    const {
      isSubmitting,
      values,
      touched,
      errors,
      handleSubmit,
      setFieldValue,
      handleBlur,
      handleChange,
      setValues,
    } = useFormik<DetailsFormInputs>({
      initialValues: { ...defaultValues, ...initialValues },
      onSubmit,
      validationSchema: retailItem ? verifiedItemSchema : nonVerifiedItemSchema,
      enableReinitialize: true,
    });

    const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue('productName', event.target.value.toLocaleUpperCase());
    };

    const setItemNumber = (itemNumberInput: string) => {
      setFieldValue('itemNumber', formatItemNumberWithRegex(itemNumberInput));
    };

    const setBusinessStructureValue = (
      businessStructure: RetailItemBusinessStructureInput
    ) => {
      setFieldValue('businessStructure', businessStructure, false);
    };

    const setGradeValue = (grade: GradeValue) =>
      setFieldValue('grade', grade, false);

    const setSgfLocation = (sgfLocation: SgfLocationInput) => {
      setFieldValue('sgfLocation', sgfLocation, false);
    };

    const onForFAASChange = () => {
      setFieldValue('eligibleForFAAS', !values.eligibleForFAAS);
    };

    const openPrintDialogue = () => {
      setDisplayPrintDialogue(true);
    };

    useEffect(() => {
      return () => {
        setDuplicateItemValues(null);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (values.businessStructure?.homeFurnishingBusinessNumber) {
        setHfbError(values.businessStructure.productAreaNumber ? false : true);
      }
    }, [values.businessStructure]);

    useEffect(() => {
      if (duplicateItemValues) {
        setInitialValues(duplicateItemValues);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duplicateItemValues]);

    const handleSetRetailItem = () => {
      if (retailItem) {
        setValues({
          ...values,
          productName: retailItem.globalProductName || undefined,
          productDescription: retailItem.productDescription || undefined,
          itemNumber: formatItemNumberWithRegex(retailItem.itemNumber),
          businessStructure: retailItem.businessStructure
            ? omitTypenames(retailItem.businessStructure)
            : undefined,
          regularItemSalesPriceInclTax:
            retailItem.regularItemSalesPriceInclTax?.toString(),
        });
      }
    };

    useEffect(() => {
      if (paramItemNumber && retailItem?.itemNumber === paramItemNumber) {
        setValues({
          ...values,
          productName: retailItem.globalProductName || undefined,
          productDescription: retailItem.productDescription || undefined,
          itemNumber: formatItemNumberWithRegex(retailItem.itemNumber),
          businessStructure: retailItem.businessStructure
            ? omitTypenames(retailItem.businessStructure)
            : undefined,
          regularItemSalesPriceInclTax:
            retailItem.regularItemSalesPriceInclTax?.toString(),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retailItem]);

    const handleDialogResponse = (value: boolean) => {
      setPrintDialogueResponse(value);
      handleSubmit();
    };

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <section className={styles.formSection}>
          <Text headingSize="m" className={styles.heading}>
            {t('generalInformation')}
          </Text>
          <FormField
            className={styles.field}
            disabled={isSubmitting || loading}
            shouldValidate={touched.itemQuantity}
            valid={!errors.itemQuantity}
            validation={
              errors.itemQuantity
                ? {
                    msg: errors.itemQuantity,
                    id: 'itemQuantity-error',
                  }
                : undefined
            }
          >
            <InputField
              id="itemQuantity"
              name="itemQuantity"
              type="number"
              label={t('quantity')}
              disabled={isSubmitting || loading}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.itemQuantity}
            />
          </FormField>
          <FormField className={styles.field}>
            <InputField
              id="productName"
              name="productName"
              type="text"
              label={t('productName')}
              disabled={
                isSubmitting || loading || (!!values.itemNumber && !!retailItem)
              }
              onChange={onProductNameChange}
              onBlur={handleBlur}
              value={values.productName}
              data-cy="details-form-input-product-name"
            />
          </FormField>
          <FormField className={styles.field}>
            <InputField
              id="productDescription"
              name="productDescription"
              type="text"
              maxLength={75}
              label={t('productDescription')}
              disabled={
                isSubmitting || loading || (!!values.itemNumber && !!retailItem)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.productDescription}
              data-cy="details-form-input-product-description"
            />
            <Text className={styles.exampleDescription} bodySize={'s'}>
              {t('exProductDescription')}
            </Text>
          </FormField>
          <DetailsFormItemNumber
            className={styles.field}
            disabled={isSubmitting || loading}
            onBlur={handleBlur}
            setValue={setItemNumber}
            itemNumberError={errors.itemNumber}
            itemNumberTouched={!!touched.itemNumber}
            itemNumberInput={values.itemNumber}
            handleSetRetailItem={handleSetRetailItem}
          />
          <DetailsFormBusinessStructure
            loading={isSubmitting || loading}
            onBlur={handleBlur}
            value={values.businessStructure}
            setValue={setBusinessStructureValue}
            disabled={
              isSubmitting || loading || (!!values.itemNumber && !!retailItem)
            }
          />
          <FormField
            className={styles.grade}
            valid={!(touched.grade && errors.grade)}
            validation={
              touched.grade && errors.grade
                ? {
                    msg: errors.grade,
                    id: 'grade-error',
                  }
                : undefined
            }
            disabled={isSubmitting || loading}
          >
            <Text headingSize="s" className={styles.gradeHeading}>
              {t('grade')}
            </Text>
            <GradeInput
              loading={loading || isSubmitting}
              className={styles.gradeInput}
              empty={!values?.grade}
              value={values.grade}
              setValue={setGradeValue}
            />
          </FormField>
        </section>
        <section className={styles.formSection}>
          <Text headingSize="m" className={styles.heading}>
            {t('price')}
          </Text>
          {retailItem && (
            <EstimatedValueDisplay
              gradeValue={values.grade}
              regularItemSalesPriceInclTax={
                retailItem?.regularItemSalesPriceInclTax!
              }
              regularItemSalesPriceCurrencyCode={
                retailItem.regularItemSalesPriceCurrencyCode!
              }
              itemNumber={retailItem.itemNumber}
            />
          )}
          {!retailItem && (
            <FormField
              className={styles.field}
              shouldValidate={touched.regularItemSalesPriceInclTax}
              valid={!errors.regularItemSalesPriceInclTax}
              validation={
                errors.regularItemSalesPriceInclTax
                  ? {
                      msg: errors.regularItemSalesPriceInclTax,
                      id: 'regularItemSalesPriceInclTax-error',
                    }
                  : undefined
              }
            >
              <InputField
                id="regularItemSalesPriceInclTax"
                name="regularItemSalesPriceInclTax"
                type="number"
                inputMode="number"
                label={t('originalSalesPrice')}
                suffixLabel={getCurrentCpc().defaultCurrencyCode}
                disabled={isSubmitting || loading}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.regularItemSalesPriceInclTax}
                data-cy="details-form-regular-item-sales-price"
              />
            </FormField>
          )}

          <FormField
            className={styles.field}
            shouldValidate={touched.estimatedSalesPrice}
            valid={!errors.estimatedSalesPrice}
            validation={
              errors.estimatedSalesPrice
                ? {
                    msg: errors.estimatedSalesPrice,
                    id: 'estimatedSalesPrice-error',
                  }
                : undefined
            }
          >
            <InputField
              id="estimatedSalesPrice"
              name="estimatedSalesPrice"
              type="number"
              inputMode="number"
              label={t('recSalesPrice')}
              suffixLabel={getCurrentCpc().defaultCurrencyCode}
              disabled={isSubmitting || loading}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.estimatedSalesPrice}
              data-cy="details-form-estimated-value"
            />
          </FormField>
        </section>

        <section className={styles.formSection}>
          <Text
            headingSize="m"
            className={styles.heading && styles.additionalInformation}
          >
            {t('additionalInformation')}
          </Text>
          <FormField>
            <InputField
              id="sekundReference"
              name="sekundReference"
              className={styles.field}
              type="text"
              label={t('sekundReference')}
              data-cy="details-form-input-sekund-ref"
              disabled={isSubmitting || loading}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.sekundReference}
            />
          </FormField>
          <DetailsFormManufacturingDate
            loading={isSubmitting || loading}
            year={values.year}
            week={values.week}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isSubmitting || loading}
          />
          <FormField
            className={styles.field}
            shouldValidate={
              touched.supplierNumber && !!values.supplierNumber?.length
            }
            valid={!errors.supplierNumber}
            validation={
              errors.supplierNumber
                ? {
                    msg: errors.supplierNumber,
                    id: 'supplierNumber-error',
                  }
                : undefined
            }
          >
            <InputField
              id="supplierNumber"
              name="supplierNumber"
              type="number"
              inputMode="number"
              label={t('supplierNumber')}
              disabled={isSubmitting || loading}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.supplierNumber}
              data-cy="details-form-supplier-number"
            />
          </FormField>
          <DetailsFormSgf
            loading={loading || isSubmitting}
            value={values?.sgfLocation}
            onBlur={handleBlur}
            setValue={setSgfLocation}
            disabled={isSubmitting || loading}
          />
        </section>

        <section className={styles.formSection}>
          <Text headingSize="m">{t('faas')}</Text>
          <FormField
            className={styles.field}
            disabled={isSubmitting || loading}
          >
            <fieldset role="group" className="radio-button-group">
              <legend>{t('eligibleForFaaS')}</legend>
              <Switch
                name="eligibleForFAAS"
                id="eligibleForFAAS"
                onChange={onForFAASChange}
                onBlur={handleBlur}
                disabled={loading}
                subtle={true}
                value="eligibleForFAAS"
                label={
                  values.eligibleForFAAS === undefined
                    ? ''
                    : values.eligibleForFAAS === true
                    ? t('yes')
                    : t('no')
                }
                checked={values.eligibleForFAAS}
                data-cy="details-form-for-faas"
              />
            </fieldset>
          </FormField>
          {values.eligibleForFAAS && (
            <FormField
              className={styles.field}
              disabled={isSubmitting || loading}
            >
              <InputField
                id="FAASCustomer"
                name="FAASCustomer"
                type="text"
                label={t('FaaSCustomer')}
                disabled={isSubmitting || loading}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.FAASCustomer}
              />
            </FormField>
          )}
        </section>

        <section className={styles.formSection}>
          <Text headingSize="m" className={styles.heading}>
            {t('print')}
          </Text>
          <FormField
            className={styles.field}
            disabled={isSubmitting || loading}
          >
            <Checkbox
              id="print"
              name="print"
              value="print"
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('actions.print.custom')}
              disabled={isSubmitting || loading}
            />
          </FormField>
        </section>

        <div className={styles.actions}>
          <DialoguePrompt
            displayDialogue={displayPrintDialogue}
            setDisplayDialogue={setDisplayPrintDialogue}
            handleDialogueResponse={handleDialogResponse}
            positiveBtnText={t('actions.print.dialogue.positive')}
            negativeBtnText={t('actions.print.dialogue.negative')}
            promptTitle={t('dialogues.print.title', {
              quantity: values.itemQuantity,
            })}
            promptBody={t('dialogues.print.body', {
              quantity: values.itemQuantity,
            })}
          />
          <Button
            type="primary"
            loading={isSubmitting}
            disabled={loading || hfbError || disabled}
            onClick={
              values.itemQuantity! > 1 && values.print
                ? openPrintDialogue
                : handleSubmit
            }
            text={t('actions.save')}
            data-cy="details-form-submit"
          />
          {!!onCancel && (
            <Button
              type="secondary"
              loading={isSubmitting}
              disabled={loading}
              onClick={onCancel}
              text={t('actions.cancel')}
            />
          )}
        </div>
      </form>
    );
  }
);
