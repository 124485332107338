import { createContext } from 'react';

import { RetailItem } from 'types';

export interface FormContextValues {
  itemNumber?: string | null;
  retailItem?: RetailItem | null;
  setItemNumber(value: string | null): void;
  setRetailItem(value: RetailItem | null): void;
}

export const defaultFormContextValues: FormContextValues = {
  itemNumber: null,
  retailItem: undefined,
  setItemNumber: (value: string | null) => {},
  setRetailItem: (value: RetailItem | null) => {},
};

export const FormContext = createContext<FormContextValues>(
  defaultFormContextValues
);

export const FormProvider = FormContext.Provider;
