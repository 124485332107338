import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { selectedItemsClearAtom } from 'atoms';
import { ItemBulkMenu, SourcedBatchDetailsView } from 'components';
import { useMessage, usePrint, useUpdateSourcedBatch } from 'hooks';

import { useSourcedBatch } from '../hooks/useSourcedBatch';
import { UpdateSourcedBatchFormValues } from '../sourced-batch-details-form/sourced-batch-details-form';

export interface SourcedBatchDetailsPageProps {
  id: string;
}

export const SourcedBatchDetailsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [printItems, setPrintItems] = useState<string[]>();
  const { bulkPrint } = usePrint();
  const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);

  const { sourcedBatch } = useSourcedBatch();
  const { updateSourcedBatch } = useUpdateSourcedBatch();
  const { setCustomMessage } = useMessage();

  const handleBulkPrint = async (printItems: string[]) => {
    bulkPrint(printItems);
    setPrintItems(undefined);
    clearSelectedItems();
  };

  const onSubmitUpdateSourcedBatch = async (
    values: UpdateSourcedBatchFormValues
  ) => {
    if (!sourcedBatch?.id) {
      return;
    }

    await updateSourcedBatch(
      sourcedBatch.id,
      values.durationInMinutes || null,
      values.comment || null
    );

    setCustomMessage({
      title: t('sourcedBatch.updateSuccess'),
      variant: 'positive',
    });
  };

  useEffect(() => {
    if (printItems) {
      handleBulkPrint(printItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printItems]);

  return sourcedBatch ? (
    <>
      <ItemBulkMenu
        menuItemKeys={[
          'activity',
          'print',
          'status',
          'shipment',
          'assignItemsToBatch',
        ]}
        setPrintItems={setPrintItems}
      />
      <SourcedBatchDetailsView
        sourcedBatch={sourcedBatch}
        onUpdateSourcedBatch={onSubmitUpdateSourcedBatch}
      />
    </>
  ) : null;
};
