import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import Modal, { ModalBody, ModalFooter, Sheets } from '@ingka/modal';
import Text from '@ingka/text';

import { DialoguePrompt, StatusInput } from 'components';
import { useItemStatus, useSelect } from 'hooks';
import { FactoryComponentProps, Status } from 'types';

import styles from './change-status-modal.module.scss';

export interface ChangeStatusFormValues {
  status?: Status;
}

export interface ChangeStatusProps extends FactoryComponentProps {
  ids: string[];
  visible: boolean;
  onClose(): void;
  refetchExternalReference?: () => void;
}

export const SetStatusModal: FunctionComponent<ChangeStatusProps> = memo(
  ({ ids, visible, onClose, refetchExternalReference }) => {
    const { t } = useTranslation();
    const { clearSelectedItems, setHasSelectAll, selectedItems } = useSelect();
    const { setStatuses } = useItemStatus();
    const [displayStatusDialogue, setDisplayStatusDialogue] =
      useState<boolean>(false);
    const [hasSelected, setHasSelected] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);

    const onSubmit = async (
      statusInput: ChangeStatusFormValues,
      { setSubmitting }: FormikHelpers<ChangeStatusFormValues>
    ) => {
      try {
        const status = statusInput.status;
        await setStatuses(status, ids);
        setSubmitting(false);
        clearSelectedItems();
        setHasSelectAll(false);
        onClose();
        if (refetchExternalReference) {
          refetchExternalReference();
        }
      } catch (err) {
        setError(true);
        console.error(err);
      }
    };

    const initialValues = {
      status: undefined,
    };

    const {
      isSubmitting,
      values,
      handleSubmit,
      setFieldValue,
      resetForm,
      submitForm,
    } = useFormik<ChangeStatusFormValues>({
      initialValues,
      onSubmit,
    });

    const setStatusValue = (status: Status) => {
      setHasSelected(true);
      setFieldValue('status', status, false);
    };

    const handleSetStatusValue = (status: Status) => {
      setStatusValue(status);
    };

    const onSubmitClick = (event: MouseEvent) => {
      event.preventDefault();

      const isInShipment = selectedItems.some(
        (item) => item.shipments?.length > 0
      );

      if (
        (isInShipment || window.location.pathname.includes('shipments')) &&
        (values.status === 'pending' || values.status === 'available')
      ) {
        setDisplayStatusDialogue(true);
      } else {
        submitForm();
      }
    };

    const handleDialogueResponse = (value: boolean) => {
      if (value) {
        submitForm();
      }
    };

    const handleCloseBtn = () => onClose();

    const onModalClosed = () => resetForm();

    const onCancelClick = () => onClose();

    useEffect(() => {});

    const footer = (
      <ModalFooter>
        <Button
          type="primary"
          fluid={true}
          // loading={isSubmitting || loading}
          htmlType="submit"
          disabled={!hasSelected}
          text={t('actions.save')}
          onClick={onSubmitClick}
          data-cy={'modal-submit-btn'}
        />
        <Button
          type="secondary"
          fluid={true}
          // loading={isSubmitting || loading}
          text={t('actions.cancel')}
          onClick={onCancelClick}
        />
      </ModalFooter>
    );
    return (
      <form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
        <Modal
          visible={visible}
          handleCloseBtn={handleCloseBtn}
          onModalClosed={onModalClosed}
        >
          <DialoguePrompt
            positiveBtnText={t('actions.confirm')}
            negativeBtnText={t('actions.noDont')}
            promptTitle={t('dialogues.status.title')}
            promptBody={t(
              `dialogues.status.${
                selectedItems?.length > 1 ? 'bodyMultiple' : 'bodySingle'
              }`,
              {
                status: values.status,
              }
            )}
            displayDialogue={displayStatusDialogue}
            setDisplayDialogue={setDisplayStatusDialogue}
            handleDialogueResponse={handleDialogueResponse}
          />
          <Sheets size="small" footer={footer}>
            <ModalBody>
              <Text headingSize={'m'} className={styles.heading}>
                {t('statusInterpolation', { quantity: ids.length })}
              </Text>
              <FormField>
                <StatusInput
                  loading={error || isSubmitting}
                  setValue={handleSetStatusValue}
                  value={values.status}
                />
              </FormField>
            </ModalBody>
          </Sheets>
        </Modal>
      </form>
    );
  }
);
