import { FunctionComponent, memo } from 'react';

import Choice, { ChoiceItem } from '@ingka/choice';

import { useStatus } from 'components';
import { FactoryComponentProps, Status } from 'types';

import styles from './status-input.module.scss';
export interface StatusOption {
  id: string;
  title: string;
  value: Status;
  type: string;
}
export interface StatusinputProps extends FactoryComponentProps {
  key?: string;
  empty?: boolean;
  loading?: boolean;
  setValue(value: Status | null): void;
  value?: Status | null;
}

export const StatusInput: FunctionComponent<StatusinputProps> = memo(
  ({ loading, setValue, value }) => {
    const { statuses, findStatusById } = useStatus();

    const defaultFinder = (status: StatusOption) => value === status.value;
    const preselectedIndex = statuses.findIndex(defaultFinder);

    const onStatusSelect = (id: string) => {
      const status = findStatusById(id);
      if (status && status.value !== value) {
        setValue(status.value);
      } else {
        setValue(null);
      }
    };
    const statusMapper = ({ id, title }: StatusOption) => {
      return (
        <ChoiceItem
          key={id}
          className={styles.statusInputItem}
          title={title}
          disabled={loading}
          id={id}
          data-cy={`status-choice-${title}`}
        />
      );
    };

    return (
      <Choice
        className={styles.statusInput}
        onSelect={onStatusSelect}
        preselectedIndex={preselectedIndex}
        data-cy="status-input"
        deselectable
      >
        {statuses.map(statusMapper)}
      </Choice>
    );
  }
);
