import { FormikHelpers, useFormik } from 'formik';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { assignToBatchSchema } from 'yup-schemas/yup-schemas';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import InlineMessage from '@ingka/inline-message';
import InputField from '@ingka/input-field';
import Modal, { ModalBody, ModalFooter, Sheets } from '@ingka/modal';
import Text from '@ingka/text';

import { AssignToBatch } from 'components/_form/assign-to-batch/assign-to-batch';
import { useCreateSourcedBatch } from 'hooks';
import { FactoryComponentProps, FactoryLocationState } from 'types';

import styles from './create-items-modal.module.scss';

export interface CreateItemsInputValues extends FactoryComponentProps {
  quantity: number;
  sourceId?: string;
  sourcedBatchId?: string;
  sourcingTimestamp?: Date;
  batchType: string;
}
const initialValues: CreateItemsInputValues = {
  quantity: 1,
  sourceId: '',
  sourcedBatchId: undefined,
  sourcingTimestamp: undefined,
  batchType: 'new',
};
interface CreateItemsModalProps {
  onClose(): void;
  visible: boolean;
}

export const CreateItemsModal: FC<CreateItemsModalProps> = ({
  onClose,
  visible,
}) => {
  const history = useHistory<FactoryLocationState>();
  const { t } = useTranslation();
  const { createItemsNewBatch, createItemsExistingBatch, createItemErrorMsg } =
    useCreateSourcedBatch();
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = async (
    {
      quantity,
      sourceId,
      sourcedBatchId,
      sourcingTimestamp,
      batchType,
    }: CreateItemsInputValues,
    { setSubmitting }: FormikHelpers<CreateItemsInputValues>
  ) => {
    quantity = parseInt(`${quantity}`, 10);

    let labels: string[] = [];
    if (batchType === 'new' && sourcingTimestamp && sourceId) {
      const result = await createItemsNewBatch({
        quantity,
        sourcingTimestamp,
        sourceId: sourceId,
      });

      labels = result?.newCircularItems.map((x) => x.labelImageUrl || '') || [];
    }

    if (batchType === 'existing' && sourcingTimestamp && sourcedBatchId) {
      const result = await createItemsExistingBatch({
        quantity,
        sourcingTimestamp,
        sourcedBatchId,
      });

      labels = result?.newCircularItems.map((x) => x.labelImageUrl || '') || [];
    }

    setSubmitting(false);

    history.replace(`${`/inventory`}`, {
      message: {
        title: t('notifications.createBatchedItems.newBatch'),
        variant: 'positive',
      },
      labels: labels,
    });
  };

  const {
    isSubmitting,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    isValid,
    dirty,
    submitForm,
  } = useFormik<CreateItemsInputValues>({
    initialValues,
    onSubmit,
    validationSchema: assignToBatchSchema,
  });

  const setBatchType = (batchType: string) => {
    setFieldValue('batchType', batchType);
  };

  const handleDateInput = (date: Date) => {
    setFieldValue('sourcingTimestamp', date);
  };

  const handleCloseBtn = () => onClose();
  const onModalClosed = () => resetForm();
  const onCancelClick = () => onClose();

  const onSubmitClick = (event: MouseEvent) => {
    event.preventDefault();
    submitForm();
  };

  const footer = (
    <ModalFooter>
      <Button
        type="primary"
        fluid={true}
        loading={isSubmitting}
        htmlType="submit"
        disabled={!isValid || !dirty}
        text={t('actions.print.print')}
        onClick={onSubmitClick}
        data-cy={'modal-submit-btn'}
      />
      <Button
        type="secondary"
        fluid={true}
        loading={isSubmitting}
        text={t('actions.cancel')}
        onClick={onCancelClick}
      />
    </ModalFooter>
  );

  return (
    <form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
      <Modal
        visible={visible}
        handleCloseBtn={handleCloseBtn}
        onModalClosed={onModalClosed}
      >
        <Sheets size="small" footer={footer}>
          <ModalBody>
            <Text headingSize={'m'} className={styles.heading}>
              {t('actions.createNewItems')}
            </Text>
            <FormField className={styles.field}>
              <InputField
                id="quantity"
                name="quantity"
                type="number"
                label={t('quantity')}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.quantity}
                data-cy="create-batch-quantity"
              />
            </FormField>
            <AssignToBatch
              disabled={isSubmitting}
              formValues={values}
              onBatchTypeChange={setBatchType}
              onDateInputChange={handleDateInput}
              onBlur={handleBlur}
              onChange={handleChange}/>

            {createItemErrorMsg && (
              <InlineMessage
                variant="negative"
                title={t('notifications.error')}
                body={createItemErrorMsg}
              />
            )}
          </ModalBody>
        </Sheets>
      </Modal>
    </form>
  );
};
