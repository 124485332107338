import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Loading, { LoadingBall } from '@ingka/loading';

export interface LoaderProps {
  text?: string;
  children?: string;
}

export const Loader: FunctionComponent<LoaderProps> = memo(
  ({ text, children }) => {
    const { t } = useTranslation();

    return (
      <Loading
        text={children || text || t('notifications.loaders.loading')}
        labelTransitions
      >
        <LoadingBall />
      </Loading>
    );
  }
);

export default Loader;
