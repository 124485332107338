import { useMutation } from '@apollo/client';

import { mutationSetStatuses } from 'services';
import { MutationUpdateStatusesArgs, Status, CircularItem } from 'types';
import { MutationUpdateItemStatusResult } from 'types/mutation-update-item-status-result';

export const useItemStatus = () => {
  const [setStatusMutation, setStatusResult] = useMutation<
    MutationUpdateItemStatusResult,
    MutationUpdateStatusesArgs
  >(mutationSetStatuses);

  const setStatus = async (
    status: Status | undefined,
    circularItemId: string
  ) => setStatuses(status, [circularItemId]);

  const setStatuses = async (status: Status | undefined, ids: string[]) => {
    if (!status) {
      return Promise.resolve(0);
    }
    await setStatusMutation({
      variables: {
        circularItemIds: ids,
        newStatus: status,
      },
      update(cache, { data }) {
        data?.updateStatuses.forEach((statusUpdate) => {
          cache.modify({
            id: cache.identify({
              __typename: 'CircularItem',
              id: statusUpdate.circularItemId,
            } as CircularItem),
            fields: {
              status: () => statusUpdate.status,
            },
          });
        });
      },
    });
  };

  return {
    setStatus,
    setStatuses,
    setStatusResult,
  };
};
