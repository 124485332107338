import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { hasSelectAllAtom, selectedItemsClearAtom } from 'atoms';

export const NavigationActions: FunctionComponent = memo(() => {
  const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);
  const setHasSelectAll = useUpdateAtom(hasSelectAllAtom);

  const location = useLocation();
  useEffect(() => {
    clearSelectedItems();
    setHasSelectAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSelectedItems, location]);
  return null;
});

export default NavigationActions;
