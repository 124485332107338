import { atom } from 'jotai';

export const imageFileAtom = atom<File | undefined>(undefined);

export const imageSrcAtom = atom<string | undefined>((get) => {
  const file = get(imageFileAtom);

  if (file) {
    const imageSrc = URL.createObjectURL(file);
    return imageSrc;
  }
});
