import { FormikHelpers } from 'formik';
import { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import allenKey from '@ingka/ssr-icon/paths/allen-key';

import { ActivityFormValues, AddActivityModal } from 'components';
import { useAddActivities, useMessage } from 'hooks';
import { ActivityBulkInput, GradeValue } from 'types';

export interface AddActivityProps {
  id: string;
  currentGrade?: GradeValue;
}

export const AddActivity: FunctionComponent<AddActivityProps> = memo(
  ({ id, currentGrade }) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const { addActivities } = useAddActivities();
    const message = useMessage();

    const onSubmit = async (
      values: ActivityFormValues,
      { setSubmitting }: FormikHelpers<ActivityFormValues>
    ) => {
      try {
        setError(false);
        const grade =
          values.grade && values.grade !== currentGrade
            ? values.grade
            : undefined;
          const mappedValues = values.activities.map(a => ({
            activityTypeId: a.type!,
            executionTimeInMins: parseInt(a.duration!, 10)
          }) as ActivityBulkInput);
          mappedValues.at(-1)!.grade = grade;
        const result = await addActivities(
          id,
          mappedValues
        );
        if (!result) {
          setError(true);
        }
        setSubmitting(false);
        setVisible(false);
        message.setCustomMessage({title: t("notifications.activity.addSuccess"), variant: 'positive'});
      } catch (error) {
        setSubmitting(false);
      }
    };

    const onClick = () => setVisible(true);
    const onClose = () => {
      setVisible(false);
      setError(false);
    };

    const initialValues = {
      activities: [
        {}
      ],
      grade: currentGrade,
    };

    return (
      <>
        <Button
          type="primary"
          ssrIcon={allenKey}
          onClick={onClick}
          text={t('actions.addActivity')}
          small={true}
          data-cy="details-view-add-activity-btn"
        />
        <AddActivityModal
          visible={visible}
          error={error}
          onSubmit={onSubmit}
          onClose={onClose}
          initialValues={initialValues}
        />
      </>
    );
  }
);
