import { useAtomValue } from 'jotai/utils';
import { memo, FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';

import { isAuthenticatedAtom } from 'atoms';
import { LoginView } from 'components';

export const LoginPage: FunctionComponent = memo(() => {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);

  return isAuthenticated ? <Redirect to={'/inventory'} /> : <LoginView />;
});
