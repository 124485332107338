import { useTranslation } from 'react-i18next';

import { StatusOption } from 'components';
import { Status } from 'types';

import { ShipmentStatusOption } from '../shipment-status-input/shipment-status-input';

export const useStatus = () => {
  const { t } = useTranslation();

  const statuses: StatusOption[] = [
    {
      id: 'status-Pending',
      title: t('statuses.pending.label'),
      value: 'pending',
      type: 'pending',
    },
    {
      id: 'status-Available',
      title: t('statuses.available.label'),
      value: 'available',
      type: 'available',
    },
    {
      id: 'status-Reserved',
      title: t('statuses.reserved.label'),
      value: 'reserved',
      type: 'reserved',
    },
    {
      id: 'status-Departed',
      title: t('statuses.departed.label'),
      value: 'departed',
      type: 'departed',
    },
  ];

  const shipmentStatuses: ShipmentStatusOption[] = [
    {
      id: 'status-Shipped',
      title: 'Shipped',
      value: 'shipped',
      type: 'shipped',
    },
    {
      id: 'status-Unshipped',
      title: 'Unshipped',
      value: 'unshipped',
      type: 'unshipped',
    },
  ];

  const findStatusById = (id: string) => {
    if (!id) return null;
    return statuses.find((status) => status.id === id);
  };

  const findStatusByValue = (value: Status) => {
    return statuses.find((status) => status.value === value);
  };

  return { statuses, shipmentStatuses, findStatusById, findStatusByValue };
};
