/* eslint-disable i18next/no-literal-string */
import { t } from 'i18next';
import { FunctionComponent, memo } from 'react';
// import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Checkbox from '@ingka/checkbox';

import { Shipment } from 'types';
import { formatDate } from 'utils';

import styles from './shipment-list-row.module.scss';

export interface ShipmentListRowProps {
  shipment: Shipment;
  setSelected(selectedState: boolean): void;
  selected?: boolean;
  highlighted?: boolean;
}

export const ShipmentListRow: FunctionComponent<ShipmentListRowProps> = memo(
  ({ shipment, selected, setSelected, highlighted }: ShipmentListRowProps) => {
    const onChange = () => {
      setSelected(!selected);
    };

    return (
      <tr className={`${styles.row} ${highlighted && styles.highlighted}`}>
        <td className={styles.selectCell}>
          <Checkbox
            id={`select-${shipment.id}`}
            onChange={onChange}
            checked={selected}
            value={''}
          />
        </td>
        <td>
          <Link to={`/shipments/${shipment.id}`} className={styles.idLink}>
            {shipment.id}
          </Link>
          <div className={styles.displayHoverModal}></div>
        </td>

        <td>
          <Link
            to={`/reference/${shipment.externalReferenceId}`}
            className={styles.idLink}
          >
            {shipment.externalReference}
          </Link>
        </td>
        <td>{shipment.status === 'shipped' ? t('yes') : t('no')}</td>

        <td>{shipment.circularItemIds.length || ''}</td>
        <td>{formatDate(shipment.updatedTimestamp)}</td>
        <td>{formatDate(shipment.createdTimestamp)}</td>
      </tr>
    );
  }
);
