import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ingka/text';

import { Sale } from 'types';
import { formatDate } from 'utils';

import styles from './hover-box.module.scss';

export const SaleHoverBox: FC<Partial<Sale>> = ({
  historyType,
  price,
  userId,
  currencyCode,
  userName,
  createdTimestamp,
}) => {
  const { t } = useTranslation();
  return (
    <span>
      <Text className={styles.activityTypeHeading}>{historyType}</Text>
      <dl className={styles.details}>
        <dt>
          <Text bodySize={'m'}>{t('price')}:</Text>
        </dt>
        <dd>
          <Text bodySize={'m'}>
            {price} {currencyCode}
          </Text>
        </dd>
        <dt>
          <Text bodySize={'m'}>{t('user')}:</Text>
        </dt>
        <dd>
          <Text bodySize={'m'} className={styles.username}>
            {userName || userId}
          </Text>
        </dd>
        <dt>
          <Text bodySize={'m'}>{t('time')}:</Text>
        </dt>
        <dd>
          <Text bodySize={'m'}>{formatDate(createdTimestamp)}</Text>
        </dd>
      </dl>
    </span>
  );
};
