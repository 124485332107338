import { FunctionComponent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import Text from '@ingka/text';

// This needs to be here to avoid bug with image not displayed
import '@ingka/aspect-ratio-image/style.scss';

import { PageContent } from 'components';

import styles from './create-item-view.module.scss';

export interface CreateItemViewNewProps {
  onCreateItemsClick: MouseEventHandler;
}

export const CreateItemViewNew: FunctionComponent<CreateItemViewNewProps> = ({
  onCreateItemsClick,
}) => {
  const { t } = useTranslation();

  return (
    <PageContent className={styles.contentWrapper}>
      <Text tagName="h1" headingSize="m">
        {t('createItems')}
      </Text>
      <Text tagName="p" bodySize="s">
        {t('createItemsPageDescription')}
      </Text>
      <Button type="primary" data-cy="create-new-batch" onClick={onCreateItemsClick}>
        {t('actions.createNewItems')}
      </Button>
    </PageContent>
  );
};
