import { useCallback, useEffect, useState } from 'react';

import { useStatus, Filter } from 'components';
import { usePageParams } from 'hooks/use-search-term/use-page-params';
import { ShipmentStatus } from 'types';
import { filterEmpty } from 'utils';

export const useStatusFilter = () => {
  const { statuses, shipmentStatuses } = useStatus();
  const { pageFilter, updatePageFilter, shipmentFilter, updateShipmentFilter } =
    usePageParams();
  const [statusFilter, setStatusFilter] = useState<string | null>(() => {
    if (pageFilter && pageFilter.status) return pageFilter.status;
    if (shipmentFilter && shipmentFilter.status) return shipmentFilter.status;
    return '';
  });

  const setItemStatusFilterValue = (status: string) => {
    setStatusFilter(status);
    updatePageFilter({ status: status });
  };

  const setShipmentStatusFilterValue = (status: ShipmentStatus | null) => {
    setStatusFilter(status);
    updateShipmentFilter({ status: status });
  };

  const getFilterValues = useCallback(() => {
    const options = statuses.map((status) => {
      return {
        label: status.title,
        value: status.value,
        selected: statusFilter === status.value,
      };
    });

    const filters: Filter[] = [
      {
        label: 'Status',
        options: [
          // uncomment to enable All option. Still in discussion if it's a good idea
          // { label: 'All', value: '', selected: statusFilter === 'all' },
          ...options,
        ],
      },
    ];
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  const getShipmentFilterValues = useCallback(() => {
    const options = shipmentStatuses.map((status) => {
      return {
        label: status.title,
        value: status.value,
        selected: statusFilter === status.value,
      };
    });

    const filters: Filter[] = [
      {
        label: 'Status',
        options: [
          // uncomment to enable All option. Still in discussion if it's a good idea
          // { label: 'All', value: '', selected: statusFilter === 'all' },
          ...options,
        ],
      },
    ];
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentFilter]);

  const [currentFilterValues, setCurrentFilterValues] = useState<Filter[]>(() =>
    getFilterValues()
  );

  const [currentShipmentFilterValues, setCurrentShipmentFilterValues] =
    useState<Filter[]>(() => getShipmentFilterValues());

  const getSelectedFilterOptions = () => {
    const filters = getFilterValues().concat(getShipmentFilterValues());

    const activeFilters = filters.filter((filter) => {
      return filter.options.some((option) => option.selected === true);
    });

    const selectedOptions = filterEmpty(
      activeFilters.map((filter) => {
        return filter.options.find((option) => option.selected === true);
      })
    );

    return selectedOptions;
  };

  useEffect(() => {
    setCurrentFilterValues(getFilterValues);
  }, [getFilterValues]);

  useEffect(() => {
    setCurrentShipmentFilterValues(getShipmentFilterValues);
  }, [getShipmentFilterValues]);

  return {
    statusFilter,
    currentFilterValues,
    currentShipmentFilterValues,
    setItemStatusFilterValue,
    setShipmentStatusFilterValue,
    getSelectedFilterOptions,
  };
};
