import { useMutation } from '@apollo/client';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AspectRatioImage from '@ingka/aspect-ratio-image';
import Text from '@ingka/text';

import { imageSrcAtom } from 'atoms';
import {
  Back,
  Columns,
  PageContent,
  Row,
  PageHeader,
  DetailsFormEdit,
  DetailsFormEditInputs,
  DetailsFormEditProps,
  UploadImage,
  Delete,
} from 'components';
import { DEFAULT_PAGE_SIZE } from 'config';
import { useButtonLink, useFormContext } from 'hooks';
import {
  mutationRemoveCircularItem,
  queryCircularItemsCollection,
} from 'services';
import { CircularItem, RetailItem } from 'types';

import styles from './item-edit-view.module.scss';

export interface ItemEditViewProps
  extends Pick<DetailsFormEditProps, 'onSubmit' | 'onCancel'> {
  circularItem: CircularItem;
  initialValues: DetailsFormEditInputs;
  retailItem?: RetailItem;
  isInShipment: boolean;
}

export const ItemEditView: FunctionComponent<ItemEditViewProps> = memo(
  ({ onSubmit, onCancel, circularItem, initialValues, isInShipment }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { buttonLinkHandler } = useButtonLink();
    const imageSrc = useAtomValue(imageSrcAtom);
    const [image, setImage] = useState<string>();
    const [removeCircularItem] = useMutation(mutationRemoveCircularItem);
    const { retailItem, itemNumber } = useFormContext();

    useEffect(() => {
      if (
        !retailItem &&
        !itemNumber &&
        !circularItem.images?.[0]?.image &&
        !imageSrc
      ) {
        setImage('');
      }

      if (!retailItem && imageSrc) {
        setImage(imageSrc);
      }

      if (!retailItem && circularItem.images?.[0]?.image.href && !imageSrc) {
        setImage(circularItem?.images?.[0]?.image.href);
      }

      if (retailItem && retailItem?.itemNumber === itemNumber) {
        setImage(
          retailItem?.mainProductImages?.[
            retailItem.mainProductImages.length - 1
          ]?.href
        );
      }
    }, [circularItem, retailItem, itemNumber, imageSrc]);

    const handleDeleteItem = async () => {
      try {
        await removeCircularItem({
          variables: {
            circularItemId: circularItem.id,
          },
          refetchQueries: [
            {
              query: queryCircularItemsCollection,
              variables: {
                skip: 0,
                limit: DEFAULT_PAGE_SIZE,
              },
            },
          ],
        });
        history.push('/inventory', {
          message: {
            title: t('notifications.delete.success', {
              type: t('item'),
            }),
            variant: 'positive',
          },
        });
      } catch (error) {
        history.replace(history.location.pathname, {
          message: {
            title: t('notifications.delete.error'),
            variant: 'negative',
          },
        });
      }
    };

    return (
      <PageContent>
        <PageHeader divider={true}>
          <Row position="start">
            <Back
              label={t('actions.back.detail')}
              href={`/inventory/${circularItem.id}`}
              onClick={buttonLinkHandler}
              dataCy={'Back-Button'}
            />
            <Text tagName="h1" headingSize="m" className={styles.title}>
              {t('idInterpolation', { id: circularItem.id })}
            </Text>
          </Row>
          <Row position="end">
            <Delete
              buttonText={t('actions.deleteItem')}
              type={t('item')}
              deleteAction={handleDeleteItem}
              dataCy={'item-delete-button'}
            />
          </Row>
        </PageHeader>
        <Columns>
          <figure>
            <AspectRatioImage
              src={
                retailItem?.mainProductImages?.[
                  retailItem.mainProductImages.length - 1
                ]?.href || image
              }
              ratio="square"
            />
            {!circularItem.retailItem?.itemNumber ||
              (retailItem?.itemNumber !== itemNumber && <UploadImage />)}
          </figure>
          <section>
            <DetailsFormEdit
              onSubmit={onSubmit}
              onCancel={onCancel}
              initialValues={initialValues}
              isInShipment={isInShipment}
            />
          </section>
        </Columns>
      </PageContent>
    );
  }
);
