import { useQuery } from '@apollo/client';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import { globalAppErrorAtom, globalLoadingAtom } from 'atoms';
import { useDuplicateItem, useDuration, usePrint } from 'hooks';
import {
  queryCircularItem,
  queryCircularItemGrades,
  queryItemActivities,
} from 'services';
import {
  CircularItemActivity,
  CircularItemGrade,
  CircularItemHistoryItem,
  CircularItemSale,
  QueryCircularItemArgs,
  QueryCircularItemGradesResult,
  QueryCircularItemResult,
  QueryGradesArgs,
  QueryItemActivitiesArgs,
  QueryItemActivitiesResult,
} from 'types';

export const useItemDetails = (circularItemId:string) => {
  const history = useHistory();
  const { state } = useLocation();
  const { print } = usePrint();
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const { duplicateItemValues, onDuplicateClick } = useDuplicateItem();
  const [itemHistory, setItemHistory] = useState<CircularItemHistoryItem[]>([]);
  const getDuration = useDuration();

  const {
    loading: circularItemLoading,
    error: circularItemError,
    data: circularItemData,
  } = useQuery<QueryCircularItemResult, QueryCircularItemArgs>(
    queryCircularItem,
    {
      variables: {
        circularItemId,
      },
    }
  );

  useEffect(() => {
    setGlobalLoading(circularItemLoading);
    setGlobalAppError(circularItemError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circularItemLoading, circularItemError]);

  const {
    loading: activitiesLoading,
    // error: activitiesError,
    data: activitiesData,
  } = useQuery<QueryItemActivitiesResult, QueryItemActivitiesArgs>(
    queryItemActivities,
    {
      variables: {
        circularItemId: circularItemId,
      },
    }
  );

  const {
    loading: gradesLoading,
    // error: gradesError,
    data: gradesData,
  } = useQuery<QueryCircularItemGradesResult, QueryGradesArgs>(
    queryCircularItemGrades,
    {
      variables: {
        circularItemId: circularItemId,
      },
    }
  );

  useEffect(() => {
    setGlobalLoading(activitiesLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesLoading]);

  useEffect(() => {
    setGlobalLoading(gradesLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradesLoading]);

  useEffect(() => {
    if (activitiesData && gradesData && circularItemData?.circularItem) {
      const activitiesMapper = (
        activity: CircularItemActivity
      ): CircularItemHistoryItem => ({
        _type: 'activity',
        id: activity.id,
        gradeValue: activity?.grade?.value,
        historyType: 'Refurbishing',
        activity: activity.activityType.name,
        userName: activity?.user?.name,
        userId: activity.userId,
        createdTimestamp: activity.createdTimestamp,
        duration: getDuration(activity.startTime, activity.endTime),
      });

      const salesMapper = (
        sale: CircularItemSale
      ): CircularItemHistoryItem => ({
        _type: 'sale',
        id: sale.id,
        historyType: 'Registered sale',
        userName: sale?.createdByUser?.name,
        userId: sale.createdById,
        currencyCode: sale.currencyCode,
        createdTimestamp: sale.saleTimestamp,
        price: sale.amount,
      });

      // To avoid grade changes associated to activities showing twice in history
      const gradeFilter = (grade: CircularItemGrade) => !grade.activity;

      const gradesMapper = (
        grade: CircularItemGrade
      ): CircularItemHistoryItem => ({
        _type: 'gradeChange',
        id: grade.id,
        gradeValue: grade?.value,
        historyType: 'Changed grade',
        userId: grade.userId,
        userName: grade?.user?.name,
        createdTimestamp: grade.createdTimestamp,
      });

      const historySorter = (
        a: CircularItemHistoryItem,
        b: CircularItemHistoryItem
      ) => {
        const diff = new Date(b.createdTimestamp).getTime() - new Date(a.createdTimestamp).getTime();
        // Activities created in bulk will have the creation timestamp, so any tiebreakers need to be resolved by looking at the
        // ID (which should be sequential)
        if (diff === 0) {
          return b.id.localeCompare(a.id);
        }
        return diff;
      }

      setItemHistory(
        [
          ...activitiesData.itemActivities.map(activitiesMapper),
          ...gradesData.grades.filter(gradeFilter).map(gradesMapper),
          ...circularItemData?.circularItem.sales.map(salesMapper),
        ].sort(historySorter)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesData, gradesData, circularItemData]);

  useEffect(() => {
    if (duplicateItemValues && circularItemData?.circularItem) {
      if (circularItemData?.circularItem.retailItem?.itemNumber) {
        history.push(
          `/create/item/${circularItemData?.circularItem.retailItem.itemNumber}`,
          {}
        );
      } else {
        history.push(`/create/item`, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateItemValues]);

  useEffect(() => {
    if (state?.print && circularItemData?.circularItem?.labelImageUrl) {
      print(circularItemData?.circularItem?.labelImageUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.print, circularItemData?.circularItem?.labelImageUrl]);

  return {
    circularItem: circularItemData?.circularItem,
    onDuplicateClick,
    duplicateItemValues,
    itemHistory,
  };
};
