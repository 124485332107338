import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ingka/text';

import { Footer, Login, Message } from 'components';

import logo from '../../../../assets/ikea-logo-white.svg';

import styles from './login-view.module.scss';

export const LoginView: FunctionComponent = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.side_column}>
        <div className={styles.logo_container}>
          <img src={logo} alt="IKEA" className={styles.logo} loading="lazy" />
        </div>
        <div className={styles.text_container}>
          <Text headingSize="l" className={styles.headline}>
            {t('hello')}
          </Text>
          <Text headingSize="l" className={styles.text}>
            {t('login_text')}
          </Text>
        </div>
      </div>
      <div className={styles.form}>
        <Message />
        <Login />
      </div>
      <Footer className={styles.footer} />
    </div>
  );
});
