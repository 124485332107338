import { LocationDescriptor } from 'history';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { tokenAtom, userAtom } from 'atoms';
import { FactoryLocationState } from 'types';

export const NotFoundPage: FunctionComponent = memo(() => {
  const token = useAtomValue(tokenAtom);
  const user = useAtomValue(userAtom);
  const { t } = useTranslation();
  const to: LocationDescriptor<FactoryLocationState> = {
    pathname: user ? '/inventory' : '/',
    state: user
      ? {
          message: {
            title: t('notifications.auth.notFound'),
            variant: 'cautionary',
          },
        }
      : undefined,
  };

  return token && !user ? null : <Redirect to={to} />;
});
