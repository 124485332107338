import { FunctionComponent, memo } from 'react';

import { LanguageSelector } from 'components';
import { FactoryComponentProps } from 'types';

import styles from './footer.module.scss';

export const Footer: FunctionComponent<FactoryComponentProps> = memo(
  ({ className = '' }) => {
    return (
      <footer className={`${styles.container} ${className}`}>
        <div className={styles.inner}>
          <LanguageSelector />
        </div>
      </footer>
    );
  }
);

export default Footer;
