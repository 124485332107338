import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';

import { FactoryFormFieldProps } from 'types';

import styles from './batch-input.module.scss';

interface BatchInputProps extends FactoryFormFieldProps {}

export const BatchInput: FC<BatchInputProps> = ({
  onChange,
  onBlur,
  value,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <FormField className={styles.field}>
      <InputField
        id="sourcedBatchId"
        data-cy="sourced-batch-id"
        type="text"
        label={t('batchId')}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        required
      />
    </FormField>
  );
};
