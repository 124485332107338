import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import FormField from '@ingka/form-field';
import RadioButtonGroup from '@ingka/radio-button-group';

import { BatchInput, DateInput, SourceInput } from 'components';
import { FactoryFormFieldProps } from "types";

export interface AssignToBatchFormValues {
  sourcingTimestamp?: Date;
  sourceId?: string;
  sourcedBatchId?: string;
}

export interface AssignToBatchProps extends FactoryFormFieldProps {
  disabled: boolean;
  formValues: AssignToBatchFormValues;
  onBatchTypeChange: (value: ('new' | 'existing')) => void;
  onDateInputChange: (value: Date) => void;
}

export const AssignToBatch: FC<AssignToBatchProps> = ({
  disabled,
  formValues : { sourcingTimestamp, sourceId, sourcedBatchId },
  onBatchTypeChange,
  onDateInputChange,
  onBlur,
  onChange
}: AssignToBatchProps
) => {

  /* LOCAL STATE VALUES & DECL'S */
  const { t } = useTranslation();
  const [batchType, setBatchType] = useState<('new'|'existing')>('new');

  /* LOCAL EVENT HANDLERS */
  const handleBatchTypeChange = (newValue: ('new'|'existing')) => {
    setBatchType(newValue);
    onBatchTypeChange(newValue);
  }

  return (
    <>
      <FormField>
        <RadioButtonGroup
          name={t('assignTo')}
          list={[
            {
              id: 'batchTypeNew',
              label: t('new'),
              value: 'new',
              defaultChecked: true,
              onChange: (event: any) => handleBatchTypeChange(event.target.value),
            },
            {
              id: 'batchTypeExisting',
              label: t('existing'),
              value: 'existing',
              onChange: (event: any) => handleBatchTypeChange(event.target.value),
            },
          ]}
          data-cy="assign-to-radiobutton-type"
        />
      </FormField>
      {batchType === 'new' ? (
        <SourceInput
          onChange={onChange}
          value={sourceId}
          onBlur={onBlur}
          disabled={disabled}
        />
      ) : (
        <BatchInput
          onChange={onChange}
          value={sourcedBatchId}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}

      <DateInput
        label={t('sourcingDate')}
        value={sourcingTimestamp}
        setValue={onDateInputChange}
        disabled={disabled}
        name={'sourcingTimestamp'}
      />
    </>
  );
}
