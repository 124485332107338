import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '@ingka/form-field';
import Select, { Option } from '@ingka/select';

import { SgfLocationInput, FactoryFormFieldProps } from 'types';

import styles from './details-form-sgf.module.scss';

export interface DetailsFormSgfProps extends FactoryFormFieldProps {
  value?: SgfLocationInput;
  setValue(value: SgfLocationInput): void;
}

export const DetailsFormSgf: FunctionComponent<DetailsFormSgfProps> = ({
  loading: formLoading,
  setValue,
  onBlur,
  value,
}) => {
  const { t } = useTranslation();
  const [SGF, setSGF] = useState<SgfLocationInput>(value!);
  const bayArr = ['A', 'B', 'C'],
    locationArr = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
    ],
    levelArr = ['00', '10'];

  const bayMapper = (bay: string) => {
    return <Option value={bay} name={bay} key={`SGFbay-${bay}`} />;
  };

  const locationMapper = (location: string) => {
    return (
      <Option
        value={location}
        name={location}
        key={`SGFlocation-${location}`}
      />
    );
  };

  const levelMapper = (level: string) => {
    return <Option value={level} name={level} key={`SGFlevel-${level}`} />;
  };

  const onBayChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const bay = event.target.value;
    const obj = {
      ...SGF,
      bay,
    };

    setSGF(obj);
  };

  const onLocationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const location = event.target.value;
    const obj = {
      ...SGF,
      location,
    };

    setSGF(obj);
  };

  const onLevelChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const level = event.target.value;
    const obj = {
      ...SGF,
      level,
    };

    setSGF(obj);
  };

  useEffect(() => {
    if (SGF) {
      setValue(SGF);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SGF]);

  return (
    <FormField className={styles.field}>
      <fieldset role="group" className={styles.sgf}>
        <legend>{t('sgf.title')}</legend>

        <div className={styles.selectContainer}>
          <Select
            className={styles.select}
            id="sgf-bay"
            name="sgf-bay"
            label={t('sgf.bay')}
            onChange={onBayChange}
            onBlur={onBlur}
            disabled={formLoading}
            value={value?.bay!}
            data-cy="details-form-sgf-bay"
          >
            <Option name={'-'} value={'-'} key={'bay-null'} />
            {bayArr.map(bayMapper)}
          </Select>
          <Select
            className={styles.select}
            id="sgf-location"
            name="sgf-location"
            label={t('sgf.location')}
            onChange={onLocationChange}
            onBlur={onBlur}
            disabled={formLoading}
            value={value?.location || undefined}
            data-cy="details-form-sgf-location"
          >
            <Option name={'-'} value={'-'} key={'location-null'} />
            {locationArr.map(locationMapper)}
          </Select>
          <Select
            className={styles.select}
            id="sgf-level"
            name="sgf-level"
            label={t('sgf.level')}
            onChange={onLevelChange}
            onBlur={onBlur}
            disabled={formLoading}
            value={value?.level || undefined}
            data-cy="details-form-sgf-level"
          >
            <Option name={'-'} value={'-'} key={'level-null'} />
            {levelArr.map(levelMapper)}
          </Select>
        </div>
      </fieldset>
    </FormField>
  );
};
