import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ItemDetailView } from 'components';

import { useItemDetails } from '../hooks/use-item-details/use-item-details';

export interface ItemDetailPageParameters {
  id: string;
}

export const ItemDetailPage: FC = () => {
  const { id } = useParams<ItemDetailPageParameters>();
  const { circularItem, onDuplicateClick } = useItemDetails(id);

  return (
    <ItemDetailView
      circularItem={circularItem}
      onDuplicateClick={onDuplicateClick}
    />
  );
};
