import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

import { Filter } from 'components';
import { usePageParams } from 'hooks/use-search-term/use-page-params';
import { useSources } from 'hooks/use-sources/use-sources';
import { filterEmpty } from 'utils';

export const useSourceFilter = () => {

  const { data } = useSources();
  const { sourcedBatchFilter, updateSourcedBatchFilter } = usePageParams();

  const [sourceFilter, setSourceFilter] = useState<string | null>(() => {
    if (sourcedBatchFilter && sourcedBatchFilter.source)
      return sourcedBatchFilter.source;
    return '';
  });

  const setSourcedBatchFilterValue = (source: string | null) => {
    setSourceFilter(source);
    updateSourcedBatchFilter(source ? { source } : null);
  };

  const getFilterValues = useCallback(() => {
    if (!data) {
      return [];
    }

    const options = data.sources.map((source) => {
      return {
        label: source.name,
        value: source.name,
        selected: sourceFilter === source.name,
      };
    });

    const filters: Filter[] = [
      {
        label: t('source'),
        options: [
          // uncomment to enable All option. Still in discussion if it's a good idea
          // { label: 'All', value: '', selected: statusFilter === 'all' },
          ...options,
        ],
      },
    ];
    return filters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceFilter, data]);

  const [currentFilterValues, setCurrentFilterValues] = useState<Filter[]>(() =>
    getFilterValues()
  );

  const getSelectedFilterOptions = () => {
    const filters = getFilterValues();

    const activeFilters = filters.filter((filter) => {
      return filter.options.some((option) => option.selected === true);
    });

    const selectedOptions = filterEmpty(
      activeFilters.map((filter) => {
        return filter.options.find((option) => option.selected === true);
      })
    );

    return selectedOptions;
  };

  useEffect(() => {
    setCurrentFilterValues(getFilterValues);
  }, [getFilterValues]);

  return {
    sourceFilter,
    currentFilterValues,
    setSourcedBatchFilterValue,
    getSelectedFilterOptions,
  };
};
