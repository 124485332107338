import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { updateShipmentDetailsSchema } from 'yup-schemas/yup-schemas';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import TextArea from '@ingka/text-area';

import { Shipment } from 'types';

import styles from './shipment-details-form.module.scss';

export interface UpdateShipmentDetailsFormValues {
  euroPalletCount?: number;
  longPalletCount?: number;
  carrier?: string;
  comment?: string;
}

export interface ShipmentDetailsDetailsFormProps {
  shipment: Shipment;
  onSubmit: (
    values: UpdateShipmentDetailsFormValues,
    formikHelpers: FormikHelpers<UpdateShipmentDetailsFormValues>
  ) => void | Promise<any>;
}

export const ShipmentDetailsDetailsForm: FunctionComponent<
  ShipmentDetailsDetailsFormProps
> = ({ shipment, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues: UpdateShipmentDetailsFormValues = {
    comment: shipment.comment,
    euroPalletCount: shipment.euroPalletCount || undefined,
    longPalletCount: shipment.longPalletCount || undefined,
    carrier: shipment.carrier || undefined,
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    isSubmitting,
    isValid,
    errors,
  } = useFormik<UpdateShipmentDetailsFormValues>({
    initialValues,
    onSubmit,
    validationSchema: updateShipmentDetailsSchema,
  });

  const handleNumberChange = (
    field: keyof UpdateShipmentDetailsFormValues,
    value: string
  ) => {
    const parsed = parseInt(value);
    if (!errors[field] && !isNaN(parsed)) {
      setFieldValue(field, parsed);
    } else {
      // Set the raw value so that the validation can take over
      setFieldValue(field, value);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <FormField
        className={styles.field}
        fieldHelper={{
          msg: t('helpMessages.shippmentDetails.euroPalletCount'),
        }}
        valid={!errors.euroPalletCount}
        shouldValidate={!!errors.euroPalletCount}
        validation={{ msg: errors.euroPalletCount }}
      >
        <InputField
          id="euroPalletCount"
          name="euroPalletCount"
          type="number"
          label={t('euroPalletCount')}
          value={values.euroPalletCount}
          onBlur={handleBlur}
          onChange={(event: any) =>
            handleNumberChange('euroPalletCount', event.target.value)
          }
          data-cy="update-batch-euroPalletCount"
        />
      </FormField>
      <FormField
        className={styles.field}
        fieldHelper={{
          msg: t('helpMessages.shippmentDetails.longPalletCount'),
        }}
        valid={!errors.longPalletCount}
        shouldValidate={!!errors.longPalletCount}
        validation={{ msg: errors.longPalletCount }}
      >
        <InputField
          id="longPalletCount"
          name="longPalletCount"
          type="number"
          label={t('longPalletCount')}
          value={values.longPalletCount}
          onBlur={handleBlur}
          onChange={(event: any) =>
            handleNumberChange('longPalletCount', event.target.value)
          }
          data-cy="update-batch-longPalletCount"
        />
      </FormField>
      <FormField
        className={styles.field}
        fieldHelper={{ msg: t('helpMessages.shippmentDetails.carrier') }}
        valid={!errors.carrier}
        shouldValidate={!!errors.carrier}
        validation={{ msg: errors.carrier }}
      >
        <InputField
          id="carrier"
          name="carrier"
          type="text"
          label={t('carrier')}
          value={values.carrier}
          onBlur={handleBlur}
          onChange={handleChange}
          data-cy="update-batch-carrier"
        />
      </FormField>
      <FormField className={styles.field}>
        <TextArea
          id="comment"
          name="comment"
          label={t('comment')}
          value={values.comment}
          onBlur={handleBlur}
          onChange={handleChange}
          data-cy="update-batch-comment"
        />
      </FormField>

      <Button
        className={styles.saveButton}
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        type="primary"
        htmlType="submit"
      >
        {t('actions.save')}
      </Button>
      <Button
        htmlType="reset"
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        onClick={() => resetForm({ values: initialValues })}
        className={styles.cancelButton}
      >
        {t('actions.cancel')}
      </Button>
    </form>
  );
};
