import { FunctionComponent } from 'react';

import { useAuth } from 'hooks';
import { useSources } from 'hooks/use-sources/use-sources';

import { EditSourcesView } from '../edit-sources-view/edit-sources-view';

export interface EditSourcesPageProps {}

export const EditSourcesPage: FunctionComponent = () => {
  const { getCurrentCpcId } = useAuth();
  const sources = useSources(getCurrentCpcId());

  return (
    <EditSourcesView
      sources={sources.data?.sources || []}
      error={!!sources.error}
      loading={sources.loading}
    />
  );
};
