import { FunctionComponent, memo } from 'react';

import { FilterStatusPill } from '../filter-pill-selector/filter-status-pill';
export interface FilterRowProps {
  filters: Filter[];
  onFilterChange(filter: string, optionValue: string): void;
}

export const FilterRow: FunctionComponent<FilterRowProps> = memo(
  ({ filters, onFilterChange }: FilterRowProps) => {
    return (
      <>
        {filters.map((filter) => {
          return (
            <FilterStatusPill
              key={filter.label}
              onFilterChange={onFilterChange}
              filter={filter}
            ></FilterStatusPill>
          );
        })}
      </>
    );
  }
);

export interface Filter {
  label: string;
  options: FilterOption[];
}

export interface FilterOption {
  label: string;
  value: string;
  selected: boolean;
}
