import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Text from '@ingka/text';

import { Columns } from 'components';
import { Shipment } from 'types';

import styles from './shipment-details.module.scss';

interface ShipmentDetailsProps {
  shipment: Shipment;
}

export const ShipmentDetails: FunctionComponent<ShipmentDetailsProps> = ({
  shipment,
}) => {
  const { t } = useTranslation();
  const shipped = shipment.status === 'shipped';

  return (
    <Columns data-cy="order-detail-view-columns">
      <section>
        <dl className={styles.details}>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('shipped')}</Text>
          </dt>
          <dd>
            <Text>{shipped ? t('yes') : t('no')}</Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('shipment.externalreference')}</Text>
          </dt>
          <dd>
            <Link
              to={`/reference/${shipment.externalReferenceId}`}
              data-cy={'shipment-details-external-reference-link'}
            >
              {shipment.externalReferenceId}
            </Link>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('shipment.items')}</Text>
          </dt>
          <dd>
            <Text tagName="span" data-cy="details-view-hfb">
              {shipment.circularItems.length}
            </Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('euroPalletCount')}</Text>
          </dt>
          <dd>
            <Text tagName="span" data-cy="details-view-hfb">
              {shipment.euroPalletCount}
            </Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('longPalletCount')}</Text>
          </dt>
          <dd>
            <Text tagName="span" data-cy="details-view-hfb">
              {shipment.longPalletCount}
            </Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('carrier')}</Text>
          </dt>
          <dd>
            <Text tagName="span" data-cy="details-view-hfb">
              {shipment.carrier}
            </Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('comment')}</Text>
          </dt>
          <dd>
            <Text tagName="span" data-cy="details-view-hfb">
              {shipment.comment}
            </Text>
          </dd>
        </dl>
      </section>
    </Columns>
  );
};
