import { FunctionComponent, memo } from 'react';

import { FactoryComponentProps } from 'types';

import styles from './hover-modal.module.scss';

interface HoverModalProps extends FactoryComponentProps {
  style?: React.CSSProperties;
}
export const HoverModal: FunctionComponent<HoverModalProps> = memo(
  ({ children, className = '', style={} }) => {
    return children ? (
      <div style={style} className={styles.hoverModal}>
        <div className={`${styles.box} ${className}`}>{children}</div>
      </div>
    ) : null;
  }
);
