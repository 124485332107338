import {
  ChangeEvent,
  MouseEvent,
  FocusEvent,
  KeyboardEvent,
  useRef,
  useState,
} from 'react';

import InputField from '@ingka/input-field';
import crossSmallIcon from '@ingka/ssr-icon/paths/cross-small';

import styles from './autocomplete.module.scss';

interface Option {
  name: string;
  value: string;
  _inputValue?: string;
}

export interface AutocompleteProps {

  /** The ID for the underlying input field. */
  id: string;

  /** If set, a label for the autocomplete field. */
  label?: string;

  /** The list of possible values that autocomplete will filter through. */
  options: Option[];

  /** If true, typed values that are not in the options list can be created when submitted. */
  creatable?: boolean;

  /** Hook for when the selected value changes */
  onChange: (value: string | undefined, isNew: boolean | undefined) => void;
}

export function Autocomplete({
  id,
  label,
  options,
  creatable,
  onChange
}: AutocompleteProps) {
  const [value, setValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);
  const [showOptions, setShowOptions] = useState(false);

  const activeLIRef = useRef<HTMLLIElement>(null);

  const filterOptions = (str: string): Option[] => {
    const result = options.filter(
      (option) => option.name.toLowerCase().indexOf(str.toLowerCase()) > -1
    );

    if (creatable && result.length < 1) {
      result.push({ name: `Add "${str}"`, value: str, _inputValue: str });
    }

    return result;
  };

  const findOption = (search: string) => {
    return filteredOptions.find(
      (x) =>
        x.name.toLowerCase() === search.toLowerCase() ||
        (x._inputValue && x._inputValue.toLowerCase() === search.toLowerCase())
    );
  };

  const changeInputValue = (value: string) => {
    setValue(value);
    const fo = filterOptions(value);
    setFilteredOptions(fo);

    const option = findOption(value);
    // Reference is either new or existing
    if (option?.value) {
      onChange(option.value, !!option?._inputValue);
    } else {
      onChange(value, true);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeInputValue(event.currentTarget.value);
    setActiveOptionIndex(0);
    setShowOptions(true);
  };

  const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setShowOptions(true);
  };

  const onInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setShowOptions(false);
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (filteredOptions.length > 0) {
        
        // showOptions is effectively a marker for whether a user has confirmed
        // their choice: if showOptions == false, then the user has either
        // explicitly clicked on an option from the dropdown, or has pressed enter
        // once to confirm what they've typed. If the user's choice is confirmed,
        // then pressing enter should submit the form. 
        if (showOptions) {
          event.preventDefault();
        }
      }
      setActiveOptionIndex(0);
      setShowOptions(false);
    }

    if (event.key === 'ArrowUp') {
      if (activeOptionIndex === 0) {
        return;
      }

      setActiveOptionIndex(activeOptionIndex - 1);
      activeLIRef.current?.scrollIntoView({ block: 'end' }); // Can improve this feeling
    }

    if (event.key === 'ArrowDown') {
      if (activeOptionIndex === filteredOptions.length - 1) {
        return;
      }

      setActiveOptionIndex(activeOptionIndex + 1);
      activeLIRef.current?.scrollIntoView({ block: 'end' }); // Can improve this feeling
    }
  };

  const onInputIconClick = () => {
    changeInputValue('');
  };

  const onOptionClick = (event: MouseEvent<HTMLLIElement>, index: number) => {
    const selectedOption = filteredOptions[index];
    const newValue = selectedOption._inputValue || selectedOption.name;
    changeInputValue(newValue);

    setShowOptions(false);
  };

  return (
    <>
      <InputField
        id={id}
        type="text"
        label={label}
        autoComplete="off"
        ssrIcon={value ? crossSmallIcon : undefined}
        iconOnClick={onInputIconClick}
        iconPosition="trailing"
        value={value}
        onChange={onInputChange}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        onKeyDown={onInputKeyDown}
      />

      {showOptions && (
        <ul className={styles.autocomplete_suggestions}>
          {filteredOptions.map((option, index) => (
            <li
              data-cy={'autocomplete-suggestion-el'}
              key={option.name}
              className={
                activeOptionIndex === index
                  ? styles.autocomplete_suggestions__active
                  : ''
              }
              ref={activeOptionIndex + 1 === index ? activeLIRef : undefined}
              onMouseDown={(event) => event.preventDefault()} // Hack to fix the input onBlur order
              onClick={(event) => onOptionClick(event, index)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
