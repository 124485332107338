import { useQuery } from '@apollo/client';
import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { globalAppErrorAtom, globalLoadingAtom } from 'atoms';
import { ItemPrintView } from 'components';
import { queryCircularItem } from 'services';
import { QueryCircularItemArgs, QueryCircularItemResult } from 'types';

export interface ItemPrintPageParameters {
  id: string;
}

export const ItemPrintPage: FunctionComponent = memo(() => {
  const { id } = useParams<ItemPrintPageParameters>();
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);

  const { data, loading, error } = useQuery<
    QueryCircularItemResult,
    QueryCircularItemArgs
  >(queryCircularItem, {
    variables: {
      circularItemId: id,
    },
  });

  useEffect(() => {
    setGlobalLoading(loading);
  }, [loading, setGlobalLoading]);

  useEffect(() => {
    setGlobalAppError(error);
  }, [error, setGlobalAppError]);

  return loading ? null : error ? null : (
    <ItemPrintView circularItem={data?.circularItem} />
  );
});
