import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '@ingka/form-field';
import Select, { Option } from '@ingka/select';

import { FactoryFormFieldProps } from 'types';

import styles from './details-form-manufacturing-date.module.scss';

export interface DetailsFormManufacturingDateProps
  extends FactoryFormFieldProps {
  pastYears?: number;
  year: string | undefined;
  week: string | undefined;
}

export const DetailsFormManufacturingDate: FunctionComponent<
  DetailsFormManufacturingDateProps
> = ({ loading, year, week, onBlur, onChange }) => {
  const { t } = useTranslation();

  const years: Array<number> = Array.from(
    Array(new Date().getFullYear() - 1949),
    (_, i) => {
      return new Date().getFullYear() - i;
    }
  );

  const yearsMapper = (year: number) => {
    return <Option value={`${year}`} name={`${year}`} key={`year-${year}`} />;
  };

  const weekMapper = (_item: ReactNode, index: number): ReactNode => {
    const week: number = index + 1;
    return <Option value={`${week}`} name={`${week}`} key={`week-${week}`} />;
  };
  const weeks: ReactNode[] = Array(53).fill(null).map(weekMapper);
  return (
    <FormField className={styles.field}>
      <fieldset role="group" className={styles.date}>
        <div className={styles.dateLegend}>
          <legend>{t('manufacturingDate')}</legend>
        </div>
        <Select
          id="manufacturingDateYear"
          name="year"
          label={t('year')}
          onChange={onChange}
          onBlur={onBlur}
          disabled={loading}
          value={year}
          data-cy="details-form-manufacturing-year"
          className={styles.dateYear}
        >
          {years.map(yearsMapper)}
        </Select>
        <Select
          id="manufacturingDateWeek"
          name="week"
          label={t('week')}
          onChange={onChange}
          onBlur={onBlur}
          disabled={loading}
          value={week}
          data-cy="details-form-manufacturing-week"
          className={styles.dateWeek}
        >
          {weeks}
        </Select>
      </fieldset>
    </FormField>
  );
};
