import { useMutation } from "@apollo/client";

import { mutationAssignItemsAndCreateNewSourcedBatch, mutationAssignItemsToSourcedBatch } from "services";
import { AssignItemsAndCreateNewSourcedBatchInput, AssignItemsToSourcedBatchInput, MutationAssignItemsAndCreateNewSourcedBatchArgs, MutationAssignItemsToSourcedBatchArgs, SourcedBatch } from "types"

export const useAssignToBatch = () => {

  const [assignItemsToNewSourcedBatch,] = useMutation<
    SourcedBatch,
    MutationAssignItemsAndCreateNewSourcedBatchArgs
  >(mutationAssignItemsAndCreateNewSourcedBatch);

  const [assignItemsToSourcedBatch,] = useMutation<
    SourcedBatch,
    MutationAssignItemsToSourcedBatchArgs
  >(mutationAssignItemsToSourcedBatch);

  const assignToSourcedBatch = async (input: AssignItemsToSourcedBatchInput) => {
    return await assignItemsToSourcedBatch({
      variables: {input: input as AssignItemsToSourcedBatchInput}
    })
  }

  const assignToNewSourcedBatch = async (input: AssignItemsAndCreateNewSourcedBatchInput) => {
    return await assignItemsToNewSourcedBatch({
      variables: { input: input as AssignItemsAndCreateNewSourcedBatchInput }
    });
  }

  return {
    assignToSourcedBatch,
    assignToNewSourcedBatch,
  }
}
