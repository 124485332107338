import { createContext } from 'react';

export interface PaginationContextValue {
  page: number;
  pages: number;
  setPage(page: number): void;
}

export const defaultPaginationContextValue: PaginationContextValue = {
  page: 1,
  pages: 1,
  setPage: (page) => {},
};

export const PaginationContext = createContext<PaginationContextValue>(
  defaultPaginationContextValue
);

export const PaginationProvider = PaginationContext.Provider;
