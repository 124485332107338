import { useHistory } from 'react-router-dom';

import { FactoryLocationState, PartialMessageProps } from 'types';

export interface UseMessage {
  redirectWithMessage(message: PartialMessageProps, path?: string): void;
  setCustomMessage: (value: PartialMessageProps) => void;
}

export const useMessage = (): UseMessage => {
  const history = useHistory<FactoryLocationState>();

  const redirectWithMessage = (
    message: PartialMessageProps,
    path?: string
  ): void => {
    if (path) {
      history.push(path, {
        message,
      });
    } else {
      history.replace(history.location.pathname, {
        message,
      });
    }
  };

  const setCustomMessage = (message: PartialMessageProps): void => {
    history.replace({
      ...history.location,
      state: { message },
    });
  };

  return { redirectWithMessage, setCustomMessage };
};
