/* eslint-disable i18next/no-literal-string */
import { FunctionComponent, memo } from 'react';
import { Link } from 'react-router-dom';

import { SourcedBatch } from 'types';
import { formatDate } from 'utils';

import styles from './batch-list-row.module.scss';

export interface BatchListRowProps {
  sourcedBatch: SourcedBatch;
}

export const BatchListRow: FunctionComponent<BatchListRowProps> = memo(
  ({ sourcedBatch }: BatchListRowProps) => {
    return (
      <tr className={`${styles.row}`}>
        <td>
          <Link
            to={`sourcedBatch/${sourcedBatch.id}`}
            className={styles.idLink}
          >
            {sourcedBatch.id}
          </Link>
        </td>
        <td className={styles.status}>{sourcedBatch.source.name}</td>
        <td className={styles.status}>{sourcedBatch.circularItems.length}</td>
        <td>{formatDate(sourcedBatch.created)}</td>
      </tr>
    );
  }
);
