import { FunctionComponent, memo } from 'react';
import { useLocation } from 'react-router-dom';

import InlineMessage from '@ingka/inline-message';

import { FactoryLocationState } from 'types';

import styles from './message.module.scss';

export const Message: FunctionComponent = memo(({ children }) => {
  const { state = {} } = useLocation<FactoryLocationState>();
  const { message } = state || {};

  return (
    <>
      <div className={styles.container}>
        {message?.title && (
          <InlineMessage
            className={styles.message}
            title={message?.title || ''}
            body={message?.body || ''}
            variant={message?.variant || undefined}
            data-cy="inline-message"
          />
        )}
      </div>
      {children}
    </>
  );
});
