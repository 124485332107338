import { useMutation } from "@apollo/client"

import { mutationRemoveItemActivity } from "services"

export function useRemoveActivity() {
  const [removeActivityMutation] = useMutation(
    mutationRemoveItemActivity
  )

  const removeActivity = async (activityId: string): Promise<void> => {
    await removeActivityMutation({
      variables: { activityId },
      update(cache, { data }) {
        if (!data) {
          return;
        }
        const activityCacheId = cache.identify({ id: activityId, __typename: "CircularItemActivity" })
        cache.evict({ id: activityCacheId })
        cache.gc();
      }
    });
  }

  return { removeActivity }
}
