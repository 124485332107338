import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { DialoguePrompt } from "components/_common"

import { DialogueProps } from "../dialogue-props";

export interface ConfirmDeleteActivityDialogueProps extends DialogueProps {
}

export const ConfirmDeleteActivityDialogue: FC<ConfirmDeleteActivityDialogueProps> = memo(
  ({
    displayDialogue,
    setDisplayDialogue,
    handleDialogueResponse
  }: ConfirmDeleteActivityDialogueProps) => {

    const { t } = useTranslation();
    return (
      <DialoguePrompt
        compact={true}
        displayDialogue={displayDialogue}
        setDisplayDialogue={setDisplayDialogue}
        handleDialogueResponse={handleDialogueResponse}
        positiveBtnText={t('actions.confirmDelete')}
        negativeBtnText={t('actions.noDont')}
        promptTitle={t('dialogues.confirmActivityDelete.title')}
        promptBody={t("dialogues.confirmActivityDelete.body")}
        id={"prompt-confirm-delete-activity"}
      />
    )

  })
