import i18next from 'i18next';
import * as Yup from 'yup';

export const verifiedItemSchema = Yup.object().shape({
  itemNumber: Yup.string().matches(
    /^\d{3}\.\d{3}\.\d{2}$/,
    i18next.t('notifications.itemNumber')
  ),
  itemQuantity: Yup.number()
    .min(1)
    .max(100)
    .typeError(i18next.t('notifications.mustBeNumber.typeError')),
  estimatedSalesPrice: Yup.number().typeError(
    i18next.t('notifications.estimatedSalesPrice.typeError')
  ),
  supplierNumber: Yup.number().typeError(
    i18next.t('notifications.supplierNumber.error')
  ),
});

export const nonVerifiedItemSchema = Yup.object().shape({
  itemQuantity: Yup.number()
    .min(1)
    .max(100)
    .typeError(i18next.t('notifications.mustBeNumber.typeError')),
  itemNumber: Yup.string().matches(
    /^\d{3}\.\d{3}\.\d{2}$/,
    i18next.t('notifications.itemNumber')
  ),
  regularItemSalesPriceInclTax: Yup.number().typeError(
    i18next.t('notifications.estimatedSalesPrice.typeError')
  ),
  estimatedSalesPrice: Yup.number().typeError(
    i18next.t('notifications.estimatedSalesPrice.typeError')
  ),
  supplierNumber: Yup.number().typeError(
    i18next.t('notifications.supplierNumber.error')
  ),
});

export const nonVerifiedItemEditSchema = Yup.object().shape({
  itemNumber: Yup.string().matches(
    /^\d{3}\.\d{3}\.\d{2}$/,
    i18next.t('notifications.itemNumber')
  ),
  regularItemSalesPriceInclTax: Yup.number().typeError(
    i18next.t('notifications.estimatedSalesPrice.typeError')
  ),
  estimatedSalesPrice: Yup.number().typeError(
    i18next.t('notifications.estimatedSalesPrice.typeError')
  ),
  supplierNumber: Yup.number().typeError(
    i18next.t('notifications.supplierNumber.error')
  ),
});

export const registerSaleSchema = Yup.object().shape({
  salesPrice: Yup.number()
    .positive()
    .typeError(i18next.t('notifications.mustBeNumber.typeError'))
    .required(i18next.t('notifications.registerSale.inputError')),
});

export const assignToBatchSchema = Yup.object().shape({
  sourceId: Yup.string().when('batchType', {
    is: 'new',
    then: Yup.string().required(),
  }),
  sourcedBatchId: Yup.string().when('batchType', {
    is: 'existing',
    then: Yup.string().required(),
  }),
  sourcingTimestamp: Yup.date().required(),
});

export const createItemSchema = Yup.object()
  .shape({
    durationInMinutes: Yup.number()
      .integer(i18next.t('notifications.activity.durationType'))
      .typeError(i18next.t('notifications.activity.durationType')),
  })
  .concat(assignToBatchSchema);

export const updateSourcedBatchSchema = Yup.object().shape({
  durationInMinutes: Yup.number()
    .integer(i18next.t('notifications.activity.durationType'))
    .positive()
    .typeError(i18next.t('notifications.activity.durationType')),
});

export const updateShipmentDetailsSchema = Yup.object().shape({
  euroPalletCount: Yup.number().integer().positive(),
  longPalletCount: Yup.number().integer().positive(),
});
