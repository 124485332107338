import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BatchStockView } from 'components';
import { DEFAULT_PAGE_SIZE } from 'config';
import { PaginationProvider } from 'context';
import { usePageParams } from 'hooks';
import { FactoryLocationState } from 'types';

import { useBatchStockPage } from '../hooks/useBatchStockPage';

export const BatchStockPage: FC = () => {
  const location = useLocation<FactoryLocationState>();
  const { search } = location;

  const {
    page,
    pages,
    skip,
    sourcedBatchOrder,
    searchTerm,
    sourcedBatchFilter,
    updatePageParams,
    gotoPage,
    setPages,
  } = usePageParams();

  const { batchItems, total, error, loading } = useBatchStockPage({
    skip,
    limit: DEFAULT_PAGE_SIZE,
    order: sourcedBatchOrder,
    searchTerm: searchTerm,
    filter: sourcedBatchFilter,
  });

  useEffect(() => {
    updatePageParams(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setPages(Math.ceil(total / DEFAULT_PAGE_SIZE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return (
    <PaginationProvider value={{ page, pages, setPage: gotoPage }}>
      <BatchStockView
        pageMessage={''}
        batchItems={batchItems}
        limit={DEFAULT_PAGE_SIZE}
        skip={skip}
        orderBy={sourcedBatchOrder}
        total={total}
        error={error}
        loading={loading}
      />
    </PaginationProvider>
  );
};
