import { FC } from 'react';
import DatePicker from 'react-datepicker';

import FormField from '@ingka/form-field';
import Text from '@ingka/text';

import './stylesheets/datepicker.scss';
import styles from './date-input.module.scss';

// This component is built from reactdatepicker by Hackerone
// Stylesheets are copied from node_modules and edited.
// https://reactdatepicker.com

interface DatePickerProps {
  label: string;
  value: Date | undefined;
  setValue: (date: Date) => void;
  disabled: boolean;
  name?: string;
}

export const DateInput: FC<DatePickerProps> = ({
  label,
  value,
  setValue,
  disabled,
  name,
}) => {
  const handleSetDate = (date: Date | null) => {
    if (date) {
      setValue(date);
    }
  };

  return (
    <FormField className={styles.field}>
      <Text tagName={'span'} headingSize="xs" className={styles.fieldLabel}>
        {label}
      </Text>
      <DatePicker
        id={name || 'dateInput'}
        selected={value || null}
        onChange={(date) => handleSetDate(date)}
        // highlightDates={[value]}
        className={styles.datePicker}
        calendarClassName={styles.calendar}
        disabled={disabled}
      />
    </FormField>
  );
};
