import { useQuery } from '@apollo/client';
import { FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik';
import { FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';


import Accordion, { AccordionItem } from '@ingka/accordion';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Modal, { ModalBody, ModalFooter, Sheets } from '@ingka/modal';
import Text from '@ingka/text';

import { GradeInput } from 'components';
import { Activity, ActivityFormValue } from 'components/_form/activity/activity';
import { queryActivityTypes } from 'services';
import {
  Activity as ActivityType,
  FactoryComponentProps,
  GradeValue,
  QueryActivityTypesResult,
} from 'types';

import styles from './edit-activity-modal.module.scss';

export interface EditActivityFormValues extends FactoryComponentProps {
  // TODO: Due to the way the Activity form component works, it's pretty tightly coupled
  // to a list of activities. This interface (for now) only allows editing a single
  // activity at a time, so this needs to be wrapped in an array.
  activities: ActivityFormValue[];
  grade?: GradeValue | undefined;
}

export interface EditActivityModalProps {
  visible: boolean;
  error?: boolean;
  onSubmit(
    values: EditActivityFormValues,
    helpers: FormikHelpers<EditActivityFormValues>
  ): void;
  onClose(): void;
  activity: ActivityType;
}

export const EditActivityModal: FunctionComponent<EditActivityModalProps> =
  ({
    visible,
    error,
    onSubmit,
    onClose, 
    activity,
  }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();
    const { loading } =
      useQuery<QueryActivityTypesResult>(queryActivityTypes);

    const validationSchema = Yup.object().shape({
      activities: Yup.array().of(Yup.object().shape({
        type: Yup.string().required(t('notifications.activity.selectType')),
        duration: Yup.number()
          .integer(t('notifications.activity.durationType'))
          .max(60, t('notifications.activity.durationMax', { max: 60 }))
          .required(t('notifications.activity.duration'))
          .typeError(t('notifications.activity.durationType')),
      }))
    });

    const {
      isSubmitting,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      resetForm,
      submitForm,
    } = useFormik<EditActivityFormValues>({
      initialValues: {
        activities: [{
          type: activity.activity,
          duration: activity.duration.match(/[0-9]+/g)![0]
        }],
        grade: activity.gradeValue
      },
      onSubmit,
      validationSchema,
    });

    const setGradeValue = (grade: GradeValue) =>
      setFieldValue('grade', grade, false);

    const onSubmitClick = (event: MouseEvent) => {
      event.preventDefault();
      submitForm();
    };

    const handleCloseBtn = () => onClose();

    const onModalClosed = () => resetForm();

    const onCancelClick = () => onClose();

    const footer = (
      <ModalFooter>
        <>
          <Button
            type="primary"
            fluid={true}
            loading={isSubmitting || loading}
            htmlType="submit"
            text={t('actions.save')}
            onClick={onSubmitClick}
            data-cy="edit-activity-submit-btn"
          />
          <Button
            type="secondary"
            fluid={true}
            loading={isSubmitting || loading}
            text={t('actions.cancel')}
            onClick={onCancelClick}
            data-cy="edit-activity-cancel-btn"
          />
        </>
      </ModalFooter>
    );
    return (
      <form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
        <Modal
          visible={visible}
          handleCloseBtn={handleCloseBtn}
          onModalClosed={onModalClosed}
          data-cy="edit-activity-modal"
        >
          <Sheets size="small" footer={footer}>
            <ModalBody>
              {loading ? (
                <div>...</div>
              ) : (
                <>
                  <Text headingSize={'m'} className={styles.heading}>
                    {t('editActivity')}
                  </Text>
                  <Activity
                    index={0}
                    disabled={isSubmitting}
                    values={values.activities[0]}
                    errors={(errors.activities || [])[0] as FormikErrors<ActivityFormValue>}
                    touched={(touched.activities instanceof Array<ActivityFormValue> ? touched.activities[0] : touched.activities) as FormikTouched<ActivityFormValue>}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setPreselectedType={(v) => setFieldValue('activities[0].type', v)}
                  />
                  {error && (
                    <InlineMessage
                      variant="negative"
                      title={t('notifications.error')}
                      body={t('notifications.activity.addError')}
                      className={styles.error}
                    />
                  )}
                  <Accordion className={styles.field}>
                    <AccordionItem
                      id="EditActivity-grade-content"
                      title={t('changeGrade')}
                      data-cy="accordion-change-grade"
                    >
                      <>
                        <GradeInput
                          loading={isSubmitting}
                          setValue={setGradeValue}
                          value={values.grade}
                          empty={!values.grade}
                        />
                      </>
                    </AccordionItem>
                  </Accordion>
                </>
              )}
            </ModalBody>
          </Sheets>
        </Modal>
      </form>
    );
  }
