import { t } from "i18next";
import { FC, useState } from "react";

import Button from "@ingka/button";
import copy from "@ingka/ssr-icon/paths/copy";
import Text from "@ingka/text"

import styles from './copy-button.module.scss';
export interface CopyButtonProps {
  /** The value to copy to the clipboard when clicked */
  value: string;

  /** If true, confirmation text is forced inline with the copy button, instead of floating over other elements.
   * Helpful for some display issues */
  forceConfirmInline?: boolean;

  /** A CSS class to set on the button. */
  className?: string;

}

export const CopyButton : FC<CopyButtonProps> = ({
  value,
  forceConfirmInline,
  className
}) => {

  const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);


  const onClick = async () => {
    await navigator.clipboard.writeText(value);
    
    // Quick flip to trigger a fade-out 
    setConfirmationVisible(true);
    setTimeout(() => setConfirmationVisible(false), 250);
    
  }

  // Mix in any external class name for this copy button container with our own needed style
  const mixedInClassName = `${className} ${styles.container}`;

  // If not visible, will add the 'hidden' class (which has a transition attached, for a smooth fade-out effect)
  const confirmClassName = `${styles.confirm} ${!confirmationVisible ? styles.hidden : ''} ${forceConfirmInline ? styles.confirmInline : ''}`


  return (
    <div className={mixedInClassName}>
      <Button className={styles.copyBtn} iconOnly xsmallIconOnly type="tertiary" ssrIcon={copy} onClick={onClick} />
      <Text tagName="span" className={confirmClassName}>{t("notifications.copy.success")}</Text>
    </div>
  )
}