import { Keys } from 'constants/Keys';

import axios from 'axios';
import { useEffect, useState } from 'react';


import { useCheckMissingValues } from 'hooks';
import { RetailItem } from 'types';

export function useRetailItemQuery(itemNumber: string, retailUnitCode: string) {
  const [retailItem, setRetailItem] = useState<RetailItem | null>();
  const { checkMissingValues } = useCheckMissingValues();

  const fetchRetailItem = async (value: string) => {
    const URL =
      window._env_.REACT_APP_GRAPHQL_URL || process.env.REACT_APP_GRAPHQL_URL;
    const itemNumber = value.replace(/\D/g, '');

    try {
      const { data } = await axios({
        url: URL,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(Keys.TOKEN_KEY)}`,
        },
        data: {
          query: `
        query retailItem($itemNumber: String!, $retailUnitCode: String!) {
          retailItem(itemNumber: $itemNumber, retailUnitCode: $retailUnitCode) {
            itemNumber
            globalProductName
            regularItemSalesPriceInclTax
            regularItemSalesPriceCurrencyCode
            productDescription
            mainProductImages {
              quality
              href
            }
            businessStructure {
              productAreaNumber
              productAreaName
              productRangeAreaNumber
              productRangeAreaName
              homeFurnishingBusinessNumber
              homeFurnishingBusinessName
            }
          }
        }
      `,
          variables: {
            itemNumber: itemNumber,
            retailUnitCode,
          },
        },
      });

      if (data?.data?.retailItem?.itemNumber) {
        checkMissingValues(data.data.retailItem);
        setRetailItem(data.data.retailItem);
      } else {
        setRetailItem(null);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchRetailItem(itemNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemNumber]);

  return {
    retailItem,
    setRetailItem,
  };
}
