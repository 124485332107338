import { useAtomValue } from 'jotai';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '@ingka/button';
import documentPencil from '@ingka/ssr-icon/paths/document-pencil';
import Text from '@ingka/text';

import { globalLoadingAtom } from 'atoms';
import {
  Back,
  PageContent,
  Row,
  PageHeader,
  ReferenceItemList,
  ReferenceDetails,
} from 'components';
import { circularItem } from 'mocks';
import { CircularItem, ExternalReference } from 'types';
import { formatDate } from 'utils';

import styles from './reference-details-view.module.scss';

export interface ReferenceDetailsViewProps {
  externalReference: ExternalReference;
  editPage: boolean;
  itemsAtReference: Record<string, CircularItem[]>;
  itemsReturnedFromReference: Record<string, CircularItem[]>;
  totalItemsAtReference: number;
  totalItemsReturnedFromReference: number;
}

export const ReferenceDetailsView: FunctionComponent<ReferenceDetailsViewProps> =
  memo(
    ({
      externalReference,
      editPage,
      itemsAtReference,
      itemsReturnedFromReference,
      totalItemsAtReference,
      totalItemsReturnedFromReference,
    }) => {
      const { t } = useTranslation();
      const history = useHistory();

      const globalLoading = useAtomValue(globalLoadingAtom);

      return (
        <PageContent>
          <PageHeader divider={true}>
            <Row position="start" className={styles.start}>
              <Back
                label={t('actions.back.root')}
                onClick={() => history.goBack()}
                dataCy={'Back-Button'}
              />
              <Text tagName="h1" headingSize="m" className={styles.title}>
                {`${t('shipment.reference')}: ${
                  externalReference.externalReference
                }`}
              </Text>
            </Row>
            {!editPage && (
              <Row position="end" data-cy={'reference-details-header-buttons'}>
                <Button
                  type="primary"
                  small={true}
                  ssrIcon={documentPencil}
                  text={t('actions.edit')}
                  inverseTheme
                  href={`/reference/${externalReference?.id}/edit`}
                  data-cy="reference-details-edit-btn"
                />
              </Row>
            )}
          </PageHeader>
          {circularItem && !globalLoading && externalReference && (
            <section className={styles.detailsWrapper}>
              <Text tagName="h1" headingSize="m" className={styles.detailsId}>
                {externalReference.externalReference}
              </Text>
              <ReferenceDetails
                cpcId={externalReference.cpcId}
                totalShipmentsCount={externalReference.shippedShipments.length}
                createdAt={formatDate(externalReference.createdTimestamp)}
              />
            </section>
          )}
          <section className={styles.referenceItemsWrapper}>
            <ReferenceItemList
              externalReference={externalReference.externalReference}
              editPage={editPage}
              items={itemsAtReference}
              totalItemsCount={totalItemsAtReference}
              dataCy={'at-reference'}
            />
            <ReferenceItemList
              externalReference={externalReference.externalReference}
              items={itemsReturnedFromReference}
              totalItemsCount={totalItemsReturnedFromReference}
              fromReferenceList={true}
              dataCy={'returned-from-reference'}
            />
          </section>
        </PageContent>
      );
    }
  );
