import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { updateSourcedBatchSchema } from 'yup-schemas/yup-schemas';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import TextArea from '@ingka/text-area';

import { SourcedBatch } from 'types';

import styles from './sourced-batch-details-form.module.scss';

export interface UpdateSourcedBatchFormValues {
  durationInMinutes?: number;
  comment?: string;
}

export interface SourcedBatchDetailsFormProps {
  sourcedBatch: SourcedBatch;
  onSubmit: (
    values: UpdateSourcedBatchFormValues,
    formikHelpers: FormikHelpers<UpdateSourcedBatchFormValues>
  ) => void | Promise<any>;
}

export const SourcedBatchDetailsForm: FunctionComponent<
  SourcedBatchDetailsFormProps
> = ({ sourcedBatch, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues = {
    comment: sourcedBatch.comment,
    durationInMinutes: sourcedBatch.durationInMinutes || undefined,
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    isSubmitting,
    isValid,
    errors,
  } = useFormik<UpdateSourcedBatchFormValues>({
    initialValues,
    onSubmit,
    validationSchema: updateSourcedBatchSchema,
  });

  const handleDurationChange = (value: string) => {
    const parsed = parseInt(value);
    if (!errors.durationInMinutes && !isNaN(parsed)) {
      setFieldValue('durationInMinutes', parsed);
    } else {
      // Set the raw value so that the validation can take over
      setFieldValue('durationInMinutes', value);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <FormField
        className={styles.field}
        fieldHelper={{ msg: t('helpMessages.createItem.durationInMinutes') }}
        valid={!errors.durationInMinutes}
        shouldValidate={!!errors.durationInMinutes}
        validation={{ msg: errors.durationInMinutes }}
      >
        <InputField
          id="durationInMinutes"
          name="durationInMinutes"
          type="number"
          label={t('durationType')}
          value={values.durationInMinutes}
          onBlur={handleBlur}
          onChange={(event: any) => handleDurationChange(event.target.value)}
          data-cy="update-batch-duration"
        />
      </FormField>
      <FormField className={styles.field}>
        <TextArea
          id="comment"
          name="comment"
          label={t('comment')}
          value={values.comment}
          onBlur={handleBlur}
          onChange={handleChange}
          data-cy="update-batch-comment"
        />
      </FormField>

      <Button
        className={styles.saveButton}
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        type="primary"
        htmlType="submit"
      >
        {t('actions.save')}
      </Button>
      <Button
        htmlType="reset"
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        onClick={() => resetForm({ values: initialValues })}
        className={styles.cancelButton}
      >
        {t('actions.cancel')}
      </Button>
    </form>
  );
};
