import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { DialoguePrompt } from "components/_common"

import { DialogueProps } from "../dialogue-props";


export interface ConfirmDeleteSourceDialogueProps extends DialogueProps {
}

export const ConfirmDeleteSourceDialogue: FC<ConfirmDeleteSourceDialogueProps> = memo(
  ({
    displayDialogue,
    setDisplayDialogue,
    handleDialogueResponse
  }: ConfirmDeleteSourceDialogueProps) => {


    const { t } = useTranslation();

    return (
      <DialoguePrompt
        id={"prompt-confirm-move"}
        compact={true}
        displayDialogue={displayDialogue}
        setDisplayDialogue={setDisplayDialogue}
        handleDialogueResponse={handleDialogueResponse}
        positiveBtnText={t('actions.confirmDelete')}
        negativeBtnText={t('actions.noDont')}
        promptTitle={t('dialogues.confirmSourceDelete.title')}
        promptBody={t('dialogues.confirmSourceDelete.body')}
      />
    )

  })
