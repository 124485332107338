import { FunctionComponent } from 'react';

import Pill from '@ingka/pill';
import cross from '@ingka/ssr-icon/paths/cross';

import { FilterOption } from '../filter-row/filter-row';

interface SelectedFilterRowProps {
  filterOptions: FilterOption[];
  onRemoveFilterClick(value: string): void;
}

export const SelectedFilterRow: FunctionComponent<SelectedFilterRowProps> = ({
  filterOptions,
  onRemoveFilterClick,
}) => {
  return (
    <>
      {filterOptions.map((pill) => (
        <Pill
          id={pill.label}
          key={pill.label}
          iconPosition="leading"
          value={pill.value}
          label={pill.label}
          onClick={() => onRemoveFilterClick(pill.value)}
          ssrIcon={cross}
        ></Pill>
      ))}
    </>
  );
};
