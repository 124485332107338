import { useLazyQuery } from '@apollo/client';
import axios from 'axios';
import { useAtomValue } from 'jotai/utils';

import { imageFileAtom } from 'atoms';
import { queryCircularItemImageUploadUrl } from 'services';
import {
  QueryCircularItemImageUploadUrlArgs,
  circularItemImageUploadUrlResult,
  ImageMimeContentType,
} from 'types';

const imageTypeMap: { [key: string]: ImageMimeContentType } = {
  'image/png': 'IMAGE_PNG',
  'image/jpeg': 'IMAGE_JPEG',
};

export function useUploadImage() {
  const imageFile = useAtomValue(imageFileAtom);
  const [getUploadUrlQuery] = useLazyQuery<
    circularItemImageUploadUrlResult,
    QueryCircularItemImageUploadUrlArgs
  >(queryCircularItemImageUploadUrl);

  const getImageFilename = async (): Promise<string[] | undefined> => {
    try {
      if (imageFile) {
        const imageContentType = imageTypeMap[imageFile.type];
        const { data } = await getUploadUrlQuery({
          variables: { imageContentType },
        });

        if (data) {
          const { filename, uploadUrl } = data.circularItemImageUploadUrl;
          try {
            await axios.put(uploadUrl, imageFile, {
              headers: {
                'Content-Type': imageFile.type,
              },
            });

            return [filename];
          } catch (error) {
            console.error(error);
          }
        } else {
          console.error('Could not retrieve uploadUrl');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getImageFilename,
  };
}
