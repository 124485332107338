import { atom } from 'jotai';

import { CircularItem, Shipment } from 'types';

export const selectedShipmentsAtom = atom<Shipment[]>([]);

export const selectedShipmentsLengthAtom = atom(
  (get) => get(selectedShipmentsAtom).length
);

export const selectedShipmentsClearAtom = atom(null, (_get, set) =>
  set(selectedShipmentsAtom, [])
);

export const selectedShipmentsAddAllAtom = atom(
  null,
  (get, set, shipments: Shipment[]) => set(selectedShipmentsAtom, shipments)
);

export const selectedShipmentsAddAtom = atom(
  null,
  (get, set, order: Shipment) =>
    set(
      selectedShipmentsAtom,
      !get(selectedShipmentsAtom).includes(order)
        ? [...get(selectedShipmentsAtom), order]
        : get(selectedShipmentsAtom)
    )
);

export const selectedShipmentsRemoveAtom = atom(
  null,
  (get, set, order: Shipment) => {
    const itemsFilter = ({ id }: Shipment): Boolean => id !== order.id;
    return set(selectedShipmentsAtom, [
      ...get(selectedShipmentsAtom).filter(itemsFilter),
    ]);
  }
);

export const selectedItemsAtom = atom<CircularItem[]>([]);

export const selectedItemsLengthAtom = atom(
  (get) => get(selectedItemsAtom).length
);

export const selectedItemsIdsAtom = atom((get): string[] =>
  get(selectedItemsAtom).map(({ id }: CircularItem) => id)
);

export const selectedItemsAddAtom = atom(
  null,
  (get, set, item: CircularItem) => {
    set(
      selectedItemsAtom,
      !get(selectedItemsAtom).includes(item)
        ? [...get(selectedItemsAtom), item]
        : get(selectedItemsAtom)
    );
  }
);
export const selectedItemsAddAllAtom = atom(
  null,
  (get, set, items: CircularItem[]) => set(selectedItemsAtom, items)
);

export const hasSelectAllAtom = atom<boolean>(false);

export const selectedItemsRemoveAtom = atom(
  null,
  (get, set, item: CircularItem) => {
    const itemsFilter = ({ id }: CircularItem): Boolean => id !== item.id;
    return set(selectedItemsAtom, [
      ...get(selectedItemsAtom).filter(itemsFilter),
    ]);
  }
);

export const selectedItemsClearAtom = atom(null, (_get, set) =>
  set(selectedItemsAtom, [])
);
