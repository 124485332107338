import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { registerSaleSchema } from 'yup-schemas/yup-schemas';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import InlineMessage from '@ingka/inline-message';
import InputField from '@ingka/input-field';
import Modal, { ModalFooter, Sheets, ModalBody } from '@ingka/modal';
import Text from '@ingka/text';

import { useAuth } from 'hooks';

import styles from './register-sale-modal.module.scss';
import { useRegisterSale } from './use-register-sale';

export interface RegisterSaleFormInput {
  salesPrice?: string;
}

const initialValues: RegisterSaleFormInput = {
  salesPrice: '',
};

interface RegisterSaleModalProps {
  circularItemId: string;
  onClose(): void;
  visible: boolean;
}
export const RegisterSaleModal: FC<RegisterSaleModalProps> = ({
  circularItemId,
  onClose,
  visible,
}) => {
  const { t } = useTranslation();
  const { getCurrentCpc } = useAuth();
  const { onSubmit } = useRegisterSale({
    circularItemId,
    currencyCode: getCurrentCpc().defaultCurrencyCode,
    onClose,
  });

  const {
    isSubmitting,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    submitForm,
  } = useFormik<RegisterSaleFormInput>({
    onSubmit,
    initialValues,
    validationSchema: registerSaleSchema,
  });

  const onSubmitClick = (event: MouseEvent) => {
    event.preventDefault();
    submitForm();
  };

  const handleCloseBtn = () => onClose();
  const onModalClosed = () => resetForm();
  const onCancelClick = () => onClose();

  const footer = (
    <ModalFooter>
      <>
        <Button
          type="primary"
          fluid={true}
          loading={isSubmitting}
          htmlType="submit"
          text={t('actions.save')}
          onClick={onSubmitClick}
          data-cy="register-sale-submit-btn"
        />
        <Button
          type="secondary"
          fluid={true}
          loading={isSubmitting}
          text={t('actions.cancel')}
          onClick={onCancelClick}
          data-cy="register-sale-cancel-btn"
        />
      </>
    </ModalFooter>
  );

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Modal
        visible={visible}
        handleCloseBtn={handleCloseBtn}
        onModalClosed={onModalClosed}
        data-cy="register-sale-modal"
      >
        <Sheets size="small" footer={footer}>
          <ModalBody>
            <Text headingSize={'m'} className={styles.heading}>
              {t('actions.registerSale')}
            </Text>
            <FormField
              className={styles.field}
              valid={!errors.salesPrice}
              shouldValidate={touched.salesPrice}
              validation={
                errors.salesPrice
                  ? {
                      msg: errors.salesPrice,
                      id: 'salesPrice-error',
                    }
                  : undefined
              }
            >
              <InputField
                id="salesPrice"
                type="number"
                inputMode="numeric"
                label={t('salesPrice')}
                suffixLabel={getCurrentCpc().defaultCurrencyCode}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.salesPrice}
                data-cy="register-sale-modal-price-input"
              />
            </FormField>
            {false && (
              <InlineMessage
                variant="negative"
                title={t('notifications.error')}
                body={t('notifications.registerSale.saveError')}
                className={styles.error}
              />
            )}
          </ModalBody>
        </Sheets>
      </Modal>
    </form>
  );
};
