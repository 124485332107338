window._env_ = window._env_ || {};

export const Settings = {
  graphqlUrl:
    window._env_.REACT_APP_GRAPHQL_URL || process.env.REACT_APP_GRAPHQL_URL,
  sentryDns:
    window._env_.REACT_APP_SENTRY_DNS || process.env.REACT_APP_SENTRY_DNS,
  sentrySampleRate:
    window._env_.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ||
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  sentryEnviroment:
    window._env_.REACT_APP_ENVIROMENT || process.env.REACT_APP_ENVIROMENT,
  mockPrinter:
    (window._env_.REACT_APP_MOCK_PRINTER || process.env.REACT_APP_MOCK_PRINTER) === "true"
};

export default Settings;
