import { useContext } from 'react';
import zplConvert from 'zpl-image';

import { PrintContext } from 'context';
import { nullFilter } from 'utils';

export const usePrint = () => {
  const { getPrinterMethod } = useContext(PrintContext);

  const findPrinterContainer = () => {
    return document.querySelector('#printContainer');
  };

  const getZpl = (img: ImageBitmap): string => {
    const res = zplConvert.imageToZ64(img);
    /* Build ZPL data string */
    const zpl = `^XA^LH0,30^FO0,0^GFA,${res.length},${res.length},${res.rowlen},${res.z64})^XZ`;
    return zpl;
  };

  const print = async (src: string) => {
    const printMethod = await getPrinterMethod();
    const container = findPrinterContainer();

    if (container && printMethod) {
      const img = document.createElement('img');
      img.setAttribute('crossOrigin', '');

      const onload = () => {
        // Verify the image being loaded
        if (!img.complete || img.naturalHeight < 1) {
          return;
        }

        // Create a bitmap based on the <img> element and wait
        createImageBitmap(img, 0, 0, img.width, img.height).then(
          (imageBitmap) => {
            // Once bitmap loaded, use this to create the ZPL print protocol string
            const zpl = getZpl(imageBitmap);
            printMethod(zpl);

            // Once printed remove the event listener and remove the image from the DOM
            img.onload = null;
            img.remove();
          }
        );
      };

      img.onload = onload;
      img.src = src;

      container.appendChild(img);
    }
  };

  const bulkPrint = async (srcs: string[]) => {
    const noNullSrcs = srcs.filter(nullFilter);

    // Print in sequenece
    for (const src of noNullSrcs) {
      await print(src);
    }
  };

  return { print, bulkPrint };
};

export default usePrint;
