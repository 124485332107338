import { ApolloError, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import { DEFAULT_PAGE_SIZE } from 'config';
import { mutationCreateBatchOfItems, mutationCreateItemsInExistingBatch, queryCircularItemsCollection } from 'services';
import { CreateCircularItemInput, CreateItemsExistingBatchInput } from 'types';

export const useCreateItems = () => {
  const [apolloError, setApolloError] = useState<ApolloError | null>(null);
  const [createBatchOfItems, { error: createBatchOfItemsError }] = useMutation(
    mutationCreateBatchOfItems
  );
  const [createItemsInExistingBatch, {error: createItemsInExistingBatchError}] = useMutation(
    mutationCreateItemsInExistingBatch
  );

  useEffect(() => {
    const error = createBatchOfItemsError || createItemsInExistingBatchError;
    if (error) {
      console.error(error);
      setApolloError(apolloError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBatchOfItemsError, createItemsInExistingBatchError]);

  return {
    /**
     * Creates a new batch of items with the same preselected data.
     *
     * @param batchInput data about the new batch, such as its quantity.
     * @param circularItemInput the data about the items in the batch. Applied
     * to every item.
     *
     * @returns
     */
    createBatchOfItems: async (
      batchInput: {sourcingTimestamp: Date; quantity: number, comment?: string, durationInMinutes?: number},
      circularItemInput: CreateCircularItemInput) => {

      const { data } = await createBatchOfItems({
        variables: {
          input: {
            ...batchInput,
            itemInput: circularItemInput
          },
        },
        refetchQueries: [
          {
            query: queryCircularItemsCollection,
            variables: {
              skip: 0,
              limit: DEFAULT_PAGE_SIZE
            }
          }
        ]
      });

      return data?.createBatchOfItems;
    },
    /**
     * Creates new items with the same preselected data and adds them to
     * a given batch.
     *
     * @param batchInput data about the existing batch, such as its ID.
     * @param circularItemInput the data about the items in the batch. Applied
     * to every item.
     *
     * @returns
     */
    createItemsInExistingBatch: async (
      batchInput: CreateItemsExistingBatchInput,
      circularItemInput: CreateCircularItemInput
    ) => {
      const { data } = await createItemsInExistingBatch({
        variables: {
          input: {
            ...batchInput,
            itemInput: circularItemInput
          },
        },
      });

      return data?.createItemsInExistingBatch;
    },
    apolloError,
  };
};
