// import { CircularItem } from 'types';

export const circularItem = {
  id: '64395841549140470',
  prettyId: '64395841549140470',
  itemType: null,
  userId: '9523844a-5d72-4dec-bca3-c3476a0c2f20',
  user: {
    id: '9523844a-5d72-4dec-bca3-c3476a0c2f20',
    name: 'TestUser',
  },
  source: {
    id: '1',
    name: 'Private customer donation - Recycling center',
  },
  manufacturingDate: '2022-1',
  registerDate: '2022-01-28T20:23:02.505Z',
  modifiedDate: '2022-01-28T20:23:02.505Z',
  regularItemSalesPriceInclTax: 123,
  regularItemSalesPriceCurrencyCode: 'SEK',
  FAASCustomer: '12345',
  eligibleForFAAS: true,
  estimatedSalesPrice: 123,
  sgfLocation: {
    bay: 'A',
    level: 0,
    location: 1,
  },
  tags: [],
  labelImageUrl:
    'https://europe-west1-ingka-circular-platform-dev.cloudfunctions.net/factory-create-label?circularId=64395841549140470',
  activities: [],
  currentGrade: {
    value: 'New',
  },
  initialGrade: {
    value: 'New',
  },
  itemNumber: '12345678',
  globalProductName: 'TEST ITEM',
  productDescription: 'description field',
  retailItem: null,
  supplierNumber: 12345,
  images: [],
  businessStructure: {
    productAreaNumber: '0111',
    productAreaName: 'Sofas',
    productRangeAreaNumber: '011',
    productRangeAreaName: 'Sofas',
    homeFurnishingBusinessNumber: '01',
    homeFurnishingBusinessName: 'Living room seating',
  },
};
