import { useAtomValue } from 'jotai';
import {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Search from '@ingka/search';
import Text from '@ingka/text';

import { selectedItemsLengthAtom } from 'atoms';
import {
  FilterRow,
  PageContent,
  PageHeader,
  Pagination,
  SelectedFilterRow,
  ShipmentList,
  StockListHeaderItemProps,
} from 'components';
import { usePageParams, useStatusFilter } from 'hooks';
import { SearchData } from 'types';
import {
  Shipment,
  ShipmentOrderBy,
  ShipmentStatus,
} from 'types/generated/graphql';
import { getShipmentSortOrder, invertOrder } from 'utils';

import styles from './shipment-stock-view.module.scss';

export interface ShipmentStockViewProps {
  shipments?: Shipment[];
  limit: number;
  skip: number;
  total?: number;
  orderBy: ShipmentOrderBy;
  highlighted?: string[];
}

export const ShipmentStockView: FunctionComponent<ShipmentStockViewProps> =
  memo(
    ({
      shipments = [],
      skip = 0,
      limit = 0,
      total,
      orderBy,
      highlighted,
    }: ShipmentStockViewProps) => {
      const { t } = useTranslation();
      const { searchTerm, updatePageSearch } = usePageParams();
      const [searchInput, setSearchInput] = useState<string>(searchTerm || '');
      const [message, setMessage] = useState<string>(t('inventoryEmpty'));
      const selectedItemsLength = useAtomValue(selectedItemsLengthAtom);
      const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
      };

      const onSearchSubmit = (_e: Event, { value = '' }: SearchData) => {
        updatePageSearch(value);
      };
      // const {
      //   currentFilterValues,
      //   setStatusFilterValue,
      //   getSelectedFilterOptions,
      // } = useFilter();

      const onSearchClear = () => {
        setSearchInput('');
        updatePageSearch('');
      };

      const onFilterChange = (filterInput: ShipmentStatus | null) => {
        setShipmentStatusFilterValue(filterInput);
      };

      const {
        currentShipmentFilterValues,
        getSelectedFilterOptions,
        setShipmentStatusFilterValue,
      } = useStatusFilter();

      useEffect(() => {
        let nextMessage: string = '';
        if (total && searchTerm && selectedItemsLength) {
          nextMessage = t('shipmentCount.bulkSearch', {
            selected: selectedItemsLength,
            total,
            searchTerm,
          });
        } else if (total && searchTerm) {
          nextMessage = t('shipmentCount.search', {
            pageSize: `${skip + 1}-${Math.min(skip + limit, total)}`,
            total,
            searchTerm,
          });
        } else if (total && selectedItemsLength) {
          nextMessage = t('shipmentCount.bulk', {
            selected: selectedItemsLength,
            total,
          });
        } else if (total) {
          nextMessage = t('shipmentCount.default', {
            pageSize: `${skip + 1}-${Math.min(skip + limit, total)}`,
            total,
          });
        } else {
          nextMessage = t('inventoryEmpty');
        }
        setMessage(nextMessage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [total, searchTerm, skip, limit, selectedItemsLength]);

      const headers: StockListHeaderItemProps[] = [
        { label: t('id'), className: styles.colId },
        {
          label: t('shipment.externalreference'),
          className: styles.colProductName,
          // sortKey: 'globalProductName',
        },
        {
          label: t('shipped'),
          className: styles.colShipped,
          title: t('shipped'),
          // sortKey: 'lastUpdatedAt',
        },
        {
          label: t('shipment.items'),
          className: styles.colPa,
          title: t('shipment.items'),
        },
        {
          label: t('shipment.lastUpdatedAt'),
          className: styles.colDescription,
          title: t('shipment.lastUpdatedAt'),
          // sortKey: 'lastUpdatedAt',
        },
        {
          label: t('shipment.createdAt'),
          className: styles.colDescription,
          title: t('shipment.createdAt'),
          sortKey: 'createdTimestamp',
        },
      ];

      const headerMapper = ({
        sortKey,
        sortOrder: defaultSortOrder = 'DESC',
        ...rest
      }: StockListHeaderItemProps): StockListHeaderItemProps => {
        const { field, order } = getShipmentSortOrder(orderBy);
        const active = !!sortKey && sortKey === field;
        const sortOrder = sortKey
          ? active && order
            ? invertOrder(order)
            : defaultSortOrder
          : undefined;
        return { active, sortKey, sortOrder, ...rest };
      };

      return (
        <PageContent>
          <PageHeader className={styles.header}>
            <div className={styles.headerStart}>
              <Text tagName="h1" headingSize="m">
                {t('shipment.shipments')}
              </Text>
              <Text bodySize="s" className={styles.total}>
                {message}
              </Text>
            </div>
          </PageHeader>
          <div className={styles.searchBar}>
            <div>
              <Search
                id="search"
                ariaLabel={t('search')}
                placeholder={t('search')}
                clearBtnText={t('clear')}
                // disabled={globalLoading}
                onSearch={onSearchSubmit}
                onClear={onSearchClear}
                onChange={onSearchChange}
                value={searchInput}
                autoFocus
              />
            </div>
            <FilterRow
              filters={currentShipmentFilterValues}
              onFilterChange={(_, value) =>
                onFilterChange(value as ShipmentStatus)
              }
            />
          </div>
          <div className={styles.selectedFiltes}>
            <SelectedFilterRow
              filterOptions={getSelectedFilterOptions()}
              onRemoveFilterClick={() => onFilterChange(null)}
            />
          </div>
          <ShipmentList
            shipments={shipments}
            headers={headers.map(headerMapper)}
            highlighted={highlighted}
          />
          <Pagination hide={!shipments?.length} />
        </PageContent>
      );
    }
  );
