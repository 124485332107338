import { FunctionComponent, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@ingka/skeleton';
import Table, { TableBody, TableHeader } from '@ingka/table';

import { StockListHeaderItem, StockListHeaderItemProps } from 'components';
import { Source } from 'types';

import { SourceListRow } from './source-list-row/source-list-row';
import styles from './source-list.module.scss';

export interface SourceListProps {
  items: Source[];
  error: boolean;
  loading: boolean;
  onDeleteSource: (source:Source) => void;
}

export const SourceList: FunctionComponent<SourceListProps> = memo(
  ({ items = [], error, loading, onDeleteSource }) => {
    const { t } = useTranslation();
    const max = 10;
    const fallbackHeight = useMemo<number>(() => 77 + max * 83, [max]);

    const rowMapper = (source: Source) => {
      return <SourceListRow key={source.id} source={source} handleDeleteButtonClick={() => onDeleteSource(source)} />;
    };

    const headers: StockListHeaderItemProps[] = [
      { label: t('id'), className: styles.colId },
      {
        label: t('source'),
        className: styles.colSource,
      },
      {
        label: t("actions.deleteSource"),
        className: styles.colDeleteItem
      }
    ];

    const headerMapper = ({
      sortKey,
      label,
      className,
      title,
    }: StockListHeaderItemProps) => {
      return (
        <StockListHeaderItem
          key={label}
          label={label}
          className={className}
          title={title}
        />
      );
    };

    return error ? null : loading ? (
      <Skeleton height={`${fallbackHeight}px`} />
    ) : items?.length ? (
      <Table className={styles.table}>
        <TableHeader>
          <tr className={styles.tableHeader}>{headers.map(headerMapper)}</tr>
        </TableHeader>
        <TableBody>{items.map(rowMapper)}</TableBody>
      </Table>
    ) : null;
  }
);
