import { FunctionComponent, useState } from 'react';

import { CreateItemsModal, CreateItemViewNew } from 'components';

export const CreateItemPageNew: FunctionComponent = () => {
  const [createItemsModal, setCreateItemsModal] = useState<boolean>(false);

  return (
    <>
      <CreateItemsModal
        visible={createItemsModal}
        onClose={() => setCreateItemsModal(false)}
      />
      <CreateItemViewNew onCreateItemsClick={() => setCreateItemsModal(true)} />
    </>
  );
};
