import { ApolloError } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { globalAppErrorAtom } from 'atoms';
import { CreateItemFormValues, CreateItemView } from 'components';
import { createSgfLocationInput } from 'components/_pages/edit-item/item-edit-page/item-edit-page-utils';
import { useItemStock } from 'components/_pages/item-stock/hooks/use-item-stock';
import { DEFAULT_PAGE_SIZE } from 'config';
import { FormProvider } from 'context';
import {
  useAuth,
  useCreateItems,
  useGradeItem,
  useItemNumber,
  usePrint,
  useRetailItemQuery,
  useScrollToTop,
  useUploadImage,
} from 'hooks';
import {
  FactoryLocationState,
  CreateCircularItemInput,
} from 'types';
import { nullFilter } from 'utils';
export interface CreateItemPageParameters {
  paramItemNumber: string;
}

export const CreateItemPage: FunctionComponent = memo(() => {
  const { t } = useTranslation();
  const { paramItemNumber } = useParams<CreateItemPageParameters>();
  const history = useHistory<FactoryLocationState>();
  const { getCurrentCpcId, getCurrentCpc } = useAuth();
  const { scrollToTop } = useScrollToTop();
  const { getImageFilename } = useUploadImage();
  const { gradeItem } = useGradeItem();
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const [printDialogueResponse, setPrintDialogueResponse] = useState<boolean>();
  const { itemNumber, setItemNumber } = useItemNumber();
  const { print: printHook, bulkPrint } = usePrint();
  const {createBatchOfItems, createItemsInExistingBatch, apolloError} = useCreateItems();
  const currentCPC = getCurrentCpc();
  const { retailItem, setRetailItem } = useRetailItemQuery(
    itemNumber,
    currentCPC.retailUnitCode
  );

  useEffect(() => {
    if (paramItemNumber) {
      setItemNumber(paramItemNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramItemNumber]);

  const { refetch } = useItemStock({
    skip: 0,
    limit: DEFAULT_PAGE_SIZE,
    order: {
      registerDate: 'DESC',
    },
    searchTerm: '',
  });


  useEffect(() => {
    if (apolloError) {
      setGlobalAppError(apolloError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloError]);

  const onSubmit = async (
    {
      itemQuantity = 1,
      productName,
      productDescription,
      itemNumber,
      businessStructure,
      grade,
      supplierNumber,
      sourceId,
      eligibleForFAAS,
      FAASCustomer,
      year,
      week,
      tags,
      print = false,
      estimatedSalesPrice,
      regularItemSalesPriceInclTax,
      regularItemSalesPriceCurrencyCode,
      sgfLocation,
      batchType,
      sourcingTimestamp,
      sourcedBatchId,
      comment,
      durationInMinutes
    }: CreateItemFormValues,
    { setSubmitting }: FormikHelpers<CreateItemFormValues>
  ) => {
    sourcingTimestamp = sourcingTimestamp!;
    try {

      const created: string[] = [];
      const quantity = parseInt(`${itemQuantity}`, 10) || 1;
      const input: CreateCircularItemInput = {
        productName,
        productDescription,
        itemNumber: itemNumber?.replace(/\D/g, ''),
        businessStructure,
        retailUnitCode: currentCPC.retailUnitCode,
        sgfLocation: sgfLocation ? createSgfLocationInput(sgfLocation) : null,
        cpcId: getCurrentCpcId(),
        sourceId,
        eligibleForFAAS,
        FAASCustomer,
        manufacturingDate: year && week ? `${year}-${week}` : null,
        estimatedSalesPrice: estimatedSalesPrice?.length
          ? parseInt(estimatedSalesPrice, 10)
          : undefined,
        regularItemSalesPriceInclTax: retailItem?.regularItemSalesPriceInclTax
          ? retailItem.regularItemSalesPriceInclTax
          : regularItemSalesPriceInclTax?.length
          ? parseInt(regularItemSalesPriceInclTax, 10)
          : undefined,
        regularItemSalesPriceCurrencyCode:
          retailItem?.regularItemSalesPriceCurrencyCode
            ? retailItem?.regularItemSalesPriceCurrencyCode
            : regularItemSalesPriceCurrencyCode,
        supplierNumber: supplierNumber
          ? parseInt(supplierNumber, 10)
          : undefined,
        tags,
        uploadedFilenames: await getImageFilename()
      };

      const response = batchType === 'new'
        ? await createBatchOfItems({quantity, sourcingTimestamp, comment, durationInMinutes}, input)
        : await createItemsInExistingBatch({quantity, sourcedBatchId: sourcedBatchId!, sourcingTimestamp}, input);

      if (!response) {
        throw new Error("Response was null and no Apollo error was thrown!");
      }

      // TODO: obviously a one-by-one grade update is suboptimal, however it's (1) how this flow previously worked
      // anyway, and (2) running these in parallel should be ok from a UX perspective.
      //
      // If we get more traffic later on though, definitely should make a bulk mutation.
      if (grade) {
        await Promise.all(response.newCircularItems.map(item => gradeItem(grade, item.id)));
      }

      const labels = response.newCircularItems.map(item => item.labelImageUrl).filter(nullFilter);

      if (print && !printDialogueResponse) {
        await printHook(labels[0]);
      } else if (print && printDialogueResponse) {
        await bulkPrint(labels);
      }

      if (quantity > 1 && print) {
        history.push(`/inventory/`, {
          message: {
            title: t(
              printDialogueResponse
                ? 'notifications.print.multiple'
                : 'notifications.print.singlePrint',
              { quantity }
            ),
            variant: 'positive',
          },
        });
      } else if (quantity > 1 && !print) {
        history.push(`/inventory/`, {
          message: {
            title: t('notifications.addItem.multiple', { quantity }),
            variant: 'positive',
          },
        });
      } else if (quantity === 1) {
        const id = created.pop() || '';
        history.push(`/inventory/${id}`, {
          message: {
            title: t(
              print
                ? 'notifications.print.single'
                : 'notifications.addItem.single'
            ),
            variant: 'positive',
          },
        });
      }
      // because apollo does not fetch if no values are changed
      refetch();
      scrollToTop();
    } catch (error) {
      if (error instanceof ApolloError) {
        setGlobalAppError(error);
      }
      console.error(error);
    }
    finally {
      setSubmitting(false);
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const handleSetItemNumber = (value: string) => {
    setItemNumber(value?.replace(/\D/g, ''));
  };

  return (
    <FormProvider
      value={{
        itemNumber,
        retailItem,
        setItemNumber: handleSetItemNumber,
        setRetailItem,
      }}
    >
      <CreateItemView
        onSubmit={onSubmit}
        onCancel={onCancel}
        setPrintDialogueResponse={setPrintDialogueResponse}
      />
    </FormProvider>
  );
});
