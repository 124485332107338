import { useMutation } from '@apollo/client';

import { mutationUpdateItemActivity } from 'services';
import { GradeValue, CircularItemActivity } from 'types';

export function useUpdateActivity() {
  const [updateItemActivityMutation] = useMutation(mutationUpdateItemActivity);

  const updateItemActivity = async (
    id: string,
    activityTypeId: string,
    executionTimeInMins: number,
    grade?: GradeValue
  ): Promise<CircularItemActivity | null | undefined> => {
    try {
      const response = await updateItemActivityMutation({
        variables: {
          id,
          input: {
            activityTypeId,
            executionTimeInMins,
            grade,
          },
        },
        update(cache, { data }) {
          if (!data) {
            return;
          }
          const updatedActivity = data.updateItemActivity;
          // Add activity to cache
          cache.modify({
            id: cache.identify({
              ...updatedActivity,
            }),
            fields: {
              startTime: () => updatedActivity.startTime,
              endTime: () => updatedActivity.endTime,
              activityType: () => updatedActivity.activityType,
              grade: () => updatedActivity.grade,
            },
          });
        },
      });
      return response.data?.updateItemActivity as CircularItemActivity;
    } catch (error) {
      //@TODO redirect with another message?
      console.error(error);
      return null;
    }
  };
  return { updateItemActivity };
}
