import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { selectedItemsClearAtom } from 'atoms';
import { ItemBulkMenu, ReferenceDetailsView } from 'components';
import { usePrint } from 'hooks';

import { useReferencePage } from '../hooks/useReferencePage';

export interface ReferenceDetailsPageProps {
  id: string;
}

export const ReferenceDetailsPage: FunctionComponent = () => {
  const location = useLocation();
  const [printItems, setPrintItems] = useState<string[]>();
  const { bulkPrint } = usePrint();
  const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);

  const {
    externalReference,
    itemsAtReference,
    itemsReturnedFromReference,
    totalItemsAtReference,
    totalItemsReturnedFromReference,
    refetchExternalReference,
  } = useReferencePage();

  const handleBulkPrint = async (printItems: string[]) => {
    bulkPrint(printItems);
    setPrintItems(undefined);
    clearSelectedItems();
  };

  useEffect(() => {
    if (printItems) {
      handleBulkPrint(printItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printItems]);

  return externalReference ? (
    <>
      <ItemBulkMenu
        menuItemKeys={['print', 'status']}
        setPrintItems={setPrintItems}
        refetchExternalReference={refetchExternalReference}
      />
      <ReferenceDetailsView
        externalReference={externalReference}
        editPage={!!location.pathname.endsWith('/edit')}
        itemsAtReference={itemsAtReference}
        itemsReturnedFromReference={itemsReturnedFromReference}
        totalItemsAtReference={totalItemsAtReference}
        totalItemsReturnedFromReference={totalItemsReturnedFromReference}
      />
    </>
  ) : null;
};
