export const useScrollToTop = () => {
  function scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  return { scrollToTop };
};
