import { useMessage } from 'hooks';
import { RetailItem } from 'types';

export const useCheckMissingValues = () => {
  const { setCustomMessage } = useMessage();

  const checkMissingValues = (retailItem: RetailItem): void => {
    const hasMissingValues = Object.values(retailItem).some((x) => x === null);

    if (hasMissingValues) {
      setCustomMessage({
        title: 'Missing information',
        body: 'Some information was not possible to retrieve for this article because it does not exist in the sellable range.',
        variant: 'cautionary',
      });
    }
  };

  return { checkMissingValues };
};
