import { t } from 'i18next';
import { FunctionComponent, memo, useEffect } from 'react';

import { StockListHeaderItem } from 'components';
import { BaseStockList } from 'components/base-stock-list/base-stock-list';
import { useSelect } from 'hooks';
import { CircularItem } from 'types';

import { SourcedBatchItemListRow } from './sourced-batch-item-list-row/sourced-batch-item-list-row';
import styles from './sourced-batch-item-list.module.scss';

export interface SourcedBatchItemListProps {
  items: CircularItem[];
  highlighted?: string[];
}

export const SourcedBatchItemList: FunctionComponent<SourcedBatchItemListProps> =
  memo(({ items = [], highlighted = [] }) => {
    const {
      hasSelectAll,
      setHasSelectAll,
      setSelectedItemsAddAllAtom,
      clearSelectedItems,
      selectedItems,
      toggleItemSelect,
    } = useSelect();

    const headers = [
      <StockListHeaderItem key="id" label={t('id')} className={styles.colId} />,
      <StockListHeaderItem
        key="productName"
        label={t('productName')}
        className={styles.colProductName}
        sortKey="globalProductName"
      />,
      <StockListHeaderItem
        key="pa"
        label={t('pa.short')}
        className={styles.colPa}
        title={t('pa.long')}
      />,

      <StockListHeaderItem
        key="sourcingDate"
        label={t('sourcingDate')}
        className={styles.colSourcingTimestamp}
      />,
      <StockListHeaderItem
        key="currentGrade"
        label={t('currentGrade')}
        className={styles.colGrade}
      />,

      <StockListHeaderItem
        key="status"
        label={t('status')}
        className={styles.colStatus}
      />,
      <StockListHeaderItem
        key="created"
        label={t('created')}
        className={styles.colStatus}
        sortKey="registerDate"
      />,
    ];

    const rowMapper = (circularItem: CircularItem) => {
      const selected = selectedItems.includes(circularItem);
      const setSelected = () => {
        toggleItemSelect(!selected, circularItem);
      };

      return (
        <SourcedBatchItemListRow
          key={circularItem.id}
          item={circularItem}
          selected={selected}
          setSelected={setSelected}
          highlighted={highlighted.includes(circularItem.id)}
        />
      );
    };

    const onSelectAllChange = () => {
      setHasSelectAll(!hasSelectAll);
    };

    useEffect(() => {
      hasSelectAll ? setSelectedItemsAddAllAtom(items) : clearSelectedItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelectAll]);

    return (
      <BaseStockList
        rows={items.map(rowMapper)}
        headers={headers}
        onSelectAllChange={onSelectAllChange}
        hasSelectAll={hasSelectAll}
      />
    );
  });
