import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en';
import sv from './sv';

export interface Language {
  key: string;
  name: string;
}

export const availableLanguages: Language[] = [
  { key: 'en', name: 'English' },
  { key: 'sv', name: 'Svenska' },
];

export const fallbackLng = 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      sv,
    },
    detection: {
      order: ['cookie', 'localStorage', 'navigator', 'htmlTag'],
    },
    fallbackLng,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'copy',
  });

export default i18n;
