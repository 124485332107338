import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import imageIcon from '@ingka/ssr-icon/paths/image';

import { imageFileAtom } from 'atoms';

import styles from './upload-image.module.scss';

export interface UploadImageProps {
  loading?: boolean;
}

export const UploadImage: FunctionComponent<UploadImageProps> = memo(
  ({ loading }) => {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const setImageFile = useUpdateAtom(imageFileAtom);

    function handleClick() {
      if (fileInputRef.current) fileInputRef.current.click();
    }

    function handleFileInput(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.files) {
        setImageFile(e.target.files[0]);
      }
    }

    useEffect(() => {
      setImageFile(undefined);
    });

    return (
      <FormField className={styles.form}>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          id="imageInputField"
          className={styles.fileInput}
          onChange={handleFileInput}
        />
        <Button
          type="secondary"
          loading={loading}
          disabled={loading}
          ssrIcon={imageIcon}
          onClick={handleClick}
        >
          {t('actions.addImage')}
        </Button>
      </FormField>
    );
  }
);
