import axios from 'axios';
import { FunctionComponent, memo, useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import Choice, { ChoiceItem } from '@ingka/choice';
import Modal, { Sheets, ModalHeader, ModalBody } from '@ingka/modal';
import SSRIcon from '@ingka/ssr-icon';
import globe from '@ingka/ssr-icon/paths/globe';
import Text from '@ingka/text';

import { availableLanguages, Language } from 'i18n';
import { FactoryComponentProps, FontManifest } from 'types';

import styles from './language-selector.module.scss';

export interface LanguageSelectorProps extends FactoryComponentProps {
  title?: string;
}

const fontManifestUrl =
  'https://www.ikea.com/global/assets/fonts/manifest.json';

export const LanguageSelector: FunctionComponent<LanguageSelectorProps> = memo(
  ({ className = '' }) => {
    const { t, i18n } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [fontLanguages, setFontLanguages] = useState<string[]>(['en']);
    const [fontBaseUrl, setFontBaseUrl] = useState<string>('');
    const [fontUrl, setFontUrl] = useState<string>('');
    const preselectedIndex = availableLanguages.indexOf(
      availableLanguages.find((lang) => lang.key === i18n.language) ||
        availableLanguages[0]
    );
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const languageMapper = ({ key, name }: Language) => (
      <ChoiceItem
        id={key}
        title={name}
        selected={i18n.language === key}
        key={`LanguageSelector-language-${key}`}
        data-cy="Language-Selector-Choice"
      />
    );

    const openModal = () => setVisible(true);

    const closeModal = () => setVisible(false);

    const onSelect = (language: string) =>
      i18n.changeLanguage(language, closeModal);

    const getFontInfo = async () => {
      try {
        const { data } = await axios.get<FontManifest>(fontManifestUrl, {
          cancelToken: source.token,
        });
        if (data?.languages) {
          setFontLanguages(Object.keys(data.languages));
        }
        if (data?.urls?.css) {
          setFontBaseUrl(data.urls.css);
        }
      } catch (error) {
        // @TODO don't show this in test
        // console.error(error)
      }
    };

    useEffect(() => {
      getFontInfo();
      return () => {
        source.cancel('Clean-up');
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (
        i18n.language &&
        fontBaseUrl &&
        fontLanguages?.length &&
        fontLanguages.includes(i18n.language)
      ) {
        const tempFontUrl = fontBaseUrl.replace('{{language}}', i18n.language);
        if (tempFontUrl && tempFontUrl !== fontUrl) {
          setFontUrl(tempFontUrl);
        }
      }
    }, [fontLanguages, fontBaseUrl, i18n.language, fontUrl]);

    return (
      <div className={`${styles.container} ${className}`}>
        <Button
          className="LanguageSelector-button"
          type="secondary"
          small={true}
          disabled={visible}
          data-cy="language-selector-button"
          onClick={openModal}
        >
          <SSRIcon paths={globe} />
          <Text
            className={styles.label}
            data-cy="language-selector-button-text"
          >
            {t('actions.select-language')}
          </Text>
        </Button>
        <Modal
          visible={visible}
          handleCloseBtn={closeModal}
          data-cy="language-selector-modal"
        >
          <Sheets
            size="small"
            header={<ModalHeader title={t('actions.select-language')} />}
            footer={<></>}
          >
            <ModalBody>
              <Choice
                subtle={true}
                onSelect={onSelect}
                preselectedIndex={preselectedIndex}
                data-cy="language-selector-select"
              >
                {availableLanguages.map(languageMapper)}
              </Choice>
            </ModalBody>
          </Sheets>
        </Modal>
        <Helmet>{fontUrl && <link rel="stylesheet" href={fontUrl} />}</Helmet>
      </div>
    );
  }
);
