import { useAtomValue } from 'jotai/utils';
import {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Search from '@ingka/search';
import Text from '@ingka/text';

import { selectedItemsLengthAtom } from 'atoms';
import {
  ItemList,
  StockListHeaderItemProps,
  PageContent,
  PageHeader,
  Pagination,
  FilterRow,
  SelectedFilterRow,
} from 'components';
import { usePageParams, useStatusFilter } from 'hooks';
import { CircularItem, CircularItemOrderBy, SearchData } from 'types';
import { getSortOrder, invertOrder } from 'utils';

import styles from './item-stock-view.module.scss';

export interface ItemStockViewProps {
  items?: CircularItem[];
  limit: number;
  skip: number;
  total?: number;
  order?: CircularItemOrderBy;
  highlighted?: string[];
}

export const ItemStockView: FunctionComponent<ItemStockViewProps> = memo(
  ({
    items = [],
    skip = 0,
    limit = 0,
    total,
    order,
    highlighted,
  }: ItemStockViewProps) => {
    const { t } = useTranslation();
    const { searchTerm, updatePageSearch } = usePageParams();
    const {
      currentFilterValues,
      setItemStatusFilterValue,
      getSelectedFilterOptions,
    } = useStatusFilter();
    const [searchInput, setSearchInput] = useState<string>(searchTerm || '');
    const [message, setMessage] = useState<string>(t('inventoryEmpty'));
    const selectedItemsLength = useAtomValue(selectedItemsLengthAtom);
    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    };

    const onFilterChange = (filterInput: string) => {
      setItemStatusFilterValue(filterInput);
    };

    const onSearchSubmit = (_e: Event, { value = '' }: SearchData) => {
      updatePageSearch(value);
    };

    const onSearchClear = () => {
      setSearchInput('');
      updatePageSearch('');
    };

    useEffect(() => {
      let nextMessage: string = '';
      if (total && searchTerm && selectedItemsLength) {
        nextMessage = t('inventoryCount.bulkSearch', {
          selected: selectedItemsLength,
          total,
          searchTerm,
        });
      } else if (total && searchTerm) {
        nextMessage = t('inventoryCount.search', {
          pageSize: `${skip + 1}-${Math.min(skip + limit, total)}`,
          total,
          searchTerm,
        });
      } else if (total && selectedItemsLength) {
        nextMessage = t('inventoryCount.bulk', {
          selected: selectedItemsLength,
          total,
        });
      } else if (total) {
        nextMessage = t('inventoryCount.default', {
          pageSize: `${skip + 1}-${Math.min(skip + limit, total)}`,
          total,
        });
      } else {
        nextMessage = t('inventoryEmpty');
      }
      setMessage(nextMessage);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total, searchTerm, skip, limit, selectedItemsLength]);

    const headers: StockListHeaderItemProps[] = [
      { label: t('id'), className: styles.colId },
      {
        label: t('productName'),
        className: styles.colProductName,
        sortKey: 'globalProductName',
      },
      {
        label: t('pa.short'),
        className: styles.colPa,
        title: t('pa.long'),
      },
      {
        label: t('productDescription'),
        className: styles.colDescription,
        title: t('productDescription'),
      },
      {
        label: t('currentGrade'),
        className: styles.colGrade,
      },
      {
        label: t('status'),
        className: styles.colStatus,
      },
      {
        label: t('created'),
        className: styles.colDate,
        sortKey: 'registerDate',
      },
    ];

    const headerMapper = ({
      sortKey,
      sortOrder: defaultSortOrder = 'DESC',
      ...rest
    }: StockListHeaderItemProps): StockListHeaderItemProps => {
      const { key, value } = getSortOrder(order);
      const active = !!sortKey && sortKey === key;
      const sortOrder = sortKey
        ? active && value
          ? invertOrder(value)
          : defaultSortOrder
        : undefined;
      return { active, sortKey, sortOrder, ...rest };
    };
    return (
      <PageContent>
        <PageHeader className={styles.header}>
          <div className={styles.headerStart}>
            <Text tagName="h1" headingSize="m">
              {t('circularStock')}
            </Text>
            <Text bodySize="s" className={styles.total}>
              {message}
            </Text>
          </div>
        </PageHeader>
        <div className={styles.searchBar}>
          <div>
            <Search
              id="search"
              ariaLabel={t('search')}
              placeholder={t('search')}
              clearBtnText={t('clear')}
              // disabled={globalLoading}
              onSearch={onSearchSubmit}
              onClear={onSearchClear}
              onChange={onSearchChange}
              value={searchInput}
              autoFocus
            />
          </div>
          <FilterRow
            filters={currentFilterValues}
            onFilterChange={(_, value) => onFilterChange(value)}
          />
        </div>
        <div className={styles.selectedFiltes}>
          <SelectedFilterRow
            filterOptions={getSelectedFilterOptions()}
            onRemoveFilterClick={() => onFilterChange('')}
          />
        </div>
        <ItemList
          items={items}
          headers={headers.map(headerMapper)}
          highlighted={highlighted}
        />
        <Pagination hide={!items?.length} />
      </PageContent>
    );
  }
);
