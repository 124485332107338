import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

import {
  hasSelectAllAtom,
  selectedShipmentsAtom,
  selectedShipmentsAddAllAtom,
  selectedShipmentsAddAtom,
  selectedShipmentsClearAtom,
  selectedShipmentsRemoveAtom,
  selectedShipmentsLengthAtom,
} from 'atoms';
import { Shipment } from 'types';

export const useSelectShipment = () => {
  const [hasSelectAll, setHasSelectAll] = useAtom(hasSelectAllAtom);
  const setSelectedShipmentsAddAllAtom = useUpdateAtom(
    selectedShipmentsAddAllAtom
  );
  const addSelectedShipment = useUpdateAtom(selectedShipmentsAddAtom);
  const removeSelectedShipment = useUpdateAtom(selectedShipmentsRemoveAtom);
  const selectedShipments = useAtomValue(selectedShipmentsAtom);
  const clearSelectedShipments = useUpdateAtom(selectedShipmentsClearAtom);
  const selectShipmentsLength = useAtomValue(selectedShipmentsLengthAtom);

  const toggleShipmentSelect = (selected: boolean, shipment: Shipment) => {
    if (selected) {
      addSelectedShipment(shipment);
    } else {
      removeSelectedShipment(shipment);
    }
  };

  return {
    hasSelectAll,
    setHasSelectAll,
    setSelectedShipmentsAddAllAtom,
    clearSelectedShipments,
    selectedShipments,
    toggleShipmentSelect,
    selectShipmentsLength,
  };
};
