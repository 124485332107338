import { FunctionComponent, memo, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import arrowLeft from '@ingka/ssr-icon/paths/arrow-left';

import { FactoryComponentProps } from 'types';

export interface BackProps extends FactoryComponentProps {
  label?: string;
  onClick: MouseEventHandler;
  href?: string;
  dataCy: string;
}

export const Back: FunctionComponent<BackProps> = memo(
  ({ className = '', label, href, onClick, dataCy }) => {
    const { t } = useTranslation();
    const text = label || t('actions.back.back');

    return (
      <Button
        className={className}
        type="tertiary"
        onClick={onClick}
        iconOnly={true}
        ssrIcon={arrowLeft}
        text={text}
        href={href}
        data-cy={dataCy}
      />
    );
  }
);
