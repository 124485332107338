import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ZebraBrowserPrint from 'zebra-browser-print-wrapper';

import { useMessage } from "hooks";

import { Settings } from '../../constants';

/**
 * 
 * @returns A method that allows commands to be sent to a real Zebra printer.
 */
export default function usePrinterProvider() {
  const [zebra, setZebra] = useState(new ZebraBrowserPrint());
  const location = useLocation();
  const { setCustomMessage } = useMessage();
  let defaultPrinter;
  const initializeZebraPrinter = async () => {
    /* Configure device to print from */
    try {
      defaultPrinter = await zebra.getDefaultPrinter();
      zebra.setPrinter(defaultPrinter);
      setZebra(zebra);
    } catch (err) {
      if (location.pathname === '/inventory') {
        setCustomMessage({
          title: 'Failed to connect to printer',
          body: 'Either there is no printer connected or the connection is blocked',
          variant: 'cautionary',
        });
      }

      // This error should not be captured by sentry
      console.error('Failed to initialize printer, no printer connected');
    }
  };

  useEffect(() => {
    if (!Settings.mockPrinter) {
      initializeZebraPrinter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrinterMethod = async (): Promise<
    ((zpl: string) => void) | undefined
  > => {
    try {
      if (!zebra) {
        throw new Error("Don't have any connected printer");
      }
      // CIR-1485 Make sure printer has been init. There is a problem with context handling
      if (!zebra.device.deviceType) {
        await initializeZebraPrinter();
      }

      /* Check if printer is ready. Does not seem to work with emulator. Makes two attempts to solve issue with "cold" printer [CIR-1485] */
      const printerStatus = await zebra.checkPrinterStatus();
      if (!printerStatus.isReadyToPrint) {
        const printerStatusSecondAttempt = await zebra.checkPrinterStatus();
        if (!printerStatusSecondAttempt.isReadyToPrint)
          throw Error(
            `Printer not ready: ${printerStatusSecondAttempt.errors}`
          );
      }
      return (zpl: string) => {
        zebra.print(zpl);
      };
      // }
    } catch (error) {
      console.error(error);
    }
  };

  return getPrinterMethod;
}