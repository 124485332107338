import {
  memo,
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import Button from '@ingka/button';
import allenKey from '@ingka/ssr-icon/paths/allen-key';
import status from '@ingka/ssr-icon/paths/arrow-pair-squarepath';
import cross from '@ingka/ssr-icon/paths/cross';
import documentAdd from '@ingka/ssr-icon/paths/document-add';
import list from '@ingka/ssr-icon/paths/list';
import plus from '@ingka/ssr-icon/paths/plus';
import printer from '@ingka/ssr-icon/paths/printer';
import trashCan from '@ingka/ssr-icon/paths/trash-can';

import { DialoguePrompt, SetStatusModal } from 'components';
import { AssignItemsToBatchModal } from 'components/_modals/assign-items-to-batch-modal/assign-items-to-batch-modal';
import { AddActivityBulk } from 'components/add-activity-bulk/add-activity-bulk';
import { UpsertShipmentBulk } from 'components/upsert-shipment-bulk/upsert-shipment-bulk';
import { useAuth, useSelect } from 'hooks';
import { CircularItem, FactoryComponentProps } from 'types';
import { BulkMenuItem } from 'types';
import { nullFilter } from 'utils';

import styles from './item-bulk-menu.module.scss';

export type MenuItemKey = (
  | 'activity'
  | 'print'
  | 'status'
  | 'remove'
  | 'grade'
  | 'shipment'
  | 'editBatch'
  | 'assignItemsToBatch'
);

export interface ItemBulkMenuProps extends FactoryComponentProps {
  menuItemKeys: MenuItemKey[];
  setPrintItems?: (value: string[]) => void;
  handleRemoveItems?: () => void;
  refetchExternalReference?: () => void;
}

export const ItemBulkMenu: FunctionComponent<ItemBulkMenuProps> = memo(
  ({
    menuItemKeys,
    setPrintItems,
    handleRemoveItems,
    refetchExternalReference,
  }) => {
    const { t } = useTranslation();
    const { getCurrentCpcId } = useAuth();
    const { selectedItems, selectedItemsLength, selectedItemsIds } =
      useSelect();
    const [visible, setVisible] = useState<boolean>(false);
    const [displayDialogue, setDisplayDialogue] = useState<boolean>(false);
    const [addActivityVisible, setAddActivityVisible] = useState(false);
    const [changeStatusVisible, setChangeStatusVisible] = useState(false);
    const [upsertShipmentVisible, setupsertShipmentVisible] = useState(false);
    const [assignItemsToBatchVisible, setAssignItemsToBatchVisible] = useState(false);
    const closeMenu = () => setVisible(false);
    const ref = useRef(null);
    useClickAway(ref, closeMenu);

    const onClick = () => {
      setVisible(!visible);
    };

    const onMenuItemClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      closeMenu();
    };

    const onPrintClick = async (event: MouseEvent<HTMLButtonElement>) => {
      if (setPrintItems) {
        event.preventDefault();
        const labels: string[] = selectedItems
          .filter(nullFilter)
          .filter(({ labelImageUrl }: CircularItem) => !!labelImageUrl?.length)
          .map(({ labelImageUrl }: CircularItem) => labelImageUrl || '');
        setPrintItems(labels);
        closeMenu();
      }
    };

    const onAddActivityClick = async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAddActivityVisible(true);
      closeMenu();
    };

    const onStatusClick = async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      setChangeStatusVisible(true);
      closeMenu();
    };

    const onUpsertShipmentClick = async (
      event: MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
      setupsertShipmentVisible(true);
      closeMenu();
    };

    const onRemoveItemsClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setDisplayDialogue(true);
      closeMenu();
    };

    const onAssignItemsToBatchClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setAssignItemsToBatchVisible(true);
      closeMenu();
    };

    useEffect(() => () => closeMenu(), []);

    useEffect(() => {
      if (!selectedItemsLength) {
        closeMenu();
      }
    }, [selectedItemsLength]);

    const menuItems: BulkMenuItem[] = [
      {
        ssrIcon: allenKey,
        text: t('bulk.menu.add'),
        onClick: onAddActivityClick,
        enabled: menuItemKeys.includes('activity'),
        dataCy: 'activity-bulk-button',
      },
      {
        ssrIcon: printer,
        text: t('bulk.menu.print'),
        onClick: onPrintClick,
        enabled: menuItemKeys.includes('print'),
        dataCy: 'printer-bulk-button',
      },
      {
        ssrIcon: status,
        text: t('bulk.menu.status'),
        onClick: onStatusClick,
        enabled: menuItemKeys.includes('status'),
        dataCy: 'status-bulk-button',
      },
      {
        ssrIcon: documentAdd,
        text: t('bulk.menu.shipment'),
        onClick: onUpsertShipmentClick,
        enabled: menuItemKeys.includes('shipment'),
        dataCy: 'shipment-bulk-button',
      },
      {
        ssrIcon: trashCan,
        text: t('bulk.menu.remove'),
        onClick: onRemoveItemsClick,
        enabled: menuItemKeys.includes('remove'),
        dataCy: 'remove-bulk-button',
      },
      {
        ssrIcon: list,
        text: t('bulk.menu.assignToBatch'),
        onClick: onAssignItemsToBatchClick,
        enabled: menuItemKeys.includes('assignItemsToBatch'),
        dataCy: 'assign-to-batch-button',
      },
    ];

    const menuItemMapper = (
      {
        ssrIcon,
        text,
        onClick = onMenuItemClick,
        enabled,
        dataCy,
      }: BulkMenuItem,
      index: number
    ) =>
      enabled ? (
        <Button
          key={`MenuItem${index}${text}`}
          type="tertiary"
          ssrIcon={ssrIcon}
          text={text}
          small={true}
          onClick={onClick}
          role="menuitem"
          tabIndex={-1}
          className={styles.menuItem}
          data-cy={dataCy}
        />
      ) : null;

    const onAddActivityClose = () => setAddActivityVisible(false);
    const onChangeStatusClose = () => setChangeStatusVisible(false);
    const onUpsertShipmentClose = () => setupsertShipmentVisible(false);
    const onAssignItemsToBatchClose = () => setAssignItemsToBatchVisible(false);
    const onRemoveItemsDialogueClose = (value: boolean) =>
      setDisplayDialogue(value);

    const handleDialogueResponse = (value: boolean) => {
      if (value && handleRemoveItems) {
        handleRemoveItems();
      }
    };

    return selectedItemsLength ? (
      <div className={styles.container} ref={ref}>
        <Button
          id="bulkActionButton"
          iconOnly={true}
          ssrIcon={visible ? cross : plus}
          type="primary"
          text={visible ? t('bulk.hide') : t('bulk.show')}
          onClick={onClick}
          className={styles.button}
          aria-haspopup="true"
          aria-expanded={visible}
          aria-controls="bulkActionMenu"
          role="button"
          data-cy={'bulk-menu-btn'}
        />
        {visible && (
          <div
            className={styles.menu}
            id="bulkActionMenu"
            aria-labelledby="bulkActionButton"
            role="menu"
          >
            {menuItems.filter(nullFilter).map(menuItemMapper)}
          </div>
        )}
        <AddActivityBulk
          ids={selectedItemsIds}
          onClose={onAddActivityClose}
          visible={addActivityVisible}
        />
        <SetStatusModal
          ids={selectedItemsIds}
          onClose={onChangeStatusClose}
          visible={changeStatusVisible}
          refetchExternalReference={refetchExternalReference}
        />
        <UpsertShipmentBulk
          ids={selectedItemsIds}
          cpcId={getCurrentCpcId()}
          onClose={onUpsertShipmentClose}
          visible={upsertShipmentVisible}
        />
        <AssignItemsToBatchModal
          ids={selectedItemsIds}
          onClose={onAssignItemsToBatchClose}
          visible={assignItemsToBatchVisible}
        />
        <DialoguePrompt
          positiveBtnText={t('actions.confirmDelete')}
          negativeBtnText={t('actions.cancel')}
          promptTitle={t('dialogues.delete.title', {
            type: t('items'),
          })}
          promptBody={t('dialogues.delete.bodyMultiple', {
            type: t('items'),
          })}
          displayDialogue={displayDialogue}
          setDisplayDialogue={onRemoveItemsDialogueClose}
          handleDialogueResponse={handleDialogueResponse}
        />
      </div>
    ) : null;
  }
);
