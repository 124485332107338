import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_PAGE_SIZE } from 'config';
import { useAuth } from 'hooks';
import { mutationCreateBatchedItems, queryCircularItemsCollection } from 'services';
import { CreateItemsExistingBatchInput, CreateItemsNewBatchInput } from 'types';

export const useCreateSourcedBatch = () => {
  const { t } = useTranslation();
  const { getCurrentCpc } = useAuth();
  const currentCPC = getCurrentCpc();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [createBatchedItems, { error }] = useMutation(
    mutationCreateBatchedItems
  );

  const createItemsNewBatch = async ({
    quantity,
    sourcingTimestamp,
    sourceId,
  }: CreateItemsNewBatchInput) => {
    const { data } = await createBatchedItems({
      variables: {
        input: {
          quantity,
          sourcingTimestamp,
          sourceId,
          cpcId: currentCPC.id,
          retailUnitCode: currentCPC.retailUnitCode,
          existingBatch: false,
        },
      },
      refetchQueries: [
        {
          query: queryCircularItemsCollection,
          variables: {
            skip: 0,
            limit: DEFAULT_PAGE_SIZE
          }
        }
      ]
    });

    return data?.createBatchedItems;
  };

  const createItemsExistingBatch = async ({
    quantity,
    sourcingTimestamp,
    sourcedBatchId,
  }: CreateItemsExistingBatchInput) => {
    const { data } = await createBatchedItems({
      variables: {
        input: {
          quantity,
          sourcingTimestamp,
          cpcId: currentCPC.id,
          retailUnitCode: currentCPC.retailUnitCode,
          existingBatch: true,
          sourcedBatchId,
        },
      },
    });

    return data?.createBatchedItems;
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      setErrorMsg(t('notifications.createBatchedItems.error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    createItemsNewBatch,
    createItemsExistingBatch,
    createItemErrorMsg: errorMsg,
  };
};
