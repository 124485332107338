interface WithTypename {
  __typename?: string;
}

export const omitTypenames = <T extends WithTypename>(
  object: T
): Omit<T, '__typename'> => {
  let r = Object.assign({}, object);
  delete r.__typename;
  return r;
};
