import { t } from 'i18next';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import Button from '@ingka/button';
import Checkbox from '@ingka/checkbox';
import trashCan from '@ingka/ssr-icon/paths/trash-can';

import { useSelect } from 'hooks';
import { CircularItem } from 'types';

import styles from './shipment-item-row-edit.module.scss';

interface ShipmentItemRowEditProps {
  circularItem: CircularItem;
  selected: boolean;
  handleRemoveShipmentItem?: (id: string) => void;
  shipped: boolean;
}

export const ShipmentItemRowEdit: FC<ShipmentItemRowEditProps> = ({
  circularItem,
  handleRemoveShipmentItem,
  selected,
  shipped,
}) => {
  const { toggleItemSelect } = useSelect();

  const onSelectChange = () => {
    toggleItemSelect(!selected, circularItem);
  };

  return (
    circularItem && (
      <>
        <tr className={styles.itemTableRow}>
          <td className={`${styles.selectCell} ${styles.borderLess}`}>
            <Checkbox
              id={`select-${circularItem.id}`}
              value={circularItem.id}
              onChange={onSelectChange}
              checked={selected}
              data-cy="shipment-item-row-checkbox"
            />
          </td>
          <td>
            <Link
              to={`/inventory/${circularItem.id}`}
              className={styles.idLink}
            >
              {circularItem.id}
            </Link>
          </td>
          <td>{circularItem.globalProductName}</td>
          {!shipped && <td>{circularItem.status}</td>}
          <td>{circularItem.currentGrade?.value}</td>
          {handleRemoveShipmentItem && (
            <td className={styles.rowDeleteBtn}>
              <Button
                type="tertiary"
                onClick={() => handleRemoveShipmentItem(circularItem.id)}
                iconOnly={true}
                ssrIcon={trashCan}
                text={t('actions.delete')}
                data-cy="shipment-item-row-delete-btn"
                className={styles.deleteBtn}
              />
            </td>
          )}
        </tr>
      </>
    )
  );
};
