import { makeReference, useMutation } from '@apollo/client';

import { mutationCreateSource } from 'services';

export function useCreateSource() {
  const [createSourceMutation] = useMutation(mutationCreateSource);

  const createSource = async (name: string, cpcId: string): Promise<void> => {
    await createSourceMutation({
      variables: { input: { cpcId, name } },
      update(cache, { data }) {
        if (!data) {
          return;
        }
        // Add source to cache
        cache.modify({
          id: cache.identify(makeReference('ROOT_QUERY')),
          fields: {
            sources: (previous) => {
              return [...previous, data.createSource];
            },
          },
        });
      },
    });
    return;
  };

  return { createSource };
}
