import { nullFilter } from 'utils';

export const formatItemNumberWithRegex = (
  input: string | number | undefined
): string => {
  if (!input) return '';

  return (
    `${input}`
      .trim()
      .replace(/\D/g, '')
      .match(/^(\d{1,3})(\d{1,3})?(\d{1,2})?/)
      ?.slice(1)
      .filter(nullFilter)
      ?.join('.') || ''
  );
};
