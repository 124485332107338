import { Maybe } from 'graphql/jsutils/Maybe';

export const getManufacturingDateYear = (manufacturingDate: Date) => {
  if (!manufacturingDate) return undefined;
  return manufacturingDate.toString().substring(0, 4);
};

export const getManufacturingDateWeek = (manufacturingDate: Date) => {
  if (!manufacturingDate) return undefined;

  return manufacturingDate.toString().substring(5, 7) || undefined;
};

// Returns the ISO week of the date.
export const getWeek = function (date = new Date()) {
  date.setHours(0, 0, 0, 0);

  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));

  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);

  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

export const yearWeek = (date: Date = new Date()): string =>
  `${date.getFullYear()}-${getWeek(date)}`;

export const formatDate = (input: Maybe<Date>) => {
  if (!input) {
    return '';
  }

  const options = { hour12: false };
  const date = new Date(input);
  const dateString = `${date.toLocaleDateString(
    undefined,
    options
  )} ${date.toLocaleTimeString(undefined, options)}`;
  return dateString.slice(0, -3);
};

/**
 * HACK: For some reason, even though we have the DateTime GQL scalar
 * mapped to the Date object for Javascript (in codegen.yml), the conversion
 * is not happening, and so even though the input is considered a Date
 * type by Typescript, it is actually a string; this means that using Date methods
 * on it passes compilation, but throws an error when the code is actually run.
 *
 * We get around this by calling `new Date()`, which creates a new Date object based
 * around what is actually a string (this command is compiled 1:1, so even though `input`
 * is a string in Javascript, this line still works by happy accident). But we should
 * definitely take a look at how Apollo Client resolves scalar types, and make sure it
 * converts the DateTime string to a Date object at the query level.
 *
 * @param input A string disguised as a Date object for Halloween.
 * @returns
 */
export const formatDateShort = (input: Maybe<Date>) => {
  if (!input) {
    return '';
  }

  const options = { hour12: false };
  const date = new Date(input);
  const dateString = `${date.toLocaleDateString(undefined, options)}`;
  return dateString;
};
