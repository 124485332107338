import { FormikHelpers } from 'formik';
import { useAtomValue } from 'jotai';
import { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import deliveryTruck from '@ingka/ssr-icon/paths/delivery-truck';
import documentPencil from '@ingka/ssr-icon/paths/document-pencil';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';

import { globalLoadingAtom } from 'atoms';
import {
  Back,
  PageContent,
  Row,
  PageHeader,
  ShipmentDetails,
  GroupedItemsView,
  DialoguePrompt,
  Delete,
} from 'components';
import { useButtonLink } from 'hooks';
import { circularItem } from 'mocks';
import { CircularItem, Shipment } from 'types';

import {
  ShipmentDetailsDetailsForm,
  UpdateShipmentDetailsFormValues,
} from '../shipment-details-form/shipment-details-form';

import styles from './shipment-details-view.module.scss';

export interface OrderDetailsViewProps {
  shipment: Shipment;
  editPage: boolean;
  groupedLines: Record<string, CircularItem[]>;
  totalItemsCount: number;
  removeShipment(id: string): Promise<void>;
  removeShipmentItems(itemIds: string[]): void;
  shipShipment: () => void;
  onUpdateShipmentDetails: (
    values: UpdateShipmentDetailsFormValues,
    formikHelpers: FormikHelpers<UpdateShipmentDetailsFormValues>
  ) => void | Promise<any>;
}

export const ShipmentDetailsView: FunctionComponent<OrderDetailsViewProps> =
  memo(
    ({
      shipment,
      editPage,
      groupedLines,
      totalItemsCount,
      removeShipment,
      removeShipmentItems,
      shipShipment,
      onUpdateShipmentDetails,
    }) => {
      const { t } = useTranslation();
      const { buttonLinkHandler } = useButtonLink();
      const globalLoading = useAtomValue(globalLoadingAtom);
      const [displayShipShipmentDialogue, setDisplayShipShipmentDialogue] =
        useState<boolean>(false);

      const handleDeleteShipment = async () => {
        await removeShipment(shipment.id);
      };

      const handleShipShipment = () => {
        setDisplayShipShipmentDialogue(true);
      };

      const handleDialogueResponse = (value: boolean) => {
        if (value) {
          shipShipment();
        }
      };

      const shipmentDetails = (
        <>
          {circularItem && !globalLoading && shipment && (
            <section className={styles.detailsWrapper}>
              {!editPage && <ShipmentDetails shipment={shipment} />}
            </section>
          )}
          {groupedLines && (
            <GroupedItemsView
              groupedItems={groupedLines}
              totalItems={totalItemsCount}
              removeShipmentItems={removeShipmentItems}
              editPage={editPage}
              shipped={shipment.status === 'shipped'}
            />
          )}
        </>
      );

      const tabs = [
        <Tab
          key="tab-1"
          text={t('shipment.productsNum', {
            num: shipment.circularItemIds.length,
          })}
          tabPanelId="tab_1"
        />,
        <Tab
          key="tab-2"
          text={t('shipment.shippmentDetails')}
          tabPanelId="tab_2"
        />,
      ];

      const tabPanels = [
        <TabPanel key="shippment-details" tabPanelId="tab_1">
          {shipmentDetails}
        </TabPanel>,
        <TabPanel key="shippment-form" tabPanelId="tab_2">
          <ShipmentDetailsDetailsForm
            shipment={shipment}
            onSubmit={onUpdateShipmentDetails}
          />
        </TabPanel>,
      ];

      return (
        <PageContent>
          <DialoguePrompt
            displayDialogue={displayShipShipmentDialogue}
            setDisplayDialogue={setDisplayShipShipmentDialogue}
            positiveBtnText={t('actions.confirmMark')}
            negativeBtnText={t('actions.noDont')}
            promptTitle={t('dialogues.markAsShipped.title')}
            promptBody={t('dialogues.markAsShipped.body')}
            handleDialogueResponse={handleDialogueResponse}
          />
          <PageHeader divider={true}>
            <Row position="start" className={styles.start}>
              <Back
                label={t('actions.back.root')}
                onClick={buttonLinkHandler}
                href={editPage ? `/shipments/${shipment.id}` : '/shipments'}
                dataCy={'shipment-details-header-back-btn'}
              />
              <Text
                tagName="h1"
                headingSize="m"
                className={styles.title}
                data-cy="shipment-details-header-id-title"
              >
                {t('shipmentIdInterpolation', { id: shipment.id })}
              </Text>
            </Row>
            {shipment.status !== 'shipped' && (
              <Row position="end" data-cy={'shipment-details-header-buttons'}>
                {editPage ? (
                  <Delete
                    deleteAction={handleDeleteShipment}
                    buttonText={t('actions.deleteList')}
                    type={t('list')}
                    dataCy={'delete-shipment-btn'}
                  />
                ) : (
                  <>
                    <Button
                      type="secondary"
                      onClick={handleShipShipment}
                      small={true}
                      ssrIcon={deliveryTruck}
                      text={t('actions.markAsShipped')}
                      data-cy="ship-shipment-btn"
                    />
                    <Button
                      type="primary"
                      small={true}
                      ssrIcon={documentPencil}
                      text={t('actions.edit')}
                      inverseTheme
                      href={`/shipments/${shipment?.id}/edit`}
                      data-cy="shipment-details-header-edit-btn"
                    />
                  </>
                )}
              </Row>
            )}
          </PageHeader>

          {editPage ? (
            <Tabs
              className={styles.tabs}
              tabs={tabs}
              tabPanels={tabPanels}
              defaultActiveTab="tab_1"
            />
          ) : (
            shipmentDetails
          )}
        </PageContent>
      );
    }
  );
