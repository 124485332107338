
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import '@ingka/text/dist/style.css';

import styles from './primary-menu.module.scss';

export const PrimaryMenu: FunctionComponent = memo(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const handleInventoryClick = () => {
    if (location.pathname === '/inventory') {
      window.location.reload();
    }
  };

  return (
    <nav>
      <NavLink
        className={`tabs__tab ${styles.item}`}
        activeClassName="tabs__tab--active"
        to="/inventory"
        onClick={handleInventoryClick}
      >
        {t('circularStock')}
      </NavLink>
      <NavLink
        className={`tabs__tab ${styles.item}`}
        activeClassName="tabs__tab--active"
        to={'/create/item'}
      >
        {t('createItems')}
      </NavLink>
      <NavLink
        className={`tabs__tab ${styles.item}`}
        activeClassName="tabs__tab--active"
        to={'/shipments'}
      >
        {t('shipment.shipment')}
      </NavLink>
      <NavLink
        className={`tabs__tab ${styles.item}`}
        activeClassName="tabs__tab--active"
        to={'/sourcedBatches'}
      >
        {t('sourcedBatches')}
      </NavLink>
    </nav>
  );
});
