import { memo, FunctionComponent } from 'react';

import Button from '@ingka/button';
import Modal, { Prompt, ModalFooter } from '@ingka/modal';

import { FactoryComponentProps } from 'types';

import styles from './dialogue-prompt.module.scss';

export interface DialoguePromptProps extends FactoryComponentProps {
  positiveBtnText: string;
  negativeBtnText: string;
  promptTitle: string;
  promptBody: string;
  displayDialogue: boolean;
  setDisplayDialogue: (value: boolean) => void;
  handleDialogueResponse: (value: boolean) => void;
  compact?: boolean;
  id?: string;
}

export const DialoguePrompt: FunctionComponent<DialoguePromptProps> = memo(
  ({
    positiveBtnText,
    negativeBtnText,
    promptTitle,
    promptBody,
    displayDialogue,
    setDisplayDialogue,
    handleDialogueResponse,
    compact = false,
    id
  }) => {
    const handleCloseBtn = () => setDisplayDialogue(false);

    function handleClick(value: boolean) {
      if (handleDialogueResponse) {
        handleDialogueResponse(value);
      }

      setDisplayDialogue(false);
    }

    return (
      <Modal
        visible={displayDialogue}
        handleCloseBtn={handleCloseBtn}
        className={styles.modal}
        id={id}
      >
        <Prompt
          title={promptTitle}
          footer={
            <ModalFooter>
              <Button
                text={positiveBtnText}
                type="primary"
                onClick={() => handleClick(true)}
                data-cy={'dialogue-prompt-positive-btn'}
              />
              <Button
                text={negativeBtnText}
                type="secondary"
                onClick={() => handleClick(false)}
                data-cy={'dialogue-prompt-negative-btn'}
              />
            </ModalFooter>
          }
        >
          {promptBody}
        </Prompt>
      </Modal>
    );
  }
);
