import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Hyperlink from '@ingka/hyperlink';
import SSRIcon from '@ingka/ssr-icon';
import person from '@ingka/ssr-icon/paths/person';
import Text from '@ingka/text';

import { isAuthenticatedAtom, userAtom } from 'atoms';
import { useAuth } from 'hooks';

import styles from './secondary-menu.module.scss';

export const SecondaryMenu: FunctionComponent = memo(() => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const user = useAtomValue(userAtom);

  return (
    <div className={styles.container}>
      <SSRIcon paths={person} className={styles.icon} />

      <Text className={styles.message} data-cy="secondary-menu-user-name">
        {(isAuthenticated && user?.name) ||
          t('notifications.auth.not-logged-in')}
      </Text>

      {isAuthenticated && (
        <Hyperlink
          button={true}
          onClick={signOut}
          colour="black"
          subtle={true}
          aria-label={t('notifications.auth.log-out')}
          className={styles.action}
          data-cy="sign-out"
        >
          {t('notifications.auth.log-out')}
        </Hyperlink>
      )}
    </div>
  );
});
