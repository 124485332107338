import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { Settings, Keys } from '../constants';
import { FactoryApolloClient } from '../types/import/apollo/apolloExtends.types';

const httpLink = createHttpLink({
  uri: Settings.graphqlUrl,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(Keys.TOKEN_KEY);
  // return the headers to the context so httpLink can read them
  const authorization = {
    authorization: `Bearer ${token}`,
  };
  return {
    headers: {
      ...headers,
      ...(token ? authorization : {}),
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export function getApolloClient(): FactoryApolloClient {
  return apolloClient;
}
