import { useQuery } from '@apollo/client';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ingka/text';

import { Columns } from 'components';
import { queryCircularProcessCenter } from 'services';
import { CircularProcessCenter, QueryCircularProcessCenterResult } from 'types';

import styles from './reference-details.module.scss';

interface ReferenceDetailsProps {
  totalShipmentsCount: number;
  cpcId?: string;
  createdAt?: string;
}

export const ReferenceDetails: FunctionComponent<ReferenceDetailsProps> = ({
  totalShipmentsCount,
  cpcId,
  createdAt,
}) => {
  const { t } = useTranslation();
  const [cpc, setCPC] = useState<CircularProcessCenter>();
  const { data } = useQuery<QueryCircularProcessCenterResult>(
    queryCircularProcessCenter,
    {
      variables: {
        cpcId: cpcId,
      },
    }
  );

  useEffect(() => {
    if (data?.circularProcessCenter) {
      setCPC(data.circularProcessCenter);
    }
  }, [data]);

  return (
    <Columns data-cy="order-detail-view-columns">
      <section>
        <dl className={styles.details}>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('shipment.shipments')}</Text>
          </dt>
          <dd>
            <Text>{totalShipmentsCount}</Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('cpc')}</Text>
          </dt>
          <dd>
            <Text headingSize="xs">{cpc?.name}</Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('shipment.createdAt')}</Text>
          </dt>
          <dd>
            <Text tagName="span" data-cy="details-view-hfb">
              {createdAt}
            </Text>
          </dd>
        </dl>
      </section>
    </Columns>
  );
};
