import { FunctionComponent } from 'react';

// Styles are put here to keep components cleaner and prevent css bugs, some imports have to be imported in a certain way.
// For instance button needs to be above search.
// Order in alphabetic order to prevent duplicates

import '@ingka/button/style.scss';
import '@ingka/accordion/style.scss';
import '@ingka/aspect-ratio-image/style.scss';
import '@ingka/base/style.scss';
import '@ingka/carousel/style.scss';
import '@ingka/choice/style.scss';
import '@ingka/focus/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/hyperlink/style.scss';
import '@ingka/image-info/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/loading/style.scss';
import '@ingka/modal/style.scss';
import '@ingka/pill/style.scss';
import '@ingka/search/style.scss';
import '@ingka/skeleton/style.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/table/style.scss';
import '@ingka/tabs/style.scss';
import '@ingka/text/style.scss';
import '@ingka/typography/style.scss';

import './global-styles.scss';

export const GlobalStyles: FunctionComponent = () => null;

export default GlobalStyles;
