import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect, memo, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import {
  globalAppErrorAtom,
  globalLoadingAtom,
  selectedItemsClearAtom,
} from 'atoms';
import { ItemBulkMenu, ItemStockView, MenuItemKey } from 'components';
import { DEFAULT_PAGE_SIZE } from 'config';
import { PaginationProvider } from 'context';
import { usePageParams, usePrint, useSelect } from 'hooks';
import { FactoryLocationState } from 'types';

import { useItemStock } from '../hooks/use-item-stock';
const standardMenuKeys: MenuItemKey[] = [
  'activity',
  'print',
  'status',
  'shipment',
];

export const ItemStockPage: FunctionComponent = memo(() => {
  const location = useLocation<FactoryLocationState>();
  const { search, state } = location;
  const error = useAtomValue(globalAppErrorAtom);
  const loading = useAtomValue(globalLoadingAtom);
  const [printItems, setPrintItems] = useState<string[] | undefined>(
    state?.labels ?? []
  );
  const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);

  const { bulkPrint } = usePrint();

  const { selectedItems } = useSelect();
  const [menuItemKeys, setMenuItemKeys] =
    useState<MenuItemKey[]>(standardMenuKeys);

  const {
    page,
    pages,
    skip,
    order,
    pageFilter,
    searchTerm,
    updatePageParams,
    gotoPage,
    setPages,
  } = usePageParams();

  const { stockItems, total } = useItemStock({
    skip,
    limit: DEFAULT_PAGE_SIZE,
    order,
    filter: pageFilter,
    searchTerm,
  });

  const handleBulkPrint = async (printItems: string[]) => {
    bulkPrint(printItems);
    setPrintItems(undefined);
    clearSelectedItems();
  };


  useEffect(() => {
    if (printItems) {
      handleBulkPrint(printItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printItems]);

  useEffect(() => {
    updatePageParams(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setPages(Math.ceil(total / DEFAULT_PAGE_SIZE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  // Only show "Assign to Batch" if all items are of the same batch
  useEffect(() => {
    const uniqueBatchIds = new Set(
      selectedItems.map((i) => i.sourcedBatch?.id).filter((id) => !!id)
    );
    if (uniqueBatchIds.size <= 1) {
      setMenuItemKeys(standardMenuKeys.concat(['assignItemsToBatch']));
    } else {
      setMenuItemKeys(standardMenuKeys);
    }
  }, [selectedItems]);

  const itemsLabelPreloadMapper = (labelImageUrl: string) =>
    labelImageUrl ? (
      <link
        rel="preload"
        href={labelImageUrl}
        as="image"
        key={`preload-${labelImageUrl}`}
      />
    ) : null;

  return error && loading && !pages ? null : (
    <PaginationProvider value={{ page, pages, setPage: gotoPage }}>
      <ItemBulkMenu menuItemKeys={menuItemKeys} setPrintItems={setPrintItems} />
      {stockItems && (
        <ItemStockView
          items={stockItems}
          limit={DEFAULT_PAGE_SIZE}
          skip={skip}
          total={total}
          order={order}
          highlighted={state?.created || []}
        />
      )}
      <Helmet>{printItems?.map(itemsLabelPreloadMapper)}</Helmet>
    </PaginationProvider>
  );
});
