import { useMutation } from '@apollo/client';

import { mutationUpdateShipment } from 'services';
import { UpdateShipmentInput } from 'types';

export function useUpdateShipment() {
  const [updateShipmentMutation] = useMutation(mutationUpdateShipment);

  const updateShipment = async (id: string, input: UpdateShipmentInput) => {
    try {
      await updateShipmentMutation({
        variables: {
          id,
          input,
        },
        update: (cache, { data }) => {
          if (!data) {
            return;
          }
          const updateShipment = data.updateShipment;

          // Update cache for sourcedBatch
          cache.modify({
            id: cache.identify({
              ...updateShipment,
            }),
            fields: {
              comment: () => updateShipment.comment,
              carrier: () => updateShipment.carrier,
              euroPalletCount: () => updateShipment.euroPalletCount,
              longPalletCount: () => updateShipment.longPalletCount,
            },
          });
        },
      });

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  return { updateShipment };
}
