import { useAtomValue } from 'jotai';
import { FunctionComponent, useMemo } from 'react';

import Checkbox from '@ingka/checkbox';
import Skeleton from '@ingka/skeleton';
import Table, { TableBody, TableHeader } from '@ingka/table';

import { globalAppErrorAtom, globalLoadingAtom } from 'atoms';

import styles from './base-stock-list.module.scss';

export interface BaseStockListProps {
  rows: JSX.Element[];
  headers: JSX.Element[];
  onSelectAllChange(): void;
  hasSelectAll: boolean;
}

export const BaseStockList: FunctionComponent<BaseStockListProps> = ({
  headers,
  rows,
  onSelectAllChange,
  hasSelectAll,
}) => {
  const globalLoading = useAtomValue(globalLoadingAtom);
  const globalAppError = useAtomValue(globalAppErrorAtom);
  const limit = 10;
  const fallbackHeight = useMemo<number>(() => 77 + limit * 83, [limit]);

  return globalAppError ? null : globalLoading ? (
    <Skeleton height={`${fallbackHeight}px`} />
  ) : rows?.length ? (
    <Table className={styles.table}>
      <TableHeader>
        <tr className={styles.tableHeader}>
          <th className={styles.selectAllHeader}>
            <Checkbox
              className={styles.selectAll}
              id={'select-all-checkbox'}
              onChange={onSelectAllChange}
              checked={hasSelectAll}
              value={''}
              data-cy={'item-stock-select-all-checkbox'}
            />
          </th>
          {headers}
        </tr>
      </TableHeader>
      <TableBody>{rows}</TableBody>
    </Table>
  ) : null;
};
