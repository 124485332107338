import { FormikHelpers, useFormik } from 'formik';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Text from '@ingka/text';

import { globalLoadingAtom } from 'atoms';
import { useAuth } from 'hooks';

import styles from './login.module.scss';

interface LoginFormInput {
  email: string;
  password: string;
}

const initialValues: LoginFormInput = { email: '', password: '' };

export const Login: FunctionComponent = memo(() => {
  const globalLoading = useAtomValue(globalLoadingAtom);
  const { signin } = useAuth();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('notifications.auth.email'))
      .required(t('notifications.auth.email')),
    password: Yup.string().required(t('notifications.auth.password')),
  });

  const onSubmit = async (
    values: LoginFormInput,
    { setSubmitting }: FormikHelpers<LoginFormInput>
  ) => {
    try {
      const { email, password } = values;
      await signin(email, password);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const {
    isValid,
    isSubmitting,
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
  } = useFormik<LoginFormInput>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={handleSubmit} className={styles.form} data-cy="login">
      <Text headingSize="l" className={styles.decommission}>
        {t('decommission_text')}
      </Text>
      <h1>{t('welcome')}</h1>
      <FormField
        className={styles.field}
        shouldValidate={touched.email}
        valid={!errors.email}
        validation={
          errors.email
            ? {
                msg: errors.email,
                id: 'email-error',
              }
            : undefined
        }
      >
        <InputField
          id="email"
          type="text"
          label={t('email')}
          disabled={isSubmitting || globalLoading}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          data-cy="email"
        />
      </FormField>

      <FormField
        className={styles.field}
        shouldValidate={touched.password}
        valid={!errors.password}
        validation={
          errors.password
            ? {
                msg: errors.password,
                id: 'password-error',
              }
            : undefined
        }
      >
        <InputField
          id="password"
          type="password"
          label={t('password')}
          disabled={isSubmitting || globalLoading}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          data-cy="password"
        />
      </FormField>
      <Button
        type="primary"
        small={true}
        loading={globalLoading || isSubmitting}
        disabled={!isValid || globalLoading}
        htmlType="submit"
        text={t('actions.login')}
        data-cy="submit"
      />
    </form>
  );
});

export default Login;
