import { useLocation } from 'react-router-dom';

import { SortOrderType } from 'types';
import { invertOrder } from 'utils';

export const useSortUrl = (
  sortKey: string | undefined,
  sortOrder: SortOrderType,
  active: boolean
): string | undefined => {
  const { search } = useLocation();
  if (typeof sortKey === 'undefined') {
    return undefined;
  }
  const params = new URLSearchParams(search);
  params.set('sort', sortKey);
  params.set('order', !active ? invertOrder(sortOrder) : sortOrder);
  params.delete('page');
  return `?${params.toString()}`;
};
