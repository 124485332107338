import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Modal, { ModalBody, ModalFooter, Sheets } from '@ingka/modal';
import Text from '@ingka/text';

import { useAuth } from 'hooks';
import { useCreateSource } from 'hooks/use-create-source/use-create-source';
import { FactoryComponentProps } from 'types';

import styles from './add-source-modal.module.scss';

export interface AddSourceFormValues {
  name: string | null;
}

export interface AddSourceProps extends FactoryComponentProps {
  visible: boolean;
  onClose(): void;
}

export const AddSourceModal: FunctionComponent<AddSourceProps> = memo(
  ({ visible, onClose }) => {
    const { t } = useTranslation();

    const { getCurrentCpcId, getCurrentCpc } = useAuth();
    const { createSource } = useCreateSource();
    const formRef = useRef<HTMLFormElement>(null);

    const onSubmit = async (
      addSourceInput: AddSourceFormValues,
      { setSubmitting }: FormikHelpers<AddSourceFormValues>
    ) => {
      try {
        if (!addSourceInput.name) {
          return;
        }

        await createSource(addSourceInput.name, getCurrentCpcId());

        setSubmitting(false);
        onClose();
      } catch (err) {
        console.error(err);
      }
    };

    const initialValues = {
      name: null,
    };

    const {
      isSubmitting,
      values,
      handleSubmit,
      handleChange,
      handleBlur,
      resetForm,
      submitForm,
      isValid,
      dirty,
    } = useFormik<AddSourceFormValues>({
      initialValues,
      onSubmit,
    });

    const handleCloseBtn = () => onClose();
    const onModalClosed = () => resetForm();
    const onCancelClick = () => onClose();

    const onSubmitClick = (event: MouseEvent) => {
      event.preventDefault();
      submitForm();
    };

    const footer = (
      <ModalFooter>
        <Button
          type="primary"
          fluid={true}
          htmlType="submit"
          text={t('actions.save')}
          loading={isSubmitting}
          disabled={!isValid || !dirty}
          onClick={onSubmitClick}
          data-cy={'modal-submit-btn'}
        />
        <Button
          type="secondary"
          fluid={true}
          loading={isSubmitting}
          text={t('actions.cancel')}
          onClick={onCancelClick}
        />
      </ModalFooter>
    );
    return (
      <form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
        <Modal
          visible={visible}
          handleCloseBtn={handleCloseBtn}
          onModalClosed={onModalClosed}
        >
          <Sheets size="small" footer={footer}>
            <ModalBody>
              <Text headingSize={'m'} className={styles.heading}>
                {t('createNewSource', { cpc: getCurrentCpc().name })}
              </Text>
              <FormField
                fieldHelper={{
                  msg: t('createNewSourceNameFieldHelper').toString(),
                }}
              >
                <InputField
                  required
                  id="name"
                  name="name"
                  type="text"
                  label={t('name')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name || ''}
                  data-cy="create-source-name"
                />
              </FormField>
            </ModalBody>
          </Sheets>
        </Modal>
      </form>
    );
  }
);
