import {
  CircularItemOrderBy,
  Maybe,
  OrderableField,
  ShipmentOrderBy,
  SortOrderType,
} from 'types';

export const getSortOrder = (
  order?: CircularItemOrderBy
): { key?: string; value?: SortOrderType } => {
  if (!order) {
    return {};
  }
  for (let key in order) {
    if (key) {
      const field = key as keyof CircularItemOrderBy;
      if (field) {
        const value: Maybe<SortOrderType> | undefined = order[field];
        if (value) {
          return { key, value };
        }
      }
    }
  }
  return {};
};

export const getShipmentSortOrder = (
  orderBy?: ShipmentOrderBy
): { field?: OrderableField; order?: SortOrderType } => {
  if (!orderBy) {
    return {};
  }

  const field = orderBy.field;
  const order = orderBy.order;

  return { field, order };
};
