import { useQuery } from "@apollo/client"
import { FormikErrors, FormikTouched } from "formik";
import { t } from "i18next"
import { FC } from "react"

import FormField, { FormFieldValidation } from "@ingka/form-field"
import InputField from '@ingka/input-field';
import Select, {Option} from "@ingka/select"

import { queryActivityTypes } from "services"
import { ItemActivityType, QueryActivityTypesResult } from "types"
import { nullFilter } from "utils"

// TODO: This component is intended to be used in an array of activities,
// and its way of working is tightly coupled to be that way. We should
// change it up so that it's agnostic re: how it's being used.

export interface ActivityFormValue {
  type?: string;
  duration?: string;
}

export interface ActivityProps {
  /** Is this the 0th, 1st, nth activity added */
  index?: number;
  disabled: boolean;
  values: ActivityFormValue;
  errors?: FormikErrors<ActivityFormValue>;
  touched?: FormikTouched<ActivityFormValue>,
  handleBlur: any;
  handleChange: any;
  setPreselectedType?: (typeId?: string) => void;
}

export const Activity: FC<ActivityProps> = ({
  index,
  disabled,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setPreselectedType
}) => {

  index = index || 0;

  const { data, loading } = useQuery<QueryActivityTypesResult>(queryActivityTypes);

  const maybeValidation = (field: string, msg?: string | null) : FormFieldValidation | undefined => {
    if (msg) {
      return {
        msg,
        id: `${field}-${index}-error`
      }
    }
  }

  // HACK:
  // If the type is set already (i.e. it's in edit mode), this is going to be
  // a human-readable value - but the Select component databinds to activity
  // IDs, and not their human readable name. Therefore, we need to take the
  // activity types, find the one that matches the name, and slug the ID on the form
  // value.
  if (setPreselectedType && values.type && isNaN(parseInt(values.type))) {
    setPreselectedType(data?.itemActivityTypes.find(t => t.name === values.type)?.id);
  }

  const typeMapper = ({ id, name }: ItemActivityType) => {
    return <Option value={id} name={name} key={`add-activity-type-${id}`} />;
  };
  return (
    <>
      <FormField
        valid={!errors?.type}
        shouldValidate={!!errors?.type}
        validation={ maybeValidation('type', errors?.type)}
        data-cy={`add-activity-select-type-${index}`}
      >
        <Select
          id={`activities[${index}].type`}
          name={`activities[${index}].type`}
          label={t('activityType')}
          data-cy="add-activity-input-type"
          disabled={disabled || loading}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.type}
          required
        >
          {data?.itemActivityTypes
            ?.filter(nullFilter)
            ?.map(typeMapper)}
        </Select>
      </FormField>
      <FormField
        valid={!errors?.duration}
        shouldValidate={touched?.duration || undefined}
        validation={maybeValidation('duration', errors?.duration)}
        data-cy="add-activity-input-duration"
      >
        <InputField
          id={`activities[${index}].duration`}
          name={`activities[${index}].duration`}
          type="number"
          max={60}
          maxLength={2}
          inputMode="numeric"
          label={t('durationType')}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.duration}
          required
        />
      </FormField>
    </>
  )
}
