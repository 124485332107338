import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ingka/text';

import { GradeChange } from 'types';
import { formatDate } from 'utils';

import styles from './hover-box.module.scss';

export const GradeChangeHoverBox: FC<GradeChange> = ({
  historyType,
  gradeValue,
  userId,
  userName,
  createdTimestamp,
}) => {
  const { t } = useTranslation();

  return (
    <span>
      <Text className={styles.activityTypeHeading}>{historyType}</Text>
      <dl className={styles.details}>
        <dt>
          <Text bodySize={'m'}>{t('toGrade')}:</Text>
        </dt>
        <dd>{<Text bodySize={'m'}>{gradeValue}</Text>}</dd>
        <dt>
          <Text bodySize={'m'}>{t('user')}:</Text>
        </dt>
        <dd>
          <Text bodySize={'m'} className={styles.username}>
            {userName || userId}
          </Text>
        </dd>
        <dt>
          <Text bodySize={'m'}>{t('time')}:</Text>
        </dt>
        <dd>
          <Text bodySize={'m'}>{formatDate(createdTimestamp)}</Text>
        </dd>
      </dl>
    </span>
  );
};
