import { useQuery } from '@apollo/client';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';

import { globalAppErrorAtom, globalLoadingAtom } from 'atoms';
import { useAuth } from 'hooks';
import { querySourcedBatchCollection } from 'services';
import { QuerySourcedBatchCollectionArgs, SourcedBatch } from 'types';
import { QuerySourcedBatchCollectionResult } from 'types/query-sourced-batch-collection-result';

export const useBatchStockPage = ({
  skip,
  limit,
  order,
  searchTerm,
  filter,
}: Partial<QuerySourcedBatchCollectionArgs>) => {
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const { getCurrentCpcId } = useAuth();
  const [batchItems, setBatchItems] = useState<SourcedBatch[]>([]);
  const [total, setTotal] = useState<number>(0);

  const { data, loading, error } = useQuery<
    QuerySourcedBatchCollectionResult,
    QuerySourcedBatchCollectionArgs
  >(querySourcedBatchCollection, {
    variables: {
      skip,
      limit,
      order,
      searchTerm,
      filter,
      cpcId: getCurrentCpcId(),
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  });

  useEffect(() => {
    setGlobalLoading(loading);
    setGlobalAppError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  useEffect(() => {
    if (data?.sourcedBatchCollection) {
      setTotal(data.sourcedBatchCollection.total);
      if (data.sourcedBatchCollection.items) {
        const items = [...data.sourcedBatchCollection.items];
        setBatchItems(items);
      }
    }
  }, [data]);

  return {
    batchItems,
    total,
    error: false,
    loading: false,
  };
};
