import { FunctionComponent, memo, MouseEventHandler } from 'react';

import Button from '@ingka/button';
import iconSortAsc from '@ingka/ssr-icon/paths/sort-ascending';
import iconSortDesc from '@ingka/ssr-icon/paths/sort-descending';

import { useSortClickHandler, useSortUrl } from 'hooks';
import { SortOrderType } from 'types';

import styles from './stock-list-header-item.module.scss';

export interface StockListHeaderItemProps {
  label: string;
  className: string;
  title?: string;
  sortKey?: string;
  sortOrder?: SortOrderType;
  active?: boolean;
}

export const StockListHeaderItem: FunctionComponent<StockListHeaderItemProps> =
  memo(({ sortKey, label, className, sortOrder = 'DESC', active = false }) => {
    const href = useSortUrl(sortKey, sortOrder, active);
    const onClick = useSortClickHandler(sortKey, sortOrder, active);

    const tmp: MouseEventHandler = (event) => {
      event.preventDefault();
      if (onClick) {
        onClick(event);
      }
    };

    return (
      <th className={`${styles.th} ${className}`}>
        <Button
          ssrIcon={
            sortKey
              ? sortOrder === 'ASC'
                ? iconSortAsc
                : iconSortDesc
              : undefined
          }
          iconPosition="trailing"
          type="plain"
          small={true}
          disabled={!sortKey}
          className={active ? styles.active : styles.button}
          onClick={tmp}
          data-sortkey={sortKey}
          data-sortorder={active ? sortOrder : ''}
          href={href}
        >
          {label}
        </Button>
      </th>
    );
  });
