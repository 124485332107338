import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE } from 'config';
import {
  CircularItemFilter,
  CircularItemOrderBy,
  FactoryLocationState,
  OrderableField,
  ShipmentFilter,
  ShipmentOrderBy,
  SortOrderType,
  SourcedBatchOrderBy,
  SourcedBatchOrderableField,
  SourcedBatchFilter,
} from 'types';
import { getSortOrder } from 'utils';

export const usePageParams = () => {
  const history = useHistory<FactoryLocationState>();
  const location = useLocation<FactoryLocationState>();
  const { search } = location;
  const initialParams = new URLSearchParams(search);
  const [pageFilter, setPageFilter] = useState<CircularItemFilter>(() => {
    return JSON.parse(initialParams.get('pageFilter') || '{}');
  });
  const [shipmentFilter, setShipmentFilter] = useState<ShipmentFilter>(() => {
    return JSON.parse(initialParams.get('shipmentFilter') || '{}');
  });
  const [sourcedBatchFilter, setSourcedBatchFilter] =
    useState<SourcedBatchFilter | null>(() => {
      return JSON.parse(initialParams.get('sourcedBatchFilter') || '{}');
    });
  const [searchTerm, setSearchTerm] = useState<string>(() => {
    return initialParams.get('searchTerm') || '';
  });
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [skip, setSkip] = useState<number>(0);
  const [order, setOrder] = useState<CircularItemOrderBy>({
    registerDate: 'DESC',
  });
  const [shipmentOrder, setShipmentOrder] = useState<ShipmentOrderBy>({
    field: 'createdTimestamp',
    order: 'DESC',
  });

  const [sourcedBatchOrder, setSourcedBatchOrder] =
    useState<SourcedBatchOrderBy>({
      field: 'created',
      order: 'DESC',
    });

  const gotoPage = (newPage: number) => {
    const actualPage = newPage < 1 ? 1 : newPage > pages ? pages : newPage;
    if (actualPage !== page) {
      const params = new URLSearchParams(search);
      params.set('page', `${newPage}`);
      history.push({
        search: params.toString(),
        state: {},
      });
    }
  };

  const updatePageSearch = (term: string) => {
    if (term !== searchTerm) {
      const params = new URLSearchParams(search);
      params.set('searchTerm', term);
      history.push({
        search: params.toString(),
        state: {},
      });
    }
    setSearchTerm(term);
  };

  const updatePageFilter = (filterInput: CircularItemFilter | null) => {
    if (filterInput) {
      if (filterInput !== pageFilter) {
        const params = new URLSearchParams(search);
        params.set('pageFilter', JSON.stringify(filterInput));
        history.push({
          search: params.toString(),
          state: {},
        });
      }
      setPageFilter(filterInput);
    }
  };

  const updateShipmentFilter = (filterInput: ShipmentFilter | null) => {
    if (filterInput) {
      if (filterInput !== shipmentFilter) {
        const params = new URLSearchParams(search);
        params.set('shipmentFilter', JSON.stringify(filterInput));
        history.push({
          search: params.toString(),
          state: {},
        });
      }
      setShipmentFilter(filterInput);
    }
  };

  const updateSourcedBatchFilter = (filterInput: SourcedBatchFilter | null) => {
    if (filterInput !== sourcedBatchFilter) {
      const params = new URLSearchParams(search);
      params.set('sourcedBatchFilter', JSON.stringify(filterInput));
      history.push({
        search: params.toString(),
        state: {},
      });
    }
    setSourcedBatchFilter(filterInput);
  };

  // Recieve page params and set state
  const updatePageParams = (search: string) => {
    const params = new URLSearchParams(search);
    let newPage =
      (params.has('page') && parseInt(params.get('page') || '', 10)) || 1;

    // Circular stock sort order
    if (params.has('sort') && location.pathname.includes('inventory')) {
      // set sort order
      const field = params.get('sort') as keyof CircularItemOrderBy;
      const direction: SortOrderType =
        params.get('order') === ('ASC' || 'DESC')
          ? (params.get('order') as SortOrderType)
          : 'DESC';
      if (field && direction) {
        const newOrder: CircularItemOrderBy = { [field]: direction };
        const { key: oldField = '', value: oldDirection = 'DESC' } =
          getSortOrder(order);
        if (
          field !== oldField ||
          (field === oldField && direction !== oldDirection)
        ) {
          newPage = 1;
        }
        setOrder(newOrder);
      }
    }

    // Shipment stock sort order (not same order system as circularStock)
    if (params.has('sort') && location.pathname.includes('shipments')) {
      // set sort order
      const field = params.get('sort') as OrderableField;
      const direction: SortOrderType =
        params.get('order') === ('ASC' || 'DESC')
          ? (params.get('order') as SortOrderType)
          : 'DESC';
      if (field && direction) {
        const newOrder: ShipmentOrderBy = { field, order: direction };
        const { key: oldField = '', value: oldDirection = 'DESC' } =
          getSortOrder(order);
        if (
          field !== oldField ||
          (field === oldField && direction !== oldDirection)
        ) {
          newPage = 1;
        }
        setShipmentOrder(newOrder);
      }
    }

    if (params.has('sort') && location.pathname.includes('sourcedbatches')) {
      // set sort order
      const field = params.get('sort') as SourcedBatchOrderableField;
      const direction: SortOrderType =
        params.get('order') === ('ASC' || 'DESC')
          ? (params.get('order') as SortOrderType)
          : 'DESC';
      if (field && direction) {
        const newOrder: SourcedBatchOrderBy = { field, order: direction };
        const { key: oldField = '', value: oldDirection = 'DESC' } =
          getSortOrder(order);
        if (
          field !== oldField ||
          (field === oldField && direction !== oldDirection)
        ) {
          newPage = 1;
        }
        setSourcedBatchOrder(newOrder);
      }
    }

    if (params.has('searchTerm') && params.get('searchTerm') !== searchTerm) {
      setSearchTerm(params.get('searchTerm') || '');
      newPage = 1;
    }
    if (!params.has('pageFilter')) {
      setPageFilter({});
    } else if (
      params.has('pageFilter') &&
      params.get('pageFilter') !== JSON.stringify(pageFilter)
    ) {
      setPageFilter(JSON.parse(params.get('pageFilter') || ''));
      newPage = 1;
    }

    if (!params.has('shipmentFilter')) {
      setShipmentFilter({});
    } else if (
      params.has('shipmentFilter') &&
      params.get('shipmentFilter') !== JSON.stringify(shipmentFilter)
    ) {
      setShipmentFilter(JSON.parse(params.get('shipmentFilter') || ''));
      newPage = 1;
    }

    if (!params.has('sourcedBatchFilter')) {
      setSourcedBatchFilter(null);
    } else if (
      params.has('sourcedBatchFilter') &&
      params.get('sourcedBatchFilter') !== JSON.stringify(shipmentFilter)
    ) {
      setSourcedBatchFilter(JSON.parse(params.get('sourcedBatchFilter') || ''));
      newPage = 1;
    }

    setPage(newPage);
  };

  useEffect(() => {
    setSkip(Math.max((page - 1) * DEFAULT_PAGE_SIZE, 0));
  }, [page]);

  return {
    setSearchTerm,
    updatePageSearch,
    updatePageFilter,
    updateShipmentFilter,
    updateSourcedBatchFilter,
    updatePageParams,
    gotoPage,
    setPages,
    page,
    setPage,
    pages,
    skip,
    order,
    shipmentOrder,
    setShipmentOrder,
    searchTerm,
    pageFilter,
    shipmentFilter,
    sourcedBatchOrder,
    sourcedBatchFilter,
    setSourcedBatchOrder,
  };
};
