import { FunctionComponent, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import Pill from '@ingka/pill';
import RadioButton from '@ingka/radio-button';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';

import { Filter } from '../filter-row/filter-row';

import styles from './filter-status-pill.module.scss';

export interface FilterStatusPillProps {
  filter: Filter;
  onFilterChange(filter: string, value: string): void;
}
export const FilterStatusPill: FunctionComponent<FilterStatusPillProps> = ({
  filter,
  onFilterChange,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(filter.label, event.target.value);
  };

  const [isOpen, toggleIsOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => toggleIsOpen(false));

  return (
    <div className={styles.filterSatusPill} ref={ref}>
      <Pill
        ssrIcon={chevronDown}
        label={filter.label}
        onClick={() => toggleIsOpen(!isOpen)}
        iconPosition="trailing"
        selected={filter.options.some((x) => x.selected)}
        data-cy={'status-filter-pill'}
      />
      {isOpen && (
        <div className={styles.dropDown}>
          {filter.options.map((option) => {
            return (
              <RadioButton
                name={filter.label}
                value={option.value}
                label={option.label}
                id={option.value}
                key={option.value}
                checked={option.selected}
                onChange={onChange}
                className={styles.radioButton}
                data-cy={`status-filter-radio-btn-${option.value}`}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
