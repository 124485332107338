import { useMutation, useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { globalLoadingAtom, globalAppErrorAtom } from 'atoms';
import { useAuth } from 'hooks';
import { mutationRemoveShipment, queryShipmentCollection } from 'services';
import { QueryShipmentsCollectionArgs, Shipment } from 'types';
import { QueryShipmentCollectionResult } from 'types/query-order-collection-result';
import { nullFilter } from 'utils';

export const useShipmentStock = ({
  skip,
  limit,
  searchTerm,
  order,
  filter,
}: Partial<QueryShipmentsCollectionArgs>) => {
  const history = useHistory();
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const { getCurrentCpcId } = useAuth();
  const [stockShipments, setStockShipments] = useState<Shipment[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [removeShipmentMutation] = useMutation(mutationRemoveShipment);

  const { data, loading, error, refetch } = useQuery<
    QueryShipmentCollectionResult,
    QueryShipmentsCollectionArgs
  >(queryShipmentCollection, {
    variables: {
      skip,
      limit,
      searchTerm,
      order,
      filter: filter ?? null,
      cpcId: getCurrentCpcId(),
    },
  });

  useEffect(() => {
    setGlobalLoading(loading);
    setGlobalAppError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  useEffect(() => {
    if (data?.shipmentsCollection) {
      setTotal(data.shipmentsCollection.total || 0);
      if (data.shipmentsCollection.items) {
        const filteredItems: Shipment[] =
          data.shipmentsCollection.items.filter<Shipment>(nullFilter);
        setStockShipments(filteredItems);
      }
    }
  }, [data]);

  const removeShipments = async (shipments: Shipment[]) => {
    try {
      shipments.forEach(async (shipment) => {
        await removeShipmentMutation({
          variables: {
            shipmentId: shipment.id,
          },
          update(cache, { data }) {
            if (data?.removeShipment) {
              cache.evict({ id: `Shipment:${data.removeShipment}` });
            }
          },
        });
      });

      history.push('/shipments', {
        message: {
          title: t('notifications.delete.success', {
            type: t('shipment.shipment'),
          }),
          variant: 'positive',
        },
      });
    } catch (error) {
      console.error(error);
      history.replace(history.location.pathname, {
        message: {
          title: t('notifications.delete.error'),
          variant: 'negative',
        },
      });
    }
  };

  return {
    stockShipments,
    total,
    refetch,
    removeShipments,
  };
};
