import { FormikHelpers } from 'formik';
import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, memo, useState } from 'react';

import { hasSelectAllAtom, selectedItemsClearAtom } from 'atoms';
import { UpsertShipmentFormValues, UpsertShipmentModal } from 'components';
import { useShipment } from 'hooks';

export interface UpsertShipmentBulkProps {
  ids: string[];
  cpcId: string;
  visible: boolean;
  onClose(): void;
}

export const UpsertShipmentBulk: FunctionComponent<UpsertShipmentBulkProps> =
  memo(({ ids, cpcId, visible, onClose }) => {
    const [error, setError] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | undefined>(
      undefined
    );
    const { createShipment, addShipmentItems } = useShipment();
    const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);
    const setHasSelectAll = useUpdateAtom(hasSelectAllAtom);

    const onSubmit = async (
      values: UpsertShipmentFormValues,
      { setSubmitting }: FormikHelpers<UpsertShipmentFormValues>
    ) => {
      try {
        setError(false);
        const { externalRef, externalRefType, shipmentId, shipmentType } =
          values;

        const result =
          shipmentType === 'new'
            ? await createShipment(
                ids,
                cpcId,
                externalRef,
                externalRefType !== 'new'
              )
            : await addShipmentItems(ids, shipmentId);

        if (!result) {
          setError(true);

          return;
        }
        setSubmitting(false);
        clearSelectedItems();
        setHasSelectAll(false);
        onClose();
      } catch (error: any) {
        setError(true);
        setSubmitError(error.message);
      }
    };

    const onModalClose = () => {
      onClose();
      setError(false);
    };

    return (
      <UpsertShipmentModal
        numberOfItems={ids.length}
        visible={visible}
        submitErrorMsg={submitError}
        error={error}
        onSubmit={onSubmit}
        onClose={onModalClose}
      />
    );
  });
