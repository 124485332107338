import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Text from '@ingka/text';

import { Columns } from 'components';
import { SourcedBatch } from 'types';
import { formatDate } from 'utils';

import styles from './sourced-batch-details.module.scss';

interface SourcedBatchDetailsProps {
  sourcedBatch: SourcedBatch;
}

export const SourcedBatchDetails: FunctionComponent<
  SourcedBatchDetailsProps
> = ({ sourcedBatch }) => {
  const { t } = useTranslation();

  return (
    <Columns data-cy="order-detail-view-columns">
      <section>
        <dl className={styles.details}>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('source')}</Text>
          </dt>
          <dd>
            <Text headingSize="xs">{sourcedBatch.source.name}</Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('amountOfItems')}</Text>
          </dt>
          <dd>
            <Text data-cy="batch-details-num-items">
              {sourcedBatch.circularItems.length}
            </Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('durationType')}</Text>
          </dt>
          <dd>
            <Text tagName="span">{sourcedBatch.durationInMinutes}</Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('created')}</Text>
          </dt>
          <dd>
            <Text tagName="span">{formatDate(sourcedBatch.created)}</Text>
          </dd>
          <dt className={styles.key}>
            <Text headingSize="xs">{t('comment')}</Text>
          </dt>
          <dd>
            <Text tagName="span">{sourcedBatch.comment}</Text>
          </dd>
        </dl>
      </section>
    </Columns>
  );
};
