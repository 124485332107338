import { useMutation, useQuery } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { globalAppErrorAtom, globalLoadingAtom, imageSrcAtom } from 'atoms';
import { DetailsFormEditInputs, ItemEditView } from 'components';
import { useItemStock } from 'components/_pages/item-stock/hooks/use-item-stock';
import { DEFAULT_PAGE_SIZE } from 'config';
import { FormProvider } from 'context';
import {
  useAuth,
  useGradeItem,
  useItemNumber,
  useItemStatus,
  useRetailItemQuery,
  useUploadImage,
  usePrint,
} from 'hooks';
import { queryCircularItem, mutationUpdateCircularItem } from 'services';
import {
  QueryCircularItemResult,
  FactoryLocationState,
  MutationUpdateCircularItemResult,
  QueryCircularItemArgs,
  CircularItem,
} from 'types';

import {
  createInitialValues,
  createCircularItemInput,
} from './item-edit-page-utils';

export interface ItemEditPageParameters {
  id: string;
}

export const ItemEditPage: FunctionComponent = memo(() => {
  const { t } = useTranslation();
  const { getCurrentCpcId, getCurrentCpc } = useAuth();
  const history = useHistory<FactoryLocationState>();
  const { id } = useParams<ItemEditPageParameters>();
  const { getImageFilename } = useUploadImage();
  const [globalLoading, setGlobalLoading] = useAtom(globalLoadingAtom);
  const [globalAppError, setGlobalAppError] = useAtom(globalAppErrorAtom);
  const [isInShipment, setIsInShipment] = useState<boolean>(false);
  const imageSrc = useAtomValue(imageSrcAtom);
  const { gradeItem, gradeItemResult } = useGradeItem();
  const { setStatus, setStatusResult } = useItemStatus();
  const { itemNumber, setItemNumber } = useItemNumber();
  const { retailItem, setRetailItem } = useRetailItemQuery(
    itemNumber,
    getCurrentCpc().retailUnitCode
  );
  const { print } = usePrint();
  const [initialValues, setInitialValues] = useState<
    DetailsFormEditInputs | undefined
  >(undefined);

  const { refetch } = useItemStock({
    skip: 0,
    limit: DEFAULT_PAGE_SIZE,
    order: {
      registerDate: 'DESC',
    },
    searchTerm: '',
  });

  const { loading, error, data } = useQuery<
    QueryCircularItemResult,
    QueryCircularItemArgs
  >(queryCircularItem, {
    variables: {
      circularItemId: id,
    },
  });

  const [updateItem, updateResult] =
    useMutation<MutationUpdateCircularItemResult>(mutationUpdateCircularItem);

  const handleInitialValues = async (circularItem: CircularItem) => {
    const values = createInitialValues(circularItem);
    setInitialValues(values);
  };

  const handleCustomImage = async (): Promise<string[] | undefined> => {
    if (
      retailItem?.itemNumber &&
      itemNumber !== '' &&
      itemNumber === retailItem.itemNumber
    ) {
      // remove images if retailItem
      return [];
    } else if (imageSrc && !retailItem) {
      const filename = await getImageFilename();
      return filename;
    } else {
      return undefined;
    }
  };

  const handleUpdateCircularItem = async (
    formValues: DetailsFormEditInputs
  ) => {
    let values = createCircularItemInput(
      formValues,
      data?.circularItem.retailItem
    );

    const input = {
      ...values,
      cpcId: getCurrentCpcId(),
      uploadedFilenames: await handleCustomImage(),
    };

    await updateItem({
      variables: { circularItemId: id, input: input },

      update(cache, { data }) {
        if (!data?.updateCircularItem) {
          return;
        }
      },
      refetchQueries: [
        {
          query: queryCircularItem,
          variables: {
            circularItemId: id,
          },
        },
      ],
    });

    if (
      formValues.grade &&
      formValues.grade !== data?.circularItem?.currentGrade?.value
    ) {
      await gradeItem(formValues.grade, id);
    }

    if (formValues.status && formValues.status !== data?.circularItem?.status) {
      await setStatus(formValues.status, id);
    }
  };

  useEffect(() => {
    setGlobalLoading(
      loading || updateResult.loading || gradeItemResult.loading
    );
  }, [
    loading,
    updateResult.loading,
    gradeItemResult.loading,
    setStatusResult.loading,
    setGlobalLoading,
  ]);

  useEffect(() => {
    setGlobalAppError(error || updateResult.error || gradeItemResult.error);
  }, [
    error,
    updateResult.error,
    gradeItemResult.error,
    setStatusResult.error,
    setGlobalAppError,
  ]);

  useEffect(() => {
    if (data?.circularItem) {
      handleInitialValues(data.circularItem);
      if (data.circularItem.assignedShipment) {
        setIsInShipment(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.circularItem]);

  const onSubmit = async (
    values: DetailsFormEditInputs,
    { setSubmitting }: FormikHelpers<DetailsFormEditInputs>
  ) => {
    try {
      await handleUpdateCircularItem(values);

      if (values.print) {
        const labelImageUrl = data?.circularItem?.labelImageUrl;
        if (!labelImageUrl) {
          throw new Error(
            `labelImageUrl missing for item ${data?.circularItem.id}`
          );
        }
        await print(labelImageUrl);
      }

      history.push(`${`/inventory/${id}`}`, {
        message: {
          title: t(
            values.print
              ? 'notifications.print.editItem'
              : 'notifications.editItem'
          ),
          variant: 'positive',
        },
      });

      refetch();
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  const handleSetItemNumber = (value: string) => {
    setItemNumber(value?.replace(/\D/g, ''));
  };

  return initialValues &&
    data?.circularItem &&
    !globalAppError &&
    !globalLoading ? (
    <FormProvider
      value={{
        itemNumber,
        retailItem,
        setItemNumber: handleSetItemNumber,
        setRetailItem,
      }}
    >
      <ItemEditView
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        circularItem={data?.circularItem}
        isInShipment={isInShipment}
      />
    </FormProvider>
  ) : null;
});
