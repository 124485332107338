import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';

import { DialoguePrompt } from 'components';
import { useFormContext } from 'hooks';
import { FactoryFormFieldProps } from 'types';

export interface DetailsFormItemNumberProps extends FactoryFormFieldProps {
  className: string;
  itemNumberInput?: string;
  itemNumberError: string | undefined;
  itemNumberTouched: boolean;
  setValue: (value: string) => void;
  handleSetRetailItem: () => void;
}

export const DetailsFormItemNumber: FunctionComponent<
  DetailsFormItemNumberProps
> = ({
  onBlur,
  setValue,
  handleSetRetailItem,
  className,
  itemNumberError,
  itemNumberTouched,
  itemNumberInput,
  disabled,
}) => {
  const { t } = useTranslation();
  const { retailItem, setItemNumber, setRetailItem } = useFormContext();
  const [hasRendered, setHasRendered] = useState<boolean>(false);
  const [itemNumberDialogueResponse, setItemNumberDialogueResponse] =
    useState<boolean>();
  const [displayItemNumberDialogue, setDisplayItemNumberDialogue] =
    useState<boolean>(false);

  useEffect(() => {
    if (retailItem && hasRendered) {
      setDisplayItemNumberDialogue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailItem]);

  useEffect(() => {
    if (itemNumberDialogueResponse === false) {
      setItemNumber('');
      setValue('');
      setRetailItem(null);
      setItemNumberDialogueResponse(undefined);
    }
    if (itemNumberDialogueResponse === true) {
      handleSetRetailItem();
      setItemNumberDialogueResponse(undefined);
      setDisplayItemNumberDialogue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemNumberDialogueResponse]);

  useEffect(() => {
    if (itemNumberInput?.length === 10) {
      setItemNumber(itemNumberInput);
    } else if (itemNumberInput === '') {
      setItemNumber(itemNumberInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemNumberInput]);

  const onItemNumberInput = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      setHasRendered(true);
    }, 1000);
  }, []);

  return (
    <>
      <DialoguePrompt
        compact={true}
        displayDialogue={displayItemNumberDialogue}
        setDisplayDialogue={setDisplayItemNumberDialogue}
        handleDialogueResponse={setItemNumberDialogueResponse}
        positiveBtnText={t('actions.itemNumberDialogue.positive')}
        negativeBtnText={t('actions.itemNumberDialogue.negative')}
        promptTitle={t('dialogues.foundRetailItem.title')}
        promptBody={t('dialogues.foundRetailItem.body')}
      />
      <FormField
        className={className}
        shouldValidate={itemNumberTouched}
        valid={!itemNumberError}
        validation={
          itemNumberError
            ? {
                msg: itemNumberError,
                id: 'itemNumber-error',
              }
            : undefined
        }
      >
        <InputField
          id="itemNumber"
          name="itemNumber"
          type="text"
          label={t('itemNumber.short')}
          disabled={disabled}
          onChange={onItemNumberInput}
          onBlur={onBlur}
          value={itemNumberInput}
          maxLength={10}
          data-cy="details-form-input-product-number"
        />
      </FormField>
    </>
  );
};
