import { Keys } from 'constants/Keys';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Location, LocationDescriptor } from 'history';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import {
  createContext,
  FunctionComponent,
  ReactElement,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import {
  globalAppErrorAtom,
  globalLoadingAtom,
  tokenAtom,
  userAtom,
  userCpcAtom,
} from 'atoms';
import { useFactoryApolloClient, useMessage } from 'hooks';
import { mutationLogin, queryMe } from 'services';
import {
  CircularProcessCenter,
  MutationLoginArgs,
  MutationLoginResult,
  QueryMeResult,
} from 'types';


interface GuardProps {
  location?: Location;
}

export interface AuthContextValue {
  signin(email: string, password: string): void;
  Guard(props: GuardProps): ReactElement;
  signOut(): void;
  getCurrentCpc(): CircularProcessCenter;
  getCurrentCpcId(): string;
  selectCpc(id: string): void;
}

export const defaultAuthContextValue: AuthContextValue = {
  signin: async (email, password) => {
    return {};
  },
  Guard: (_props) => <></>,
  signOut: async () => {},
  getCurrentCpc: () => ({
    id: '',
    defaultCurrencyCode: '',
    name: '',
    retailUnitCode: '',
    address: {
      city: '',
      countryCode: '',
      id: '',
      latitude: 0,
      longitude: 0,
      street: '',
      zipCode: '',
    },
  }),
  getCurrentCpcId: () => '',
  selectCpc: (id: string) => {},
};

export const AuthContext = createContext<AuthContextValue>(
  defaultAuthContextValue
);

export const AuthProvider: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const { redirectWithMessage } = useMessage();
  const client = useFactoryApolloClient();
  const [user, setUser] = useAtom(userAtom);
  const [selectedCpc, setSelectedCpc] = useAtom(userCpcAtom);
  const [token, setToken] = useAtom(tokenAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);

  useEffect(() => {
    localStorage.setItem(Keys.TOKEN_KEY, token);
  }, [token]);

  const [attemptLogin, loginResult] = useMutation<
    MutationLoginResult,
    MutationLoginArgs
  >(mutationLogin);
  const [fetchUserData, userResult] = useLazyQuery<QueryMeResult>(queryMe,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache'
    });

  useEffect(() => {
    setGlobalLoading(loginResult.loading || userResult.loading);
  }, [loginResult.loading, userResult.loading, setGlobalLoading]);

  useEffect(() => {
    setGlobalAppError(loginResult.error || userResult.error);
    if (loginResult.error || userResult.error) {
      setToken('');
      setUser(null);
      redirectWithMessage({
        title: t(
          loginResult.error
            ? 'notifications.auth.error.title'
            : 'notifications.auth.expired.title'
        ),
        body: t(
          loginResult.error
            ? 'notifications.auth.error.body'
            : 'notifications.auth.expired.body'
        ),
        variant: 'negative',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResult.error, userResult.error]);

  useEffect(() => {
    if (loginResult?.data?.login) {
      setToken(loginResult.data.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResult.data]);

  useEffect(() => {
    if (token && userResult?.data?.me && user !== userResult.data.me) {
      setUser(userResult?.data?.me);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userResult.data, token]);

  useEffect(() => {
    if (token && !user) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  const signin = async (email: string, password: string) => {
    await attemptLogin({ variables: { email, password } });
  };

  const Guard = ({ location }: GuardProps): ReactElement => {
    const to: LocationDescriptor = {
      pathname: '/',
      state: {
        from: location,
        message: {
          variant: 'informative',
          title: t('notifications.auth.signout.title'),
          body: t('notifications.auth.signout.body'),
        },
      },
    };
    return <Redirect to={to} />;
  };

  const clearSession = async () => {
    setToken('');
    setUser(null);
    await client.clearStore();
  };

  const signOut = () => {
    clearSession();
  };

  const getCurrentCpc = (): CircularProcessCenter => {
    if (!user) {
      throw new Error('No signed in user!');
    }
    if (!user.cpcs?.[selectedCpc]) {
      throw new Error('user has no Circular Process Center!');
    }
    return user.cpcs[selectedCpc] as CircularProcessCenter;
  };

  const getCurrentCpcId = (): string => {
    if (!user) {
      throw new Error('No signed in user!');
    }
    if (!user.cpcIds[selectedCpc]) {
      throw new Error('User has no Circular Process Center!');
    }
    return user?.cpcIds[selectedCpc];
  };

  const selectCpc = (id: string): void => {
    if (!user) {
      throw new Error('No signed in user!');
    }
    if (!user.cpcIds.length) {
      throw new Error('User has no Circular Process Center!');
    }
    const newIndex = user.cpcIds.findIndex((value: string) => value === id);
    if (newIndex < 0) {
      throw new Error(
        'Selected Circular Process Center is not available for user!'
      );
    }
    setSelectedCpc(newIndex);
  };

  return (
    <AuthContext.Provider
      value={{
        signin,
        Guard,
        signOut,
        getCurrentCpc,
        getCurrentCpcId,
        selectCpc,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
