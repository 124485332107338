import { DetailsFormEditInputs } from 'components';
import {
  CircularItem,
  Maybe,
  RetailItem,
  SgfLocation,
  SgfLocationInput,
  UpdateCircularItemInput,
} from 'types';
import {
  getManufacturingDateYear,
  getManufacturingDateWeek,
  formatItemNumberWithRegex,
  omitTypenames,
} from 'utils';

const createSgfInitialValues = (sgf: SgfLocation): SgfLocationInput => {
  return {
    bay: sgf.bay ? sgf.bay : undefined,
    location: sgf.location ? sgf.location : undefined,
    level: sgf.level ? sgf.level : undefined,
  };
};

export const createInitialValues = (
  circularItem: CircularItem
): DetailsFormEditInputs => {
  const values: DetailsFormEditInputs = {
    status: circularItem.status || undefined,
    productName: circularItem.globalProductName || '',
    productDescription: circularItem.productDescription || '',
    itemNumber: formatItemNumberWithRegex(circularItem.itemNumber!),
    businessStructure: circularItem.businessStructure
      ? omitTypenames(circularItem.businessStructure)
      : undefined,
    grade: circularItem?.currentGrade?.value,
    estimatedSalesPrice: circularItem.estimatedSalesPrice?.toString(),
    regularItemSalesPriceInclTax:
      circularItem.regularItemSalesPriceInclTax?.toString(),
    regularItemSalesPriceCurrencyCode:
      circularItem.regularItemSalesPriceCurrencyCode!,
    year: getManufacturingDateYear(circularItem.manufacturingDate),
    week: getManufacturingDateWeek(circularItem.manufacturingDate),
    supplierNumber: circularItem.supplierNumber?.toString() || '',
    sourceId: circularItem.source?.id,
    sgfLocation: circularItem.sgfLocation
      ? createSgfInitialValues(circularItem.sgfLocation)
      : undefined,
    note: circularItem.note?.text!,
    eligibleForFAAS: circularItem.eligibleForFAAS!,
    FAASCustomer: circularItem.FAASCustomer!,
    sekundReference: circularItem.sekundReference || '',
  };

  return values;
};

export const createSgfLocationInput = (sgf: SgfLocationInput) => {
  if (
    (sgf.bay === '-' || !sgf.bay) &&
    (sgf.location === '-' || !sgf.location) &&
    (sgf.level === '-' || !sgf.level)
  ) {
    return null;
  } else if (sgf.bay === '-' || sgf.location === '-' || sgf.level === '-') {
    return {
      bay: sgf.bay === '-' ? null : sgf.bay,
      location: sgf.location === '-' ? null : sgf.location,
      level: sgf.level === '-' ? null : sgf.level,
    };
  } else {
    return sgf;
  }
};

export const createCircularItemInput = (
  input: DetailsFormEditInputs,
  retailItem?: Maybe<RetailItem>
) => {
  const verifiedRetailItem = !!retailItem?.itemNumber;

  const getRegularItemSalesPriceInclTax = () => {
    if (verifiedRetailItem) {
      return retailItem.regularItemSalesPriceInclTax;
    }
    return input.regularItemSalesPriceInclTax
      ? parseInt(input.regularItemSalesPriceInclTax)
      : null;
  };

  const getRegularItemSalesPriceCurrencyCode = () => {
    return verifiedRetailItem && retailItem.regularItemSalesPriceCurrencyCode
      ? undefined
      : input.regularItemSalesPriceCurrencyCode;
  };

  const values: Partial<UpdateCircularItemInput> = {
    productName: input.productName,
    productDescription: input.productDescription,
    itemNumber: input.itemNumber ? input.itemNumber.replace(/\D/g, '') : '',
    businessStructure: input.businessStructure || undefined,
    estimatedSalesPrice: input.estimatedSalesPrice
      ? parseInt(input.estimatedSalesPrice, 10)
      : null,
    regularItemSalesPriceInclTax: getRegularItemSalesPriceInclTax(),
    regularItemSalesPriceCurrencyCode: getRegularItemSalesPriceCurrencyCode(),
    manufacturingDate:
      input.year && input.week ? `${input.year}-${input.week}` : null,
    sgfLocation: input.sgfLocation
      ? createSgfLocationInput(input.sgfLocation)
      : null,
    supplierNumber: input.supplierNumber
      ? parseInt(input.supplierNumber, 10)
      : null,
    note: input?.note,
    sourceId: input.sourceId,
    eligibleForFAAS: input.eligibleForFAAS,
    FAASCustomer: input.FAASCustomer,
    sekundReference: input.sekundReference,
  };

  return values;
};
