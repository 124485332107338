import { atom } from 'jotai';

import { RetailItemBusinessStructureInput } from 'types';

export interface DuplicateValues {
  globalProductName?: string;
  itemNumber?: string;
  estimatedSalesPrice?: string;
  regularItemSalesPriceInclTax?: string;
  businessStructure?: RetailItemBusinessStructureInput;
}

export const duplicateItemValuesAtom = atom<DuplicateValues | null>(null);
