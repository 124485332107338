import { FC } from 'react';
import { Link } from 'react-router-dom';

import Checkbox from '@ingka/checkbox';

import { useSelect } from 'hooks';
import { CircularItem } from 'types';

import styles from './reference-item-row.module.scss';

interface ReferenceItemRowProps {
  editPage?: boolean;
  circularItem: CircularItem;
  selected: boolean;
  fromReferenceList?: boolean;
  dataCy: string;
}

export const ReferenceItemRow: FC<ReferenceItemRowProps> = ({
  editPage,
  circularItem,
  selected,
  fromReferenceList,
  dataCy,
}) => {
  const { toggleItemSelect } = useSelect();

  const onSelectChange = () => {
    toggleItemSelect(!selected, circularItem);
  };

  return (
    circularItem && (
      <tr className={styles.itemTableRow} data-cy={`${dataCy}-row`}>
        {editPage && !fromReferenceList && (
          <td className={`${styles.selectCell} ${styles.borderLess}`}>
            <Checkbox
              id={`select-${circularItem.id}`}
              value={circularItem.id}
              onChange={onSelectChange}
              checked={selected}
              data-cy={`${dataCy}-row-checkbox`}
            />
          </td>
        )}
        <td>
          <Link to={`/inventory/${circularItem.id}`} className={styles.idLink}>
            {circularItem.id}
          </Link>
        </td>
        <td>{circularItem.globalProductName}</td>
        <td>{circularItem.status}</td>
      </tr>
    )
  );
};
