
import { atom } from 'jotai';

import { Maybe, User } from 'types';

import { Keys } from '../../constants';


export const tokenAtom = atom<string>(localStorage.getItem(Keys.TOKEN_KEY) || '');
export const userAtom = atom<Maybe<User>>(null);
export const userCpcAtom = atom<number>(0);
export const isAuthenticatedAtom = atom<boolean>((get) => {
  const isAuthenticated = !!get(userAtom) && !!get(tokenAtom);
  return isAuthenticated;
});
