import { FormikHelpers } from 'formik';
import { t } from 'i18next';
import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, memo, useState } from 'react';

import { hasSelectAllAtom, selectedItemsClearAtom } from 'atoms';
import { ActivityFormValues, AddActivityModal } from 'components';
import { useAddActivities, useMessage } from 'hooks';
import { ActivityBulkInput } from 'types';

export interface AddActivityBulkProps {
  ids: string[];
  visible: boolean;
  onClose(): void;
}

export const AddActivityBulk: FunctionComponent<AddActivityBulkProps> = memo(
  ({ ids, visible, onClose }) => {
    const [error, setError] = useState<boolean>(false);
    const { addActivitiesBulk } = useAddActivities();
    const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);
    const setHasSelectAll = useUpdateAtom(hasSelectAllAtom);
    const message = useMessage();

    const onSubmit = async (
      values: ActivityFormValues,
      { setSubmitting }: FormikHelpers<ActivityFormValues>
    ) => {
      try {
        setError(false);
        const mappedValues = values.activities.map(a => ({
          activityTypeId: a.type!,
          executionTimeInMins: parseInt(a.duration!, 10)
        }) as ActivityBulkInput);
        mappedValues.at(-1)!.grade = values.grade;
        const result = await addActivitiesBulk(
          ids,
          mappedValues
        );
        if (!result) {
          setError(true);
        }
        setSubmitting(false);
        clearSelectedItems();
        setHasSelectAll(false);
        message.setCustomMessage({title: t("notifications.activity.addSuccess"), variant: 'positive'});
        onClose();
      } catch (error) {
        setSubmitting(false);
      }
    };

    const onModalClose = () => {
      onClose();
      setError(false);
    };

    const initialValues = {
      activities: [{}],
      grade: undefined,
    };

    return (
      <AddActivityModal
        visible={visible}
        error={error}
        onSubmit={onSubmit}
        onClose={onModalClose}
        initialValues={initialValues}
      />
    );
  }
);
