import { useQuery } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Button from '@ingka/button';
import FormField from '@ingka/form-field';
import InlineMessage from '@ingka/inline-message';
import InputField from '@ingka/input-field';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sheets,
} from '@ingka/modal';
import RadioButtonGroup from '@ingka/radio-button-group';

import { Autocomplete } from 'components';
import { useAuth } from 'hooks';
import { queryExternalReferences } from 'services';
import { QueryExternalReferencesArgs } from 'types';
import { FactoryComponentProps } from 'types/factory-component-props';
import { QueryExternalReferencesResult } from 'types/query-external-references-result';

export interface UpsertShipmentFormValues extends FactoryComponentProps {
  externalRef: string;
  externalRefType: string;
  shipmentId: string;
  shipmentType: string;
}

export interface UpsertShipmentModalProps {
  numberOfItems: number;
  visible: boolean;
  error?: boolean;
  submitErrorMsg?: string;
  onSubmit(
    values: UpsertShipmentFormValues,
    helpers: FormikHelpers<UpsertShipmentFormValues>
  ): void;
  onClose(): void;
  initialValues?: UpsertShipmentFormValues;
}

export const UpsertShipmentModal: FunctionComponent<UpsertShipmentModalProps> =
  memo(
    ({
      numberOfItems,
      visible,
      error,
      submitErrorMsg,
      onSubmit,
      onClose,
      initialValues = {
        externalRef: '',
        externalRefType: '',
        shipmentId: '',
        shipmentType: 'existing',
      },
    }) => {
      const { t } = useTranslation();
      const { getCurrentCpcId } = useAuth();

      const getExternalReferencesQuery = useQuery<
        QueryExternalReferencesResult,
        QueryExternalReferencesArgs
      >(queryExternalReferences, {
        variables: { cpcId: getCurrentCpcId() },
      });

      const validationSchema = Yup.object().shape({
        externalRef: Yup.string().when('shipmentType', {
          is: 'new',
          then: Yup.string().required().max(60).min(3),
        }),
        shipmentId: Yup.string().when('shipmentType', {
          is: 'existing',
          then: Yup.string().required(),
        }),
      });

      const {
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        submitForm,
      } = useFormik<UpsertShipmentFormValues>({
        initialValues,
        onSubmit,
        validationSchema,
      });

      const setShipmentType = (shipmentType: string) =>
        setFieldValue('shipmentType', shipmentType);

      const setExternalRef = (
        externalRef: string | undefined,
        isNew: boolean | undefined
      ) => {
        setFieldValue('externalRef', externalRef);
        setFieldValue('externalRefType', isNew ? 'new' : 'existing');
      };

      const onSubmitClick = (event: MouseEvent) => {
        event.preventDefault();
        submitForm();
      };

      const handleCloseBtn = () => onClose();

      const onModalClosed = () => resetForm();

      const onCancelClick = () => onClose();

      const externalReferenceItems =
        getExternalReferencesQuery?.data?.externalReferences.map(
          (externalRef) => ({
            name: externalRef.externalReference,
            value: externalRef.externalReference,
          })
        ) || [];

      const header = (
        <ModalHeader
          title={t('addToShipment') + ` (${numberOfItems}) ${t('items')}`}
        />
      );

      const footer = (
        <ModalFooter>
          <>
            <Button
              type="primary"
              fluid={true}
              loading={isSubmitting || getExternalReferencesQuery.loading}
              htmlType="submit"
              text={t('actions.save')}
              onClick={onSubmitClick}
              data-cy="upsert-shipment-submit-btn"
            />
            <Button
              type="secondary"
              fluid={true}
              loading={isSubmitting || getExternalReferencesQuery.loading}
              text={t('actions.cancel')}
              onClick={onCancelClick}
              data-cy="upser-shipment-cancel-btn"
            />
          </>
        </ModalFooter>
      );
      return (
        <form onSubmit={handleSubmit}>
          <Modal
            visible={visible}
            handleCloseBtn={handleCloseBtn}
            onModalClosed={onModalClosed}
            data-cy="upser-shipment-modal"
          >
            <Sheets size="small" header={header} footer={footer}>
              <ModalBody>
                <FormField>
                  <RadioButtonGroup
                    name={t('whatShipment')}
                    list={[
                      {
                        id: 'shipmentTypeNew',
                        label: t('new'),
                        value: 'new',
                        onChange: (event: any) =>
                          setShipmentType(event.target.value),
                      },
                      {
                        id: 'shipmentTypeExisting',
                        label: t('existing'),
                        value: 'existing',
                        defaultChecked: true,
                        onChange: (event: any) =>
                          setShipmentType(event.target.value),
                      }
                    ]}
                    data-cy="upsert-shipment-radiobutton-type"
                  />
                </FormField>

                {values.shipmentType === 'new' && (
                  <FormField
                    valid={!errors.externalRef}
                    shouldValidate={touched.externalRef}
                    validation={
                      errors.externalRef
                        ? {
                            msg: errors.externalRef,
                            id: 'type-error',
                          }
                        : undefined
                    }
                    data-cy="upsert-shipment-input-external-ref"
                  >
                    <Autocomplete
                      id="externalRef"
                      creatable
                      label={t('externalRef')}
                      options={externalReferenceItems}
                      onChange={setExternalRef}
                    />
                  </FormField>
                )}

                {values.shipmentType === 'existing' && (
                  <FormField
                    valid={!errors.shipmentId}
                    shouldValidate={touched.shipmentId}
                    validation={
                      errors.shipmentId
                        ? {
                            msg: errors.shipmentId,
                            id: 'type-error',
                          }
                        : undefined
                    }
                    fieldHelper={{
                      msg: t('shipmentIdEg'),
                    }}
                    data-cy="upsert-shipment-input-shipment-id"
                  >
                    <InputField
                      id="shipmentId"
                      type="text"
                      label={t('shipmentId')}
                      maxLength={60}
                      minLength={3}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shipmentId}
                      required
                    />
                  </FormField>
                )}

                {error && (
                  <InlineMessage
                    variant="negative"
                    title={t('notifications.error')}
                    body={
                      submitErrorMsg ?? t('notifications.shipment.upsertError')
                    }
                  />
                )}
              </ModalBody>
            </Sheets>
          </Modal>
        </form>
      );
    }
  );
