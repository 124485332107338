import { ApolloError } from '@apollo/client';
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@ingka/button';
import PlusIcon from '@ingka/ssr-icon/paths/plus';
import Text from '@ingka/text';

import {
  AddSourceModal,
  PageContent,
  PageHeader,
  SourceList,
} from 'components';
import { ConfirmDeleteSourceDialogue } from 'components/_prompts/confirm-remove-source/confirm-remove-source';
import { useAuth, useMessage } from 'hooks';
import { useDeleteSource } from 'hooks/use-delete-source/use-delete-source';
import { Source } from 'types';

import styles from './edit-sources-view.module.scss';

export interface EditSourcesViewProps {
  sources: Source[];
  error: boolean;
  loading: boolean;
}

export const EditSourcesView: FunctionComponent<EditSourcesViewProps> = memo(
  ({ sources, error, loading }) => {
    const { t } = useTranslation();
    const { getCurrentCpc } = useAuth();
    const [createSourceModalOpen, openCreateSourceModal] = useState(false);
    const [displayDialogueVisible, setDisplayDialogueVisible] = useState(false);
    const [pendingDeleteSource, setPendingDeleteSource] = useState<Source>();

    const { deleteSource } = useDeleteSource();
    const { setCustomMessage } = useMessage();

    useEffect(() => {
      setDisplayDialogueVisible(pendingDeleteSource !== undefined);
    }, [pendingDeleteSource]);

    const onDeleteSource = async (s: Source) => {
      setPendingDeleteSource(s);
    };

    const handleDialogueResponse = async (response: boolean) => {
      try {
        if (response) {
          await deleteSource(pendingDeleteSource!);
          setCustomMessage({
            title: t('notifications.source.delete.success'),
            variant: 'positive',
          });
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          const apolloError = e as ApolloError;
          setCustomMessage({
            title: t('notifications.error'),
            body: apolloError.message,
            variant: 'negative',
          });
        } else {
          throw e;
        }
      } finally {
        setPendingDeleteSource(undefined);
      }
    };

    return (
      <PageContent>
        <PageHeader>
          <div className={styles.header}>
            <Text tagName="h1" headingSize="m">
              {t('adminSources')}
            </Text>
            <Text bodySize="s" className={styles.description}>
              {t('adminSourcesDescription', { cpc: getCurrentCpc().name })}
            </Text>
          </div>
          <Button
            type="primary"
            ssrIcon={PlusIcon}
            onClick={() => openCreateSourceModal(true)}
          >
            {t('addSource')}
          </Button>
        </PageHeader>
        <SourceList
          items={sources}
          error={error}
          loading={loading}
          onDeleteSource={onDeleteSource}
        />
        <ConfirmDeleteSourceDialogue
          displayDialogue={displayDialogueVisible}
          setDisplayDialogue={setDisplayDialogueVisible}
          handleDialogueResponse={handleDialogueResponse}
        />
        <AddSourceModal
          visible={createSourceModalOpen}
          onClose={() => openCreateSourceModal(false)}
        />
      </PageContent>
    );
  }
);
