import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';

import {
  selectedItemsAtom,
  selectedItemsAddAtom,
  selectedItemsRemoveAtom,
  hasSelectAllAtom,
  selectedItemsAddAllAtom,
  selectedItemsClearAtom,
  selectedItemsLengthAtom,
  selectedItemsIdsAtom,
} from 'atoms';
import { CircularItem } from 'types';

export const useSelect = () => {
  const [hasSelectAll, setHasSelectAll] = useAtom(hasSelectAllAtom);
  const setSelectedItemsAddAllAtom = useUpdateAtom(selectedItemsAddAllAtom);
  const clearSelectedItems = useUpdateAtom(selectedItemsClearAtom);
  const selectedItemsLength = useAtomValue(selectedItemsLengthAtom);
  const selectedItemsIds = useAtomValue(selectedItemsIdsAtom);
  const selectedItems = useAtomValue(selectedItemsAtom);
  const addSelectedItem = useUpdateAtom(selectedItemsAddAtom);
  const removeSelectedItem = useUpdateAtom(selectedItemsRemoveAtom);

  const toggleItemSelect = (selected: boolean, circularItem: CircularItem) => {
    if (selected) {
      addSelectedItem(circularItem);
    } else {
      removeSelectedItem(circularItem);
    }
  };

  return {
    hasSelectAll,
    setHasSelectAll,
    setSelectedItemsAddAllAtom,
    clearSelectedItems,
    selectedItemsLength,
    selectedItems,
    selectedItemsIds,
    toggleItemSelect,
  };
};
