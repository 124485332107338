import { useAuth } from "hooks";

export const useCurrency = () => {
  const { getCurrentCpc } = useAuth();

  return {
    /**
     * Formats a currency based on a given amount and currency code.
     * 
     * If no amount is provided, returns an empty string.
     * 
     * If no currency code is provided, uses the default currency code
     * for the current CPC.
     *  
     * @param amount 
     * @param currencyCode 
     * @returns 
     */
    formatCurrency(amount?: number | null, currencyCode?: string | null) {
      if (!amount) {
        return '';
      }
      currencyCode = currencyCode || getCurrentCpc().defaultCurrencyCode;
      const locale = navigator.language;
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency:
          currencyCode,
      }).format(amount)
    }
  }
}