import { useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { RegisterSaleFormInput } from 'components';
import { mutationCreateSale } from 'services';
import {
  CircularItem,
  CreateSaleInput,
  MutationCreateSaleArgs,
  MutationCreateSaleResult,
} from 'types';

export interface UseRegisterSaleProps {
  circularItemId: string;
  currencyCode: string;
  onClose(): void;
}

export const useRegisterSale = ({
  circularItemId,
  currencyCode,
  onClose,
}: UseRegisterSaleProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [registerSaleMutation] = useMutation<
    MutationCreateSaleResult,
    MutationCreateSaleArgs
  >(mutationCreateSale);

  const onSubmit = async (
    { salesPrice }: RegisterSaleFormInput,
    { setSubmitting }: FormikHelpers<RegisterSaleFormInput>
  ) => {
    if (salesPrice) {
      try {
        const input: CreateSaleInput = {
          amount: parseInt(salesPrice, 10),
          circularItemId,
          currencyCode,
          saleTimestamp: new Date(Date.now()),
        };

        const { data } = await registerSaleMutation({
          variables: {
            input,
          },
          update(cache, { data }) {
            if (!data) {
              return;
            }
            cache.modify({
              id: cache.identify({
                __typename: 'CircularItem',
                id: data.createSale.circularItemId,
              } as CircularItem),
              fields: {
                sales: (previous, { toReference }) => {
                  return [...previous, toReference(data.createSale)];
                },
              },
            });
          },
        });
        if (data?.createSale.id) {
          history.replace(history.location.pathname, {
            message: {
              title: t('notifications.registerSale.success'),
              variant: 'positive',
            },
          });
        }
        onClose();
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        setSubmitting(false);
        history.replace(history.location.pathname, {
          message: {
            title: t('notifications.registerSale.saveError'),
            variant: 'negative',
          },
        });
      }
    }
  };

  return { onSubmit };
};
