import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  YearWeek: any;
  join__FieldSet: any;
  _FieldSet: any;
};

export type ActivityBulkInput = {
  activityTypeId: Scalars['ID'];
  executionTimeInMins: Scalars['Int'];
  grade?: InputMaybe<GradeValue>;
};

export type AddShipmentItemsInput = {
  circularItemIds: Array<Scalars['ID']>;
  shipmentId: Scalars['ID'];
};

export type Address = {
  __typename?: 'Address';
  city: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
  street: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};

export type AssignItemsAndCreateNewSourcedBatchInput = {
  circularItemIds: Array<Scalars['ID']>;
  deleteConfirmation?: InputMaybe<Scalars['Boolean']>;
  moveSourceConfirmation?: InputMaybe<Scalars['Boolean']>;
  sourceId: Scalars['ID'];
};

export type AssignItemsToSourcedBatchInput = {
  circularItemIds: Array<Scalars['ID']>;
  deleteConfirmation?: InputMaybe<Scalars['Boolean']>;
  moveSourceConfirmation?: InputMaybe<Scalars['Boolean']>;
  sourcedBatchId: Scalars['ID'];
};

/** Digital copy and of one unique pyshical IKEA article */
export type CircularItem = Node & {
  __typename?: 'CircularItem';
  FAASCustomer: Maybe<Scalars['String']>;
  /** List of activities made on the circular item */
  activities: Maybe<Array<Maybe<CircularItemActivity>>>;
  assignedShipment: Maybe<Shipment>;
  atExternalReference: Maybe<ExternalReference>;
  businessStructure: Maybe<RetailItemBusinessStructure>;
  cpc: Maybe<CircularProcessCenter>;
  /** The current grade that was set on the product */
  currentGrade: Maybe<CircularItemGrade>;
  eligibleForFAAS: Maybe<Scalars['Boolean']>;
  estimatedSalesPrice: Maybe<Scalars['Float']>;
  estimatedSalesPriceCurrencyCode: Maybe<Scalars['String']>;
  globalProductName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Maybe<Array<CircularItemImage>>;
  /** The very first recognized grade for the product */
  initialGrade: Maybe<CircularItemGrade>;
  itemNumber: Maybe<Scalars['String']>;
  itemType: Maybe<RetailItemType>;
  /** Url to JPEG for unique printable label */
  labelImageUrl: Maybe<Scalars['String']>;
  latestStatusTransition: Maybe<StatusTransition>;
  manufacturingDate: Maybe<Scalars['YearWeek']>;
  modifiedDate: Maybe<Scalars['DateTime']>;
  note: Maybe<Note>;
  prettyId: Scalars['String'];
  productDescription: Maybe<Scalars['String']>;
  registerDate: Maybe<Scalars['DateTime']>;
  regularItemSalesPriceCurrencyCode: Maybe<Scalars['String']>;
  regularItemSalesPriceInclTax: Maybe<Scalars['Float']>;
  retailItem: Maybe<RetailItem>;
  retailUnitCode: Scalars['String'];
  sales: Array<CircularItemSale>;
  sekundReference: Maybe<Scalars['String']>;
  sgfLocation: Maybe<SgfLocation>;
  shipments: Array<Shipment>;
  /** Source from where the item was collected */
  source: Maybe<Source>;
  sourcedBatch: Maybe<SourcedBatch>;
  sourcingTimestamp: Maybe<Scalars['DateTime']>;
  status: Maybe<Status>;
  statusTransitions: Array<StatusTransition>;
  supplierNumber: Maybe<Scalars['Int']>;
  tags: Maybe<Array<Maybe<CircularItemTag>>>;
  user: Maybe<User>;
  userId: Scalars['String'];
};

export type CircularItemActivity = {
  __typename?: 'CircularItemActivity';
  activityType: ItemActivityType;
  circularItemId: Scalars['ID'];
  createdTimestamp: Scalars['DateTime'];
  /** When activity work ended */
  endTime: Scalars['DateTime'];
  /** Item activity can have an associated grade if activity led to change of grade */
  grade: Maybe<CircularItemGrade>;
  id: Scalars['ID'];
  /** When activity work started */
  startTime: Scalars['DateTime'];
  user: Maybe<User>;
  userId: Scalars['String'];
};

export type CircularItemConnection = {
  __typename?: 'CircularItemConnection';
  edges: Maybe<Array<Maybe<CircularItemEdge>>>;
  pageInfo: Maybe<PageInfo>;
};

export type CircularItemDetermineDiscountInput = {
  grade: GradeValue;
  itemNumber: Scalars['String'];
};

/** An edge in CircularItemConnection. */
export type CircularItemEdge = {
  __typename?: 'CircularItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CircularItem>;
};

export type CircularItemFilter = {
  status?: InputMaybe<Scalars['String']>;
};

export type CircularItemGrade = {
  __typename?: 'CircularItemGrade';
  activity: Maybe<CircularItemActivity>;
  circularItemId: Scalars['ID'];
  createdTimestamp: Scalars['DateTime'];
  id: Scalars['ID'];
  user: Maybe<User>;
  userId: Scalars['String'];
  value: GradeValue;
};

export type CircularItemImage = {
  __typename?: 'CircularItemImage';
  id: Scalars['ID'];
  image: ImageMediaItem;
};

/** Targeted url and information for uploading a new circular item image */
export type CircularItemImageUpload = {
  __typename?: 'CircularItemImageUpload';
  expires: Scalars['DateTime'];
  filename: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type CircularItemOrderBy = {
  eligibleForFAAS?: InputMaybe<SortOrderType>;
  globalProductName?: InputMaybe<SortOrderType>;
  itemNumber?: InputMaybe<SortOrderType>;
  itemType?: InputMaybe<SortOrderType>;
  modifiedDate?: InputMaybe<SortOrderType>;
  registerDate?: InputMaybe<SortOrderType>;
};

export type CircularItemSale = {
  __typename?: 'CircularItemSale';
  amount: Scalars['Float'];
  circularItemId: Scalars['ID'];
  createdById: Scalars['ID'];
  createdByUser: Maybe<User>;
  currencyCode: Scalars['String'];
  id: Scalars['ID'];
  saleTimestamp: Scalars['DateTime'];
};

export type CircularItemTag = {
  __typename?: 'CircularItemTag';
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  value: Scalars['String'];
};

export type CircularItemsCollection = {
  __typename?: 'CircularItemsCollection';
  items: Maybe<Array<Maybe<CircularItem>>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

/** Place where this item was initially collected and processed and where it currently is hold */
export type CircularProcessCenter = {
  __typename?: 'CircularProcessCenter';
  address: Maybe<Address>;
  /** Example: SEK, USD etc. */
  defaultCurrencyCode: Scalars['String'];
  /** Sometime reffered as 'CPC Code' */
  id: Scalars['ID'];
  name: Scalars['String'];
  retailUnitCode: Scalars['String'];
};

export type CreateActivityBulkInput = {
  activities: Array<ActivityBulkInput>;
  circularItemIds: Array<Scalars['ID']>;
};

export type CreateBatchOfItemsInput = {
  comment?: InputMaybe<Scalars['String']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
  itemInput: CreateCircularItemInput;
  quantity: Scalars['Int'];
  sourcingTimestamp: Scalars['DateTime'];
};

/** DEPREACTED */
export type CreateBatchedItemsInput = {
  cpcId: Scalars['ID'];
  existingBatch: Scalars['Boolean'];
  quantity: Scalars['Int'];
  retailUnitCode: Scalars['String'];
  sourceId?: InputMaybe<Scalars['ID']>;
  sourcedBatchId?: InputMaybe<Scalars['ID']>;
  sourcingTimestamp: Scalars['DateTime'];
};

export type CreateBatchedItemsResult = {
  __typename?: 'CreateBatchedItemsResult';
  newCircularItems: Array<CircularItem>;
  sourcedBatch: SourcedBatch;
};

export type CreateCircularItemInput = {
  FAASCustomer?: InputMaybe<Scalars['String']>;
  businessStructure?: InputMaybe<RetailItemBusinessStructureInput>;
  /** Associated the product with a CPC, where it currently located */
  cpcId: Scalars['ID'];
  eligibleForFAAS?: InputMaybe<Scalars['Boolean']>;
  estimatedSalesPrice?: InputMaybe<Scalars['Float']>;
  /** Item number of the IKEA item */
  itemNumber?: InputMaybe<Scalars['String']>;
  manufacturingDate?: InputMaybe<Scalars['YearWeek']>;
  productDescription?: InputMaybe<Scalars['String']>;
  /** Name of the IKEA product (ex. Billy Bookcase) */
  productName?: InputMaybe<Scalars['String']>;
  regularItemSalesPriceCurrencyCode?: InputMaybe<Scalars['String']>;
  regularItemSalesPriceInclTax?: InputMaybe<Scalars['Float']>;
  retailUnitCode: Scalars['String'];
  sekundReference?: InputMaybe<Scalars['String']>;
  sgfLocation?: InputMaybe<SgfLocationInput>;
  sourceId?: InputMaybe<Scalars['ID']>;
  sourcedBatchId?: InputMaybe<Scalars['ID']>;
  supplierNumber?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The uploaded filenames (not the URL's) */
  uploadedFilenames?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateItemsInExistingBatchInput = {
  itemInput: CreateCircularItemInput;
  quantity: Scalars['Int'];
  sourcedBatchId: Scalars['ID'];
  sourcingTimestamp: Scalars['DateTime'];
};

export type CreateSaleInput = {
  amount: Scalars['Float'];
  circularItemId: Scalars['ID'];
  currencyCode: Scalars['String'];
  saleTimestamp: Scalars['DateTime'];
};

export type CreateShipmentInput = {
  carrier?: InputMaybe<Scalars['String']>;
  circularItemIds: Array<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  cpcId: Scalars['ID'];
  euroPalletCount?: InputMaybe<Scalars['Int']>;
  existingExternalReference: Scalars['Boolean'];
  externalReference: Scalars['String'];
  longPalletCount?: InputMaybe<Scalars['Int']>;
};

export type CreateSourceInput = {
  cpcId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateUserInput = {
  cpcIds: Array<Scalars['ID']>;
  email: Scalars['String'];
  name: Scalars['String'];
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  password: Scalars['String'];
  user: User;
};

export type ExternalReference = {
  __typename?: 'ExternalReference';
  circularItemIdsAtReference: Array<Scalars['ID']>;
  circularItemIdsReturnedFromReference: Array<Scalars['ID']>;
  circularItemReturnedFromReference: Array<CircularItem>;
  circularItemsAtReference: Array<CircularItem>;
  cpc: CircularProcessCenter;
  cpcId: Scalars['ID'];
  createdTimestamp: Scalars['DateTime'];
  externalReference: Scalars['String'];
  id: Scalars['ID'];
  shippedShipments: Array<Shipment>;
};

export type GradeItemInput = {
  activityId?: InputMaybe<Scalars['ID']>;
  circularItemId: Scalars['ID'];
  grade: GradeValue;
};

export type GradeValue = 'A' | 'B' | 'C' | 'New' | 'Recycle';

export type HomeFurnishingBusiness = {
  __typename?: 'HomeFurnishingBusiness';
  name: Maybe<Scalars['String']>;
  number: Maybe<Scalars['String']>;
  productRanges: Maybe<Array<ProductRangeArea>>;
};

export type ImageMimeContentType =
  /** Content type image/jpeg */
  | 'IMAGE_JPEG'
  /** Content type image/png */
  | 'IMAGE_PNG';

export type ImageMediaItem = {
  __typename?: 'ImageMediaItem';
  fileMimeType: Scalars['String'];
  fileName: Scalars['String'];
  height: Maybe<Scalars['Int']>;
  href: Scalars['String'];
  quality: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Int']>;
};

export type ItemActivityType = {
  __typename?: 'ItemActivityType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addShipmentItems: Shipment;
  assignItemsAndCreateNewSourcedBatch: SourcedBatch;
  assignItemsToSourcedBatch: SourcedBatch;
  /** Determine a discount percentage for item. Returns in decimal format (0.1 == 10%) */
  circularItemDetermineDiscount: Maybe<Scalars['Float']>;
  createActivitiesInBulk: Array<CircularItemActivity>;
  createBatchOfItems: CreateBatchedItemsResult;
  /** DEPREACTED */
  createBatchedItems: CreateBatchedItemsResult;
  /** Create circular item and verify towards the current selling range */
  createCircularItem: Maybe<CircularItem>;
  createItemsInExistingBatch: CreateBatchedItemsResult;
  createSale: CircularItemSale;
  createShipment: Shipment;
  createSource: Source;
  /** Create tag associated with a ciruclar item. **Returns** list of circular items current tags */
  createTag: Maybe<Array<Maybe<CircularItemTag>>>;
  createUser: Maybe<CreateUserResponse>;
  deleteSource: Maybe<Scalars['ID']>;
  gradeItem: Maybe<CircularItemGrade>;
  login: Maybe<Scalars['String']>;
  removeCircularItem: Maybe<Scalars['ID']>;
  removeCircularItemImage: Maybe<Scalars['ID']>;
  removeItemActivity: Scalars['ID'];
  removeShipment: Scalars['ID'];
  removeShipmentItems: Shipment;
  /** Remove tag associated with a ciruclar item. **Returns** list of circular items current tags */
  removeTag: Maybe<Array<Maybe<CircularItemTag>>>;
  resetPassword: Maybe<ResetPasswordResponse>;
  shipShipment: Shipment;
  updateCircularItem: Maybe<CircularItem>;
  updateItemActivity: CircularItemActivity;
  updateShipment: Shipment;
  updateSourcedBatch: SourcedBatch;
  updateStatuses: Array<StatusTransition>;
  updateUser: Maybe<User>;
};

export type MutationAddShipmentItemsArgs = {
  input: AddShipmentItemsInput;
};

export type MutationAssignItemsAndCreateNewSourcedBatchArgs = {
  input: AssignItemsAndCreateNewSourcedBatchInput;
};

export type MutationAssignItemsToSourcedBatchArgs = {
  input: AssignItemsToSourcedBatchInput;
};

export type MutationCircularItemDetermineDiscountArgs = {
  input: CircularItemDetermineDiscountInput;
};

export type MutationCreateActivitiesInBulkArgs = {
  input: CreateActivityBulkInput;
};

export type MutationCreateBatchOfItemsArgs = {
  input: CreateBatchOfItemsInput;
};

export type MutationCreateBatchedItemsArgs = {
  input: CreateBatchedItemsInput;
};

export type MutationCreateCircularItemArgs = {
  input: CreateCircularItemInput;
};

export type MutationCreateItemsInExistingBatchArgs = {
  input: CreateItemsInExistingBatchInput;
};

export type MutationCreateSaleArgs = {
  input: CreateSaleInput;
};

export type MutationCreateShipmentArgs = {
  input: CreateShipmentInput;
};

export type MutationCreateSourceArgs = {
  input: CreateSourceInput;
};

export type MutationCreateTagArgs = {
  circularItemId: Scalars['ID'];
  tag: Scalars['String'];
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteSourceArgs = {
  sourceId: Scalars['ID'];
};

export type MutationGradeItemArgs = {
  input: GradeItemInput;
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationRemoveCircularItemArgs = {
  circularItemId: Scalars['ID'];
};

export type MutationRemoveCircularItemImageArgs = {
  circularItemImageId: Scalars['ID'];
};

export type MutationRemoveItemActivityArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveShipmentArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveShipmentItemsArgs = {
  input: RemoveShipmentItemsInput;
};

export type MutationRemoveTagArgs = {
  circularItemId: Scalars['ID'];
  tagId: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationShipShipmentArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateCircularItemArgs = {
  circularItemId: Scalars['ID'];
  input: UpdateCircularItemInput;
};

export type MutationUpdateItemActivityArgs = {
  id: Scalars['ID'];
  input: UpdateItemActivityInput;
};

export type MutationUpdateShipmentArgs = {
  id: Scalars['ID'];
  input: UpdateShipmentInput;
};

export type MutationUpdateSourcedBatchArgs = {
  id: Scalars['ID'];
  input: UpdateSourcedBatchInput;
};

export type MutationUpdateStatusesArgs = {
  circularItemIds: Array<Scalars['ID']>;
  newStatus: Status;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  userId: Scalars['ID'];
};

export type Node = {
  id: Scalars['ID'];
};

export type Note = {
  __typename?: 'Note';
  createdById: Scalars['ID'];
  createdByUser: Maybe<User>;
  createdTimestamp: Scalars['DateTime'];
  id: Scalars['ID'];
  lastUpdatedById: Scalars['ID'];
  lastUpdatedByUser: Maybe<User>;
  text: Maybe<Scalars['String']>;
  updatedTimestamp: Scalars['DateTime'];
};

export type OrderableField = 'createdTimestamp';

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type ProductArea = {
  __typename?: 'ProductArea';
  name: Maybe<Scalars['String']>;
  number: Maybe<Scalars['String']>;
};

export type ProductRangeArea = {
  __typename?: 'ProductRangeArea';
  name: Maybe<Scalars['String']>;
  number: Maybe<Scalars['String']>;
  productAreas: Maybe<Array<ProductArea>>;
};

export type Query = {
  __typename?: 'Query';
  availableHFBs: Maybe<Array<Maybe<HomeFurnishingBusiness>>>;
  circularItem: Maybe<CircularItem>;
  /** Create a signed url to upload image, URL expires within 1 minute */
  circularItemImageUploadUrl: Maybe<CircularItemImageUpload>;
  circularItems: Maybe<CircularItemConnection>;
  circularItemsCollection: Maybe<CircularItemsCollection>;
  circularProcessCenter: Maybe<CircularProcessCenter>;
  /** List all registred Circular process centers ('CPC') */
  circularProcessCenters: Maybe<Array<Maybe<CircularProcessCenter>>>;
  /** Get all unique tags that have been registred in the system */
  distinctCircularItemTags: Maybe<Array<Maybe<Scalars['String']>>>;
  externalReference: Maybe<ExternalReference>;
  externalReferences: Array<ExternalReference>;
  grades: Maybe<Array<Maybe<CircularItemGrade>>>;
  itemActivities: Maybe<Array<Maybe<CircularItemActivity>>>;
  itemActivityTypes: Maybe<Array<Maybe<ItemActivityType>>>;
  me: Maybe<User>;
  resyncStatusesJob: Maybe<Scalars['Boolean']>;
  retailItem: Maybe<RetailItem>;
  shipment: Maybe<Shipment>;
  shipmentsCollection: ShipmentCollection;
  sourcedBatch: Maybe<SourcedBatch>;
  sourcedBatchCollection: SourcedBatchCollection;
  sources: Array<Source>;
  statusTransitions: Array<StatusTransition>;
};

export type QueryCircularItemArgs = {
  circularItemId: Scalars['ID'];
};

export type QueryCircularItemImageUploadUrlArgs = {
  imageContentType: ImageMimeContentType;
};

export type QueryCircularItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cpcId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CircularItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrderType>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type QueryCircularItemsCollectionArgs = {
  cpcId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CircularItemFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<CircularItemOrderBy>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type QueryCircularProcessCenterArgs = {
  cpcId: Scalars['ID'];
};

export type QueryExternalReferenceArgs = {
  id: Scalars['ID'];
};

export type QueryExternalReferencesArgs = {
  cpcId: Scalars['ID'];
};

export type QueryGradesArgs = {
  circularItemId: Scalars['ID'];
};

export type QueryItemActivitiesArgs = {
  circularItemId: Scalars['ID'];
};

export type QueryRetailItemArgs = {
  itemNumber: Scalars['String'];
  retailUnitCode: Scalars['String'];
};

export type QueryShipmentArgs = {
  id: Scalars['ID'];
};

export type QueryShipmentsCollectionArgs = {
  cpcId: Scalars['ID'];
  filter?: InputMaybe<ShipmentFilter>;
  limit?: Scalars['Int'];
  order?: ShipmentOrderBy;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
};

export type QuerySourcedBatchArgs = {
  id: Scalars['ID'];
};

export type QuerySourcedBatchCollectionArgs = {
  cpcId: Scalars['ID'];
  filter?: InputMaybe<SourcedBatchFilter>;
  limit?: Scalars['Int'];
  order?: SourcedBatchOrderBy;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
};

export type QuerySourcesArgs = {
  cpcId: Scalars['ID'];
};

export type RemoveShipmentItemsInput = {
  circularItemIds: Array<Scalars['ID']>;
  shipmentId: Scalars['ID'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  password: Scalars['String'];
  user: User;
};

export type RetailItem = {
  __typename?: 'RetailItem';
  businessStructure: Maybe<RetailItemBusinessStructure>;
  detailedMeasurements: Maybe<Array<Maybe<RetailItemDetailedMeasurement>>>;
  globalProductName: Maybe<Scalars['String']>;
  itemNumber: Scalars['String'];
  itemType: RetailItemType;
  mainProductImages: Maybe<Array<Maybe<ImageMediaItem>>>;
  productDescription: Maybe<Scalars['String']>;
  regularItemSalesPriceCurrencyCode: Maybe<Scalars['String']>;
  regularItemSalesPriceInclTax: Maybe<Scalars['Float']>;
  retailUnitCode: Scalars['String'];
};

export type RetailItemBusinessStructure = {
  __typename?: 'RetailItemBusinessStructure';
  homeFurnishingBusinessName: Maybe<Scalars['String']>;
  homeFurnishingBusinessNumber: Maybe<Scalars['String']>;
  productAreaName: Maybe<Scalars['String']>;
  productAreaNumber: Maybe<Scalars['String']>;
  productRangeAreaName: Maybe<Scalars['String']>;
  productRangeAreaNumber: Maybe<Scalars['String']>;
};

export type RetailItemBusinessStructureInput = {
  homeFurnishingBusinessName?: InputMaybe<Scalars['String']>;
  homeFurnishingBusinessNumber?: InputMaybe<Scalars['String']>;
  productAreaName?: InputMaybe<Scalars['String']>;
  productAreaNumber?: InputMaybe<Scalars['String']>;
  productRangeAreaName?: InputMaybe<Scalars['String']>;
  productRangeAreaNumber?: InputMaybe<Scalars['String']>;
};

export type RetailItemDetailedMeasurement = {
  __typename?: 'RetailItemDetailedMeasurement';
  textImperial: Scalars['String'];
  textMetric: Scalars['String'];
  typeName: Scalars['String'];
};

export type RetailItemType = 'ART' | 'SGR' | 'SPR';

export type SgfLocation = {
  __typename?: 'SGFLocation';
  bay: Maybe<Scalars['String']>;
  level: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
};

export type SgfLocationInput = {
  bay?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
};

export type Shipment = {
  __typename?: 'Shipment';
  carrier: Maybe<Scalars['String']>;
  circularItemIds: Array<Scalars['ID']>;
  circularItems: Array<CircularItem>;
  comment: Scalars['String'];
  cpc: CircularProcessCenter;
  cpcId: Scalars['ID'];
  createdBy: User;
  createdById: Scalars['ID'];
  createdTimestamp: Scalars['DateTime'];
  euroPalletCount: Maybe<Scalars['Int']>;
  externalReference: Maybe<Scalars['String']>;
  externalReferenceId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  lastUpdatedBy: User;
  lastUpdatedById: Scalars['ID'];
  longPalletCount: Maybe<Scalars['Int']>;
  shippedTimestamp: Maybe<Scalars['DateTime']>;
  status: ShipmentStatus;
  updatedTimestamp: Scalars['DateTime'];
};

export type ShipmentCollection = {
  __typename?: 'ShipmentCollection';
  items: Array<Shipment>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ShipmentFilter = {
  status?: InputMaybe<ShipmentStatus>;
};

export type ShipmentOrderBy = {
  field: OrderableField;
  order: SortOrderType;
};

export type ShipmentStatus = 'shipped' | 'unshipped';

export type SortOrderType = 'ASC' | 'DESC';

/** Available places where a physical product is sourced */
export type Source = {
  __typename?: 'Source';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SourcedBatch = {
  __typename?: 'SourcedBatch';
  circularItems: Array<CircularItem>;
  circularItemsCount: Scalars['Int'];
  comment: Scalars['String'];
  created: Scalars['DateTime'];
  durationInMinutes: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  source: Source;
};

export type SourcedBatchCollection = {
  __typename?: 'SourcedBatchCollection';
  items: Array<SourcedBatch>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SourcedBatchFilter = {
  source: Scalars['String'];
};

export type SourcedBatchOrderBy = {
  field: SourcedBatchOrderableField;
  order: SortOrderType;
};

export type SourcedBatchOrderableField = 'created';

export type Status = 'available' | 'departed' | 'pending' | 'reserved';

export type StatusTransition = {
  __typename?: 'StatusTransition';
  circularItemId: Scalars['ID'];
  id: Scalars['ID'];
  inducedById: Maybe<Scalars['ID']>;
  status: Status;
  transitionTimestamp: Scalars['DateTime'];
};

export type UpdateCircularItemInput = {
  FAASCustomer?: InputMaybe<Scalars['String']>;
  businessStructure?: InputMaybe<RetailItemBusinessStructureInput>;
  cpcId: Scalars['ID'];
  eligibleForFAAS?: InputMaybe<Scalars['Boolean']>;
  estimatedSalesPrice?: InputMaybe<Scalars['Float']>;
  /** Item number of the IKEA item. NOTE! If item is verified, changeing this will throw an error */
  itemNumber?: InputMaybe<Scalars['String']>;
  manufacturingDate?: InputMaybe<Scalars['YearWeek']>;
  note?: InputMaybe<Scalars['String']>;
  productDescription?: InputMaybe<Scalars['String']>;
  /** Name of the IKEA product (ex. Billy Bookcase) */
  productName?: InputMaybe<Scalars['String']>;
  regularItemSalesPriceCurrencyCode?: InputMaybe<Scalars['String']>;
  regularItemSalesPriceInclTax?: InputMaybe<Scalars['Float']>;
  sekundReference?: InputMaybe<Scalars['String']>;
  sgfLocation?: InputMaybe<SgfLocationInput>;
  sourceId?: InputMaybe<Scalars['ID']>;
  supplierNumber?: InputMaybe<Scalars['Int']>;
  /** The uploaded filenames (not the URL's) */
  uploadedFilenames?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateItemActivityInput = {
  activityTypeId: Scalars['ID'];
  executionTimeInMins: Scalars['Int'];
  grade?: InputMaybe<GradeValue>;
};

export type UpdateShipmentInput = {
  carrier?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  euroPalletCount?: InputMaybe<Scalars['Int']>;
  longPalletCount?: InputMaybe<Scalars['Int']>;
};

export type UpdateSourcedBatchInput = {
  comment?: InputMaybe<Scalars['String']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserInput = {
  /** Update the list of associated CPC's to the user. Need to pass all current ID's as well */
  cpcIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  cpcIds: Array<Scalars['ID']>;
  cpcs: Array<Maybe<CircularProcessCenter>>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Core__Purpose =
  /** `EXECUTION` features provide metadata necessary to for operation execution. */
  | 'EXECUTION'
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  | 'SECURITY';

export type Join__Graph =
  | 'ACTIVITIES'
  | 'ORDER_MANAGEMENT'
  | 'PRODUCT'
  | 'USER';

export type AddShipmentItemsMutationVariables = Exact<{
  input: AddShipmentItemsInput;
}>;

export type AddShipmentItemsMutation = {
  __typename?: 'Mutation';
  addShipmentItems: {
    __typename?: 'Shipment';
    id: string;
    circularItems: Array<{
      __typename?: 'CircularItem';
      id: string;
      status: Status | null;
      assignedShipment: { __typename?: 'Shipment'; id: string } | null;
      atExternalReference: {
        __typename?: 'ExternalReference';
        id: string;
        externalReference: string;
      } | null;
    }>;
  };
};

export type AssignItemsAndCreateNewSourcedBatchMutationVariables = Exact<{
  input: AssignItemsAndCreateNewSourcedBatchInput;
}>;

export type AssignItemsAndCreateNewSourcedBatchMutation = {
  __typename?: 'Mutation';
  assignItemsAndCreateNewSourcedBatch: {
    __typename?: 'SourcedBatch';
    created: Date;
    id: string;
    circularItems: Array<{ __typename?: 'CircularItem'; id: string }>;
    source: { __typename?: 'Source'; id: string };
  };
};

export type AssignItemsToSourcedBatchMutationVariables = Exact<{
  input: AssignItemsToSourcedBatchInput;
}>;

export type AssignItemsToSourcedBatchMutation = {
  __typename?: 'Mutation';
  assignItemsToSourcedBatch: {
    __typename?: 'SourcedBatch';
    created: Date;
    id: string;
    circularItems: Array<{ __typename?: 'CircularItem'; id: string }>;
    source: { __typename?: 'Source'; id: string };
  };
};

export type CircularItemDetermineDiscountMutationVariables = Exact<{
  input: CircularItemDetermineDiscountInput;
}>;

export type CircularItemDetermineDiscountMutation = {
  __typename?: 'Mutation';
  circularItemDetermineDiscount: number | null;
};

export type CreateActivitiesInBulkMutationVariables = Exact<{
  input: CreateActivityBulkInput;
}>;

export type CreateActivitiesInBulkMutation = {
  __typename?: 'Mutation';
  createActivitiesInBulk: Array<{
    __typename?: 'CircularItemActivity';
    id: string;
    circularItemId: string;
    createdTimestamp: Date;
    startTime: Date;
    endTime: Date;
    userId: string;
    activityType: { __typename?: 'ItemActivityType'; id: string };
    grade: {
      __typename?: 'CircularItemGrade';
      id: string;
      value: GradeValue;
    } | null;
  }>;
};

export type CreateBatchOfItemsMutationVariables = Exact<{
  input: CreateBatchOfItemsInput;
}>;

export type CreateBatchOfItemsMutation = {
  __typename?: 'Mutation';
  createBatchOfItems: {
    __typename?: 'CreateBatchedItemsResult';
    sourcedBatch: {
      __typename?: 'SourcedBatch';
      created: Date;
      circularItems: Array<{
        __typename?: 'CircularItem';
        id: string;
        labelImageUrl: string | null;
        status: Status | null;
      }>;
    };
    newCircularItems: Array<{
      __typename?: 'CircularItem';
      id: string;
      labelImageUrl: string | null;
    }>;
  };
};

export type CreateBatchedItemsMutationVariables = Exact<{
  input: CreateBatchedItemsInput;
}>;

export type CreateBatchedItemsMutation = {
  __typename?: 'Mutation';
  createBatchedItems: {
    __typename?: 'CreateBatchedItemsResult';
    sourcedBatch: {
      __typename?: 'SourcedBatch';
      created: Date;
      circularItems: Array<{
        __typename?: 'CircularItem';
        id: string;
        labelImageUrl: string | null;
        status: Status | null;
      }>;
    };
    newCircularItems: Array<{
      __typename?: 'CircularItem';
      labelImageUrl: string | null;
    }>;
  };
};

export type CreateCircularItemMutationVariables = Exact<{
  input: CreateCircularItemInput;
}>;

export type CreateCircularItemMutation = {
  __typename?: 'Mutation';
  createCircularItem: {
    __typename?: 'CircularItem';
    id: string;
    labelImageUrl: string | null;
    status: Status | null;
  } | null;
};

export type CreateItemsInExistingBatchMutationVariables = Exact<{
  input: CreateItemsInExistingBatchInput;
}>;

export type CreateItemsInExistingBatchMutation = {
  __typename?: 'Mutation';
  createItemsInExistingBatch: {
    __typename?: 'CreateBatchedItemsResult';
    sourcedBatch: {
      __typename?: 'SourcedBatch';
      created: Date;
      circularItems: Array<{
        __typename?: 'CircularItem';
        id: string;
        labelImageUrl: string | null;
        status: Status | null;
      }>;
    };
    newCircularItems: Array<{
      __typename?: 'CircularItem';
      id: string;
      labelImageUrl: string | null;
    }>;
  };
};

export type CreateSaleMutationVariables = Exact<{
  input: CreateSaleInput;
}>;

export type CreateSaleMutation = {
  __typename?: 'Mutation';
  createSale: {
    __typename: 'CircularItemSale';
    id: string;
    createdById: string;
    circularItemId: string;
    amount: number;
    saleTimestamp: Date;
    currencyCode: string;
    createdByUser: { __typename?: 'User'; name: string } | null;
  };
};

export type CreateShipmentMutationVariables = Exact<{
  input: CreateShipmentInput;
}>;

export type CreateShipmentMutation = {
  __typename?: 'Mutation';
  createShipment: {
    __typename?: 'Shipment';
    id: string;
    circularItems: Array<{
      __typename?: 'CircularItem';
      id: string;
      status: Status | null;
      assignedShipment: { __typename?: 'Shipment'; id: string } | null;
      atExternalReference: {
        __typename?: 'ExternalReference';
        id: string;
        externalReference: string;
      } | null;
    }>;
  };
};

export type CreateSourceMutationVariables = Exact<{
  input: CreateSourceInput;
}>;

export type CreateSourceMutation = {
  __typename?: 'Mutation';
  createSource: { __typename: 'Source'; id: string; name: string };
};

export type DeleteSourceMutationVariables = Exact<{
  sourceId: Scalars['ID'];
}>;

export type DeleteSourceMutation = {
  __typename?: 'Mutation';
  deleteSource: string | null;
};

export type GradeItemMutationVariables = Exact<{
  input: GradeItemInput;
}>;

export type GradeItemMutation = {
  __typename?: 'Mutation';
  gradeItem: {
    __typename?: 'CircularItemGrade';
    id: string;
    circularItemId: string;
    userId: string;
    value: GradeValue;
    activity: { __typename?: 'CircularItemActivity'; id: string } | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'Mutation'; login: string | null };

export type RemoveCircularItemMutationVariables = Exact<{
  circularItemId: Scalars['ID'];
}>;

export type RemoveCircularItemMutation = {
  __typename?: 'Mutation';
  removeCircularItem: string | null;
};

export type RemoveItemActivityMutationVariables = Exact<{
  activityId: Scalars['ID'];
}>;

export type RemoveItemActivityMutation = {
  __typename?: 'Mutation';
  removeItemActivity: string;
};

export type RemoveShipmentMutationVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;

export type RemoveShipmentMutation = {
  __typename?: 'Mutation';
  removeShipment: string;
};

export type RemoveShipmentItemsMutationVariables = Exact<{
  input: RemoveShipmentItemsInput;
}>;

export type RemoveShipmentItemsMutation = {
  __typename?: 'Mutation';
  removeShipmentItems: { __typename?: 'Shipment'; id: string };
};

export type ShipShipmentMutationVariables = Exact<{
  shipmentId: Scalars['ID'];
}>;

export type ShipShipmentMutation = {
  __typename?: 'Mutation';
  shipShipment: { __typename?: 'Shipment'; id: string };
};

export type UpdateCircularItemMutationVariables = Exact<{
  circularItemId: Scalars['ID'];
  input: UpdateCircularItemInput;
}>;

export type UpdateCircularItemMutation = {
  __typename?: 'Mutation';
  updateCircularItem: { __typename?: 'CircularItem'; id: string } | null;
};

export type UpdateItemActivityMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateItemActivityInput;
}>;

export type UpdateItemActivityMutation = {
  __typename?: 'Mutation';
  updateItemActivity: {
    __typename?: 'CircularItemActivity';
    id: string;
    circularItemId: string;
    userId: string;
    startTime: Date;
    endTime: Date;
    createdTimestamp: Date;
    user: { __typename?: 'User'; id: string; name: string } | null;
    activityType: { __typename?: 'ItemActivityType'; id: string; name: string };
    grade: { __typename?: 'CircularItemGrade'; value: GradeValue } | null;
  };
};

export type UpdateShipmentMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateShipmentInput;
}>;

export type UpdateShipmentMutation = {
  __typename?: 'Mutation';
  updateShipment: {
    __typename?: 'Shipment';
    id: string;
    carrier: string | null;
    longPalletCount: number | null;
    euroPalletCount: number | null;
    comment: string;
  };
};

export type UpdateSourcedBatchMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateSourcedBatchInput;
}>;

export type UpdateSourcedBatchMutation = {
  __typename?: 'Mutation';
  updateSourcedBatch: {
    __typename?: 'SourcedBatch';
    id: string;
    durationInMinutes: number | null;
    comment: string;
  };
};

export type SetItemStatusesMutationVariables = Exact<{
  circularItemIds: Array<Scalars['ID']> | Scalars['ID'];
  newStatus: Status;
}>;

export type SetItemStatusesMutation = {
  __typename?: 'Mutation';
  updateStatuses: Array<{
    __typename?: 'StatusTransition';
    id: string;
    status: Status;
    circularItemId: string;
  }>;
};

export type ActivityTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityTypesQuery = {
  __typename?: 'Query';
  itemActivityTypes: Array<{
    __typename?: 'ItemActivityType';
    id: string;
    name: string;
  } | null> | null;
};

export type AvailableHfBsQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableHfBsQuery = {
  __typename?: 'Query';
  availableHFBs: Array<{
    __typename?: 'HomeFurnishingBusiness';
    name: string | null;
    number: string | null;
    productRanges: Array<{
      __typename?: 'ProductRangeArea';
      name: string | null;
      number: string | null;
      productAreas: Array<{
        __typename?: 'ProductArea';
        name: string | null;
        number: string | null;
      }> | null;
    }> | null;
  } | null> | null;
};

export type CircularItemQueryVariables = Exact<{
  circularItemId: Scalars['ID'];
}>;

export type CircularItemQuery = {
  __typename?: 'Query';
  circularItem: {
    __typename?: 'CircularItem';
    id: string;
    itemType: RetailItemType | null;
    userId: string;
    sekundReference: string | null;
    manufacturingDate: any | null;
    registerDate: Date | null;
    modifiedDate: Date | null;
    regularItemSalesPriceInclTax: number | null;
    regularItemSalesPriceCurrencyCode: string | null;
    FAASCustomer: string | null;
    eligibleForFAAS: boolean | null;
    estimatedSalesPrice: number | null;
    sourcingTimestamp: Date | null;
    labelImageUrl: string | null;
    status: Status | null;
    itemNumber: string | null;
    globalProductName: string | null;
    productDescription: string | null;
    supplierNumber: number | null;
    user: { __typename?: 'User'; id: string; name: string } | null;
    source: { __typename?: 'Source'; id: string; name: string } | null;
    cpc: {
      __typename?: 'CircularProcessCenter';
      id: string;
      defaultCurrencyCode: string;
    } | null;
    sgfLocation: {
      __typename?: 'SGFLocation';
      bay: string | null;
      level: string | null;
      location: string | null;
    } | null;
    tags: Array<{
      __typename?: 'CircularItemTag';
      value: string;
      id: string;
    } | null> | null;
    currentGrade: {
      __typename?: 'CircularItemGrade';
      value: GradeValue;
    } | null;
    initialGrade: {
      __typename?: 'CircularItemGrade';
      value: GradeValue;
    } | null;
    retailItem: {
      __typename?: 'RetailItem';
      itemNumber: string;
      mainProductImages: Array<{
        __typename?: 'ImageMediaItem';
        quality: string | null;
        href: string;
      } | null> | null;
    } | null;
    note: {
      __typename?: 'Note';
      text: string | null;
      updatedTimestamp: Date;
      lastUpdatedByUser: {
        __typename?: 'User';
        id: string;
        name: string;
      } | null;
    } | null;
    images: Array<{
      __typename?: 'CircularItemImage';
      id: string;
      image: { __typename?: 'ImageMediaItem'; href: string };
    }> | null;
    businessStructure: {
      __typename?: 'RetailItemBusinessStructure';
      productAreaNumber: string | null;
      productAreaName: string | null;
      productRangeAreaNumber: string | null;
      productRangeAreaName: string | null;
      homeFurnishingBusinessNumber: string | null;
      homeFurnishingBusinessName: string | null;
    } | null;
    shipments: Array<{
      __typename?: 'Shipment';
      id: string;
      status: ShipmentStatus;
    }>;
    sourcedBatch: {
      __typename?: 'SourcedBatch';
      id: string;
      created: Date;
    } | null;
    atExternalReference: {
      __typename?: 'ExternalReference';
      id: string;
      externalReference: string;
    } | null;
    assignedShipment: { __typename?: 'Shipment'; id: string } | null;
    sales: Array<{
      __typename: 'CircularItemSale';
      id: string;
      createdById: string;
      amount: number;
      saleTimestamp: Date;
      currencyCode: string;
      createdByUser: { __typename?: 'User'; name: string } | null;
    }>;
  } | null;
};

export type CircularItemImageUploadUrlQueryVariables = Exact<{
  imageContentType: ImageMimeContentType;
}>;

export type CircularItemImageUploadUrlQuery = {
  __typename?: 'Query';
  circularItemImageUploadUrl: {
    __typename?: 'CircularItemImageUpload';
    uploadUrl: string;
    filename: string;
    expires: Date;
  } | null;
};

export type CircularItemsCollectionQueryVariables = Exact<{
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  order?: InputMaybe<CircularItemOrderBy>;
  searchTerm?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CircularItemFilter>;
  cpcId?: InputMaybe<Scalars['ID']>;
}>;

export type CircularItemsCollectionQuery = {
  __typename?: 'Query';
  circularItemsCollection: {
    __typename?: 'CircularItemsCollection';
    total: number;
    items: Array<{
      __typename?: 'CircularItem';
      id: string;
      itemNumber: string | null;
      status: Status | null;
      globalProductName: string | null;
      productDescription: string | null;
      estimatedSalesPrice: number | null;
      estimatedSalesPriceCurrencyCode: string | null;
      regularItemSalesPriceInclTax: number | null;
      regularItemSalesPriceCurrencyCode: string | null;
      modifiedDate: Date | null;
      registerDate: Date | null;
      labelImageUrl: string | null;
      retailItem: {
        __typename?: 'RetailItem';
        itemNumber: string;
        businessStructure: {
          __typename?: 'RetailItemBusinessStructure';
          productAreaName: string | null;
          productRangeAreaName: string | null;
          homeFurnishingBusinessName: string | null;
        } | null;
        mainProductImages: Array<{
          __typename?: 'ImageMediaItem';
          quality: string | null;
          href: string;
        } | null> | null;
        detailedMeasurements: Array<{
          __typename?: 'RetailItemDetailedMeasurement';
          typeName: string;
          textMetric: string;
        } | null> | null;
      } | null;
      initialGrade: {
        __typename?: 'CircularItemGrade';
        value: GradeValue;
      } | null;
      sourcedBatch: { __typename?: 'SourcedBatch'; id: string } | null;
      currentGrade: {
        __typename?: 'CircularItemGrade';
        value: GradeValue;
      } | null;
      sgfLocation: {
        __typename?: 'SGFLocation';
        bay: string | null;
        level: string | null;
        location: string | null;
      } | null;
      businessStructure: {
        __typename?: 'RetailItemBusinessStructure';
        productAreaName: string | null;
      } | null;
      images: Array<{
        __typename?: 'CircularItemImage';
        id: string;
        image: { __typename?: 'ImageMediaItem'; href: string };
      }> | null;
      shipments: Array<{
        __typename?: 'Shipment';
        id: string;
        status: ShipmentStatus;
      }>;
    } | null> | null;
  } | null;
};

export type CircularProcessCenterQueryVariables = Exact<{
  cpcId: Scalars['ID'];
}>;

export type CircularProcessCenterQuery = {
  __typename?: 'Query';
  circularProcessCenter: {
    __typename: 'CircularProcessCenter';
    id: string;
    name: string;
  } | null;
};

export type ExternalReferenceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ExternalReferenceQuery = {
  __typename?: 'Query';
  externalReference: {
    __typename: 'ExternalReference';
    id: string;
    createdTimestamp: Date;
    cpcId: string;
    circularItemIdsReturnedFromReference: Array<string>;
    circularItemIdsAtReference: Array<string>;
    externalReference: string;
    shippedShipments: Array<{ __typename?: 'Shipment'; id: string }>;
    circularItemReturnedFromReference: Array<{
      __typename?: 'CircularItem';
      id: string;
      globalProductName: string | null;
      status: Status | null;
      currentGrade: {
        __typename?: 'CircularItemGrade';
        value: GradeValue;
      } | null;
      businessStructure: {
        __typename?: 'RetailItemBusinessStructure';
        productAreaName: string | null;
        productAreaNumber: string | null;
      } | null;
      shipments: Array<{ __typename?: 'Shipment'; id: string }>;
    }>;
    circularItemsAtReference: Array<{
      __typename?: 'CircularItem';
      id: string;
      globalProductName: string | null;
      status: Status | null;
      currentGrade: {
        __typename?: 'CircularItemGrade';
        value: GradeValue;
      } | null;
      businessStructure: {
        __typename?: 'RetailItemBusinessStructure';
        productAreaName: string | null;
        productAreaNumber: string | null;
      } | null;
      shipments: Array<{ __typename?: 'Shipment'; id: string }>;
    }>;
  } | null;
};

export type ExternalReferencesQueryVariables = Exact<{
  cpcId: Scalars['ID'];
}>;

export type ExternalReferencesQuery = {
  __typename?: 'Query';
  externalReferences: Array<{
    __typename?: 'ExternalReference';
    externalReference: string;
  }>;
};

export type GradesQueryVariables = Exact<{
  circularItemId: Scalars['ID'];
}>;

export type GradesQuery = {
  __typename?: 'Query';
  grades: Array<{
    __typename: 'CircularItemGrade';
    id: string;
    createdTimestamp: Date;
    userId: string;
    value: GradeValue;
    user: { __typename?: 'User'; name: string } | null;
    activity: { __typename?: 'CircularItemActivity'; id: string } | null;
  } | null> | null;
};

export type ItemActivitiesQueryVariables = Exact<{
  circularItemId: Scalars['ID'];
}>;

export type ItemActivitiesQuery = {
  __typename?: 'Query';
  itemActivities: Array<{
    __typename: 'CircularItemActivity';
    circularItemId: string;
    createdTimestamp: Date;
    id: string;
    startTime: Date;
    endTime: Date;
    userId: string;
    activityType: { __typename?: 'ItemActivityType'; id: string; name: string };
    grade: { __typename?: 'CircularItemGrade'; value: GradeValue } | null;
    user: { __typename?: 'User'; name: string } | null;
  } | null> | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    cpcIds: Array<string>;
    cpcs: Array<{
      __typename?: 'CircularProcessCenter';
      id: string;
      name: string;
      retailUnitCode: string;
      defaultCurrencyCode: string;
    } | null>;
  } | null;
};

export type RetailItemQueryVariables = Exact<{
  itemNumber: Scalars['String'];
  retailUnitCode: Scalars['String'];
}>;

export type RetailItemQuery = {
  __typename?: 'Query';
  retailItem: {
    __typename?: 'RetailItem';
    itemNumber: string;
    globalProductName: string | null;
    regularItemSalesPriceInclTax: number | null;
    regularItemSalesPriceCurrencyCode: string | null;
    productDescription: string | null;
    mainProductImages: Array<{
      __typename?: 'ImageMediaItem';
      quality: string | null;
      href: string;
    } | null> | null;
    businessStructure: {
      __typename?: 'RetailItemBusinessStructure';
      productAreaNumber: string | null;
      productAreaName: string | null;
      productRangeAreaNumber: string | null;
      productRangeAreaName: string | null;
      homeFurnishingBusinessNumber: string | null;
      homeFurnishingBusinessName: string | null;
    } | null;
  } | null;
};

export type ShipmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ShipmentQuery = {
  __typename?: 'Query';
  shipment: {
    __typename: 'Shipment';
    id: string;
    externalReference: string | null;
    externalReferenceId: string | null;
    circularItemIds: Array<string>;
    cpcId: string;
    lastUpdatedById: string;
    createdById: string;
    status: ShipmentStatus;
    carrier: string | null;
    longPalletCount: number | null;
    euroPalletCount: number | null;
    comment: string;
  } | null;
};

export type ShipmentWithCircularItemsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ShipmentWithCircularItemsQuery = {
  __typename?: 'Query';
  shipment: {
    __typename: 'Shipment';
    id: string;
    externalReference: string | null;
    externalReferenceId: string | null;
    circularItemIds: Array<string>;
    cpcId: string;
    lastUpdatedById: string;
    createdById: string;
    status: ShipmentStatus;
    carrier: string | null;
    longPalletCount: number | null;
    euroPalletCount: number | null;
    comment: string;
    circularItems: Array<{
      __typename?: 'CircularItem';
      id: string;
      globalProductName: string | null;
      status: Status | null;
      currentGrade: {
        __typename?: 'CircularItemGrade';
        value: GradeValue;
      } | null;
      businessStructure: {
        __typename?: 'RetailItemBusinessStructure';
        productAreaName: string | null;
        productAreaNumber: string | null;
      } | null;
    }>;
  } | null;
};

export type ShipmentsCollectionQueryVariables = Exact<{
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
  cpcId: Scalars['ID'];
  order?: InputMaybe<ShipmentOrderBy>;
  filter?: InputMaybe<ShipmentFilter>;
}>;

export type ShipmentsCollectionQuery = {
  __typename?: 'Query';
  shipmentsCollection: {
    __typename?: 'ShipmentCollection';
    total: number;
    items: Array<{
      __typename?: 'Shipment';
      id: string;
      circularItemIds: Array<string>;
      externalReference: string | null;
      externalReferenceId: string | null;
      status: ShipmentStatus;
      updatedTimestamp: Date;
      createdTimestamp: Date;
    }>;
  };
};

export type SourcedBatchQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SourcedBatchQuery = {
  __typename?: 'Query';
  sourcedBatch: {
    __typename: 'SourcedBatch';
    id: string;
    created: Date;
    comment: string;
    durationInMinutes: number | null;
    circularItems: Array<{
      __typename?: 'CircularItem';
      id: string;
      globalProductName: string | null;
      productDescription: string | null;
      registerDate: Date | null;
      status: Status | null;
      sourcingTimestamp: Date | null;
      businessStructure: {
        __typename?: 'RetailItemBusinessStructure';
        productAreaName: string | null;
      } | null;
      currentGrade: {
        __typename?: 'CircularItemGrade';
        value: GradeValue;
      } | null;
    }>;
    source: { __typename?: 'Source'; id: string; name: string };
  } | null;
};

export type SourcedBatchCollectionQueryVariables = Exact<{
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
  cpcId: Scalars['ID'];
  order?: InputMaybe<SourcedBatchOrderBy>;
  filter?: InputMaybe<SourcedBatchFilter>;
}>;

export type SourcedBatchCollectionQuery = {
  __typename?: 'Query';
  sourcedBatchCollection: {
    __typename?: 'SourcedBatchCollection';
    total: number;
    items: Array<{
      __typename?: 'SourcedBatch';
      id: string;
      created: Date;
      circularItems: Array<{ __typename?: 'CircularItem'; id: string }>;
      source: { __typename?: 'Source'; name: string };
    }>;
  };
};

export type SourcesQueryVariables = Exact<{
  cpcId: Scalars['ID'];
}>;

export type SourcesQuery = {
  __typename?: 'Query';
  sources: Array<{ __typename?: 'Source'; id: string; name: string }>;
};

export const AddShipmentItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addShipmentItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddShipmentItemsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addShipmentItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedShipment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'atExternalReference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'externalReference',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddShipmentItemsMutation,
  AddShipmentItemsMutationVariables
>;
export const AssignItemsAndCreateNewSourcedBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'assignItemsAndCreateNewSourcedBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'AssignItemsAndCreateNewSourcedBatchInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'assignItemsAndCreateNewSourcedBatch',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignItemsAndCreateNewSourcedBatchMutation,
  AssignItemsAndCreateNewSourcedBatchMutationVariables
>;
export const AssignItemsToSourcedBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'assignItemsToSourcedBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AssignItemsToSourcedBatchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignItemsToSourcedBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignItemsToSourcedBatchMutation,
  AssignItemsToSourcedBatchMutationVariables
>;
export const CircularItemDetermineDiscountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'circularItemDetermineDiscount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CircularItemDetermineDiscountInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'circularItemDetermineDiscount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CircularItemDetermineDiscountMutation,
  CircularItemDetermineDiscountMutationVariables
>;
export const CreateActivitiesInBulkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createActivitiesInBulk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateActivityBulkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createActivitiesInBulk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateActivitiesInBulkMutation,
  CreateActivitiesInBulkMutationVariables
>;
export const CreateBatchOfItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBatchOfItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBatchOfItemsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBatchOfItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourcedBatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'circularItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labelImageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newCircularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelImageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBatchOfItemsMutation,
  CreateBatchOfItemsMutationVariables
>;
export const CreateBatchedItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBatchedItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBatchedItemsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBatchedItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourcedBatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'circularItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labelImageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newCircularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelImageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBatchedItemsMutation,
  CreateBatchedItemsMutationVariables
>;
export const CreateCircularItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCircularItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCircularItemInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCircularItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelImageUrl' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCircularItemMutation,
  CreateCircularItemMutationVariables
>;
export const CreateItemsInExistingBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createItemsInExistingBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateItemsInExistingBatchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createItemsInExistingBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourcedBatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'circularItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'labelImageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newCircularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelImageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateItemsInExistingBatchMutation,
  CreateItemsInExistingBatchMutationVariables
>;
export const CreateSaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSale' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSaleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSale' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saleTimestamp' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCode' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdByUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSaleMutation, CreateSaleMutationVariables>;
export const CreateShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateShipmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignedShipment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'atExternalReference' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'externalReference',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateShipmentMutation,
  CreateShipmentMutationVariables
>;
export const CreateSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSourceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSourceMutation,
  CreateSourceMutationVariables
>;
export const DeleteSourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sourceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sourceId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSourceMutation,
  DeleteSourceMutationVariables
>;
export const GradeItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'gradeItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GradeItemInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gradeItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GradeItemMutation, GradeItemMutationVariables>;
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const RemoveCircularItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeCircularItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'circularItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCircularItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'circularItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveCircularItemMutation,
  RemoveCircularItemMutationVariables
>;
export const RemoveItemActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeItemActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeItemActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveItemActivityMutation,
  RemoveItemActivityMutationVariables
>;
export const RemoveShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveShipmentMutation,
  RemoveShipmentMutationVariables
>;
export const RemoveShipmentItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeShipmentItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveShipmentItemsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeShipmentItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveShipmentItemsMutation,
  RemoveShipmentItemsMutationVariables
>;
export const ShipShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'shipShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShipShipmentMutation,
  ShipShipmentMutationVariables
>;
export const UpdateCircularItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCircularItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'circularItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCircularItemInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCircularItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'circularItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCircularItemMutation,
  UpdateCircularItemMutationVariables
>;
export const UpdateItemActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateItemActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateItemActivityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateItemActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdTimestamp' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateItemActivityMutation,
  UpdateItemActivityMutationVariables
>;
export const UpdateShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateShipmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'longPalletCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'euroPalletCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateShipmentMutation,
  UpdateShipmentMutationVariables
>;
export const UpdateSourcedBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSourcedBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSourcedBatchInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSourcedBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationInMinutes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSourcedBatchMutation,
  UpdateSourcedBatchMutationVariables
>;
export const SetItemStatusesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setItemStatuses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'circularItemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newStatus' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Status' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStatuses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'circularItemIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'circularItemIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newStatus' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetItemStatusesMutation,
  SetItemStatusesMutationVariables
>;
export const ActivityTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'activityTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActivityTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityTypesQuery, ActivityTypesQueryVariables>;
export const AvailableHfBsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'availableHFBs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableHFBs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productRanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productAreas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableHfBsQuery, AvailableHfBsQueryVariables>;
export const CircularItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'circularItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'circularItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'circularItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'circularItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cpc' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultCurrencyCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sekundReference' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manufacturingDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registerDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'modifiedDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regularItemSalesPriceInclTax' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'regularItemSalesPriceCurrencyCode',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FAASCustomer' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eligibleForFAAS' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'estimatedSalesPrice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourcingTimestamp' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sgfLocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bay' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelImageUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'initialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'globalProductName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'retailItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainProductImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quality' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'supplierNumber' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'note' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastUpdatedByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessStructure' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productAreaNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productAreaName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productRangeAreaNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productRangeAreaName' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'homeFurnishingBusinessNumber',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'homeFurnishingBusinessName',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sourcedBatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'atExternalReference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalReference' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedShipment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sales' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdById' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saleTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdByUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CircularItemQuery, CircularItemQueryVariables>;
export const CircularItemImageUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'circularItemImageUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'imageContentType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ImageMIMEContentType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'circularItemImageUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageContentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'imageContentType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CircularItemImageUploadUrlQuery,
  CircularItemImageUploadUrlQueryVariables
>;
export const CircularItemsCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'circularItemsCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CircularItemOrderBy' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'registerDate' },
                value: { kind: 'EnumValue', value: 'DESC' },
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CircularItemFilter' },
          },
          defaultValue: {
            kind: 'ObjectValue',
            fields: [
              {
                kind: 'ObjectField',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'StringValue', value: 'pending', block: false },
              },
            ],
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpcId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'circularItemsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cpcId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpcId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'itemNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalProductName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimatedSalesPrice' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'estimatedSalesPriceCurrencyCode',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'regularItemSalesPriceInclTax',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'regularItemSalesPriceCurrencyCode',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retailItem' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'itemNumber' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessStructure',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productAreaName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productRangeAreaName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'homeFurnishingBusinessName',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mainProductImages',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quality' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'href' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'detailedMeasurements',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'typeName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'textMetric' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'initialGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sourcedBatch' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sgfLocation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bay' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modifiedDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registerDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labelImageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessStructure' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productAreaName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'href' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CircularItemsCollectionQuery,
  CircularItemsCollectionQueryVariables
>;
export const CircularProcessCenterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'circularProcessCenter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpcId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'circularProcessCenter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cpcId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpcId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CircularProcessCenterQuery,
  CircularProcessCenterQueryVariables
>;
export const ExternalReferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'externalReference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cpcId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shippedShipments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'circularItemReturnedFromReference',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalProductName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessStructure' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productAreaName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'productAreaNumber',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemsAtReference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalProductName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessStructure' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productAreaName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'productAreaNumber',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'circularItemIdsReturnedFromReference',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemIdsAtReference' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalReference' },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExternalReferenceQuery,
  ExternalReferenceQueryVariables
>;
export const ExternalReferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'externalReferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpcId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalReferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cpcId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpcId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalReference' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExternalReferencesQuery,
  ExternalReferencesQueryVariables
>;
export const GradesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'grades' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'circularItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'circularItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GradesQuery, GradesQueryVariables>;
export const ItemActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'itemActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'circularItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'circularItemId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'grade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ItemActivitiesQuery, ItemActivitiesQueryVariables>;
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cpcIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cpcs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'retailUnitCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'defaultCurrencyCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const RetailItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'retailItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'retailUnitCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'retailItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'itemNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'retailUnitCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'retailUnitCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'itemNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'globalProductName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regularItemSalesPriceInclTax' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'regularItemSalesPriceCurrencyCode',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainProductImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quality' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'href' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessStructure' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productAreaNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productAreaName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productRangeAreaNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productRangeAreaName' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'homeFurnishingBusinessNumber',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'homeFurnishingBusinessName',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RetailItemQuery, RetailItemQueryVariables>;
export const ShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'shipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalReference' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalReferenceId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cpcId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUpdatedById' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'longPalletCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'euroPalletCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentQuery, ShipmentQueryVariables>;
export const ShipmentWithCircularItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'shipmentWithCircularItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalReference' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalReferenceId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItemIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cpcId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastUpdatedById' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdById' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'carrier' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'longPalletCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'euroPalletCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalProductName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessStructure' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productAreaName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'productAreaNumber',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShipmentWithCircularItemsQuery,
  ShipmentWithCircularItemsQueryVariables
>;
export const ShipmentsCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'shipmentsCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpcId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ShipmentOrderBy' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ShipmentFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cpcId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpcId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'circularItemIds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalReference' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalReferenceId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedTimestamp' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdTimestamp' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShipmentsCollectionQuery,
  ShipmentsCollectionQueryVariables
>;
export const SourcedBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sourcedBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourcedBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationInMinutes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'circularItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalProductName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registerDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessStructure' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productAreaName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sourcingTimestamp' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'source' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourcedBatchQuery, SourcedBatchQueryVariables>;
export const SourcedBatchCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sourcedBatchCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpcId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'order' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SourcedBatchOrderBy' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SourcedBatchFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourcedBatchCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cpcId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpcId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skip' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'order' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'circularItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SourcedBatchCollectionQuery,
  SourcedBatchCollectionQueryVariables
>;
export const SourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cpcId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cpcId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cpcId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourcesQuery, SourcesQueryVariables>;
