import { MouseEventHandler } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FactoryLocationState, SortOrderType } from 'types';
import { invertOrder } from 'utils';

export const useSortClickHandler = (
  sortKey: string | undefined,
  sortOrder: SortOrderType,
  active: boolean
): MouseEventHandler | undefined => {
  const { search } = useLocation<FactoryLocationState>();
  const history = useHistory<FactoryLocationState>();

  const clickHandler: MouseEventHandler<HTMLAnchorElement> = () => {
    if (typeof sortKey !== undefined && sortKey) {
      const params = new URLSearchParams(search);
      params.set('sort', sortKey);
      params.set('order', active ? sortOrder : invertOrder(sortOrder));
      history.push({
        search: params.toString(),
        state: {},
      });
    }
  };

  return typeof sortKey === undefined || !sortKey ? undefined : clickHandler;
};
