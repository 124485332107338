import { useQuery } from "@apollo/client";

import { useAuth } from "hooks/use-auth/use-auth";
import { querySources } from "services";
import { QuerySourcesResult, QuerySourcesArgs } from "types";

export const useSources = (cpcId?: string) => {
  const { getCurrentCpc } = useAuth();
  cpcId = cpcId || getCurrentCpc().id;

  const { loading, error, data } = useQuery<QuerySourcesResult, QuerySourcesArgs>(
    querySources,
    {
      variables: {
        cpcId,
      },
    });

  return {
    loading,
    error,
    data
  }
}
