import { useQuery } from '@apollo/client';
import { FormikErrors, FormikHelpers, FormikTouched, useFormik } from 'formik';
import React, { FunctionComponent, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Accordion, { AccordionItem } from '@ingka/accordion';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Modal, { ModalBody, ModalFooter, Sheets } from '@ingka/modal';
import minusSmall from '@ingka/ssr-icon/paths/minus-small';
import plusCircle from '@ingka/ssr-icon/paths/plus-circle';
import Text from '@ingka/text';

import { GradeInput } from 'components';
import { Activity, ActivityFormValue } from 'components/_form/activity/activity';
import { queryActivityTypes } from 'services';
import {
  FactoryComponentProps,
  GradeValue,
  QueryActivityTypesResult,
} from 'types';

import styles from './add-activity-modal.module.scss';

export interface ActivityFormValues extends FactoryComponentProps {
  activities: ActivityFormValue[];
  grade?: GradeValue | undefined;
}

export interface AddActivityModalProps {
  visible: boolean;
  error?: boolean;
  onSubmit(
    values: ActivityFormValues,
    helpers: FormikHelpers<ActivityFormValues>
  ): void;
  onClose(): void;
  initialValues?: ActivityFormValues;
}
export const AddActivityModal: FunctionComponent<AddActivityModalProps> = memo(
  ({
    visible,
    error,
    onSubmit,
    onClose,
    initialValues = {
      activities: [{}],
      grade: undefined,
    },
  }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();
    const { loading } =
      useQuery<QueryActivityTypesResult>(queryActivityTypes);

    const validationSchema = Yup.object().shape({
      activities: Yup.array().of(Yup.object().shape({
        type: Yup.string().required(t('notifications.activity.selectType')),
        duration: Yup.number()
          .integer(t('notifications.activity.durationType'))
          .max(60, t('notifications.activity.durationMax', { max: 60 }))
          .required(t('notifications.activity.duration'))
          .typeError(t('notifications.activity.durationType')),
      }))
    });

    const {
      isSubmitting,
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      resetForm,
      submitForm,
    } = useFormik<ActivityFormValues>({
      initialValues,
      onSubmit,
      validationSchema,
    });

    const setGradeValue = (grade: GradeValue) =>
      setFieldValue('grade', grade, false);

    const onSubmitClick = (event: MouseEvent) => {
      event.preventDefault();
      submitForm();
    };

    const onAddAnotherActivityClicked = (e: React.MouseEvent<HTMLElement>) => {
      setFieldValue('activities', [...values.activities, {}]);
      e.stopPropagation();
    }

    const onRemoveButtonClicked = (e: React.MouseEvent<HTMLElement>, idx: number) => {
      // Need to create a new array in order to avoid mutating the state directly
      setFieldValue('activities', values.activities.filter((_, i) => i !== idx));
      e.stopPropagation();
    }

    const handleCloseBtn = () => onClose();

    const onModalClosed = () => resetForm();

    const onCancelClick = () => onClose();

    const footer = (
      <ModalFooter>
        <>
          <Button
            type="primary"
            fluid={true}
            loading={isSubmitting || loading}
            htmlType="submit"
            text={t('actions.save')}
            onClick={onSubmitClick}
            data-cy="add-activity-submit-btn"
          />
          <Button
            type="secondary"
            fluid={true}
            loading={isSubmitting || loading}
            text={t('actions.cancel')}
            onClick={onCancelClick}
            data-cy="add-activity-cancel-btn"
          />
        </>
      </ModalFooter>
    );
    return (
      <form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
        <Modal
          visible={visible}
          handleCloseBtn={handleCloseBtn}
          onModalClosed={onModalClosed}
          data-cy="add-activity-modal"
        >
          <Sheets size="small" footer={footer}>
            <ModalBody>
              {loading ? (
                <div>...</div>
              ) : (
                <>
                  <Text headingSize={'m'} className={styles.heading}>
                    {t('addActivity')}
                  </Text>
                  {values.activities.map((a, idx) => (
                    <>
                      <div className={styles.activityheader}>
                        <Text tagName='span' headingSize='s'>{`${t('activity')} #${idx + 1}`}</Text>
                        {idx > 0 && <Button onClick={(e) => onRemoveButtonClicked(e, idx)} className={styles.remove} ssrIcon={minusSmall} small={true} iconOnly xsmallIconOnly />}
                      </div>
                      <Activity
                        index={idx}
                        disabled={isSubmitting}
                        values={a}
                        errors={(errors.activities || [])[idx] as FormikErrors<ActivityFormValue>}
                        touched={(touched.activities instanceof Array<ActivityFormValue> ? touched.activities[idx] : touched.activities) as FormikTouched<ActivityFormValue>}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </>
                  ))}
                  <Button ssrIcon={plusCircle} data-cy="btn-add-another-activity" iconPosition='leading' type='tertiary' text={t('actions.addAnotherActivity')} onClick={onAddAnotherActivityClicked}/>
                  {error && (
                    <InlineMessage
                      variant="negative"
                      title={t('notifications.error')}
                      body={t('notifications.activity.addError')}
                      className={styles.error}
                    />
                  )}
                  <Accordion className={styles.field}>
                    <AccordionItem
                      id="AddActivity-grade-content"
                      title={t('changeGrade')}
                      data-cy="accordion-change-grade"
                    >
                      <>
                        <GradeInput
                          loading={isSubmitting}
                          setValue={setGradeValue}
                          value={values.grade}
                          empty={!initialValues.grade}
                        />
                      </>
                    </AccordionItem>
                  </Accordion>
                </>
              )}
            </ModalBody>
          </Sheets>
        </Modal>
      </form>
    );
  }
);
