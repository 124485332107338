import { useQuery } from '@apollo/client';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { globalLoadingAtom, globalAppErrorAtom } from 'atoms';
import { queryExternalReference } from 'services';
import {
  CircularItem,
  QueryExternalReferenceArgs,
  QueryExternalReferenceResult,
} from 'types';
import { groupBy } from 'utils';

import { ReferenceDetailsPageProps } from '../reference-details-page/reference-details-page';

export const useReferencePage = () => {
  const { id } = useParams<ReferenceDetailsPageProps>();
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const [totalItemsAtReference, setTotalItemsAtReference] = useState<number>(0);
  const [totalItemsReturnedFromReference, setTotalItemsReturnedFromReference] =
    useState<number>(0);
  const [itemsReturnedFromReference, setItemsReturnedFromReference] = useState<
    Record<string, CircularItem[]>
  >({});
  const [itemsAtReference, setItemsAtReference] = useState<
    Record<string, CircularItem[]>
  >({});

  const {
    loading,
    error,
    data,
    refetch: refetchExternalReference,
  } = useQuery<QueryExternalReferenceResult, QueryExternalReferenceArgs>(
    queryExternalReference,
    {
      variables: {
        id: id,
      },
    }
  );

  useEffect(() => {
    setGlobalLoading(loading);
  }, [loading, setGlobalLoading]);

  useEffect(() => {
    setGlobalAppError(error);
  }, [error, setGlobalAppError]);

  useEffect(() => {
    if (data?.externalReference) {
      setTotalItemsReturnedFromReference(
        data.externalReference.circularItemIdsReturnedFromReference.length
      );

      setTotalItemsAtReference(
        data.externalReference.circularItemIdsAtReference.length
      );

      if (data.externalReference.circularItemReturnedFromReference?.length) {
        setItemsReturnedFromReference(
          groupBy(
            data.externalReference.circularItemReturnedFromReference,
            (i) => i.businessStructure?.productAreaName || 'no range'
          )
        );
      }

      if (data.externalReference.circularItemsAtReference?.length) {
        setItemsAtReference(
          groupBy(
            data.externalReference.circularItemsAtReference,
            (i) => i.businessStructure?.productAreaName || 'no range'
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.externalReference,
    data?.externalReference.circularItemIdsReturnedFromReference,
  ]);

  return {
    externalReference: data?.externalReference,
    itemsAtReference,
    itemsReturnedFromReference,
    totalItemsAtReference,
    totalItemsReturnedFromReference,
    refetchExternalReference,
  };
};
