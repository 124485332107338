import { useMutation } from '@apollo/client';

import { mutationAddShipmentItems, mutationCreateShipment } from 'services';
import {
  CircularItem,
  MutationCreateShipmentResult,
  MutationCreateShipmentArgs,
  MutationAddShipmentItemsArgs,
} from 'types';
import { MutationAddShipmentItemsResult } from 'types/mutation-add-shipment-items-result';

export function useShipment() {
  const [createShipmentMutation] = useMutation<
    MutationCreateShipmentResult,
    MutationCreateShipmentArgs
  >(mutationCreateShipment);

  const [addShipmentItemsMutation] = useMutation<
    MutationAddShipmentItemsResult,
    MutationAddShipmentItemsArgs
  >(mutationAddShipmentItems);

  const createShipment = async (
    circularItemIds: string[],
    cpcId: string,
    externalRef: string,
    existingExternalReference: boolean
  ): Promise<MutationCreateShipmentResult | null | undefined> => {
    const { data } = await createShipmentMutation({
      variables: {
        input: {
          circularItemIds: circularItemIds,
          cpcId: cpcId,
          externalReference: externalRef,
          existingExternalReference: existingExternalReference,
        },
      },
      update(cache, { data }) {
        data?.createShipment.circularItems.forEach((item) => {
          cache.modify({
            id: cache.identify({
              __typename: 'CircularItem',
              id: item.id,
            } as CircularItem),

            fields: {
              status: () => item.status,
              assignedShipment: () => item.assignedShipment,
            },
          });
        });
      },
    });

    return data;
  };

  const addShipmentItems = async (
    circularItemIds: string[],
    shipmentId: string
  ) => {
    const { data } = await addShipmentItemsMutation({
      variables: {
        input: {
          circularItemIds,
          shipmentId,
        },
      },
      update(cache, { data }) {
        data?.addShipmentItems.circularItems.forEach((item) => {
          cache.modify({
            id: cache.identify({
              __typename: 'CircularItem',
              id: item.id,
            } as CircularItem),

            fields: {
              status: () => item.status,
              assignedShipment: () => item.assignedShipment,
            },
          });
        });
      },
    });

    return data;
  };

  return { createShipment, addShipmentItems };
}
