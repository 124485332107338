import { memo, FunctionComponent, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import Button, { ButtonProps } from '@ingka/button';
import ellipsesVertical from '@ingka/ssr-icon/paths/ellipses-vertical';

import { FactoryComponentProps } from 'types';

import styles from './menu-tiny.module.scss';

export interface MenuTinyProps extends FactoryComponentProps {
  menuItems: MenuItemProps[];
}

export interface MenuItemProps {
  ssrIcon: ButtonProps['ssrIcon'];
  text: string;
  onClick?: ButtonProps['onClick'];
  href?: string;
  enabled?: boolean;
  dataCy: string;
}

export const MenuTiny: FunctionComponent<MenuTinyProps> = memo(
  ({ menuItems }) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);

    const closeMenu = () => setVisible(false);
    const ref = useRef(null);
    useClickAway(ref, closeMenu);

    const onClick = () => {
      setVisible(!visible);
    };

    const menuItemMapper = (
      { ssrIcon, text, onClick, enabled, href, dataCy }: MenuItemProps,
      index: number
    ) =>
      enabled ? (
        <Button
          key={`MenuItem${index}${text}`}
          type="tertiary"
          ssrIcon={ssrIcon}
          text={text}
          small={true}
          onClick={onClick}
          href={href}
          role="menuitem"
          tabIndex={-1}
          className={styles.menuItem}
          data-cy={dataCy}
        />
      ) : null;

    return (
      <div className={styles.container} ref={ref}>
        <Button
          id="itemMenu"
          iconOnly={true}
          ssrIcon={ellipsesVertical}
          type="primary"
          text={visible ? t('bulk.hide') : t('bulk.show')}
          onClick={onClick}
          small={true}
          className={styles.button}
          aria-haspopup="true"
          aria-expanded={visible}
          aria-controls="itemMenu"
          role="button"
          data-cy={'tiny-menu-btn'}
        />
        {visible && (
          <div
            className={styles.menu}
            id="bulkActionMenu"
            aria-labelledby="bulkActionButton"
            role="menu"
          >
            {menuItems.map(menuItemMapper)}
          </div>
        )}
      </div>
    );
  }
);

export default MenuTiny;
