import { FunctionComponent, memo } from 'react';

import { FactoryComponentProps } from 'types';

import styles from './page-content.module.scss';

export const PageContent: FunctionComponent<FactoryComponentProps> = memo(
  ({ children, className = '', ...rest }) => (
    <div className={`${styles.container} ${className}`} data-cy="Page-content">
      {children}
    </div>
  )
);
