/* eslint-disable i18next/no-literal-string */
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AspectRatioImage from '@ingka/aspect-ratio-image';
import Checkbox from '@ingka/checkbox';
import Text from '@ingka/text';

import { HoverModal } from 'components';
import { CopyButton } from 'components/_buttons/copy-button/copy-button';
import { useCurrency } from 'hooks/use-currency/use-currency';
import { CircularItem } from 'types';
import {
  formatDate,
  formatDateShort,
  joinSgfWithDot,
  omitTypenames,
} from 'utils';

import styles from './item-list-row.module.scss';

export interface ItemListRowProps {
  item: CircularItem;
  setSelected(selectedState: boolean): void;
  selected?: boolean;
  highlighted?: boolean;
}

export const ItemListRow: FunctionComponent<ItemListRowProps> = memo(
  ({ item, selected, setSelected, highlighted, ...rest }: ItemListRowProps) => {
    const { t } = useTranslation();
    const { formatCurrency } = useCurrency();

    const onChange = () => {
      setSelected(!selected);
    };

    return (
      <tr className={`${styles.row} ${highlighted && styles.highlighted}`}>
        <td className={styles.selectCell}>
          <Checkbox
            id={`select-${item.id}`}
            value={item.id}
            onChange={onChange}
            checked={selected}
            data-cy={'item-list-row-checkbox'}
          />
        </td>
        <td>
          <div className={styles.idRow}>
            <Link to={`/inventory/${item.id}`} className={styles.idLink}>
              {item.id}
            </Link>
            <div className={styles.displayHoverModal}>
              <HoverModal
                className={styles.inventoryHoverModal}
              >
                <figure>
                  <AspectRatioImage
                    className={styles.hoverModalImage}
                    src={
                      item.retailItem?.mainProductImages?.[
                        item.retailItem.mainProductImages.length - 1
                      ]?.href ||
                      item?.images?.[0]?.image.href ||
                      ''
                    }
                    ratio="square"
                    data-cy="details-view-image"
                  />
                </figure>
                <dl className={styles.details}>
                  <dt>
                    <Text bodySize={'m'}>{t('name')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>{item.globalProductName || ''}</Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('description')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>{item.productDescription || ''}</Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('originalSalesPrice')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>
                      {formatCurrency(item.regularItemSalesPriceInclTax, item.cpc?.defaultCurrencyCode)}
                    </Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('estimatedSalesPrice.heading')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>
                      {formatCurrency(item.estimatedSalesPrice, item.cpc?.defaultCurrencyCode)}
                    </Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('pa.short')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>
                      {item.businessStructure?.productAreaName}
                    </Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('grade')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>{item.currentGrade?.value || ''}</Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('sgf.title')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>
                      {item.sgfLocation
                        ? joinSgfWithDot(omitTypenames(item.sgfLocation))
                        : ''}
                    </Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('modified')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>
                      {formatDateShort(item.modifiedDate)}
                    </Text>
                  </dd>
                  <dt>
                    <Text bodySize={'m'}>{t('created')}:</Text>
                  </dt>
                  <dd>
                    <Text bodySize={'m'}>
                      {formatDateShort(item.registerDate)}
                    </Text>
                  </dd>
                </dl>
              </HoverModal>
            </div>
              <CopyButton value={item.id} />
          </div>
        </td>

        <td>{item.globalProductName}</td>
        <td>{item.businessStructure?.productAreaName || ''}</td>
        <td>{item.productDescription || ''}</td>
        <td>{item.currentGrade?.value || ''}</td>
        <td className={styles.status} data-cy={item.id}>
          {item.status}
        </td>

        {/* <td>
          {item.sgfLocation
            ? joinSgfWithDot(omitTypenames(item.sgfLocation))
            : ''}
        </td> */}
        {/* <td>{formatDate(item.modifiedDate)}</td> */}
        <td>{formatDate(item.registerDate)}</td>
      </tr>
    );
  }
);
