import { useAtom } from 'jotai';

import { duplicateItemValuesAtom } from 'atoms';
import { DetailsFormInputs } from 'components';
import { CircularItem } from 'types';
import { formatItemNumberWithRegex, omitTypenames } from 'utils';

export function useDuplicateItem() {
  const [duplicateItemValues, setDuplicateItemValues] = useAtom(
    duplicateItemValuesAtom
  );

  const onDuplicateClick = (item: CircularItem) => {
    const obj: DetailsFormInputs = {
      productName: item.globalProductName ? item.globalProductName : '',
      productDescription: item.productDescription || '',
      itemNumber: formatItemNumberWithRegex(item.itemNumber || ''),

      estimatedSalesPrice: item.estimatedSalesPrice?.toString() || '',
      regularItemSalesPriceInclTax:
        item.regularItemSalesPriceInclTax?.toString(),
      businessStructure: item.businessStructure
        ? omitTypenames(item.businessStructure)
        : {
            productAreaNumber: '',
          },
    };
    setDuplicateItemValues(obj as DetailsFormInputs);
  };

  return {
    duplicateItemValues,
    setDuplicateItemValues,
    onDuplicateClick,
  };
}
