import { ImageMediaItem, ImageSources } from 'types';

export const imageSources = (
  mainProductImages: ImageMediaItem[],
  quality1x = 'S2',
  quality2x = 'S3'
): ImageSources => {
  const imageFinder1x = (item: ImageMediaItem) => item.quality === quality1x;
  const imageFinder2x = (item: ImageMediaItem) => item.quality === quality2x;
  const { href: src1x = '' } = mainProductImages.find(imageFinder1x) || {};
  const { href: src2x = '' } = mainProductImages.find(imageFinder2x) || {};
  return {
    src: src1x || src2x || '',
    srcSet:
      [src1x && `${src1x} 1x`, src2x && `${src2x} 2x`]
        .filter((item) => !!item)
        .join(', ') || '',
  };
};
