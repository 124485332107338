import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent, memo, useEffect } from 'react';

import {
  hasSelectAllAtom,
  selectedShipmentsAddAllAtom,
  selectedShipmentsAddAtom,
  selectedShipmentsAtom,
  selectedShipmentsClearAtom,
  selectedShipmentsRemoveAtom,
} from 'atoms';
import { BaseStockList } from 'components/base-stock-list/base-stock-list';
import {
  StockListHeaderItemProps,
  StockListHeaderItem,
} from 'components/stock-list-header-item/stock-list-header-item';
import { Shipment } from 'types';

import { ShipmentListRow } from './shipment-list-row/shipment-list-row';
export interface ShipmentListProps {
  shipments: Shipment[];
  headers: StockListHeaderItemProps[];
  highlighted?: string[];
}

export const ShipmentList: FunctionComponent<ShipmentListProps> = memo(
  ({ shipments = [], headers = [], highlighted = [] }) => {
    const selectedShipments = useAtomValue(selectedShipmentsAtom);
    const addSelectedShipment = useUpdateAtom(selectedShipmentsAddAtom);
    const setSelectedShipmentAddAllAtom = useUpdateAtom(
      selectedShipmentsAddAllAtom
    );
    const clearSelectedShipment = useUpdateAtom(selectedShipmentsClearAtom);
    const removeSelectedShipment = useUpdateAtom(selectedShipmentsRemoveAtom);
    const [hasSelectAll, setHasSelectAll] = useAtom(hasSelectAllAtom);

    const headerMapper = ({
      label,
      className,
      ...rest
    }: StockListHeaderItemProps) => {
      return (
        <StockListHeaderItem
          key={label}
          label={label}
          className={className}
          {...rest}
        />
      );
    };

    const rowMapper = (shipment: Shipment, index: number) => {
      const setSelected = (value: boolean) => {
        if (value) {
          addSelectedShipment(shipment);
        } else {
          removeSelectedShipment(shipment);
        }
      };

      const selected = selectedShipments.includes(shipment);

      return (
        <ShipmentListRow
          key={shipment.id}
          shipment={shipment}
          selected={selected}
          setSelected={setSelected}
          highlighted={highlighted.includes(shipment.id)}
        />
      );
    };

    const onSelectAllChange = () => {
      setHasSelectAll(!hasSelectAll);
    };

    useEffect(() => {
      hasSelectAll
        ? setSelectedShipmentAddAllAtom(shipments)
        : clearSelectedShipment();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelectAll]);

    return (
      <BaseStockList
        rows={shipments.map(rowMapper)}
        headers={headers.map(headerMapper)}
        onSelectAllChange={onSelectAllChange}
        hasSelectAll={hasSelectAll}
      />
    );
  }
);
