import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo } from 'react';
import { Link } from 'react-router-dom';

import Text from '@ingka/text';

import { userAtom } from 'atoms';
import { PrimaryMenu } from 'components';
import { FactoryComponentProps } from 'types';

import logo from '../../../assets/ikea-logo-colour.svg';

import styles from './header.module.scss';

export interface HeaderProps extends FactoryComponentProps {
  title?: string;
}

export const Header: FunctionComponent<HeaderProps> = memo(
  ({ title, children, className = '' }) => {
    const user = useAtomValue(userAtom);

    return (
      <header className={`${styles.container} ${className}`}>
        <section className={styles.branding}>
          <Link to="/">
            <img src={logo} alt="IKEA" className={styles.logo} loading="lazy" />
          </Link>
          <Text className={styles.title} headingSize="m">
            <Link to="/" className={styles.link}>
              {title}
            </Link>
          </Text>
        </section>
        <section className={styles.navigation}>
          {user && <PrimaryMenu />}
        </section>
        <section className={styles.user}>{children}</section>
      </header>
    );
  }
);

export default Header;
