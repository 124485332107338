import { useLazyQuery } from '@apollo/client';
import { useUpdateAtom } from 'jotai/utils';
import {
  FunctionComponent,
  MouseEventHandler,
  memo,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { globalAppErrorAtom, globalLoadingAtom } from 'atoms';
import { CreateItemLandingView } from 'components';
import { useAuth, useCheckMissingValues } from 'hooks';
import { queryRetailItem } from 'services';
import {
  FactoryLocationState,
  ImageMediaItem,
  ImageSources,
  QueryRetailItemArgs,
  QueryRetailItemResult,
  RetailItem,
} from 'types';
import { imageSources, nullFilter } from 'utils';

export const CreateItemLandingPage: FunctionComponent = memo(() => {
  const setGlobalLoading = useUpdateAtom(globalLoadingAtom);
  const setGlobalAppError = useUpdateAtom(globalAppErrorAtom);
  const { checkMissingValues } = useCheckMissingValues();
  const [sources, setSources] = useState<ImageSources | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [retailItem, setRetailItem] = useState<RetailItem | undefined>(
    undefined
  );
  const { search } = useLocation<FactoryLocationState>();
  const { getCurrentCpc } = useAuth();

  const [searchRetailItem, { loading, error, data }] = useLazyQuery<
    QueryRetailItemResult,
    QueryRetailItemArgs
  >(queryRetailItem);
  const { url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    setGlobalLoading(loading);
  }, [loading, setGlobalLoading]);

  useEffect(() => {
    setGlobalAppError(error);
  }, [error, setGlobalAppError]);

  useEffect(() => {
    const itemNumber = searchTerm.trim().replace(/\./g, '');
    if (itemNumber) {
      if (data?.retailItem && data.retailItem.itemNumber === itemNumber) {
        setRetailItem(data.retailItem);
      } else {
        searchRetailItem({
          variables: {
            itemNumber: searchTerm.trim().replace(/\./g, ''),
            retailUnitCode: getCurrentCpc().retailUnitCode,
          },
        });
      }
    } else {
      setRetailItem(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    setSearchTerm(params.get('searchTerm') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onSearch = (term: string = '') => {
    if (term !== searchTerm) {
      const params = new URLSearchParams({ searchTerm: term });
      history.push({
        search: params.toString(),
        state: {},
      });
    }
  };

  useEffect(() => {
    if (loading) {
      setSources(undefined);
    }
  }, [loading]);

  useEffect(() => {
    if (data?.retailItem) {
      checkMissingValues(data?.retailItem);
      setRetailItem(data?.retailItem);
      setSources(
        imageSources(
          (data.retailItem.mainProductImages || []).filter<ImageMediaItem>(
            nullFilter
          )
        )
      );
    } else {
      setRetailItem(undefined);
      setSources(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    if (data?.retailItem?.itemNumber) {
      history.push(`${url}/item/${retailItem?.itemNumber}`, {});
    }
  };

  const onCustomClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    history.push(`${url}/item`, {});
  };

  return (
    <CreateItemLandingView
      setSearchTerm={onSearch}
      onClick={onClick}
      onCustomClick={onCustomClick}
      retailItem={retailItem}
      images={sources}
      searchTerm={searchTerm}
    />
  );
});
