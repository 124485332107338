import { Children, FunctionComponent, memo } from 'react';

import { FactoryComponentProps } from 'types';

import styles from './columns.module.scss';

export const Columns: FunctionComponent<FactoryComponentProps> = memo(
  ({ children, className = '' }) =>
    children ? (
      <div
        className={`${
          Children.count(children) > 1 ? styles.grid : styles.single
        } ${className}`}
      >
        {children}
      </div>
    ) : null
);
