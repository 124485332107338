import { useTranslation } from 'react-i18next';

const secondsInAYear = 31536000;
const secondsInAMonth = 2628000; // Approximated to 1/12 year
const secondsInAWeek = 604800;
const secondsInADay = 86400;
const secondsInAnHour = 3600;
const secondsInAMinute = 60;

export const useDuration = () => {
  const { t } = useTranslation();

  const getDuration = (start: Date, end: Date): string => {
    const diff = Math.floor(
      (new Date(end).getTime() - new Date(start).getTime()) / 1000
    );

    const years = Math.floor(diff / secondsInAYear);
    if (years) {
      return t('times.year', { count: years });
    }

    const yearsRemainder = diff % secondsInAYear;
    const months = Math.floor(yearsRemainder / secondsInAMonth);
    if (months) {
      return t('times.month', { count: months });
    }

    const monthsRemainder = yearsRemainder % secondsInAMonth;
    const weeks = Math.floor(monthsRemainder / secondsInAWeek);
    if (weeks) {
      return t('times.week', { count: weeks });
    }

    const weeksRemainder = monthsRemainder % secondsInAWeek;
    const days = Math.floor(weeksRemainder / secondsInADay);
    if (days) {
      return t('times.day', { count: days });
    }

    const daysRemainder = weeksRemainder % secondsInADay;
    const hours = Math.floor(daysRemainder / secondsInAnHour);
    if (hours) {
      return t('times.hour', { count: hours });
    }

    const hoursRemainder = daysRemainder % secondsInAnHour;
    const minutes = Math.floor(hoursRemainder / secondsInAMinute);
    if (minutes) {
      return t('times.minute', { count: minutes });
    }

    const seconds = hoursRemainder % secondsInAMinute;
    if (seconds) {
      return t('times.second', { count: seconds });
    }

    return t('null');
  };

  return getDuration;
};
