import { FunctionComponent, memo, useEffect } from 'react';

import { StockListHeaderItem, StockListHeaderItemProps } from 'components';
import { BaseStockList } from 'components/base-stock-list/base-stock-list';
import { useSelect } from 'hooks';
import { CircularItem } from 'types';

import { ItemListRow } from './item-list-row/item-list-row';

export interface ItemListProps {
  items: CircularItem[];
  headers: StockListHeaderItemProps[];
  highlighted?: string[];
}

export const ItemList: FunctionComponent<ItemListProps> = memo(
  ({ items = [], headers = [], highlighted = [] }) => {
    const {
      hasSelectAll,
      setHasSelectAll,
      setSelectedItemsAddAllAtom,
      clearSelectedItems,
      selectedItems,
      toggleItemSelect,
    } = useSelect();

    const headerMapper = ({
      label,
      className,
      ...rest
    }: StockListHeaderItemProps) => {
      return (
        <StockListHeaderItem
          key={label}
          label={label}
          className={className}
          {...rest}
        />
      );
    };

    const rowMapper = (circularItem: CircularItem) => {
      const selected = selectedItems.includes(circularItem);
      const setSelected = () => {
        toggleItemSelect(!selected, circularItem);
      };

      return (
        <ItemListRow
          key={circularItem.id}
          item={circularItem}
          selected={selected}
          setSelected={setSelected}
          highlighted={highlighted.includes(circularItem.id)}
        />
      );
    };

    const onSelectAllChange = () => {
      setHasSelectAll(!hasSelectAll);
    };

    useEffect(() => {
      hasSelectAll ? setSelectedItemsAddAllAtom(items) : clearSelectedItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelectAll]);

    return (
      <BaseStockList
        rows={items.map(rowMapper)}
        headers={headers.map(headerMapper)}
        onSelectAllChange={onSelectAllChange}
        hasSelectAll={hasSelectAll}
      />
    );
  }
);
