import { FunctionComponent, memo } from 'react';

import Text from '@ingka/text';

import { useStatus } from 'components';

import { Status } from '../../../types';

import styles from './status-text.module.scss';

export interface StatusTextProps {
  value?: any;
}

export const StatusText: FunctionComponent<StatusTextProps> = memo(
  ({ value }) => {
    const { findStatusByValue } = useStatus();
    const getStatusText = (value: Status) => {
      const status = findStatusByValue(value);
      return status?.title;
    };

    return !value ? null : (
      <Text
        headingSize="xs"
        className={`${styles[value.type]} ${styles.statusText}`}
      >
        {getStatusText(value.value)}
      </Text>
    );
  }
);
