import { FunctionComponent, memo } from 'react';

import { FactoryComponentProps } from 'types';

import styles from './page-header.module.scss';

export interface PageHeaderProps extends FactoryComponentProps {
  divider?: boolean;
}

export const PageHeader: FunctionComponent<PageHeaderProps> = memo(
  ({ children, className = '', divider = false }) => (
    <div
      className={`${styles.container} ${
        divider && styles.divider
      } ${className}`}
    >
      {children}
    </div>
  )
);
