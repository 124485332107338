import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Checkbox from '@ingka/checkbox';
import Table, { TableBody, TableHeader } from '@ingka/table';

import { DialoguePrompt } from 'components';
import { useSelect } from 'hooks';
import { CircularItem } from 'types';

import styles from './grouped-items-view.module.scss';
import { ShipmentItemRowEdit } from './shipment-item-row-edit/shipment-item-row-edit';

export interface GroupedItemsEditViewProps {
  editPage?: boolean;
  groupedItems: Record<string, CircularItem[]>;
  totalItems: number;
  removeShipmentItems?: (itemIds: string[]) => void;
  shipped: boolean;
}

export const GroupedItemsView: FunctionComponent<GroupedItemsEditViewProps> = ({
  editPage,
  groupedItems,
  totalItems,
  removeShipmentItems,
  shipped,
}) => {
  const {
    selectedItems,
    hasSelectAll,
    setHasSelectAll,
    setSelectedItemsAddAllAtom,
    clearSelectedItems,
  } = useSelect();
  const [displayDialogue, setDisplayDialogue] = useState<boolean>(false);
  const [itemIdToRemove, setItemIdToRemove] = useState<string | null>();
  const groupedItemsArray = Object.entries(groupedItems).map((record) => {
    return { key: record[0], items: record[1] };
  });

  const onSelectAllChange = () => {
    setHasSelectAll(!hasSelectAll);
  };

  const handleSelectAll = () => {
    const items = groupedItemsArray.reduce(
      (r, obj) => r.concat(obj.items as any),
      []
    );

    setSelectedItemsAddAllAtom(items);
  };

  useEffect(() => {
    hasSelectAll ? handleSelectAll() : clearSelectedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectAll]);

  const handleRemoveShipmentItem = (id: string) => {
    setItemIdToRemove(id);
    setDisplayDialogue(true);
  };

  const handleDialogueResponse = (value: boolean) => {
    if (value && removeShipmentItems && itemIdToRemove) {
      removeShipmentItems([itemIdToRemove]);
      setItemIdToRemove(null);
    }
  };

  const circularItemMapper = (item: CircularItem) => {
    const selected = selectedItems.includes(item);

    return (
      <ShipmentItemRowEdit
        circularItem={item}
        handleRemoveShipmentItem={handleRemoveShipmentItem}
        selected={selected}
        shipped={shipped}
      />
    );
  };

  return (
    <>
      <DialoguePrompt
        positiveBtnText={t('actions.confirmDelete')}
        negativeBtnText={t('actions.customCancel')}
        promptTitle={t('dialogues.delete.title', {
          type: t('item'),
        })}
        promptBody={t('dialogues.delete.bodySingle', {
          type: t('item'),
        })}
        displayDialogue={displayDialogue}
        setDisplayDialogue={setDisplayDialogue}
        handleDialogueResponse={handleDialogueResponse}
      />
      {!!groupedItemsArray.length && (
        <Table className={styles.table}>
          <TableHeader className={styles.tableHead}>
            <tr>
              {editPage && <th></th>}
              <th>
                <h2>{t('shipment.products')}</h2>
              </th>
              <th colSpan={3} style={{ textAlign: 'right' }}>
                <h2>
                  {totalItems} {t('shipment.items')}
                </h2>
              </th>
            </tr>
            {editPage && (
              <tr className={styles.tableHeader}>
                <th
                  className={`${styles.selectAllHeader} ${styles.borderLess}`}
                >
                  <Checkbox
                    className={styles.selectAll}
                    id={'select-all-checkbox'}
                    onChange={onSelectAllChange}
                    checked={hasSelectAll}
                    value={''}
                  />
                </th>
                <th
                  className={`${styles.selectAllHeader} ${styles.borderLess}`}
                >
                  {t('selectAllItems')}
                </th>
              </tr>
            )}
          </TableHeader>
          {groupedItemsArray.map((range) => {
            return (
              <React.Fragment key={`groupedItems-${range.key}`}>
                <TableHeader className={styles.tableHead}>
                  <tr>
                    <th className={styles.borderLess} colSpan={3}>
                      <h3>{range.key}</h3>
                    </th>
                    {editPage && <th className={styles.borderLess} />}
                    <th
                      className={styles.borderLess}
                      colSpan={2}
                      style={{ textAlign: 'right' }}
                    >
                      <h3>
                        {range.items.length} {t('shipment.items')}
                      </h3>
                    </th>
                  </tr>
                </TableHeader>
                <TableHeader title={range.key} className={styles.tableHeader}>
                  <tr>
                    {editPage && (
                      <th
                        className={`${styles.checkbox} ${styles.borderLess}`}
                      />
                    )}
                    <th>{t('id')}</th>
                    <th className={styles.productName}>{t('productName')}</th>
                    {!shipped && <th>{t('status')}</th>}
                    <th>{t('grade')}</th>
                  </tr>
                </TableHeader>
                <TableBody className={styles.tableBody}>
                  {range.items.map((item) => (
                    <React.Fragment key={`Item-${item.id}`}>
                      {editPage ? (
                        circularItemMapper(item)
                      ) : (
                        <tr>
                          <td>
                            <Link
                              to={`/inventory/${item.id}`}
                              className={styles.idLink}
                            >
                              {item.id}
                            </Link>
                          </td>
                          <td>{item.globalProductName}</td>
                          {!shipped && <td>{item.status}</td>}
                          <td>{item.currentGrade?.value}</td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </React.Fragment>
            );
          })}
        </Table>
      )}
    </>
  );
};
