import { t } from 'i18next';
import React, { FunctionComponent } from 'react';

import Checkbox from '@ingka/checkbox';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';

import { useSelect } from 'hooks';
import { CircularItem } from 'types';

import styles from './reference-item-list.module.scss';
import { ReferenceItemRow } from './reference-item-row/reference-item-row';

export interface ReferenceItemListProps {
  editPage?: boolean;
  items: Record<string, CircularItem[]>;
  totalItemsCount: number;
  externalReference: string;
  fromReferenceList?: boolean;
  dataCy: string;
}

export const ReferenceItemList: FunctionComponent<ReferenceItemListProps> = ({
  editPage,
  fromReferenceList,
  items,
  totalItemsCount,
  externalReference,
  dataCy,
}) => {
  const {
    selectedItems,
    hasSelectAll,
    setHasSelectAll,
    setSelectedItemsAddAllAtom,
    clearSelectedItems,
  } = useSelect();

  const groupedItemsArray = Object.entries(items).map((record) => {
    return { key: record[0], items: record[1] };
  });

  const circularItemMapper = (item: CircularItem, i: number) => {
    const selected = selectedItems.includes(item);

    return (
      <ReferenceItemRow
        key={`reference-item-row-${i}`}
        editPage={editPage}
        fromReferenceList={fromReferenceList}
        circularItem={item}
        selected={selected}
        dataCy={dataCy}
      />
    );
  };

  const onSelectAllChange = () => {
    const selected = !hasSelectAll;
    if (selected) {
      const items = groupedItemsArray.reduce(
        (r, obj) => r.concat(obj.items as any),
        []
      );
      setSelectedItemsAddAllAtom(items);
    } else {
      clearSelectedItems();
    }
    setHasSelectAll(selected);
  };

  return (
    <div>
      {!!groupedItemsArray.length && (
        <>
          <section className={styles.headerWrapper}>
            <div className={styles.headerTitle}>
              {fromReferenceList ? (
                <>
                  <h3>{`${t('returnedFrom')} ${externalReference}`}</h3>
                  <h3>
                    {totalItemsCount} {t('shipment.items')}
                  </h3>
                </>
              ) : (
                <>
                  <h2>{`${t('at')} ${externalReference}`}</h2>
                  <h2>
                    {totalItemsCount} {t('shipment.items')}
                  </h2>
                </>
              )}
            </div>
            {!fromReferenceList && editPage && (
              <div className={styles.selectAll}>
                <Checkbox
                  className={styles.selectAllCheckbox}
                  id={'select-all-checkbox'}
                  onChange={onSelectAllChange}
                  checked={hasSelectAll}
                  value={''}
                />
                <Text> {t('selectAllItems')}</Text>
              </div>
            )}
          </section>
          <section
            className={`${styles.tableWrapper} ${
              fromReferenceList
                ? styles.fromReferenceList
                : styles.atReferenceList
            }`}
          >
            <Table className={styles.table}>
              {groupedItemsArray.map((range, i) => {
                return (
                  <React.Fragment key={`groupedItems-${range.key}-${i}`}>
                    <TableHeader className={styles.tableHead}>
                      <tr>
                        <th className={styles.borderLess} colSpan={3}>
                          <h3>{range.key}</h3>
                        </th>
                        {editPage && <th className={styles.borderLess} />}
                        <th
                          className={styles.borderLess}
                          colSpan={2}
                          style={{ textAlign: 'right' }}
                        >
                          <h3>
                            {range.items.length} {t('shipment.items')}
                          </h3>
                        </th>
                      </tr>
                    </TableHeader>
                    <TableHeader
                      title={range.key}
                      className={styles.tableHeader}
                    >
                      <tr>
                        {editPage && (
                          <th
                            className={`${styles.checkbox} ${styles.borderLess}`}
                          />
                        )}
                        <th className={styles.colProductId}>
                          {t('productId')}
                        </th>
                        <th className={styles.colProductName}>
                          {t('productName')}
                        </th>
                        <th className={styles.colStatus}>{t('status')}</th>
                      </tr>
                    </TableHeader>
                    <TableBody className={styles.tableBody}>
                      {range.items.map((item, i) =>
                        circularItemMapper(item, i)
                      )}
                    </TableBody>
                  </React.Fragment>
                );
              })}
            </Table>
          </section>
        </>
      )}
    </div>
  );
};
