import {
  memo,
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import Button from '@ingka/button';
import cross from '@ingka/ssr-icon/paths/cross';
import plus from '@ingka/ssr-icon/paths/plus';
import trashCan from '@ingka/ssr-icon/paths/trash-can';

import { DialoguePrompt } from 'components';
import { useSelectShipment } from 'hooks';
import { FactoryComponentProps, Shipment } from 'types';
import { BulkMenuItem } from 'types';
import { nullFilter } from 'utils';

import styles from './shipment-bulk-menu.module.scss';

export interface ShipmentBulkMenuProps extends FactoryComponentProps {
  menuItemKeys: 'remove'[];
  removeShipments: (shipments: Shipment[]) => void;
}

export const ShipmentBulkMenu: FunctionComponent<ShipmentBulkMenuProps> = memo(
  ({ menuItemKeys, removeShipments }) => {
    const { t } = useTranslation();
    const { selectedShipments, selectShipmentsLength } = useSelectShipment();
    const [visible, setVisible] = useState<boolean>(false);
    const [displayDialogue, setDisplayDialogue] = useState<boolean>(false);

    const closeMenu = () => setVisible(false);
    const ref = useRef(null);
    useClickAway(ref, closeMenu);

    const onClick = () => {
      setVisible(!visible);
    };

    const onMenuItemClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      closeMenu();
    };

    const onRemoveShipmentClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setDisplayDialogue(true);
      closeMenu();
    };

    useEffect(() => () => closeMenu(), []);

    useEffect(() => {
      if (!selectShipmentsLength) {
        closeMenu();
      }
    }, [selectShipmentsLength]);

    const menuItems: BulkMenuItem[] = [
      {
        ssrIcon: trashCan,
        text: t('bulk.menu.remove'),
        onClick: onRemoveShipmentClick,
        enabled: menuItemKeys.includes('remove'),
        dataCy: 'remove-bulk-button',
      },
    ];

    const menuItemMapper = (
      {
        ssrIcon,
        text,
        onClick = onMenuItemClick,
        enabled,
        dataCy,
      }: BulkMenuItem,
      index: number
    ) =>
      enabled ? (
        <Button
          key={`MenuItem${index}${text}`}
          type="tertiary"
          ssrIcon={ssrIcon}
          text={text}
          small={true}
          onClick={onClick}
          role="menuitem"
          tabIndex={-1}
          className={styles.menuItem}
          data-cy={dataCy}
        />
      ) : null;

    const onRemoveItemsDialogueClose = (value: boolean) =>
      setDisplayDialogue(value);

    const handleDialogueResponse = (value: boolean) => {
      if (value) {
        removeShipments(selectedShipments);
      }
    };

    return selectShipmentsLength ? (
      <div className={styles.container} ref={ref}>
        <Button
          id="bulkActionButton"
          iconOnly={true}
          ssrIcon={visible ? cross : plus}
          type="primary"
          text={visible ? t('bulk.hide') : t('bulk.show')}
          onClick={onClick}
          className={styles.button}
          aria-haspopup="true"
          aria-expanded={visible}
          aria-controls="bulkActionMenu"
          role="button"
          data-cy={'bulk-menu-btn'}
        />
        {visible && (
          <div
            className={styles.menu}
            id="bulkActionMenu"
            aria-labelledby="bulkActionButton"
            role="menu"
          >
            {menuItems.filter(nullFilter).map(menuItemMapper)}
          </div>
        )}
        <DialoguePrompt
          positiveBtnText={t('actions.confirmDelete')}
          negativeBtnText={t('actions.cancel')}
          promptTitle={t('dialogues.delete.title', {
            type: t('shipment.shipments').toLocaleLowerCase(),
          })}
          promptBody={t('dialogues.delete.bodyMultiple', {
            type: t('shipment.shipments').toLocaleLowerCase(),
          })}
          displayDialogue={displayDialogue}
          setDisplayDialogue={onRemoveItemsDialogueClose}
          handleDialogueResponse={handleDialogueResponse}
        />
      </div>
    ) : null;
  }
);
