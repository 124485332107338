import { LocationDescriptor } from 'history';
import { useAtomValue } from 'jotai/utils';
import { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';

import Button from '@ingka/button';
import IconArrowBack from '@ingka/ssr-icon/paths/arrow-left';
import IconArrowForward from '@ingka/ssr-icon/paths/arrow-right';
import Text from '@ingka/text';

import { globalLoadingAtom } from 'atoms';
import { usePagination } from 'hooks';
import { FactoryLocationState, PaginationItem } from 'types';
import { pagination } from 'utils';

import styles from './pagination.module.scss';

export interface PaginationProps {
  hide?: boolean;
}

export const Pagination: FunctionComponent<PaginationProps> = memo(
  ({ hide }) => {
    const { t } = useTranslation();
    const globalLoading = useAtomValue(globalLoadingAtom);
    const { page, pages, setPage } = usePagination();
    const history = useHistory();
    const [pageLinks, setPageLinks] = useState<PaginationItem[]>([1]);

    useEffect(() => {
      setPageLinks(pagination(page, pages));
    }, [page, pages]);

    const paginationMapper = (item: PaginationItem, index: number) => {
      if (item === '...') {
        return (
          <Text tagName="span" key={`Pagination-${index}`}>
            {item}
          </Text>
        );
      } else {
        const isActive = () => page === item;
        const params = new URLSearchParams(history.location.search);
        params.set('page', `${item}`);
        const to: LocationDescriptor<FactoryLocationState> = {
          pathname: history.location.pathname,
          search: params.toString(),
        };
        return (
          <NavLink
            key={`Pagination-${index}`}
            className={styles.page}
            activeClassName={styles.active}
            isActive={isActive}
            to={to}
          >
            <Text tagName="span">{item}</Text>
          </NavLink>
        );
      }
    };

    return hide ? null : (
      <div className={styles.container}>
        <Button
          type="primary"
          iconOnly={true}
          ssrIcon={IconArrowBack}
          onClick={() => setPage(page - 1)}
          text={t('actions.prev')}
          small={true}
          className={styles.prev}
          disabled={globalLoading}
        />
        <div className={styles.pages}>{pageLinks.map(paginationMapper)}</div>
        <Button
          type="primary"
          iconOnly={true}
          ssrIcon={IconArrowForward}
          onClick={() => setPage(page + 1)}
          text={t('actions.next')}
          small={true}
          className={styles.next}
          disabled={globalLoading}
        />
      </div>
    );
  }
);
