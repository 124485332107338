import { FormikHelpers } from 'formik';
import { useAtomValue } from 'jotai';
import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';

import { globalLoadingAtom } from 'atoms';
import {
  Back,
  PageContent,
  Row,
  PageHeader,
  SourcedBatchDetails,
} from 'components';
import { SourcedBatchItemList } from 'components/sourced-batch-item-list/sourced-batch-item-list';
import { SourcedBatch } from 'types';

import {
  SourcedBatchDetailsForm,
  UpdateSourcedBatchFormValues,
} from '../sourced-batch-details-form/sourced-batch-details-form';

import styles from './sourced-batch-details-view.module.scss';

export interface SourcedBatchDetailsViewProps {
  sourcedBatch: SourcedBatch;
  onUpdateSourcedBatch: (
    values: UpdateSourcedBatchFormValues,
    formikHelpers: FormikHelpers<UpdateSourcedBatchFormValues>
  ) => void | Promise<any>;
}

export const SourcedBatchDetailsView: FunctionComponent<SourcedBatchDetailsViewProps> =
  memo(({ sourcedBatch, onUpdateSourcedBatch }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const globalLoading = useAtomValue(globalLoadingAtom);

    const tabs = [
      <Tab
        key="tab-1"
        text={t('sourcedBatch.productsNum', {
          num: sourcedBatch.circularItems.length,
        })}
        tabPanelId="tab_1"
      />,
      <Tab
        key="tab-2"
        text={t('sourcedBatch.batchDetails')}
        tabPanelId="tab_2"
      />,
    ];

    const tabPanels = [
      <TabPanel key="batch-details" tabPanelId="tab_1">
        <section className={styles.detailsWrapper}>
          <Text
            tagName="h1"
            headingSize="m"
            className={styles.detailsId}
            data-cy={'batch-id'}
          >
            {sourcedBatch.id}
          </Text>
          <SourcedBatchDetails sourcedBatch={sourcedBatch} />
        </section>
        <SourcedBatchItemList items={sourcedBatch.circularItems} />
      </TabPanel>,
      <TabPanel key="batch-form" tabPanelId="tab_2">
        <SourcedBatchDetailsForm
          sourcedBatch={sourcedBatch}
          onSubmit={onUpdateSourcedBatch}
        />
      </TabPanel>,
    ];

    return (
      <PageContent>
        <PageHeader divider={true}>
          <Row position="start" className={styles.start}>
            <Back
              label={t('actions.back.root')}
              onClick={() => history.goBack()}
              dataCy={'Back-Button'}
            />
            <Text tagName="h1" headingSize="m" className={styles.title}>
              {`${t('batch')}: ${sourcedBatch.id}`}
            </Text>
          </Row>
        </PageHeader>
        {!globalLoading && (
          <Tabs
            className={styles.tabs}
            tabs={tabs}
            tabPanels={tabPanels}
            defaultActiveTab={'tab_1'}
          />
        )}
      </PageContent>
    );
  });
