import { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Choice, { ChoiceItem } from '@ingka/choice';

import { FactoryComponentProps, GradeValue, Maybe } from 'types';

import styles from './grade-input.module.scss';

interface GradeOption {
  id: string;
  title: string;
  caption: string;
  value: GradeValue;
}

export interface GradeInputProps extends FactoryComponentProps {
  key?: string;
  empty?: boolean;
  loading?: boolean;
  setValue(value: GradeValue | undefined): void;
  value?: GradeValue;
}

export const GradeInput: FunctionComponent<GradeInputProps> = memo(
  ({ loading, setValue, value, className = '' }) => {
    const { t } = useTranslation();

    const grades: GradeOption[] = [
      {
        id: 'grade-New',
        title: t('grades.new.label'),
        caption: t('grades.new.description'),
        value: 'New',
      },
      {
        id: 'grade-A',
        title: t('grades.a.label'),
        caption: t('grades.a.description'),
        value: 'A',
      },
      {
        id: 'grade-B',
        title: t('grades.b.label'),
        caption: t('grades.b.description'),
        value: 'B',
      },
      {
        id: 'grade-C',
        title: t('grades.c.label'),
        caption: t('grades.c.description'),
        value: 'C',
      },
      {
        id: 'grade-Recycle',
        title: t('grades.r.label'),
        caption: t('grades.r.description'),
        value: 'Recycle',
      },
    ];

    const defaultFinder = (grade: GradeOption) => value === grade.value;

    const preselectedIndex = grades.findIndex(defaultFinder);

    const onGradeSelect = (id: string) => {
      const gradeFinder = ({ id: itemId }: GradeOption): boolean =>
        itemId === id;
      const grade: Maybe<GradeValue> = grades.find(gradeFinder)?.value || null;
      if (grade) {
        setValue(grade);
      }
    };

    const gradesMapper = ({ id, title, caption }: GradeOption) => {
      return (
        <ChoiceItem
          key={id}
          className={styles.item}
          title={title}
          caption={caption}
          disabled={loading}
          id={id}
          data-cy={`blabla-${title}`}
        />
      );
    };

    return (
      <Choice
        className={`${styles.choice} ${className}`}
        onSelect={onGradeSelect}
        preselectedIndex={preselectedIndex}
        data-cy="grade-input"
        deselectable
      >
        {grades.map(gradesMapper)}
      </Choice>
    );
  }
);
