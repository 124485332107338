import { useAtomValue } from 'jotai/utils';
import {
  ChangeEvent,
  FunctionComponent,
  MouseEventHandler,
  memo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import AspectRatioImage from '@ingka/aspect-ratio-image';
import Button from '@ingka/button';
import Search from '@ingka/search';
import Text from '@ingka/text';

// This needs to be here to avoid bug with image not displayed
import '@ingka/aspect-ratio-image/style.scss';

import { globalLoadingAtom } from 'atoms';
import { Loader, PageContent } from 'components';
import { ImageSources, RetailItem, SearchData } from 'types';
import { formatItemNumberWithRegex } from 'utils';

import styles from './create-item-landing-view.module.scss';

export interface CreateItemLandingViewProps {
  retailItem?: RetailItem;
  onClick: MouseEventHandler;
  onCustomClick: MouseEventHandler;
  images?: ImageSources;
  setSearchTerm(term?: string): void;
  searchTerm: string;
}

export const CreateItemLandingView: FunctionComponent<CreateItemLandingViewProps> =
  memo(
    ({
      setSearchTerm,
      onClick,
      onCustomClick,
      retailItem,
      images,
      searchTerm = '',
      ...rest
    }) => {
      const [searchInput, setSearchInput] = useState<string>(searchTerm);
      const globalLoading = useAtomValue(globalLoadingAtom);
      const { t } = useTranslation();

      const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(formatItemNumberWithRegex(event.target.value));
      };

      const onSearchSubmit = (_e: Event, { value = '' }: SearchData) => {
        setSearchTerm(value);
      };

      const onSearchClear = () => {
        setSearchInput('');
        setSearchTerm('');
      };

      return (
        <PageContent>
          <Text tagName="h1" headingSize="m">
            {t('actions.addProductMultiple')}
          </Text>
          <div className={styles.columns}>
            <div className={styles.column}>
              <Search
                id="search"
                ariaLabel={t('search')}
                placeholder={t('search')}
                clearBtnText={t('clear')}
                disabled={globalLoading}
                onSearch={onSearchSubmit}
                onChange={onSearchChange}
                onClear={onSearchClear}
                value={searchInput}
                className={styles.search}
                autoFocus
                data-cy="create-item-search-input"
              />
              <Text bodySize="s" tagName="p" className={styles.help}>
                {t('notifications.search')}
              </Text>
            </div>
            <div className={styles.column}>
              <Button
                className={styles.custom}
                href="/create/item"
                onClick={onCustomClick}
              >
                {t('actions.addCustomProduct')}
              </Button>
            </div>
          </div>
          {globalLoading && (
            <div className={styles.loader}>
              <Loader>{t('notifications.loaders.search')}</Loader>
            </div>
          )}
          {retailItem && (
            <div className={styles.result}>
              <AspectRatioImage
                src={images?.src || ''}
                srcSet={images?.srcSet || ''}
                className={styles.image}
                alt={retailItem.itemNumber}
              />
              <div className={styles.info}>
                <Text headingSize="s">{retailItem.globalProductName}</Text>
                <Text className={styles.itemNumber} bodySize="s">
                  {formatItemNumberWithRegex(retailItem.itemNumber)}
                </Text>
                <Text className={styles.description}>
                  {retailItem.productDescription}
                </Text>
              </div>
              <Button
                type="primary"
                className={styles.button}
                onClick={onClick}
              >
                {t('actions.addProduct')}
              </Button>
            </div>
          )}
        </PageContent>
      );
    }
  );
